/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPlanInvoice } from '../servermodel';
// @ts-ignore
import { InlineObject } from '../servermodel';
// @ts-ignore
import { InlineResponse20012 } from '../servermodel';
// @ts-ignore
import { InvoiceTransaction } from '../servermodel';
// @ts-ignore
import { PlanInvoice } from '../servermodel';
/**
 * PlanInvoicesApi - axios parameter creator
 * @export
 */
export const PlanInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesAdminCreate: async (data: AdminPlanInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planInvoicesAdminCreate', 'data', data)
            const localVarPath = `/plan-invoices/admin_create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesCreate: async (data: PlanInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planInvoicesCreate', 'data', data)
            const localVarPath = `/plan-invoices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planInvoicesDelete', 'id', id)
            const localVarPath = `/plan-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InlineObject} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesDeleteDiscountCode: async (id: string, data: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planInvoicesDeleteDiscountCode', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planInvoicesDeleteDiscountCode', 'data', data)
            const localVarPath = `/plan-invoices/{id}/delete_discount_code/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [isPaid] 
         * @param {string} [isExpired] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesList: async (fromDaysBefore?: number, isActive?: string, isPaid?: string, isExpired?: string, order?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/plan-invoices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (isPaid !== undefined) {
                localVarQueryParameter['is_paid'] = isPaid;
            }

            if (isExpired !== undefined) {
                localVarQueryParameter['is_expired'] = isExpired;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesPartialUpdate: async (id: string, data: AdminPlanInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planInvoicesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planInvoicesPartialUpdate', 'data', data)
            const localVarPath = `/plan-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceTransaction} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesPay: async (id: string, data: InvoiceTransaction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planInvoicesPay', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planInvoicesPay', 'data', data)
            const localVarPath = `/plan-invoices/{id}/pay/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planInvoicesRead', 'id', id)
            const localVarPath = `/plan-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesUpdate: async (id: string, data: AdminPlanInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planInvoicesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planInvoicesUpdate', 'data', data)
            const localVarPath = `/plan-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanInvoicesApi - functional programming interface
 * @export
 */
export const PlanInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesAdminCreate(data: AdminPlanInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesAdminCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesCreate(data: PlanInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {InlineObject} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesDeleteDiscountCode(id: string, data: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesDeleteDiscountCode(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [isPaid] 
         * @param {string} [isExpired] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesList(fromDaysBefore?: number, isActive?: string, isPaid?: string, isExpired?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesList(fromDaysBefore, isActive, isPaid, isExpired, order, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesPartialUpdate(id: string, data: AdminPlanInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceTransaction} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesPay(id: string, data: InvoiceTransaction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesPay(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInvoicesUpdate(id: string, data: AdminPlanInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPlanInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInvoicesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanInvoicesApi - factory interface
 * @export
 */
export const PlanInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesAdminCreate(data: AdminPlanInvoice, options?: any): AxiosPromise<AdminPlanInvoice> {
            return localVarFp.planInvoicesAdminCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesCreate(data: PlanInvoice, options?: any): AxiosPromise<PlanInvoice> {
            return localVarFp.planInvoicesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.planInvoicesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InlineObject} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesDeleteDiscountCode(id: string, data: InlineObject, options?: any): AxiosPromise<InlineObject> {
            return localVarFp.planInvoicesDeleteDiscountCode(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [isPaid] 
         * @param {string} [isExpired] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesList(fromDaysBefore?: number, isActive?: string, isPaid?: string, isExpired?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.planInvoicesList(fromDaysBefore, isActive, isPaid, isExpired, order, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesPartialUpdate(id: string, data: AdminPlanInvoice, options?: any): AxiosPromise<AdminPlanInvoice> {
            return localVarFp.planInvoicesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceTransaction} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesPay(id: string, data: InvoiceTransaction, options?: any): AxiosPromise<InvoiceTransaction> {
            return localVarFp.planInvoicesPay(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesRead(id: string, options?: any): AxiosPromise<PlanInvoice> {
            return localVarFp.planInvoicesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminPlanInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInvoicesUpdate(id: string, data: AdminPlanInvoice, options?: any): AxiosPromise<AdminPlanInvoice> {
            return localVarFp.planInvoicesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for planInvoicesAdminCreate operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesAdminCreateRequest
 */
export interface PlanInvoicesApiPlanInvoicesAdminCreateRequest {
    /**
     * 
     * @type {AdminPlanInvoice}
     * @memberof PlanInvoicesApiPlanInvoicesAdminCreate
     */
    readonly data: AdminPlanInvoice
}

/**
 * Request parameters for planInvoicesCreate operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesCreateRequest
 */
export interface PlanInvoicesApiPlanInvoicesCreateRequest {
    /**
     * 
     * @type {PlanInvoice}
     * @memberof PlanInvoicesApiPlanInvoicesCreate
     */
    readonly data: PlanInvoice
}

/**
 * Request parameters for planInvoicesDelete operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesDeleteRequest
 */
export interface PlanInvoicesApiPlanInvoicesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesDelete
     */
    readonly id: string
}

/**
 * Request parameters for planInvoicesDeleteDiscountCode operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesDeleteDiscountCodeRequest
 */
export interface PlanInvoicesApiPlanInvoicesDeleteDiscountCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesDeleteDiscountCode
     */
    readonly id: string

    /**
     * 
     * @type {InlineObject}
     * @memberof PlanInvoicesApiPlanInvoicesDeleteDiscountCode
     */
    readonly data: InlineObject
}

/**
 * Request parameters for planInvoicesList operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesListRequest
 */
export interface PlanInvoicesApiPlanInvoicesListRequest {
    /**
     * 
     * @type {number}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly isActive?: string

    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly isPaid?: string

    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly isExpired?: string

    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly order?: string

    /**
     * A search term.
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlanInvoicesApiPlanInvoicesList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for planInvoicesPartialUpdate operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesPartialUpdateRequest
 */
export interface PlanInvoicesApiPlanInvoicesPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesPartialUpdate
     */
    readonly id: string

    /**
     * 
     * @type {AdminPlanInvoice}
     * @memberof PlanInvoicesApiPlanInvoicesPartialUpdate
     */
    readonly data: AdminPlanInvoice
}

/**
 * Request parameters for planInvoicesPay operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesPayRequest
 */
export interface PlanInvoicesApiPlanInvoicesPayRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesPay
     */
    readonly id: string

    /**
     * 
     * @type {InvoiceTransaction}
     * @memberof PlanInvoicesApiPlanInvoicesPay
     */
    readonly data: InvoiceTransaction
}

/**
 * Request parameters for planInvoicesRead operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesReadRequest
 */
export interface PlanInvoicesApiPlanInvoicesReadRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesRead
     */
    readonly id: string
}

/**
 * Request parameters for planInvoicesUpdate operation in PlanInvoicesApi.
 * @export
 * @interface PlanInvoicesApiPlanInvoicesUpdateRequest
 */
export interface PlanInvoicesApiPlanInvoicesUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanInvoicesApiPlanInvoicesUpdate
     */
    readonly id: string

    /**
     * 
     * @type {AdminPlanInvoice}
     * @memberof PlanInvoicesApiPlanInvoicesUpdate
     */
    readonly data: AdminPlanInvoice
}

/**
 * PlanInvoicesApi - object-oriented interface
 * @export
 * @class PlanInvoicesApi
 * @extends {BaseAPI}
 */
export class PlanInvoicesApi extends BaseAPI {
    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesAdminCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesAdminCreate(requestParameters: PlanInvoicesApiPlanInvoicesAdminCreateRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesAdminCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesCreate(requestParameters: PlanInvoicesApiPlanInvoicesCreateRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesDelete(requestParameters: PlanInvoicesApiPlanInvoicesDeleteRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesDeleteDiscountCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesDeleteDiscountCode(requestParameters: PlanInvoicesApiPlanInvoicesDeleteDiscountCodeRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesDeleteDiscountCode(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesList(requestParameters: PlanInvoicesApiPlanInvoicesListRequest = {}, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesList(requestParameters.fromDaysBefore, requestParameters.isActive, requestParameters.isPaid, requestParameters.isExpired, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesPartialUpdate(requestParameters: PlanInvoicesApiPlanInvoicesPartialUpdateRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesPayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesPay(requestParameters: PlanInvoicesApiPlanInvoicesPayRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesPay(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesRead(requestParameters: PlanInvoicesApiPlanInvoicesReadRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanInvoicesApiPlanInvoicesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanInvoicesApi
     */
    public planInvoicesUpdate(requestParameters: PlanInvoicesApiPlanInvoicesUpdateRequest, options?: any) {
        return PlanInvoicesApiFp(this.configuration).planInvoicesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
