
export const brown = {
  50: "#7F7666",
  100: "#4B4028",
  200: "#2F2000",
  300: "#181300",
} as const;


export const pink = {
  50: "#FC7777",
  100: "#EC008B",
  200: "#D7508D",
} as const;

export const purple = {
  500: "#360362",
} as const;

export const darkGrey = {
  50: "#96A2A1",
  100: "#9B9B9B",
  200: "#8d8e8e",
  300: "#666E6E",
  400: "#666666",
  500: "#5C6363",
  600: "#5C5C5C",
  700: "#5C595B",
  800: "#4D4D4D",
  900: "#484E4E",
  A100: "#474747",
  A200: "#353838",
  A300: "#212121",
  A400: "#191E24",
  A500: "#1A1A1A",
  A600: "#181A1A",
  A700: "#0C0D0D",
} as const;

export const lightGrey = {
  50: "#FCFDFD",
  100: "#F7F7F7",
  200: "#F4F6F6",
  300: "#F5F5F5",
  400: "#F1F4F4",
  500: "#E8EDED",
  600: "#DAE8E7",
  700: "#DCE1E1",
  800: "#DCE1DD",
  900: "#D9E0E0",
  A100: "#D8D8D8",
  A200: "#CED2D3",
  A300: "#CCD3D3",
  A400: "#CDD3CF",
  A500: "#C5D2D2",
  A600: "#B4B9B9",
  A700: "#B3B3B3",
  A800: "#A8B2B1",
  A900: "#A8A8A8",
} as const;

export const yellow = {
  50: "#FFECB5",
  100: "#FACB47",
  200: "#FFD900",
} as const;

export const blue = {
  50: "#F6F8FA",
  100: "#F5F8FA",
  200: "#EBF4F8",
  300: "#EBF1F5",
  400: "#DBF1FF",
  500: "#DBE5E8",
  600: "#ADB9BA",
  700: "#AAD1D8",
  800: "#64B2E3",
  900: "#64ABB8",
  A100: "#1DA1F2",
  A200: "#357EBD",
  A300: "#2487FF",
  A400: "#326196",
  A500: "#2F1999",
  A600: "#093A7C",
  A700: "#3C4759",
} as const;

export const green = {
  50: "#95D1C7",
  100: "#A0B469",
  200: "#02A388",
  300: "#02937A",
  400: "#028A73",
  500: "#028A71",
  600: "#28763C",
  700: "#64713F",
} as const;

export const red = {
  50: "#FF3F3F",
  100: "#FE423F",
  200: "#FE231F",
  300: "#E12522",
  400: "#D42020",
} as const;

export const palette = { red, green, purple, brown, blue, yellow, pink, darkGrey, lightGrey };
