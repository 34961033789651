import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {palette} from "@constant/colors";
import {Link} from "react-router-dom";
import Typography from "@elements/Typography";
import {NavbarChildItem} from "@layouts/MainLayout";

export interface ISubListProps {
  item: NavbarChildItem;
  onClick?: () => void;
}

const SubList = (props: ISubListProps) => {
  const {item, onClick: handleClick} = props;
  const classes = useStyle();

  return (
    <Box component='li' className={classes.widthFull}>
      <Typography
        size={1.8}
        component='h4'
        color={palette.darkGrey.A700}
        height={1.4}
        className={classes.title}
      >
        {item.name}
      </Typography>
      <Box className={classes.divider} />
      <Box component='ul' className={classes.list}>
        {item.items?.map((child, index) => (
          // link to search is temporary
          <Link to={child.href} key={index.toString()}>
            <Typography
              component='li'
              className={classes.item}
              onClick={handleClick}
              color={palette.darkGrey.A700}
            >
              {"name" in child ? child.name : child.term}
            </Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default SubList;

const useStyle = makeStyles(() => ({
  widthFull: {
    width: "100%",
  },
  title: {},
  list: {
    padding: "2.6rem 0 4.5rem",
  },
  divider: {
    display: "block",
    height: "2px",
    width: "100%",
    maxWidth: 180,
    backgroundColor: palette.lightGrey.A500,
    marginTop: "1.2rem",
  },
  item: {
    cursor: "pointer",
    marginBottom: "1.4rem",
    "&:hover": {
      color: palette.green[200],
    },
  },
  more: {
    color: palette.green[200],
    marginBottom: 0,
  },
}));
