import {PlanDiscountApi, PlanDiscountApiPlanDiscountListRequest} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const promoCodeApiInstance = new PlanDiscountApi(undefined, undefined, axiosInstance);

export const promoCodeAPI = makeApi({
  identifier: "promoCode",
  Query: {
    getList: createQuery(async (params: PlanDiscountApiPlanDiscountListRequest) => {
      const {data} = await promoCodeApiInstance.planDiscountList(params);
      return data;
    }),
  },
  Mutation: {},
});
