import {Helmet} from "react-helmet";
import {Blog} from "@schema";

export default function MetaTags({data}: {data?: Blog}) {
  return (
    <Helmet>
      <title>{data?.title?.concat(" | پارس ایمیجز")}</title>
      {/* <meta name='description' content={data?.description} />
      <meta name='keywords' content={data?.description} /> */}
      <meta content={data?.title} property='og:title' />
      {/* <meta content={data?.file} property='og:image' />
      <meta content={data?.description || data?.title} property='og:description' /> */}
      <meta property='og:image:width' />
      <meta property='og:image:height' />
      <meta
        content={window.origin.concat(data?.title.split(" ").join("-") || "")}
        property='og:url'
      />
      <meta content={data?.title} property='twitter:title' />
      {/* <meta content={data?.file} property='twitter:image' />
      <meta content={data?.description || data?.title} property='twitter:description' /> */}
      <meta property='og:twitter:width' />
      <meta property='og:twitter:height' />
    </Helmet>
  );
}
