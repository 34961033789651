import {Button, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {CustomButtonProps, DefaultCustomButtonProps} from "./types";

export type PrimaryButtonDefaultProps = DefaultCustomButtonProps & {size?: "normal" | "large"};

export type PrimaryButtonProps<T extends React.ElementType> = Omit<CustomButtonProps<T>, "size"> & {
  size?: "normal" | "large";
};

export function DefaultPrimaryButton(props: PrimaryButtonDefaultProps) {
  return <PrimaryButton {...props} />;
}

export function PrimaryButton<T extends React.ElementType = "button">({
  children,
  className,
  disabled,
  loading,
  selected,
  size,
  ...rest
}: PrimaryButtonProps<T>) {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      variant='contained'
      disableElevation
      disabled={disabled || loading}
      className={`${size === "large" ? classes.rootLarge : classes.root} font-bold ${
        disabled || loading
          ? "text-white bg-action-disabledBackground"
          : !selected
          ? "bg-primary-light text-Primary"
          : "bg-Primary text-white"
      } ${className || ""}`}
    >
      {loading ? <CircularProgress variant='indeterminate' size={20} /> : children}
    </Button>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    padding: "0.688rem 1.875rem",
    borderRadius: 4,
    fontSize: "0.75rem",
  },
  rootLarge: {
    paddingTop: "0.938rem",
    paddingBottom: "0.875rem",
    borderRadius: 4,
    fontSize: "0.875rem",
  },
}));
