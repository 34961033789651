/* eslint-disable prefer-destructuring */
import {useAlert} from "@context/AlertContext";
import {AxiosError} from "axios";

export default function useApiErrorHandler() {
  const alert = useAlert();

  return (err: AxiosError) => {
    const status = err.response?.status;
    const errorData =
      err.response?.data instanceof ArrayBuffer
        ? // @ts-ignore
          (JSON.parse(new TextDecoder().decode(new Uint8Array(err.response?.data))) as any)
        : err.response?.data;
    const _default = "مشکلی به‌ وجود آمده است";

    let errorMessage = _default;

    if (status === 401) {
      errorMessage = "لطفا ابتدا وارد شوید";
    } else if (status === 403) {
      errorMessage = "شما اجازه انجام این عملیات را ندارید";
    } else if (status === 502) {
      errorMessage = "خطای سرور، سایت در حال بروز رسانی‌است";
    } else if ((status || 0) >= 500) {
      errorMessage = "خطای سرور، لطفا پس از مدتی کوتاه دوباره امتحان کنید";
    } else if (status === 429) {
      errorMessage = errorData["detail"]
        ? errorData.detail
        : errorData["error"]
        ? errorData.error
        : errorMessage;
    } else if (status === 400 || status === 404) {
      if (errorData["non_field_errors"]) {
        errorMessage = errorData["non_field_errors"][0];
      }
      if (errorData["detail"]) {
        const detail = errorData["detail"];
        errorMessage = typeof detail === "string" ? detail : detail.join(", ");
      }
      if (errorData["error"]) {
        errorMessage = errorData["error"];
      }
    }

    alert.error({
      // extra check to prevent passing non string values in last else block
      text: typeof errorMessage === "string" ? errorMessage : _default,
    });
  };
}
