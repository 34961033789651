import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "./Typography";
import MainLayout from "@layouts/MainLayout";

export default function ErrorBoundaryFallback() {
  const c = useStyles();

  return (
    <MainLayout>
      <div className={c.container}>
        <Typography size={2.5} align='center'>
          سایت در نمایش محتوای مورد نظر شما دچار مشکل شده است. لطفا بعدا دوباره تلاش کنید و در صورت
          تکرار با پشتیبانی تماس بگیرید
        </Typography>
      </div>
    </MainLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 800,
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 500,
    backgroundColor: "white",
    position: "relative",
    zIndex: 20,
    padding: "0 2rem",
  },
}));
