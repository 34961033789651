import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Theme, useMediaQuery} from "@material-ui/core";
import {ListImage} from "@schema";
import {routes} from "@constant/routes";
import {Skeleton} from "@material-ui/lab";
import {placeholderItems} from "./placeholderItems";
import {localStMediaTypeHistoryGet} from "@modules/SearchBar/utils";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import createImageUrl from "@util/createImageUrl";
import ImageWrapper from "./ImageWrapper";
import {checkForMouseSupport} from "@util/checkForMouseSupport";

export interface IGalleryProps {
  images?: ListImage[];
  drawerIsOpen?: boolean;
  placeholderCounts?: number;
  renderSimilarButton?: boolean;
}

const Gallery = (props: IGalleryProps) => {
  const classes = useStyle();
  const {drawerIsOpen, images, placeholderCounts, renderSimilarButton} = props;
  const handleUpdateQueryString = useUpdateQueryString();
  const type = localStMediaTypeHistoryGet();
  const deviceHasMouseSupport = checkForMouseSupport();

  const mobileSize = useMediaQuery("(max-width:550px)");
  const tablet = useMediaQuery("(min-width:730px)");
  const desktop = useMediaQuery("(min-width:950px)");
  const large = useMediaQuery("(min-width:1150px)");
  const xlarge = useMediaQuery("(min-width:1440px)");

  /** @description in the latest lines of document */
  let ratio = 27;
  if (tablet) {
    ratio = !drawerIsOpen ? 23 : 27;
  }
  if (desktop) {
    ratio = !drawerIsOpen ? 20 : 23;
  }
  if (large) {
    ratio = !drawerIsOpen ? 14 : 20;
  }
  if (xlarge) {
    ratio = !drawerIsOpen ? 11 : 14;
  }

  return (
    <Grid container justify='flex-start' wrap='wrap' className={classes.container}>
      {/* rendering skeletons */}
      {!images &&
        placeholderItems
          .filter((_, index) => (placeholderCounts ? index < placeholderCounts : true))
          .map(({width, height}, index) => (
            <Grid
              item
              key={index.toString()}
              style={{
                width: mobileSize ? "100%" : `calc(${width / height} * ${ratio}%)`,
                flexGrow: width / height,
                backgroundColor: "#e2e2e2",
                height: mobileSize ? 190 * width : 210,
              }}
            >
              <Skeleton variant='rect' width='100%' height='100%' />
            </Grid>
          ))}
      {images
        ?.filter(({width}) => !!width)
        .map(({id, file, height, width, title, is_premium, type: imageType, id_code}) => {
          const h = +height!;
          const w = +width!;
          return (
            <ImageWrapper
              key={id}
              style={{
                width: mobileSize ? "100%" : `calc(${w / h} * ${ratio}%)`,
                flexGrow: w / h,
                backgroundColor: "#d4d4d4",
                minHeight: 150,
              }}
              imageCardProps={{
                src: file!,
                imageId: id || 0,
                alt: title || "",
                isPremium: is_premium ?? false,
                deviceHasMouseSupport,
                to: createImageUrl(imageType || "", title || "", id_code || ""),
                renderSimilarButton: renderSimilarButton,
                onClickSimilarButton: () =>
                  handleUpdateQueryString(
                    {similarImages: String(id), type: type ?? ""},
                    routes.landing.search
                  ),
              }}
            >
              <img
                src={file!}
                style={{width: "100%", height: "100%"}}
                alt={title || ""}
                title={title}
              />
            </ImageWrapper>
          );
        })}
      {/* rendering some fake cards with 1px height to prevent last real item to grow more than expected. */}
      {[1, 2, 3, 4, 5, 6, 7].map((item) => (
        <Grid
          key={item}
          item
          style={{
            width: `${ratio}%`,
            height: 0,
            flexGrow: 1,
          }}
        />
      ))}
    </Grid>
  );
};

export default Gallery;

const useStyle = makeStyles(() => ({
  container: {
    gap: "1.2rem",
  },
}));

// how does the calculation of images width happens? in the first place, each image will have a
// width related to it's height => w/h. until now, we will have some rows full of small images.
// so we use the ratio variable to increase each image's width in percentage.
// ratio is just a number without any calculation. a number that seems good from our point of view
// can be decreased or increased as you wish. for example you might use a <Grid item> as a container
// of a image, so you can choose any number for it's xs property, imagine ratio as something like xs
// now we will have some rows with some images, and probably some empty spaces in the last of each row
// so we use flexGrow to fill the empty spaces. but each image can only grow as it's w/h
// otherwise their widths grow equally and their heights won't be equal. we want it exactly the opposite
// we want their width grow in some way that makes their height still equal.
// this is the reason for => flexGrow: w / h

// as screen width grows, ratio decreases, otherwise each image becomes bigger and bigger (remember each
// images width is being calculated in percentage, for example in Grid item you decrease the number
//of sm prop in a Grid item as screen grows something like this => <Grid item xs={12} sm={6} md={4} lg={3}>),
// but if drawer is open, means there is much less width for each image, so we take ratio one step
// backward and let each image to increase it's size.

// all these calculation is for screen sizes bigger than mobile devices. in small screens we want
// each image to grow '100%', and this is the reason for =>
// width: mobileSize ? "100%" : `calc(${w / h} * ${ratio}%)`,
