import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {LinearProgress} from "@material-ui/core";
import Typography from "./Typography";

interface IProps {
  value: number;
}

export default function ProgressWithLabel({value}: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LinearProgress
        value={Math.round(value)}
        variant='determinate'
        classes={{
          determinate: classes.progress,
          bar1Determinate: classes.progressBar,
        }}
      />
      <Typography>{Math.round(value)}%</Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    gap: 6,
    position: "fixed",
    zIndex: 1000,
    bottom: 35,
    left: "50%",
    transform: "translateX(-50%)",
    width: 940,
    maxWidth: "94%",
    padding: "2rem 1rem",
    borderRadius: 6,
    backgroundColor: "#f8f8f8",
    border: "1px solid #d1d1d1",
    boxShadow: "0 0 10px 1px #666666aa",
  },
  progress: {
    transform: "rotateY(180deg)",
    backgroundColor: "#02A38844",
    width: "100%",
    height: 7,
    borderRadius: 5,
  },
  progressBar: {
    backgroundColor: "#02A388",
  },
}));
