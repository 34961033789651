/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Login } from '../servermodel';
// @ts-ignore
import { OTPConfirm } from '../servermodel';
// @ts-ignore
import { OTPSend } from '../servermodel';
// @ts-ignore
import { PasswordChange } from '../servermodel';
// @ts-ignore
import { PasswordResetConfirm } from '../servermodel';
// @ts-ignore
import { ReadPasswordResetOTPConfirm } from '../servermodel';
// @ts-ignore
import { ReadSignupOTPConfirm } from '../servermodel';
// @ts-ignore
import { User } from '../servermodel';
// @ts-ignore
import { UserRegistration } from '../servermodel';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Login} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginCreate: async (data: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authLoginCreate', 'data', data)
            const localVarPath = `/auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordChange} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordChangeCreate: async (data: PasswordChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authPasswordChangeCreate', 'data', data)
            const localVarPath = `/auth/password/change/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts the following POST parameters: token, uid,     new_password1, new_password2  Returns the success/fail message.
         * @summary Update password according the uuid and token provided.
         * @param {PasswordResetConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetConfirmCreate: async (data: PasswordResetConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authPasswordResetConfirmCreate', 'data', data)
            const localVarPath = `/auth/password/reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OTPSend} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetCreate: async (data: OTPSend, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authPasswordResetCreate', 'data', data)
            const localVarPath = `/auth/password/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OTPConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetOtpConfirmCreate: async (data: OTPConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authPasswordResetOtpConfirmCreate', 'data', data)
            const localVarPath = `/auth/password/reset/otp-confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRegistration} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupCreate: async (data: UserRegistration, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authSignupCreate', 'data', data)
            const localVarPath = `/auth/signup/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OTPConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupOtpConfirmCreate: async (data: OTPConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authSignupOtpConfirmCreate', 'data', data)
            const localVarPath = `/auth/signup/otp-confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OTPSend} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupOtpCreate: async (data: OTPSend, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authSignupOtpCreate', 'data', data)
            const localVarPath = `/auth/signup/otp/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserPartialUpdate: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authUserPartialUpdate', 'data', data)
            const localVarPath = `/auth/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserRead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserUpdate: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('authUserUpdate', 'data', data)
            const localVarPath = `/auth/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Login} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginCreate(data: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogoutCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogoutCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordChange} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPasswordChangeCreate(data: PasswordChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChange>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPasswordChangeCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts the following POST parameters: token, uid,     new_password1, new_password2  Returns the success/fail message.
         * @summary Update password according the uuid and token provided.
         * @param {PasswordResetConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPasswordResetConfirmCreate(data: PasswordResetConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetConfirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPasswordResetConfirmCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OTPSend} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPasswordResetCreate(data: OTPSend, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPSend>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPasswordResetCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OTPConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPasswordResetOtpConfirmCreate(data: OTPConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadPasswordResetOTPConfirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPasswordResetOtpConfirmCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRegistration} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignupCreate(data: UserRegistration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignupCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OTPConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignupOtpConfirmCreate(data: OTPConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadSignupOTPConfirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignupOtpConfirmCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OTPSend} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignupOtpCreate(data: OTPSend, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPSend>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignupOtpCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserPartialUpdate(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserPartialUpdate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserRead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserUpdate(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserUpdate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {Login} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginCreate(data: Login, options?: any): AxiosPromise<User> {
            return localVarFp.authLoginCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutCreate(options?: any): AxiosPromise<void> {
            return localVarFp.authLogoutCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordChange} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordChangeCreate(data: PasswordChange, options?: any): AxiosPromise<PasswordChange> {
            return localVarFp.authPasswordChangeCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts the following POST parameters: token, uid,     new_password1, new_password2  Returns the success/fail message.
         * @summary Update password according the uuid and token provided.
         * @param {PasswordResetConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetConfirmCreate(data: PasswordResetConfirm, options?: any): AxiosPromise<PasswordResetConfirm> {
            return localVarFp.authPasswordResetConfirmCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OTPSend} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetCreate(data: OTPSend, options?: any): AxiosPromise<OTPSend> {
            return localVarFp.authPasswordResetCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OTPConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetOtpConfirmCreate(data: OTPConfirm, options?: any): AxiosPromise<ReadPasswordResetOTPConfirm> {
            return localVarFp.authPasswordResetOtpConfirmCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRegistration} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupCreate(data: UserRegistration, options?: any): AxiosPromise<User> {
            return localVarFp.authSignupCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OTPConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupOtpConfirmCreate(data: OTPConfirm, options?: any): AxiosPromise<ReadSignupOTPConfirm> {
            return localVarFp.authSignupOtpConfirmCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OTPSend} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupOtpCreate(data: OTPSend, options?: any): AxiosPromise<OTPSend> {
            return localVarFp.authSignupOtpCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserPartialUpdate(data: User, options?: any): AxiosPromise<User> {
            return localVarFp.authUserPartialUpdate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserRead(options?: any): AxiosPromise<User> {
            return localVarFp.authUserRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserUpdate(data: User, options?: any): AxiosPromise<User> {
            return localVarFp.authUserUpdate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authLoginCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthLoginCreateRequest
 */
export interface AuthApiAuthLoginCreateRequest {
    /**
     * 
     * @type {Login}
     * @memberof AuthApiAuthLoginCreate
     */
    readonly data: Login
}

/**
 * Request parameters for authPasswordChangeCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordChangeCreateRequest
 */
export interface AuthApiAuthPasswordChangeCreateRequest {
    /**
     * 
     * @type {PasswordChange}
     * @memberof AuthApiAuthPasswordChangeCreate
     */
    readonly data: PasswordChange
}

/**
 * Request parameters for authPasswordResetConfirmCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordResetConfirmCreateRequest
 */
export interface AuthApiAuthPasswordResetConfirmCreateRequest {
    /**
     * 
     * @type {PasswordResetConfirm}
     * @memberof AuthApiAuthPasswordResetConfirmCreate
     */
    readonly data: PasswordResetConfirm
}

/**
 * Request parameters for authPasswordResetCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordResetCreateRequest
 */
export interface AuthApiAuthPasswordResetCreateRequest {
    /**
     * 
     * @type {OTPSend}
     * @memberof AuthApiAuthPasswordResetCreate
     */
    readonly data: OTPSend
}

/**
 * Request parameters for authPasswordResetOtpConfirmCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordResetOtpConfirmCreateRequest
 */
export interface AuthApiAuthPasswordResetOtpConfirmCreateRequest {
    /**
     * 
     * @type {OTPConfirm}
     * @memberof AuthApiAuthPasswordResetOtpConfirmCreate
     */
    readonly data: OTPConfirm
}

/**
 * Request parameters for authSignupCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthSignupCreateRequest
 */
export interface AuthApiAuthSignupCreateRequest {
    /**
     * 
     * @type {UserRegistration}
     * @memberof AuthApiAuthSignupCreate
     */
    readonly data: UserRegistration
}

/**
 * Request parameters for authSignupOtpConfirmCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthSignupOtpConfirmCreateRequest
 */
export interface AuthApiAuthSignupOtpConfirmCreateRequest {
    /**
     * 
     * @type {OTPConfirm}
     * @memberof AuthApiAuthSignupOtpConfirmCreate
     */
    readonly data: OTPConfirm
}

/**
 * Request parameters for authSignupOtpCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthSignupOtpCreateRequest
 */
export interface AuthApiAuthSignupOtpCreateRequest {
    /**
     * 
     * @type {OTPSend}
     * @memberof AuthApiAuthSignupOtpCreate
     */
    readonly data: OTPSend
}

/**
 * Request parameters for authUserPartialUpdate operation in AuthApi.
 * @export
 * @interface AuthApiAuthUserPartialUpdateRequest
 */
export interface AuthApiAuthUserPartialUpdateRequest {
    /**
     * 
     * @type {User}
     * @memberof AuthApiAuthUserPartialUpdate
     */
    readonly data: User
}

/**
 * Request parameters for authUserUpdate operation in AuthApi.
 * @export
 * @interface AuthApiAuthUserUpdateRequest
 */
export interface AuthApiAuthUserUpdateRequest {
    /**
     * 
     * @type {User}
     * @memberof AuthApiAuthUserUpdate
     */
    readonly data: User
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiAuthLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginCreate(requestParameters: AuthApiAuthLoginCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authLoginCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogoutCreate(options?: any) {
        return AuthApiFp(this.configuration).authLogoutCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthPasswordChangeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordChangeCreate(requestParameters: AuthApiAuthPasswordChangeCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authPasswordChangeCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts the following POST parameters: token, uid,     new_password1, new_password2  Returns the success/fail message.
     * @summary Update password according the uuid and token provided.
     * @param {AuthApiAuthPasswordResetConfirmCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordResetConfirmCreate(requestParameters: AuthApiAuthPasswordResetConfirmCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authPasswordResetConfirmCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthPasswordResetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordResetCreate(requestParameters: AuthApiAuthPasswordResetCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authPasswordResetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthPasswordResetOtpConfirmCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordResetOtpConfirmCreate(requestParameters: AuthApiAuthPasswordResetOtpConfirmCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authPasswordResetOtpConfirmCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthSignupCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignupCreate(requestParameters: AuthApiAuthSignupCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authSignupCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthSignupOtpConfirmCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignupOtpConfirmCreate(requestParameters: AuthApiAuthSignupOtpConfirmCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authSignupOtpConfirmCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiAuthSignupOtpCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignupOtpCreate(requestParameters: AuthApiAuthSignupOtpCreateRequest, options?: any) {
        return AuthApiFp(this.configuration).authSignupOtpCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
     * @param {AuthApiAuthUserPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserPartialUpdate(requestParameters: AuthApiAuthUserPartialUpdateRequest, options?: any) {
        return AuthApiFp(this.configuration).authUserPartialUpdate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserRead(options?: any) {
        return AuthApiFp(this.configuration).authUserRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
     * @param {AuthApiAuthUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserUpdate(requestParameters: AuthApiAuthUserUpdateRequest, options?: any) {
        return AuthApiFp(this.configuration).authUserUpdate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
