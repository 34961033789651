import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Box, Grid} from "@material-ui/core";
import {ArrowDropDownRounded as ArrowDropDown} from "@material-ui/icons";
import {useEffect} from "react";
import Span from "@elements/Span";
import clsx from "clsx";
import {useOnClickOutside} from "@hook/useOutsideClicker";
import {CommonProps} from "@type/global";
import TextInput, {ITextInputProps} from "./TextInput";

export interface IDropdownProps extends CommonProps {
  items?: {
    value: string;
    label: string;
  }[];
  label: string;
  value?: string;
  fullWidth?: boolean;
  onChange?: (value: string) => void;
  itemsBoxStyle?: React.CSSProperties;
  itemsBoxClassName?: string;
  withSearchInput?: boolean;
  searchInputProps?: ITextInputProps;
  color?: "white" | "gray";
}

const Dropdown = (props: IDropdownProps) => {
  const [showItems, setShowItems] = useState(false);
  const [height, setHeight] = useState<number>();
  const {
    label,
    items,
    value: controlledValue,
    fullWidth,
    onChange,
    className,
    style,
    itemsBoxStyle,
    itemsBoxClassName,
    withSearchInput,
    searchInputProps,
    color = "gray",
  } = props;
  const [value, setValue] = useState(controlledValue || "");
  const classes = useStyle({height, fullWidth});
  const topBoxRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useOnClickOutside<HTMLDivElement>(() => setShowItems(false));

  useEffect(() => {
    topBoxRef.current && setHeight(topBoxRef.current?.clientHeight);
  }, []);

  const handleToggle = () => setShowItems((prev) => !prev);

  return (
    <Grid
      className={clsx(classes.container, className)}
      style={style}
      ref={containerRef}
      tabIndex={0}
    >
      <Grid
        container
        justify='space-between'
        alignItems='center'
        className={clsx(classes.topBox, color === "white" && classes.white)}
        ref={topBoxRef}
        onClick={handleToggle}
      >
        <Grid container direction='column'>
          <Typography span className={clsx(classes.label, value !== "" && classes.labelGrey)}>
            {label}
          </Typography>
          {value !== "" && (
            <Span className={classes.value}>
              {items?.find((item) => item.value === value)?.label}
            </Span>
          )}
        </Grid>
        <ArrowDropDown />
      </Grid>
      {showItems && (
        <Box className={clsx(classes.itemsBox, itemsBoxClassName)} style={itemsBoxStyle}>
          {withSearchInput && <TextInput {...searchInputProps} style={{marginBottom: ".25rem"}} />}
          {items?.map(({label, value: v}) => (
            <Typography
              key={v}
              className={clsx(classes.item, v === value && classes.active)}
              size={1.6}
              onClick={() => {
                setValue(v);
                onChange?.(v);
                setShowItems(false);
              }}
            >
              {label}
            </Typography>
          ))}
        </Box>
      )}
    </Grid>
  );
};

export default Dropdown;

interface StyleProps {
  height?: number;
  fullWidth?: boolean;
}

const useStyle = makeStyles((theme) => ({
  container: ({fullWidth}: StyleProps) => ({
    display: "inline-block",
    width: fullWidth ? "100%" : 170,
    position: "relative",
    minHeight: 50,
    height: "100%",
    userSelect: "none",
    "& svg": {
      fontSize: 18 * fontNormalizeFactor,
    },
    "&:focus": {
      border: `solid 1px ${theme.palette.primary.main}`,
      borderRadius: 4,
    },
  }),
  topBox: {
    width: "100%",
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    padding: "10px 4px 10px 12px",
    height: "100%",
    cursor: "pointer",
    gap: 16,
  },
  white: {
    backgroundColor: "white",
    border: "solid 1px #c7c7c7",
  },
  label: {
    lineHeight: "22px",
    fontSize: 16 * fontNormalizeFactor,
  },
  labelGrey: {
    color: theme.palette.grey[800],
    fontSize: 12 * fontNormalizeFactor,
  },
  value: {
    lineHeight: "15px",
    color: theme.palette.primary.dark,
    fontSize: 14 * fontNormalizeFactor,
  },
  itemsBox: ({height}: StyleProps) => ({
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    // overflow: "hidden",
    backgroundColor: "white",
    position: "absolute",
    bottom: `-${height}`,
    left: 0,
    width: "100%",
    zIndex: 1000,
    marginTop: 5,
    padding: "4px",
    // paddingBottom: 10,
    minHeight: 50,
    boxShadow: "0 2px 3px 0 #888, 0 1px 3px 0 #777",
    maxHeight: 400,
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-msOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  }),
  item: {
    padding: "2px 5px ",
    margin: "1px 0",
    cursor: "pointer",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  active: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  searchInput: {
    marginBottom: ".25rem",
  },
}));
