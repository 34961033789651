import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, useMediaQuery} from "@material-ui/core";
import {palette} from "@constant/colors";
import GreenLink from "@elements/GreenLink";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {Theme} from "@material-ui/core";
import {routes} from "@constant/routes";

const PlansAndPricing = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.footer");
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const fontSize = xsDown ? 1.7 : smDown ? 1.9 : 2.1;

  return (
    <Box className={classes.container}>
      <Typography size={fontSize} weight={500} color='white' align='center'>
        {t("plansAndPricing.regular")}
      </Typography>
      <GreenLink to={routes.landing.pricing} size={fontSize}>
        {t("plansAndPricing.link")}
      </GreenLink>
    </Box>
  );
};

export default PlansAndPricing;

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: palette.darkGrey.A700,
    padding: "3.5rem 0.5rem",
    color: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      gap: 0,
      padding: "2.5rem 0.5rem ",
    },
  },
}));
