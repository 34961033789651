import {DialogProps, Grid, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {useTranslation} from "react-i18next";
import PanelModalRapper from "./PanelModalWrapper";
import Dropdown from "@elements/panel/Dropdown";
import {Enum} from "@util/EnumUtils";
import {ImageTypeEnumPersian, RetrieveImageTypeEnum as ImageTypeEnum} from "@schema";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import useDebouncedValue from "@hook/useDebouncedValue";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import DropdownFromDaysBefore from "@elements/panel/DropdownFromDaysBefore";

interface IProps extends DialogProps {
  onRemoveFilters?: () => void;
}

export default function ImagesFilterModal(props: IProps) {
  const [tagsSearchValue, setTagsSearchValue] = React.useState("");
  const debouncedTagsSearchValue = useDebouncedValue(tagsSearchValue, 500);
  const classes = useStyles();
  const {t} = useTranslation("pages.panel");
  const handleUpdateQueryString = useUpdateQueryString();

  const {noPreview, tag, type, category, isPremium, isActive, manualEdit} = useGetQueryString();

  const {data: categories} = useExperimentalQuery("categories", "getList", {
    variables: {pageSize: 50},
  });
  const {data: tags} = useExperimentalQuery("tags", "getList", {
    variables: {pageSize: 30, search: debouncedTagsSearchValue},
  });

  const clearFilters = () => {
    handleUpdateQueryString({
      fromDaysBefore: "",
      category: "",
      tag: "",
      isActive: "",
      isPremium: "",
      type: "",
      noPreview: "",
      manualEdit: "",
    });
    props.onRemoveFilters?.();
  };

  return (
    <PanelModalRapper title='فیلتر‌های پیشرفته' {...props}>
      <Grid direction='column' className={classes.root}>
        <DropdownFromDaysBefore width='100%' />
        <Dropdown
          fullWidth
          label={t("images.category")}
          items={[{label: t("common.all"), value: ""}].concat(
            categories?.results.map(({id, name}) => ({
              value: (id || 0).toString(),
              label: name || "",
            })) || []
          )}
          onChange={(category) => handleUpdateQueryString({category})}
          value={category || ""}
        />
        <Dropdown
          fullWidth
          label='تگ'
          value={tag}
          onChange={(tag) => handleUpdateQueryString({tag})}
          withSearchInput
          searchInputProps={{
            renderRemoveButton: true,
            value: tagsSearchValue,
            onChange: (v) => setTagsSearchValue(v),
          }}
          items={[
            {label: t("common.all"), value: ""},
            {label: "بدون تگ", value: "null"},
          ].concat(
            tags?.results.map(({id, name}) => ({
              value: (id || 0).toString(),
              label: name || "",
            })) || []
          )}
        />
        <Dropdown
          fullWidth
          label={t("common.beActiveStatus")}
          items={[
            {label: t("common.all"), value: ""},
            {label: t("common.active"), value: "true"},
            {label: t("common.inactive"), value: "false"},
          ]}
          value={isActive}
          onChange={(v) => handleUpdateQueryString({isActive: v})}
        />
        <Dropdown
          fullWidth
          label='دسته'
          items={[
            {label: t("common.all"), value: ""},
            {label: t("common.essential"), value: "false"},
            {label: t("common.premium"), value: "true"},
          ]}
          value={isPremium ?? ""}
          onChange={(v) => handleUpdateQueryString({isPremium: v})}
        />
        <Dropdown
          fullWidth
          label={t("images.type")}
          items={[{label: t("common.all"), value: ""}].concat(
            Enum(ImageTypeEnum)
              .getEntries()
              .map(([key, value]) => ({value, label: ImageTypeEnumPersian[key]}))
          )}
          onChange={(type) => handleUpdateQueryString({type})}
          value={type}
        />
        <Dropdown
          fullWidth
          label='پریویو'
          items={[{label: t("common.all"), value: ""}].concat([
            {label: "دارد", value: "false"},
            {label: "ندارد", value: "true"},
          ])}
          onChange={(value) => handleUpdateQueryString({noPreview: value})}
          value={noPreview}
        />
        <Dropdown
          fullWidth
          label='وضعیت بررسی توسط ادیتور'
          items={[{label: t("common.all"), value: ""}].concat([
            {label: "بررسی نشده", value: "false"},
            {label: "بررسی شده", value: "true"},
          ])}
          onChange={(value) => handleUpdateQueryString({manualEdit: value})}
          value={manualEdit}
        />
        <Button fullWidth onClick={clearFilters}>
          حذف همه فیلتر‌ها
        </Button>
      </Grid>
    </PanelModalRapper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    "& > *": {
      height: 56,
      marginBottom: 12,
    },
  },
}));
