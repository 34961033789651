import {routes} from "@constant/routes";
const {landing} = routes;

export default function createImageUrl(imageType: string, title: string, idCode: string) {
  const type = imageType.toLocaleLowerCase();
  const slug = title.replace("%", "percent").split(" ").join("-").concat(`-${idCode}`);

  return type === "photo"
    ? landing.imagesDetailPhotoType(slug)
    : type === "vector"
    ? landing.imagesDetailVectorType(slug)
    : type === "illustration"
    ? landing.imagesDetailIllustrationType(slug)
    : landing.search;
}
