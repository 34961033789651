import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip as MaterialTooltip, TooltipProps} from "@material-ui/core";
import {palette} from "@constant/colors";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import clsx from "clsx";

export interface ITooltipProps extends TooltipProps {
  children: React.ReactElement<any, any>;
  title: string;
  className?: string;
}

const Tooltip = (props: ITooltipProps) => {
  const classes = useStyle();
  const {children, title, className, ...rest} = props;

  return (
    <MaterialTooltip
      placement='top'
      classes={{tooltip: clsx(classes.tooltip, className)}}
      title={title}
      enterDelay={200}
      enterNextDelay={200}
      leaveDelay={200}
      {...rest}
    >
      {children}
    </MaterialTooltip>
  );
};

export default Tooltip;

const useStyle = makeStyles((theme) => ({
  tooltip: {
    fontSize: `${1.4 * fontNormalizeFactor}rem`,
    borderRadius: 0,
    border: `solid 1px  ${theme.palette.background.paper}`,
    backgroundColor: palette.darkGrey.A100,
    boxShadow: "0px 0px 5px 1px #b4b9b977",
    fontWeight: 500,
  },
}));
