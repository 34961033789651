import React from "react";
import Header from "@modules/Header";
import Footer from "@modules/Footer";
import {useMediaQuery} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import NavbarTabletSize from "@modules/mainLayout/NavbarTabletSize";
import ActionsDrawer from "@modules/mainLayout/ActionsDrawer";
import {palette} from "@constant/colors";
import {useNavbarContext} from "@context/NavbarContext";
import {useActionsDrawerContext} from "@context/ActionsDrawerContext";
import Backdrop from "@modules/mainLayout/Backdrop";
import {useHistory} from "react-router-dom";
import {routes} from "@constant/routes";
import clsx from "clsx";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {ImageType} from "@modules/SearchBar";
import useServerErrorNotify from "@hook/useServerErrorNotify";

interface IMainLayoutProps {
  noDefaultSearchBar?: boolean;
  topPayload?: React.ReactNode;
}

export interface NavbarParentItem {
  type: string;
  name: string;
  items?: NavbarChildItem[];
}

export interface NavbarChildItem {
  name: string;
  order: number;
  items?: (
    | {
        id: number;
        name: string;
        href: string;
      }
    | {
        term: string;
        href: string;
      }
  )[];
}

const navbarItemsWithNoChildren: {type: ImageType; name: string}[] = [
  {type: "Photo", name: "عکس"},
  {type: "Vector", name: "وکتور"},
  {type: "Illustration", name: "طرح"},
];

const MainLayout: React.FC<IMainLayoutProps> = (props) => {
  const {children, noDefaultSearchBar, topPayload} = props;
  const {openNavbar, setOpenNavbar} = useNavbarContext();
  const {openActionsDrawer, setOpenActionsDrawer} = useActionsDrawerContext();
  const up300 = useMediaQuery((theme: Theme) => theme.breakpoints.up(300));
  const navbarWidth = up300 ? "300px" : "100%";
  const actionsDrawerWidth = up300 ? "250px" : "100%";
  const classes = useStyle({openNavbar, openActionsDrawer, navbarWidth, actionsDrawerWidth});
  const history = useHistory();
  const ServerErrorNotify = useServerErrorNotify();

  const {data: _data} = useExperimentalQuery("panel", "getMenu", {
    variables: {},
    staleTime: Infinity,
    onError: (error) => {
      if (history.location.pathname === "/") {
        ServerErrorNotify(error);
      }
    },
  });

  const data = _data as unknown as
    | {
        category_based: NavbarChildItem[];
        search_based: NavbarChildItem[];
      }
    | undefined;

  const navbarItems: NavbarParentItem[] = navbarItemsWithNoChildren.map((parent) => ({
    ...parent,
    items: data?.category_based
      .slice()
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        ...item,
        items: item.items?.map((child) => ({
          ...child,
          href: `${routes.landing.search}?type=${parent.type}&categories=${
            "id" in child ? child.id : ""
          }`,
        })),
      }))
      .concat(
        data?.search_based.map((item) => ({
          ...item,
          items: item.items?.map((child) => ({
            ...child,
            href: `${routes.landing.search}?type=${parent.type}&search=${
              "term" in child ? child.term : ""
            }`,
          })),
        }))
      ),
  }));

  const handleClickBackDrop = () => {
    setOpenNavbar(false);
    setOpenActionsDrawer(false);
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.content}>
        <aside className={classes.navbarBox}>
          <NavbarTabletSize navbarItems={navbarItems} />
        </aside>
        <div className={classes.fullWidth}>
          {topPayload}
          <Header navbarItems={navbarItems} noDefaultSearchBar={noDefaultSearchBar} />
          <main
            className={clsx(
              classes.mainContentBox,
              history.location.pathname !== routes.landing.search && classes.overflowHidden
            )}
          >
            {children}
            {(openNavbar || openActionsDrawer) && <Backdrop onClick={handleClickBackDrop} fixed />}
          </main>
          <Footer />
        </div>
        <aside className={classes.actionsDrawerBox}>
          <ActionsDrawer />
        </aside>
      </Grid>
    </div>
  );
};

export default MainLayout;

interface IStyleProps {
  openActionsDrawer: boolean;
  openNavbar: boolean;
  navbarWidth: string;
  actionsDrawerWidth: string;
}

const useStyle = makeStyles({
  root: ({openNavbar, openActionsDrawer}: IStyleProps) => ({
    overflow: openNavbar || openActionsDrawer ? "hidden" : "visible",
    "&:focus": {
      outline: "none",
    },
  }),
  content: ({openActionsDrawer, actionsDrawerWidth}: IStyleProps) => ({
    backgroundColor: palette.darkGrey.A600,
    transition: ".2s ease-in-out",
    transform: openActionsDrawer ? `translateX(-${actionsDrawerWidth})` : undefined,
  }),
  mainContentBox: {
    position: "relative",
    backgroundColor: "white",
  },
  fullWidth: {
    width: "100%",
    flexShrink: 0,
  },
  navbarBox: ({openNavbar, navbarWidth}: IStyleProps) => ({
    flexShrink: 0,
    width: openNavbar ? navbarWidth : 0,
    transition: ".2s ease-in-out",
    overflow: "hidden",
  }),
  actionsDrawerBox: ({actionsDrawerWidth}: IStyleProps) => ({
    flexShrink: 0,
    width: actionsDrawerWidth,
  }),
  overflowHidden: {
    overflow: "hidden",
  },
});

// why asides have been styled like this
// as you can see navbar box and action drawer box are rendered on the screen with different
// initial style. actions drawer has it's full width in the beginning but navbar box has 0px width
// to show actions drawer box we translate the parent container(with classes.content) but for
// navbar box we use transition on navbar box itself. why?

// our container has a direction:'rtl', so if we let the navbar box to has it's full width from the beginning
// it will force the main content to move to left. so you may think the solution will be using translateX
// on their parent (means we should tell the parent to have translateX(equal to navbar box width)) to render
// the main content exactly in the middle of the screen. in this position main content will
// be in the middle of the screen and asides on it's left and right and we can use translateX on parent to
// show navbar box and actions drawer box when it's needed.

// like this: in the beginning main content and both asides have their full width. parent has
// translateX(equal to navbar box width) to get position on the middle of the screen. if we
// want the user to see the navbar box we will set the parent transform = translateX(0)
// and if we want the user to see actions drawer, will set the parent transform =
// translateX(equal to navbar box width + actionsDrawer width).

// this seems a good idea but there is a CSS PROBLEM. when a parent has transform property, all
// it's children fixed position will turn to absolute, and this is not what we want.
// because search bar in 2 pages has position:'fixed'

// what causes the problem is only the navbar box. because of navbar box the parent should have
// the translate property from the beginning, so we use another solution for navbar box. but the
// actions drawer is ok because when we use translate on the parent to show the actions drawer box
// there is no needed for search bar to work with position='fixed' because we will disable the
//search bar when asides are open.

// so do what ever you want but don't use translate on the parent container(classes.content) in
// it's INITIAL STATE
