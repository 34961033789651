const key = "lastLocation";

export const setUserLastLocationInLs = (location: string) => {
  localStorage.setItem(key, location);
};

export const getUserLastLocationFromLs = () => {
  return localStorage.getItem(key);
};

export const removeUserLastLocationFromLs = () => {
  localStorage.removeItem(key);
};
