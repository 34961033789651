import { createContext, Dispatch, SetStateAction, useContext } from "react";

interface IInitialValue {
  openActionsDrawer: boolean;
  setOpenActionsDrawer: Dispatch<SetStateAction<boolean>>
  toggleActionsDrawer: () => void;
}

const initialValue: IInitialValue = {
  openActionsDrawer: false,
  setOpenActionsDrawer: () => { },
  toggleActionsDrawer: () => { },
};

export const actionsDrawerContext = createContext(initialValue);

export const useActionsDrawerContext = () => useContext(actionsDrawerContext);


