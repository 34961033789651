import {routes} from "@constant/routes";
import {IListProps} from "./List";

const {landing} = routes;

export const learnMore: IListProps["items"] = [
  {title: "پلن‌ها و قیمت‌ها", href: landing.pricing},
  {title: "کد‌های تخفیف پارس ایمیجز", href: landing.promoCode},
  {title: "بلاگ", href: landing.blog},
];

export const company: IListProps["items"] = [
  {title: "قوانین و مقررات سایت", href: landing.privacyAndPolicy},
  {title: "درباره ما", href: landing.about},
];

export const support: IListProps["items"] = [
  {title: "تماس با ما", href: landing.contactUs},
  {title: "سوالات متداول", href: landing.faq},
];
