import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {palette} from "@constant/colors";
import Span from "@elements/Span";
import Typography from "@elements/Typography";
import {CommonProps} from "@type/global";
import clsx from "clsx";

export interface ISwitchProps extends CommonProps {
  label: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

const Switch = (props: ISwitchProps) => {
  const {value: controlledValue, label, onChange, className, style} = props;
  const [value, setValue] = useState<boolean>(false);
  const classes = useStyle({value});

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  const handleClick = () => {
    setValue((prev) => {
      onChange?.(!prev);
      return !prev;
    });
  };

  return (
    <Box className={clsx(classes.container, className)} onClick={handleClick} style={style}>
      <Span className={classes.switchBox}>
        <Span className={classes.switch} />
      </Span>
      <Typography span size={1.4} color='white'>
        {label}
      </Typography>
    </Box>
  );
};

export default Switch;

const useStyle = makeStyles((theme) => ({
  container: {
    cursor: "pointer",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  switchBox: ({value}: {value: boolean}) => ({
    flexShrink: 0,
    backgroundColor: !value ? palette.darkGrey.A700 : palette.green[200],
    transition: ".05s",
    width: "37px",
    height: "19px",
    borderRadius: "2px",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      left: "9px",
      bottom: "6px",
      height: "5px",
      width: "1.7px",
      transform: "rotate(45deg)",
      transformOrigin: "bottom",
      backgroundColor: theme.palette.background.paper,
      transition: ".2s ease",
    },
    "&::before": {
      position: "absolute",
      content: '""',
      left: "10px",
      bottom: "6px",
      height: "9px",
      width: "1.7px",
      transform: "rotate(-45deg)",
      transformOrigin: "bottom",
      backgroundColor: theme.palette.background.paper,
      transition: ".2s ease",
    },
  }),
  switch: ({value}: {value: boolean}) => ({
    position: "absolute",
    display: "block",
    margin: "1px",
    width: "17px",
    height: "17px",
    right: value ? 0 : "18px",
    borderRadius: "2px",
    backgroundColor: theme.palette.background.paper,
    transition: ".3s ease",
    zIndex: 5,
  }),
}));
