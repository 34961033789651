import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import PlanCard from "@elements/panel/PlanCard";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import PlanModal from "@elements/panel/modals/PlanModal";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import Pagination from "@elements/panel/Pagination";
import Spinner from "@elements/Spinner";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {ApiNamespaces} from "@api";
import {useQueryClient} from "react-query";
import {useAlert} from "@context/AlertContext";
import {Plan} from "@schema";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInputFullWidth from "@elements/panel/QuerySearchInputFullWidth";
import useModalState from "@hook/useModalState";
import {convertToNumberFormatWithComma} from "@util/convertToNumberFormatWithComma";

const PAGE_SIZE = 15;
const Plans = () => {
  const [modalState, setModalState] = useModalState();
  const classes = useStyle();
  const {search, page, pageSize} = useGetQueryString();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const {data} = useExperimentalQuery("plans", "getList", {
    variables: {
      search,
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      isActive: "true",
    },
  });
  const {mutate: update} = useExperimentalMutation("plans", "update");

  const events = {
    onSuccess: () => {
      alert.success({});
      queryClient.invalidateQueries(ApiNamespaces.plans.Query.getList.queryKey);
    },
    onError: () => alert.error({}),
  };

  const handleSetActive = (id: number) => {
    update({variables: {id, data: {is_active: true} as Plan}, ...events});
  };
  const handleSetInactive = (id: number) => {
    update({variables: {id, data: {is_active: false} as Plan}, ...events});
  };

  return (
    <>
      {/* <Grid container direction='column' className={classes.container}> */}
      <Grid className={classes.container}>
        <QuerySearchInputFullWidth
          onClickAddIcon={() => setModalState({visible: true, activeId: null})}
        />
        {!data && <Spinner />}
        {data?.results.length === 0 && <NoResult />}
        {/* <Grid item> */}
        <Grid container wrap='wrap' className={classes.plansBox}>
          {data?.results.map(
            ({
              id,
              name,
              price,
              is_active,
              days,
              description,
              essential_credit,
              signature_credit,
            }) => (
              <Box className={classes.cardBox} key={id}>
                <PlanCard
                  id={id || 0}
                  title={name}
                  price={convertToNumberFormatWithComma(Number(price))}
                  premiumCredit={String(signature_credit)}
                  normalCredit={String(essential_credit)}
                  days={String(days)}
                  description={description}
                  isActive={is_active ? true : false}
                  onClickEdit={() => setModalState({visible: true, activeId: id || 1})}
                  onSetActive={handleSetActive}
                  onSetInactive={handleSetInactive}
                />
              </Box>
            )
          )}
        </Grid>
        {/* </Grid> */}
        <Pagination count={data?.count || 0} pageSize={PAGE_SIZE} />
      </Grid>
      <PlanModal
        open={modalState.visible}
        id={modalState.activeId}
        onClose={() => setModalState({visible: false, activeId: null})}
        onSubmit={() => setModalState({visible: false, activeId: null})}
      />
    </>
  );
};

export default Plans;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  inputRoot: {
    width: "100%",
    height: 65,
    backgroundColor: "#E3F2FD",
    marginBottom: 20,
    borderRadius: 4,
    paddingLeft: 60,
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
    },
  },
  addIcon: {
    width: 65,
    height: "100%",
    boxShadow: "0 2px 5px 0 #ccc, 0 2px 4px 0 #ddd",
  },
  plansBox: {
    direction: "ltr",
    columnGap: "2%",
    rowGap: 28,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
  cardBox: {
    width: "32%",
    [theme.breakpoints.up(1500)]: {
      width: "23.5%",
    },
    [theme.breakpoints.up(1700)]: {
      width: "18.4%",
    },
  },
}));
