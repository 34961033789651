import {useAlert} from "@context/AlertContext";
import Spinner from "@elements/Spinner";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import createImageUrl from "@util/createImageUrl";
import {useHistory, useParams} from "react-router-dom";

export default function BotImage() {
  const {id} = useParams<{id: string}>();
  const alert = useAlert();
  const history = useHistory();

  useExperimentalQuery("images", "readDetail", {
    variables: {id: Number(id)},
    enabled: !!id,
    onError: () => {
      alert.error({});
      history.replace("/");
    },
    onSuccess: (data) =>
      history.replace(createImageUrl(data.type || "", data.title || "", data.id_code || "")),
  });

  return <Spinner />;
}
