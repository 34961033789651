import React from "react";
import {InputBase, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import AddIcon from "./AddIcon";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import useDebouncedValue from "@hook/useDebouncedValue";

interface IProps {
  renderAddIcon?: boolean;
  onClickAddIcon?: () => void;
}

export default function QuerySearchInputFullWidth(props: IProps) {
  const {renderAddIcon = true, onClickAddIcon} = props;
  const {search: searchedQuery} = useGetQueryString();
  const [value, setValue] = React.useState(searchedQuery || "");
  const {t} = useTranslation("pages.panel");
  const classes = useStyles();
  const debouncedSearchValue = useDebouncedValue(value, 300);
  const handleUpdateQueryString = useUpdateQueryString();

  React.useEffect(() => {
    handleUpdateQueryString({search: debouncedSearchValue});
  }, [debouncedSearchValue]);

  React.useEffect(() => {
    searchedQuery && setValue(searchedQuery);
  }, [searchedQuery]);

  return (
    <InputBase
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={t("common.search")}
      classes={{root: classes.inputRoot}}
      endAdornment={
        (renderAddIcon || onClickAddIcon) && (
          <AddIcon className={classes.addIcon} onClick={() => onClickAddIcon?.()} />
        )
      }
    />
  );
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: "100%",
    height: 65,
    flexShrink: 0,
    backgroundColor: "#E3F2FD",
    marginBottom: 20,
    borderRadius: 4,
    paddingLeft: 60,
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
    },
  },
  addIcon: {
    width: 65,
    height: "100%",
    boxShadow: "0 2px 5px 0 #ccc, 0 2px 4px 0 #ddd",
  },
}));
