import {fontNormalizeFactor} from "@elements/Typography/constant";
import {makeStyles} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import React from "react";

export default function SnackbarCustomProvider({children}: {children: React.ReactNode}) {
  const notistackRef = React.createRef<SnackbarProvider>();
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={2}
      ref={notistackRef}
      className={classes.snackbar}
      onClick={() => notistackRef?.current?.closeSnackbar()}
      classes={{variantSuccess: classes.success}}
      preventDuplicate
      // action={(key) => (
      //   <Button
      //     size='medium'
      //     style={{ backgroundColor: "rgba(255,255,255,0.2)" }}
      //     onClick={() => notistackRef?.current?.closeSnackbar()}
      //   >
      //     <Typography size={1.4} color='white'>
      //       باشه
      //     </Typography>
      //   </Button>
      // )}
    >
      {children}
    </SnackbarProvider>
  );
}

const useStyles = makeStyles(() => ({
  snackbar: {
    pointerEvents: "none",
    "& > *": {
      fontSize: 16 * fontNormalizeFactor,
    },
    "& svg": {
      width: 26,
      height: 26,
    },
  },
  success: {
    backgroundColor: "#02937A",
  },
}));
