import {ImageType} from ".";

// text input history key
const lsPhraseHistoryKey = "phraseHistory";
export const localStPhraseHistoryGet = () => {
  const stored = localStorage.getItem(lsPhraseHistoryKey);
  const history = stored ? (JSON.parse(stored) as string[]) : [];
  return history;
};
export const localStPhraseHistorySet = (value: string[]) =>
  localStorage.setItem(lsPhraseHistoryKey, JSON.stringify(value));
export const localStPhraseHistoryAdd = (newValue: string) => {
  const newHistory = localStPhraseHistoryGet()
    .filter((item) => item !== newValue)
    .concat(newValue)
    .slice(-6);
  localStPhraseHistorySet(newHistory);
};
export const localStPhraseHistoryClear = () => localStorage.removeItem(lsPhraseHistoryKey);

// drop-down history key
const lsMediaTypeHistoryKey = "mediaTypeHistory";
export const localStMediaTypeHistoryGet = () => {
  const stored = localStorage.getItem(lsMediaTypeHistoryKey);
  const history = stored ? (JSON.parse(stored) as ImageType) : null;
  return history;
};
export const localStMediaTypeHistorySet = (value: string) =>
  localStorage.setItem(lsMediaTypeHistoryKey, JSON.stringify(value));
