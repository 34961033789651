import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Collapse, useMediaQuery, Theme} from "@material-ui/core";
import clsx from "clsx";
import Checked from "@elements/Checked";
import {useValidateDistinction} from "@hook/useValidateDistinction";
import {IValueLabelPair} from "@type/global";
import {convertToNumberFormatWithComma} from "@util/convertToNumberFormatWithComma";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";

export interface IDropdownProps<T> {
  placeholder?: string;
  resultNumber?: number;
  items: IValueLabelPair<T>[];
  value?: T;
  onChange?: (value: T) => void;
}

function Dropdown<T>(props: IDropdownProps<T>) {
  const {resultNumber, items, value: controlledValue, onChange, placeholder} = props;
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(500));
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<T>();
  const classes = useStyle({open});
  const activeItemLabel = items.find((item) => item.value === value)?.label;
  useValidateDistinction(items);

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  const handleClick = (value: T) => {
    setValue(value);
    setOpen(false);
    onChange?.(value);
  };

  return (
    <Box className={classes.root} onMouseLeave={() => setOpen(false)}>
      <Box className={classes.container} onClick={() => setOpen((prev) => !prev)}>
        <Box className={classes.innerBox}>
          {!xsDown && (
            <Typography span size={1.4} color={palette.darkGrey[300]}>
              {resultNumber !== undefined && convertToNumberFormatWithComma(resultNumber)}
            </Typography>
          )}
          <Box className={classes.flex}>
            <Typography
              span
              color={palette.darkGrey.A100}
              size={xsDown ? 1.4 : 1.6}
              weight={400}
              noWrap
              className={classes.placeholder}
            >
              {activeItemLabel || placeholder}
            </Typography>
            <svg viewBox='0 -4.861 8.233 13.861' className={classes.expandMoreIcon}>
              <path d='M8.233 2.018L1.251 9 0 7.645l5.627-5.627L0-3.61l1.251-1.25 6.982 6.878z' />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box onMouseLeave={() => setOpen(false)}>
        <Collapse in={open} timeout={200} className={classes.collapse}>
          {items.map(({label, ...item}) => (
            <Box
              key={label}
              className={clsx(classes.item, classes.borderTop)}
              onClick={() => handleClick(item.value)}
            >
              {value === item.value && <Checked className={classes.checked} />}
              <Typography size={xsDown ? 1.4 : 1.6} className={classes.label}>
                {label}
              </Typography>
            </Box>
          ))}
        </Collapse>
      </Box>
    </Box>
  );
}

export default Dropdown;

const useStyle = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    // I wrote this css style to prevent onMouseLeave event to be called in the distance
    // between the root container and the collapse element. why this works ? I HAVE NO IDEA
    // this problem is also in iStock the original. But here, there is also a 1px extra padding
    // (which again, HAVE NO IDEA where it comes from) that makes the problem even bigger.
    // finally this line, solved the problem. want to see again ? change the transparent
    // color to red, check the result, then comment the whole line and check again.
    border: "solid 1px transparent",
  },
  container: {
    width: "100%",
    maxWidth: "20rem",
    height: "100%",
    textAlign: "center",
    padding: "1.3rem 0 1.1rem 0",
    cursor: "pointer",
  },
  innerBox: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: `1px solid ${palette.lightGrey.A300}`,
    gap: ".8rem",
  },
  placeholder: {
    textTransform: "capitalize",
  },
  item: {
    display: "flex",
    alignItems: "center",
    height: "4.4rem",
    padding: "0 4.5rem",
    transition: "all 0.25s ease-in-out",
    whiteSpace: "nowrap",
    color: palette.darkGrey.A100,
    fontSize: "1.4rem",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      color: palette.green[200],
    },
    [theme.breakpoints.down(500)]: {
      padding: "0 2.5rem",
    },
  },
  checked: {
    position: "absolute",
    left: "2.5rem",
    transform: "translateY(-.2rem)",
    [theme.breakpoints.down(500)]: {
      left: ".5rem",
    },
  },
  borderTop: {
    "&:not(:first-child)": {
      borderTop: `1px solid ${palette.lightGrey.A300}`,
    },
  },
  collapse: {
    padding: "0 .8rem",
    border: `1.6px solid ${palette.lightGrey.A300}`,
    borderRight: "none",
    marginTop: ".19rem",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    zIndex: 1000,
    transform: "translateX(1px)",
  },
  expandMoreIcon: ({open}: {open: boolean}) => ({
    marginTop: ".5rem",
    transform: open ? "rotate(90deg)" : "rotate(270deg)",
    fill: palette.darkGrey.A700,
    width: "1.4rem",
    height: "1.2rem",
  }),
  label: {
    position: "relative",
  },
  flex: {
    display: "flex",
    gap: ".6rem",
    alignItems: "center",
  },
}));
