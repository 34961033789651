import {AboutUsApi, AboutUsApiAboutUsListRequest} from "@schema";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const aboutApiInstance = new AboutUsApi(undefined, undefined, axiosInstance);

export const aboutAPI = makeApi({
  identifier: "about",
  Query: {
    getList: createQuery(async (params: AboutUsApiAboutUsListRequest) => {
      const {data} = await aboutApiInstance.aboutUsList(params);
      return data;
    }),
  },
  Mutation: {},
});
