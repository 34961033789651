/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateImage
 */
export interface UpdateImage {
    /**
     * 
     * @type {number}
     * @memberof UpdateImage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    description?: string;
    /**
     * 
     * @type {Set<number>}
     * @memberof UpdateImage
     */
    categories?: Set<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateImage
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof UpdateImage
     */
    res_tags?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    type?: UpdateImageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    orientation?: UpdateImageOrientationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateImage
     */
    is_premium?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateImage
     */
    credit?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    size?: UpdateImageSizeEnum;
    /**
     * 
     * @type {object}
     * @memberof UpdateImage
     */
    dominant_colors?: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateImage
     */
    is_active?: boolean;
    /**
     * 
     * @type {Set<number>}
     * @memberof UpdateImage
     */
    collections?: Set<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateImage
     */
    is_free?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateImage
     */
    manual_edit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateImage
     */
    last_edit_date?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateImageTypeEnum {
    Photo = 'Photo',
    Vector = 'Vector',
    Illustration = 'Illustration'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateImageOrientationEnum {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical',
    Square = 'Square',
    PanoramicHorizontal = 'Panoramic Horizontal',
    PanoramicVertical = 'Panoramic Vertical'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateImageSizeEnum {
    Xl = 'XL',
    L = 'L',
    M = 'M',
    S = 'S'
}



