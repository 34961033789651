import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import TextInput from "@elements/TextInput";
import {MainButton} from "@elements/button";
import {lightGrey} from "@constant/colors";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import {generateYupSchema, IFormSchema, setFormErrors} from "@util/reactHookFormUtils";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import * as yup from "yup";

interface IFormValueTypes {
  new_password1: string;
  new_password2: string;
}

const ChangePassword = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const apiErrorHandler = useApiErrorHandler();

  const yupCommonValidation = yup
    .string()
    .min(8, "این فیلد باید حداقل ۸ رقم باشد")
    .required("پر کردن این فیلد الزامی‌ است");
  const schema: IFormSchema<IFormValueTypes> = [
    {
      name: "new_password1",
      validations: yupCommonValidation.matches(
        new RegExp(/^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/),
        "رمز عبور فقط می‌تواند شامل حروف انگلیسی، اعداد و سمبل باشد"
      ),
    },
    {
      name: "new_password2",
      validations: yupCommonValidation,
    },
  ];

  const {control, handleSubmit, setError} = useForm<IFormValueTypes>({
    resolver: yupResolver(generateYupSchema(schema)),
  });

  const {mutate} = useExperimentalMutation("auth", "passwordChange");
  const alert = useAlert();

  const handleSubmitPasswordChange: SubmitHandler<IFormValueTypes> = (values) => {
    if (values.new_password1 !== values.new_password2) {
      alert.error({text: "تکرار رمز عبور با رمز عبور مطابقت ندارد"});
      return;
    }
    mutate({
      variables: {
        data: {
          new_password1: values.new_password1,
          new_password2: values.new_password2,
        },
      },
      onSuccess: () => {
        alert.success({text: "تغییر رمز عبور با موفقیت انجام شد"});
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          setFormErrors(schema, setError, error);
        }
        apiErrorHandler(error);
      },
    });
  };

  return (
    <Grid
      component='form'
      className={classes.form}
      onSubmit={handleSubmit(handleSubmitPasswordChange)}
    >
      <Typography size={2.1} weight={500} className={classes.title}>
        {t("changePassword")}
      </Typography>
      <Grid className={classes.inputsBox}>
        <Controller
          name='new_password1'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("newPassword")}
              placeholder={t("newPassword")}
              isPassword
              isEnglish
              trim
              error={!!error}
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name='new_password2'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("repeatNewPassword")}
              placeholder={t("repeatNewPassword")}
              isPassword
              isEnglish
              trim
              error={!!error}
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <MainButton color='green' type='submit' fullWidth>
        {t("saveChange")}
      </MainButton>
    </Grid>
  );
};

export default ChangePassword;

const useStyle = makeStyles((theme) => ({
  form: {
    backgroundColor: lightGrey[200],
    padding: "2.5rem 4rem",
    marginTop: "1rem",
    marginBottom: "12rem",
    border: `1px solid ${lightGrey[600]}`,
    [theme.breakpoints.down(500)]: {
      padding: "2.5rem 2rem",
      marginBottom: "4rem",
    },
  },
  inputsBox: {
    marginBottom: "4rem",
    "& > *": {
      marginBottom: "1rem",
    },
  },
  title: {
    marginBottom: "3rem",
  },
}));
