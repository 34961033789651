import React from "react";
import {useMediaQuery, Theme, Grid, makeStyles, Box} from "@material-ui/core";
import {palette} from "@constant/colors";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import EditorContentWrapper from "@elements/EditorContentWrapper";
import CollapseGroup from "@elements/CollapseGroup";
import {Envelop, Phone as PhoneIcon} from "components/svg";
import CheckOurFaq from "@elements/CheckOurFaq";
import TextInput from "@elements/TextInput";
import {MainButton} from "@elements/button";
import {Info, Telegram} from "@material-ui/icons";
import Divider from "@elements/Divider";
import {Span} from "@elements/Span";
import Spinner from "@elements/Spinner";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {Helmet} from "react-helmet";

const MD_BREAKPOINT = 750;
export default function ContactUs() {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const {t} = useTranslation("pages.contactUs");
  const classes = useStyles();
  const alert = useAlert();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const serverErrorNotify = useServerErrorNotify();

  const {data: adminControl} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  const contactUsInfo = adminControl?.contact_us_info as
    | {
        mobile?: string;
        phone1?: string;
        phone2?: string;
        telegram?: string;
      }
    | undefined;

  const {data, isLoading} = useExperimentalQuery("faqs", "getList", {
    variables: {},
    staleTime: Infinity,
    onError: (error) => serverErrorNotify(error),
  });

  const {mutate, isLoading: isLoadingMutate} = useExperimentalMutation("messages", "create");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutate({
      variables: {data: {name, content: description}},
      onError: () => alert.error({}),
      onSuccess: () => {
        alert.success({text: "پیام شما با موفقیت ارسال شد"});
        setName("");
        setDescription("");
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>تماس با ما - پارس ایمیجز</title>
        <meta name='title' content='تماس با ما - پارس ایمیجز' />
      </Helmet>
      <BoxWithWallpaper color={palette.lightGrey[500]} className={classes.container}>
        <Grid container direction='column' alignItems='center' className={classes.maxWidth}>
          <Typography size={xsDown ? 2.8 : 3.5} weight={500} className={classes.title}>
            {t("title")}
          </Typography>
          <Grid container className={classes.rootFlexBox}>
            <Grid item container xs={12} justify='flex-start'>
              <Typography size={xsDown ? 2.1 : 2.6} weight={500} className={classes.faqTitle}>
                {t("topFrequentlyAskedQuestions")}
              </Typography>
              {isLoading && <Spinner />}
              {data && (
                <CollapseGroup
                  activeIndex={null}
                  items={data?.results.map(({title, content}) => ({
                    title,
                    value: <EditorContentWrapper content={content} />,
                  }))}
                />
              )}
              <CheckOurFaq style={{margin: "2.5rem 1.5rem 0 0", justifyContent: "flex-start"}} />
            </Grid>
            <Grid container direction='column' item xs={12}>
              <Grid container direction='column' className={classes.sellYourWorksBox}>
                <Typography size={1.8} weight={700}>
                  {t("sellYourWork")}
                </Typography>
                <Grid container style={{gap: ".5rem"}}>
                  <Typography span>{t("interestedInBeingAContributor")}</Typography>
                  <a
                    href={`https://t.me${
                      adminControl?.contact_us_info?.[
                        "telegram" as keyof typeof adminControl.contact_us_info
                      ]
                    }`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Typography span size={1.4} color={palette.green[200]}>
                      {t("soon")}
                    </Typography>
                  </a>
                </Grid>
              </Grid>
              <Grid container alignItems='center' style={{gap: "1.5rem", margin: "4rem 0 3rem"}}>
                <div style={{transform: "scale(.75) translateY(.5rem)"}}>
                  <Envelop />
                </div>
                <Typography span weight={700}>
                  {t("sendYourMessage")}
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit}>
                <TextInput
                  direction='rtl'
                  value={name}
                  onChange={(v) => setName(v)}
                  placeholder={t("nameAndFamilyName")}
                  required
                />
                <Box className={classes.infoMessageBox}>
                  <Info />
                  <Typography span size={1.5} weight={500}>
                    {t("pleaseEnterYourTelegramId")}
                  </Typography>
                </Box>
                <TextInput
                  direction='rtl'
                  value={description}
                  onChange={(v) => setDescription(v)}
                  placeholder={t("howCanWeHelpYou")}
                  required
                  multiline
                  rows={8}
                  className={classes.descriptionInput}
                />
                <MainButton
                  type='submit'
                  color='green'
                  fullWidth
                  disabled={!name.length || !description.length || isLoadingMutate}
                >
                  ارسال
                </MainButton>
              </form>
              <Divider style={{margin: "3rem 0 2.5rem"}} />
              <Grid container alignItems='flex-start' style={{gap: ".5rem"}}>
                <Span style={{flexShrink: 0}}>
                  <Telegram className={classes.telegramIcon} />
                </Span>
                <a href={`https://t.me${contactUsInfo?.telegram}`} target='_blank' rel='noreferrer'>
                  <Typography weight={700}>Parsdream</Typography>
                </a>
              </Grid>
              <Typography weight={500} style={{marginTop: "-.5rem"}}>
                ۲۴ ساعت در هفت روز هفته
              </Typography>
              <div
                style={{display: "flex", flexDirection: "column", gap: "1rem", marginTop: "2.5rem"}}
              >
                {[contactUsInfo?.phone1, contactUsInfo?.phone2, contactUsInfo?.mobile].map(
                  (item) => (
                    <a href={`tel:${item?.split("-").join("")}`} target='_blank' rel='noreferrer'>
                      <Typography weight={700}>{item}</Typography>
                    </a>
                  )
                )}
              </div>
              <Typography style={{marginTop: ".5rem"}}>شنبه تا پنجشنبه - ۸:۰۰ تا ۱۷:۰۰</Typography>
            </Grid>
          </Grid>
        </Grid>
      </BoxWithWallpaper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "1rem",
    paddingBottom: "10rem",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "5rem",
    },
  },
  maxWidth: {
    maxWidth: "104rem",
    margin: "0 auto",
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down(270)]: {
      padding: "0 .75rem",
    },
  },
  title: {
    margin: "3rem 0 4.8rem",
    [theme.breakpoints.down(MD_BREAKPOINT)]: {
      margin: "1.5rem 0 2.8rem",
    },
  },
  rootFlexBox: {
    gap: "6%",
    [theme.breakpoints.down(MD_BREAKPOINT)]: {
      flexDirection: "column",
    },
  },
  faqTitle: {
    margin: "0 1.5rem 2.5rem 0",
  },
  sellYourWorksBox: {
    backgroundColor: "white",
    padding: "2rem 1.4rem 4.2rem",
    gap: "2.4rem",
    [theme.breakpoints.down(MD_BREAKPOINT)]: {
      order: 2,
      marginTop: "4rem",
    },
  },
  infoMessageBox: {
    margin: "4rem 0 .5rem",
    "& *": {
      color: palette.blue.A300,
    },
    "& svg": {
      fontSize: 20,
      marginRight: ".5rem",
      transform: "translateY(.5rem)",
    },
  },
  descriptionInput: {
    lineHeight: "2rem",
    marginBottom: "2rem",
  },
  telegramIcon: {
    fontSize: 28,
    paddingBottom: 6,
    color: "#3390EC",
  },
}));
