import {Helmet} from "react-helmet";
import createImageUrl from "@util/createImageUrl";
import {RetrieveImage} from "@schema";

export default function MetaTags({data}: {data?: RetrieveImage}) {
  const getPageStaticTitle = (imageType?: string) =>
    " - "
      .concat("دانلود ")
      .concat(imageType || "عکس")
      .concat(" - ")
      .concat("پارس ایمیجز")
      .concat(" - ")
      .concat("download ")
      .concat(imageType || "image")
      .concat(" - ")
      .concat("Pars Images");

  return (
    <Helmet>
      <title>{data?.title?.concat(getPageStaticTitle())}</title>
      <meta
        name='description'
        content={(data?.description || data?.title)?.concat(getPageStaticTitle(data?.type))}
      />
      <meta
        name='keywords'
        content={data?.type?.concat(",").concat(data?.tags?.map(({name}) => name).join(",") || "")}
      />
      <meta content={data?.title} property='og:title' />
      <meta content={data?.file} property='og:image' />
      <meta content={data?.description || data?.title} property='og:description' />
      <meta property='og:image:width' />
      <meta property='og:image:height' />
      <meta
        content={window.origin.concat(
          createImageUrl(data?.type || "", data?.title || "", data?.id_code || "")
        )}
        property='og:url'
      />
      <meta content={data?.title} property='twitter:title' />
      <meta content={data?.file} property='twitter:image' />
      <meta content={data?.description || data?.title} property='twitter:description' />
      <meta property='og:twitter:width' />
      <meta property='og:twitter:height' />
    </Helmet>
  );
}
