import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";

const Copyright = () => {
  const classes = useStyle();
  const {t} = useTranslation("elements");

  return (
    <Grid container justify='center' alignItems='center' className={classes.container}>
      <Typography size={1.2} align='center' color='#a0a0a0'>
        &copy; {t("copyright.rights")}
      </Typography>
    </Grid>
  );
};

export default Copyright;

const useStyle = makeStyles(() => ({
  container: {
    backgroundColor: "rgba(12,13,13,0.7)",
    height: "6rem",
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "0 1rem",
  },
}));
