import qs from "qs";
import {useCallback} from "react";
import {useHistory, useLocation} from "react-router-dom";

interface IParams {
  [key: string]: string;
}

export const useUpdateQueryString = () => {
  const history = useHistory();
  const location = useLocation();
  return useCallback(
    (params: IParams, pathname?: string, scrollToTopBehavior?: ScrollBehavior) => {
      const oldParams = qs.parse(location.search, {ignoreQueryPrefix: true});
      let concatenatedParams = {...oldParams, ...params};
      // resets page to 1 if other query params change
      if (!params.hasOwnProperty("page") && oldParams.hasOwnProperty("page")) {
        concatenatedParams = {...concatenatedParams, page: "1"};
      }
      // filters empty string and undefined values
      const filteredParamsArray = Object.entries(concatenatedParams).filter(([, value]) => !!value);
      const finalParams = qs.stringify(Object.fromEntries(filteredParamsArray), {
        addQueryPrefix: true,
      });
      const path = pathname || location.pathname;
      history.push({pathname: path, search: finalParams});
      window.scrollTo({top: 0, behavior: scrollToTopBehavior || "auto"});
    },
    [history, location.pathname, location.search]
  );
};
