import index from "./index.json";
import {boards} from "./boards";
import signIn from "./signIn.json";
import signUp from "./signUp.json";
import forgotPassword from "./forgotPassword.json";
import {blog} from "./blog";
import panel from "./panel.json";
import faq from "./faq.json";
import contactUs from "./contactUs.json";

const pages = {
  "pages.index": index,
  "pages.faq": faq,
  "pages.contactUs": contactUs,
  "pages.signIn": signIn,
  "pages.signUp": signUp,
  "pages.forgotPassword": forgotPassword,
  "pages.panel": panel,
  ...boards,
  ...blog,
};
export {pages};
