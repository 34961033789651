import Typography from "@elements/Typography";
import {Box, Button} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import throttle from "lodash/throttle";
import SaveButton, {ISaveButtonProps} from "./SaveButton";
import {useTranslation} from "react-i18next";
import {darkGrey} from "@constant/colors";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Link} from "react-router-dom";
import {IImageCardProps} from "./types";
import {useAuth} from "@context/AuthContext";

type IProps = IImageCardProps & ISaveButtonProps;

/** Don't import it outside, it's espoused to be used only in ImageCard component conditionally */
export function ImageCardForSearchPage(props: IProps) {
  const classes = useStyles();
  const [hovered, setHovered] = React.useState(false);

  const {
    alt,
    src,
    isPremium,
    to,
    renderSimilarButton,
    deviceHasMouseSupport,
    onClickSimilarButton,
    ...saveButtonProps
  } = props;
  const {t} = useTranslation("elements");
  const timerRef = React.useRef<NodeJS.Timeout>();
  const {user} = useAuth();

  // handlers for handling component hover effect
  const clearTimer = () => timerRef.current && clearTimeout(timerRef.current);
  const mouseMoveEvent = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    // onClick also calls onMouseMove event and makes "if (!deviceHasMouseSupport && !hovered)"
    // condition to break. solved with "deviceHasMouseSupport &&" condition
    deviceHasMouseSupport && setHovered(true);
    clearTimer();
    timerRef.current = setTimeout(() => setHovered(false), 3500);
  };
  const handleMouseMove = throttle(mouseMoveEvent, 500, {leading: true, trailing: false});
  const handleMouseOut = () => {
    clearTimer();
    setHovered(false);
  };
  const handleMouseIn = () => {
    clearTimer();
    handleMouseMove.cancel();
    setHovered(true);
  };
  React.useEffect(() => {
    setHovered(true);
    return () => clearTimer();
  }, []);

  React.useEffect(() => {
    if (hovered) {
      timerRef.current = setTimeout(() => setHovered(false), 3500);
    }
  }, [hovered]);

  return (
    <Link
      to={to || "#"}
      onClick={(e) => {
        if (!deviceHasMouseSupport && !hovered) {
          e.preventDefault();
          setHovered(true);
        }
      }}
    >
      <Box className={clsx([classes.relative, classes.wAutoHFull])}>
        <Box
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
          className={clsx([
            classes.wFullHFull,
            classes.image,
            classes.overlay,
            hovered ? classes.overlayHovered : "",
          ])}
        >
          <Button
            classes={{label: classes.similarButtonText}}
            className={classes.similarButton}
            onMouseOver={handleMouseIn}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickSimilarButton?.();
            }}
          >
            {t("imageCard.imageHolder.similar")}
          </Button>
          {user && (
            <SaveButton
              onMouseOver={handleMouseIn}
              deviceHasMouseSupport={deviceHasMouseSupport}
              activeOnMount
              className={classes.saveButton}
              {...saveButtonProps}
            />
          )}
          {isPremium && (
            <Typography
              onMouseOver={handleMouseIn}
              noWrap
              className={classes.signature}
              color='white'
              size='100%'
            >
              {t("imageCard.imageHolder.signature")}
            </Typography>
          )}
        </Box>
      </Box>
    </Link>
  );
}

const useStyles = makeStyles(() => ({
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    transition: "opacity 0.3 ease",
    opacity: 0,
  },
  wFullHFull: {
    width: "100%",
    height: "100%",
  },
  relative: {
    position: "relative",
  },
  wAutoHFull: {
    width: "100%",
    height: "100%",
  },
  overlayHovered: {
    opacity: 1,
  },
  similarButton: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    bottom: ".75rem",
    left: ".5rem",
    borderRadius: 2,
    padding: "0 .3rem",
    "&:hover": {
      backgroundColor: "white",
      color: darkGrey.A700,
    },
  },
  similarButtonText: {
    fontSize: `${1.3 * fontNormalizeFactor}rem`,
    fontWeight: 500,
    lineHeight: "2.4rem",
  },
  signature: {
    fontSize: `${1.5 * fontNormalizeFactor}rem`,
    position: "absolute",
    bottom: ".75rem",
    right: ".5rem",
    // backgroundColor: "rgba(0,0,0,.25)",
    padding: ".15rem 0",
    borderRadius: 2,
    textShadow: "1px 1px 1px #555",
  },
  image: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  saveButton: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
  },
}));
