import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import Divider from "@elements/Divider";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import EditorContentWrapper from "@elements/EditorContentWrapper";
import CheckOurFaq from "@elements/CheckOurFaq";
import {FAQCardsScopeEnum} from "@schema";

const FaqQuestion = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.images.id");

  const {data} = useExperimentalQuery("faqs", "getList", {
    variables: {},
    staleTime: Infinity,
  });

  return (
    <Box component='section'>
      <Typography component='h2' size={2.4} className={classes.title}>
        {t("faqSection.FrequentlyAskedQuestions")}
      </Typography>
      <Divider />
      <Box className={classes.faq}>
        {data?.results
          .filter((item) => item.scope === FAQCardsScopeEnum.Image)
          .slice()
          .sort((a, b) => (a.priority || 0) - (b.priority || 0))
          .map(({title, content}) => (
            <React.Fragment key={title}>
              <Typography size={2.1} className={classes.question}>
                {title}
              </Typography>
              <Box className={classes.answer}>
                <EditorContentWrapper content={content} />
              </Box>
            </React.Fragment>
          ))}
      </Box>
      <CheckOurFaq className={classes.moreQuestion} />
    </Box>
  );
};

export default FaqQuestion;

const useStyle = makeStyles((theme) => ({
  title: {
    padding: "5rem 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "2.5rem 0",
    },
  },
  faq: {
    padding: "1.5rem 0 2.5rem",
  },
  question: {
    marginTop: "2.5rem",
  },
  answer: {
    margin: "1.5rem 0",
  },
  flex: {
    padding: "3rem 2.4rem",
    gap: ".5rem",
    direction: "rtl", // temp
    [theme.breakpoints.down(400)]: {
      flexDirection: "column-reverse",
      padding: "2rem 0 1rem",
      gap: 0,
    },
  },
  moreQuestion: {
    padding: "3rem 0 2.5rem",
  },
}));
