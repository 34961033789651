import {
  PlansApi,
  PlansApiPlansReadRequest,
  PlansApiPlansListRequest,
  PlansApiPlansCreateRequest,
  PlansApiPlansPartialUpdateRequest,
  PlansApiPlansDeleteRequest,
} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const plansApiInstance = new PlansApi(undefined, undefined, axiosInstance);

export const plansAPI = makeApi({
  identifier: "plans",
  Query: {
    getList: createQuery(async (params: PlansApiPlansListRequest) => {
      const {data} = await plansApiInstance.plansList(params);
      return data;
    }),
    readDetail: createQuery(async (params: PlansApiPlansReadRequest) => {
      const {data} = await plansApiInstance.plansRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: PlansApiPlansCreateRequest) => {
      const {data} = await plansApiInstance.plansCreate(params);
      return data;
    },
    update: async (params: PlansApiPlansPartialUpdateRequest) => {
      const {data} = await plansApiInstance.plansPartialUpdate(params);
      return data;
    },
    delete: async (params: PlansApiPlansDeleteRequest) => {
      const {data} = await plansApiInstance.plansDelete(params);
      return data;
    },
  },
});
