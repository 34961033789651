import {
  CategoriesApi,
  CategoriesApiCategoriesCreateRequest,
  CategoriesApiCategoriesDeleteRequest,
  CategoriesApiCategoriesListRequest,
  CategoriesApiCategoriesPartialUpdateRequest,
  CategoriesApiCategoriesReadRequest,
} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const categoriesApiInstance = new CategoriesApi(undefined, undefined, axiosInstance);

export const categoriesAPI = makeApi({
  identifier: "categories",
  Query: {
    getList: createQuery(async (params: CategoriesApiCategoriesListRequest) => {
      const {data} = await categoriesApiInstance.categoriesList(params);
      return data;
    }),
    readDetail: createQuery(async (params: CategoriesApiCategoriesReadRequest) => {
      const {data} = await categoriesApiInstance.categoriesRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: CategoriesApiCategoriesCreateRequest) => {
      const {data} = await categoriesApiInstance.categoriesCreate(params);
      return data;
    },
    update: async (params: CategoriesApiCategoriesPartialUpdateRequest) => {
      const {data} = await categoriesApiInstance.categoriesPartialUpdate(params);
      return data;
    },
    delete: async (params: CategoriesApiCategoriesDeleteRequest) => {
      const {data} = await categoriesApiInstance.categoriesDelete(params);
      return data;
    },
  },
});
