import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, CardMedia, Grid} from "@material-ui/core";
import Divider from "@elements/Divider";
import clsx from "clsx";
import Typography from "@elements/Typography";

export interface ICollectionCardProps {
  title: string;
  description: string;
  image: string;
  className?: string;
}

const CollectionCard = (props: ICollectionCardProps) => {
  const classes = useStyle();
  const {title, description, image, className} = props;

  return (
    <Box className={clsx(classes.container, className)}>
      <CardMedia
        component='img'
        // src image is TEMPORARY
        src={image}
        className={classes.image}
      />
      <Typography size={1.8}>{title}</Typography>
      <Typography size={1.4} color='#666e6e'>
        {description}
      </Typography>
    </Box>
  );
};

export default CollectionCard;

const useStyle = makeStyles(() => ({
  container: {
    width: "35rem",
    maxWidth: "100%",
    minHeight: "24rem",
    padding: "1rem 1rem 2rem",
    textAlign: "center",
  },
  padding: {
    padding: "0 2.5rem",
  },
  image: {
    height: "20rem",
    marginBottom: 8,
  },
}));
