import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Hidden} from "@material-ui/core";
import Typography from "@elements/Typography";
import TextInput from "./TextInput";
import {palette} from "@constant/colors";
import {useTranslation} from "react-i18next";
import {useAlert} from "@context/AlertContext";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useHistory} from "react-router-dom";
import {formatDate} from "@util/global";
import {useQueryClient} from "react-query";
import {ApiNamespaces} from "@api";
import {routes} from "@constant/routes";
import {RetrieveBoard} from "@schema";

const BoardInfoSection = ({data}: {data?: RetrieveBoard}) => {
  const classes = useStyle();
  const {t} = useTranslation("pages.boards.[id]");
  const alert = useAlert();
  const history = useHistory();
  const queryClient = useQueryClient();
  const id = data?.id;

  const invalidate = () => {
    queryClient.invalidateQueries(ApiNamespaces.boards.Query.getRecentBoardsList.queryKey);
  };

  const {mutate: deleteBoard} = useExperimentalMutation("boards", "delete", {
    onError: () => alert.error({}),
    onSuccess: () => {
      alert.success({text: "بورد با موفقیت حذف شد"});
      invalidate();
      history.push(routes.landing.boards);
    },
  });
  const {mutate: updateBoard} = useExperimentalMutation("boards", "update", {
    onError: () => alert.error({}),
    onSuccess: () => {
      alert.success({text: "بورد با موفقیت ویرایش شد"});
      queryClient.invalidateQueries([ApiNamespaces.boards.Query.readDetail.queryKey, {id}]);
      invalidate();
    },
  });

  return (
    <Box className={classes.container}>
      <Grid container justify='space-between' className={classes.gap}>
        <TextInput
          fontSize={1.8}
          placeholder={t("BoardInfoSection.title")}
          value={data?.name}
          containerClassName={classes.titleInput}
          onSubmit={(v) => id && updateBoard({variables: {id, data: {name: v}}})}
        />
        <Hidden xsDown>
          <Typography
            span
            size={1.4}
            weight={500}
            className={classes.deleteButton}
            onClick={() =>
              id && alert.prompt({}).then(({result}) => result && deleteBoard({variables: {id}}))
            }
          >
            {t("BoardInfoSection.removeThisBoard")}
          </Typography>
        </Hidden>
      </Grid>
      <Typography span size={1.2} color={palette.lightGrey.A800}>
        {`${t("BoardInfoSection.lastUpdate")}: ${data && formatDate(data.last_update)}`}
      </Typography>

      <TextInput
        fontSize={1.4}
        multiline
        placeholder={t("BoardInfoSection.description")}
        value={data?.description || ""}
        onSubmit={(v) => id && updateBoard({variables: {id, data: {description: v}}})}
        containerClassName={classes.descriptionInput}
      />
      <Typography span size={1.1} color={palette.darkGrey.A100}>
        {/* {`${t("BoardInfoSection.createdAt")}: ${t("BoardInfoSection.time", {count: 4, context: "week"})}`} */}
        {`${t("BoardInfoSection.createdAt")}: ${data && formatDate(data.created)}`}
      </Typography>
    </Box>
  );
};

export default BoardInfoSection;

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down(700)]: {
      paddingLeft: "1.2rem",
    },
  },
  titleInput: {
    height: "3rem",
    maxWidth: "60rem",
    width: "100%",
    marginBottom: ".7rem",
  },
  descriptionInput: {
    minHeight: "3.5rem",
    maxWidth: "100rem",
    width: "100%",
    marginTop: "1rem",
  },
  deleteButton: {
    color: palette.green[200],
    cursor: "pointer",
    whiteSpace: "nowrap",
    "&:hover": {
      color: palette.green[500],
    },
  },
  gap: {
    gap: "2rem",
  },
}));
