import {lightGrey, palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {Collapse} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAlert} from "@context/AlertContext";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {maxPossibleImagesInOneBoard, maxPossibleBoards} from "@constant/boards";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import clsx from "clsx";
import Wrapper from "./Wrapper";
import {useModalDispatch} from "@context/ModalContext";

export interface ChooseBoardsOptionsModalProps {
  filesCount: number;
  onCopyToRecentBoards?: (boardId: number) => void;
  onMoveToRecentBoards?: (boardId: number) => void;
  onCreatingNewBoard?: (type: "copy" | "move") => void;
}

/** unlike panel modals, in landing modals, mutation are outside the modal itself
 * some times to prevent duplication, some time for different scenarios of using one modal
 * and for unification */
function ModalBody(props: ChooseBoardsOptionsModalProps) {
  const {
    onCopyToRecentBoards,
    onMoveToRecentBoards,
    onCreatingNewBoard: handleCreatingNewBoard,
    filesCount,
  } = props;
  const [showCopyOptions, setShowCopyOptions] = React.useState(false);
  const [showMoveOptions, setShowMoveOptions] = React.useState(false);
  const classes = useStyles();
  const {t} = useTranslation("modules.modals");
  const alert = useAlert();
  const handleCloseModal = useModalDispatch(null);

  const {data: boards} = useExperimentalQuery("boards", "getList", {
    variables: {pageSize: maxPossibleBoards},
    onError: () => alert.error({}),
  });

  const renderBoardsList = (type: "copy" | "move") => (
    <>
      <Typography
        className={clsx(classes.createNewBoardButton, classes.item)}
        onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
          e.stopPropagation();
          handleCreatingNewBoard?.(type);
        }}
      >
        {t("addToBoard.createNewBoard")}
      </Typography>
      <ul className={classes.list}>
        <Typography color='#a7a7a7' size={1.3} style={{marginBottom: ".5rem"}}>
          بوردهای من
        </Typography>
        {boards?.results.map(({name, id, images_count}) => (
          <li
            key={id}
            className={clsx(classes.item)}
            onClick={(e: React.MouseEvent<HTMLLIElement>) => {
              e.stopPropagation();
              if (
                (images_count ? Number(images_count) : 0) + filesCount >
                maxPossibleImagesInOneBoard
              ) {
                alert.error({
                  text: t("common.notEnoughSpaceInBoard", {
                    content: maxPossibleImagesInOneBoard - Number(images_count),
                  }),
                });
                return;
              }
              type === "copy" ? onCopyToRecentBoards?.(id!) : onMoveToRecentBoards?.(id!);
              handleCloseModal();
            }}
          >
            {name}
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <div className={classes.container}>
      <div
        className={classes.options}
        onClick={() => {
          setShowMoveOptions(false);
          setShowCopyOptions((prev) => !prev);
        }}
      >
        <Typography size={1.8} noWrap>
          {t("chooseOptions.copyToBoard")}
        </Typography>
        <Collapse in={showCopyOptions}>{renderBoardsList("copy")}</Collapse>
      </div>
      <div
        className={classes.options}
        onClick={() => {
          setShowCopyOptions(false);
          setShowMoveOptions((prev) => !prev);
        }}
      >
        <Typography size={1.8} noWrap>
          {t("chooseOptions.moveToBoard")}
        </Typography>
        <Collapse in={showMoveOptions}>{renderBoardsList("move")}</Collapse>
      </div>
    </div>
  );
}

export const ChooseBoardsOptionsModal = Wrapper(ModalBody, "chooseBoardOptions", {
  title: "یک گزینه را انتخاب کنید.",
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "38rem",
  },
  options: {
    width: "100%",
    border: `1px solid ${lightGrey.A800}`,
    borderBottom: "none",
    cursor: "pointer",
    padding: "2rem",
    "&:hover": {
      backgroundColor: "#f2f5f5",
    },
    "&:last-child": {
      borderBottom: `1px solid ${lightGrey.A800}`,
    },
  },
  createNewBoardButton: {
    margin: "2.5rem 0",
  },
  list: {
    "& li": {
      padding: ".5rem 0",
    },
  },
  item: {
    color: palette.green[200],
    fontSize: 16 * fontNormalizeFactor,
    "&:hover": {
      color: palette.green[500],
    },
  },
}));
