import {palette} from "@constant/colors";
import {useAlert} from "@context/AlertContext";
import Tooltip from "@elements/Tooltip";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Grid, IconButton, InputBase, InputBaseProps} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import clsx from "clsx";
import React, {useEffect, useState} from "react";

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
interface IProps {
  onChange?: (value: string, event: ChangeEvent) => void;
  errorMessage?: string;
  maxLength?: number;
  partiallyValidator?: RegExp;
  finalValidator?: RegExp;
  onValidValue?: () => void;
  onInvalidValue?: () => void;
  label?: string;
  isValid?: boolean;
  tooltipText?: string;
  direction?: "ltr" | "rtl";
  isPassword?: boolean;
  isEnglish?: boolean;
  optional?: boolean;
  trim?: boolean;
}
export type TextInputProps = IProps & Omit<InputBaseProps, "onChange">;
export const TextInput = React.forwardRef((props: TextInputProps, ref) => {
  const {
    value: controlledValue,
    onChange,
    placeholder,
    required,
    error,
    errorMessage,
    maxLength,
    partiallyValidator = /.*/,
    finalValidator = /.*/,
    onValidValue,
    onInvalidValue,
    isValid,
    label,
    tooltipText,
    direction = "ltr",
    isPassword,
    isEnglish,
    optional,
    className,
    trim,
    ...rest
  } = props;
  const [value, setValue] = useState("");
  const [password, setPassword] = useState(true);
  const classes = useStyle({value, errorMessage, isValid, isPassword, password});
  const alert = useAlert();

  useEffect(() => {
    const value = controlledValue as string | undefined;
    if (value === undefined) {
      return;
    }
    partiallyValidator.test(value) && setValue(value);
    finalValidator.test(value) ? onValidValue?.() : onInvalidValue?.();
  }, [controlledValue, finalValidator, onInvalidValue, onValidValue, partiallyValidator]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {charCode} = event;
    if (isEnglish && !(charCode >= 0 && charCode <= 127)) {
      alert.error({text: "لطفا زبان کیبورد خود را به انگلیسی تغییر دهید."});
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let {value} = event.target;
    if (trim) {
      value = value.trim();
    }

    if (partiallyValidator.test(value)) {
      setValue(value);
      onChange?.(value, event);
    }
    finalValidator.test(value) ? onValidValue?.() : onInvalidValue?.();
  };

  return (
    <Grid container direction='column'>
      {label && (
        <Typography size={1.4} className={classes.marginBottom}>
          {label}
          {optional && (
            <Typography span size={1.1} style={{margin: "0 .5rem"}}>
              (اختیاری)
            </Typography>
          )}
          {tooltipText && (
            <Tooltip title={tooltipText} enterDelay={200} enterNextDelay={200} leaveDelay={200}>
              <IconButton className={classes.tooltipIcon}>؟</IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <InputBase
        style={{direction: value.length && direction === "ltr" ? "ltr" : "rtl"}}
        fullWidth
        type={isPassword && password ? "password" : "text"}
        value={value}
        onChange={handleChange}
        // onKeyPress={handleKeyPress}
        placeholder={placeholder}
        className={clsx(classes.TextInput, className)}
        error={error || !!errorMessage}
        inputProps={{maxLength: maxLength, onKeyPress: handleKeyPress}}
        endAdornment={
          isPassword &&
          value && (
            <IconButton onClick={() => setPassword((prev) => !prev)}>
              {!password ? (
                <VisibilityOff className={classes.doneIcon} />
              ) : (
                <Visibility className={classes.doneIcon} />
              )}
            </IconButton>
          )
        }
        {...rest}
        classes={{
          focused: classes.focus,
          input: classes.input,
          error: classes.error,
        }}
        ref={ref}
      />
      {errorMessage && (
        <Typography size={1.4} className={classes.errorMessageBox}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
});
export default TextInput;

interface StyleProps {
  isValid?: boolean;
  errorMessage?: string;
  password: boolean;
  isPassword?: boolean;
  value: string;
}
const useStyle = makeStyles((theme) => ({
  TextInput: ({isValid}: StyleProps) => ({
    boxSizing: "border-box",
    width: " 100%",
    minHeight: "5.9rem",
    border: isValid ? `1px solid ${palette.green[200]}` : `1px solid ${palette.darkGrey[300]}`,
    backgroundColor: theme.palette.background.paper,
  }),
  input: ({value, password, isPassword}: StyleProps) => ({
    padding: "1px .85rem",
    color: palette.darkGrey.A700,
    fontSize: `${(value && isPassword && password ? 1.4 : 1.6) * fontNormalizeFactor}rem`,
    fontFamily: value && isPassword && password ? "sans-serif" : "Kalameh",
    "&::placeholder": {
      fontSize: `${1.4 * fontNormalizeFactor}rem`,
      color: palette.darkGrey[800],
      fontWeight: 500,
      [theme.breakpoints.down(350)]: {
        fontSize: `${1.2 * fontNormalizeFactor}rem`,
      },
    },
  }),
  focus: {
    border: `2px solid ${palette.darkGrey[300]} !important`,
  },
  error: ({errorMessage}: StyleProps) => ({
    border: !!errorMessage
      ? `1px solid ${palette.yellow[200]}`
      : `2px solid ${palette.yellow[200]}`,
  }),
  errorMessageBox: {
    marginTop: "3px",
    backgroundColor: palette.yellow[200],
    color: "black",
    lineHeight: "1.8rem",
    padding: "2rem ",
    transition: "all .3s ease-out",
  },
  doneIcon: {
    color: "#666",
    fontSize: "2.2rem",
  },
  marginBottom: {
    marginBottom: ".5rem",
  },
  tooltipIcon: {
    fontSize: "2rem",
    margin: "0 .5rem",
    border: "solid 1px grey",
    borderRadius: 2,
    fontWeight: "bold",
    height: "2rem",
    width: "2rem",
    padding: 0,
  },
}));
