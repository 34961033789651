import React from "react";
import {Grid, IconButton, makeStyles} from "@material-ui/core";
import IroColorPicker from "./IroColorPicker";
import {InputBase} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import Switch from "@elements/Switch";
import Tooltip from "@elements/Tooltip";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";

const WIDTH = 185;
export default function ColorPicker() {
  const classes = useStyles();
  const regex = new RegExp(/^#[0-9a-fA-F]{6}$/);
  const {color: colorParam, gray} = useGetQueryString();
  const [color, setColor] = React.useState("#ffffff");
  const [inputValue, setInputValue] = React.useState(colorParam ? "#".concat(colorParam) : "");
  const [switchValue, setSwitchValue] = React.useState(!!gray || false);

  React.useEffect(() => {
    setColor(colorParam ? "#".concat(colorParam) : "#ffffff");
  }, [colorParam]);

  React.useEffect(() => {
    setSwitchValue(!!gray);
  }, [gray]);

  const handleUpdateQueryString = useUpdateQueryString();
  const handleChangeColor = (color: string) => {
    handleUpdateQueryString({page: "1", gray: "", color: color ? color.slice(1) : ""});
  };

  return (
    <div className={classes.container}>
      <IroColorPicker
        width={WIDTH}
        color={color}
        onChange={(color: any) => {
          setColor(color.hexString);
          setInputValue(color.hexString);
          handleChangeColor(color.hexString);
        }}
      />
      <Grid container alignItems='center' className={classes.inputBox}>
        <InputBase
          placeholder='# HEX'
          classes={{root: classes.input}}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            if (regex.test(e.target.value)) {
              handleChangeColor(e.target.value);
            }
          }}
          endAdornment={
            <Tooltip title='حذف فیلتر رنگ'>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  handleChangeColor("");
                  setInputValue("");
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          }
        />
        <Tooltip title='کد وارد شده حتما باید شامل ۶ رقم باشد و با # شروع شود'>
          <IconButton className={classes.tooltip}>؟</IconButton>
        </Tooltip>
      </Grid>
      <Switch
        label='سیاه و سفید'
        value={switchValue}
        onChange={(v) => {
          setSwitchValue(v);
          handleUpdateQueryString({gray: v ? "true" : "", page: "1", color: ""});
          setInputValue("");
        }}
        style={{margin: "1.8rem 0 1rem"}}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {},
  inputBox: {
    marginTop: "1.8rem",
    gap: 4,
  },
  input: {
    direction: "rtl",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    height: 35,
    backgroundColor: "white",
    borderRadius: 4,
    width: WIDTH - 25,
    paddingRight: 10,
    "& *": {
      fontSize: 16,
    },
  },
  icon: {
    width: 35,
    height: 35,
    borderRadius: 4,
    "& svg": {
      fontSize: 25,
    },
  },
  tooltip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    width: 25,
    height: 35,
    fontWeight: 700,
    border: "solid 1px #aaa",
    borderRadius: 3,
    userSelect: "none",
    "&:hover": {
      backgroundColor: "#eee !important",
    },
  },
}));
