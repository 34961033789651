import {ReactCodeInput} from "./react-code-input";
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {ReactCodeInputProps} from "./types";
import {darkGrey, yellow} from "@constant/colors";
import Typography from "@elements/Typography";

interface CodeInputSpecificProps {
  label: string;
  errorMessage?: string;
}

export type CodeInputProps = Omit<
  ReactCodeInputProps,
  "inputStyle" | "className" | "type" | "inputMode" | "isValid"
> &
  CodeInputSpecificProps;

export default function CodeInput({label, errorMessage, ...props}: CodeInputProps) {
  const classes = useStyles({errorMessage});
  return (
    <Grid container direction='column'>
      <Typography size={1.4} color={darkGrey[300]}>
        {label}
      </Typography>
      <Box className={classes.margin}>
        <ReactCodeInput
          {...props}
          isValid={!errorMessage}
          inputMode='tel'
          className={classes.codeInput}
        />
      </Box>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  codeInput: {
    direction: "rtl",
    fontSize: "2rem",
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: darkGrey.A700,
    "&>input": {
      width: "5.6rem",
      height: "5.6rem",
      marginRight: "0.75rem",
      marginLeft: "0.75rem",
      borderRadius: 10,
      textAlign: "center",
      background: theme.palette.background.paper,
      border: "solid 2px #bbb",
      [theme.breakpoints.down(450)]: {
        width: "18%",
        height: "13vw",
        marginRight: "1%",
        marginLeft: "1%",
      },
    },
    "&>input:focus": {
      borderColor: darkGrey[300],
    },
    "&>input:first-child": {
      marginInlineStart: 0,
    },
    "&>input:last-child": {
      marginInlineEnd: 0,
    },
    "&>input[data-valid=false]": {
      borderColor: yellow[200],
    },
  },
  margin: {
    margin: "1rem auto 0",
  },
}));
