import React from "react";

export type AlertProps = {text?: string; persist?: boolean};
export type PromptResult = {result: boolean; cancelReason?: "dismiss" | "refuse"};

interface AlertContextSchema {
  info: (props: AlertProps) => Promise<boolean>;
  success: (props: AlertProps) => Promise<boolean>;
  error: (props: AlertProps) => Promise<boolean>;
  prompt: (props: AlertProps) => Promise<PromptResult>;
}

export const AlertContext = React.createContext<AlertContextSchema>({
  info: () => Promise.resolve(false),
  success: () => Promise.resolve(false),
  error: () => Promise.resolve(false),
  prompt: () => Promise.resolve({result: false}),
});

export const useAlert = () => React.useContext(AlertContext);
