/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminImageBuyByPlan } from '../servermodel';
// @ts-ignore
import { ImageBuyByPlan } from '../servermodel';
// @ts-ignore
import { InlineResponse2003 } from '../servermodel';
/**
 * BuyByPlanApi - axios parameter creator
 * @export
 */
export const BuyByPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanAdminCreate: async (data: AdminImageBuyByPlan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('buyByPlanAdminCreate', 'data', data)
            const localVarPath = `/buy-by-plan/admin_create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanCreate: async (data: ImageBuyByPlan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('buyByPlanCreate', 'data', data)
            const localVarPath = `/buy-by-plan/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyByPlanDelete', 'id', id)
            const localVarPath = `/buy-by-plan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanList: async (fromDaysBefore?: number, isActive?: string, order?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/buy-by-plan/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanPartialUpdate: async (id: string, data: AdminImageBuyByPlan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyByPlanPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('buyByPlanPartialUpdate', 'data', data)
            const localVarPath = `/buy-by-plan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyByPlanRead', 'id', id)
            const localVarPath = `/buy-by-plan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanUpdate: async (id: string, data: AdminImageBuyByPlan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buyByPlanUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('buyByPlanUpdate', 'data', data)
            const localVarPath = `/buy-by-plan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyByPlanApi - functional programming interface
 * @export
 */
export const BuyByPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyByPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanAdminCreate(data: AdminImageBuyByPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminImageBuyByPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanAdminCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanCreate(data: ImageBuyByPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageBuyByPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanList(fromDaysBefore?: number, isActive?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanList(fromDaysBefore, isActive, order, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanPartialUpdate(id: string, data: AdminImageBuyByPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminImageBuyByPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageBuyByPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyByPlanUpdate(id: string, data: AdminImageBuyByPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminImageBuyByPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyByPlanUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuyByPlanApi - factory interface
 * @export
 */
export const BuyByPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyByPlanApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanAdminCreate(data: AdminImageBuyByPlan, options?: any): AxiosPromise<AdminImageBuyByPlan> {
            return localVarFp.buyByPlanAdminCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanCreate(data: ImageBuyByPlan, options?: any): AxiosPromise<ImageBuyByPlan> {
            return localVarFp.buyByPlanCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.buyByPlanDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanList(fromDaysBefore?: number, isActive?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.buyByPlanList(fromDaysBefore, isActive, order, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanPartialUpdate(id: string, data: AdminImageBuyByPlan, options?: any): AxiosPromise<AdminImageBuyByPlan> {
            return localVarFp.buyByPlanPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanRead(id: string, options?: any): AxiosPromise<ImageBuyByPlan> {
            return localVarFp.buyByPlanRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageBuyByPlan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyByPlanUpdate(id: string, data: AdminImageBuyByPlan, options?: any): AxiosPromise<AdminImageBuyByPlan> {
            return localVarFp.buyByPlanUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for buyByPlanAdminCreate operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanAdminCreateRequest
 */
export interface BuyByPlanApiBuyByPlanAdminCreateRequest {
    /**
     * 
     * @type {AdminImageBuyByPlan}
     * @memberof BuyByPlanApiBuyByPlanAdminCreate
     */
    readonly data: AdminImageBuyByPlan
}

/**
 * Request parameters for buyByPlanCreate operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanCreateRequest
 */
export interface BuyByPlanApiBuyByPlanCreateRequest {
    /**
     * 
     * @type {ImageBuyByPlan}
     * @memberof BuyByPlanApiBuyByPlanCreate
     */
    readonly data: ImageBuyByPlan
}

/**
 * Request parameters for buyByPlanDelete operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanDeleteRequest
 */
export interface BuyByPlanApiBuyByPlanDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanDelete
     */
    readonly id: string
}

/**
 * Request parameters for buyByPlanList operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanListRequest
 */
export interface BuyByPlanApiBuyByPlanListRequest {
    /**
     * 
     * @type {number}
     * @memberof BuyByPlanApiBuyByPlanList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanList
     */
    readonly isActive?: string

    /**
     * 
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanList
     */
    readonly order?: string

    /**
     * A search term.
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BuyByPlanApiBuyByPlanList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BuyByPlanApiBuyByPlanList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for buyByPlanPartialUpdate operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanPartialUpdateRequest
 */
export interface BuyByPlanApiBuyByPlanPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanPartialUpdate
     */
    readonly id: string

    /**
     * 
     * @type {AdminImageBuyByPlan}
     * @memberof BuyByPlanApiBuyByPlanPartialUpdate
     */
    readonly data: AdminImageBuyByPlan
}

/**
 * Request parameters for buyByPlanRead operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanReadRequest
 */
export interface BuyByPlanApiBuyByPlanReadRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanRead
     */
    readonly id: string
}

/**
 * Request parameters for buyByPlanUpdate operation in BuyByPlanApi.
 * @export
 * @interface BuyByPlanApiBuyByPlanUpdateRequest
 */
export interface BuyByPlanApiBuyByPlanUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyByPlanApiBuyByPlanUpdate
     */
    readonly id: string

    /**
     * 
     * @type {AdminImageBuyByPlan}
     * @memberof BuyByPlanApiBuyByPlanUpdate
     */
    readonly data: AdminImageBuyByPlan
}

/**
 * BuyByPlanApi - object-oriented interface
 * @export
 * @class BuyByPlanApi
 * @extends {BaseAPI}
 */
export class BuyByPlanApi extends BaseAPI {
    /**
     * 
     * @param {BuyByPlanApiBuyByPlanAdminCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanAdminCreate(requestParameters: BuyByPlanApiBuyByPlanAdminCreateRequest, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanAdminCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuyByPlanApiBuyByPlanCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanCreate(requestParameters: BuyByPlanApiBuyByPlanCreateRequest, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuyByPlanApiBuyByPlanDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanDelete(requestParameters: BuyByPlanApiBuyByPlanDeleteRequest, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuyByPlanApiBuyByPlanListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanList(requestParameters: BuyByPlanApiBuyByPlanListRequest = {}, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanList(requestParameters.fromDaysBefore, requestParameters.isActive, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuyByPlanApiBuyByPlanPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanPartialUpdate(requestParameters: BuyByPlanApiBuyByPlanPartialUpdateRequest, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuyByPlanApiBuyByPlanReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanRead(requestParameters: BuyByPlanApiBuyByPlanReadRequest, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuyByPlanApiBuyByPlanUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyByPlanApi
     */
    public buyByPlanUpdate(requestParameters: BuyByPlanApiBuyByPlanUpdateRequest, options?: any) {
        return BuyByPlanApiFp(this.configuration).buyByPlanUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
