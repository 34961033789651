import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Grid, IconButton, Box} from "@material-ui/core";
import clsx from "clsx";
import CarouselGenerator from "@elements/Carousel/CarouselGenerator";
import Typography from "@elements/Typography";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {routes} from "@constant/routes";

interface IProps {
  items: {
    image: string;
    title: string;
    id: string | number;
  }[];
}

export function PanelCarousel(props: IProps) {
  const classes = useStyle();
  const {items} = props;

  const itemsArray = items.map(({title, image, id}) => ({
    href: routes.panel.imagesDetail(id),
    element: (
      <Box className={classes.box}>
        <Box className={classes.item}>
          <Grid container alignItems='center' justify='center' className={classes.imageBox}>
            <img alt={title} src={image} className={classes.image} />
          </Grid>
          <Box className={classes.textBox}>
            <Typography size={1.6} weight={500} noWrap align='center'>
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
    ),
  }));

  const nextButton = (
    <IconButton className={clsx(classes.button, classes.next)}>
      <ChevronRight />
    </IconButton>
  );

  const prevButton = (
    <IconButton className={clsx(classes.button, classes.prev)}>
      <ChevronLeft />
    </IconButton>
  );

  return (
    <Box className={classes.container}>
      {!itemsArray.length ? (
        <Typography size={2}>عکسی برا نمایش وجود ندارد</Typography>
      ) : (
        <CarouselGenerator
          items={itemsArray}
          customNextButton={nextButton}
          customPrevButton={prevButton}
          responsive={{
            100: {items: 1},
            420: {items: 3},
            740: {items: 4},
            1060: {items: 5},
            1420: {items: 6},
            1770: {items: 7},
            1940: {items: 8},
          }}
        />
      )}
    </Box>
  );
}

export default PanelCarousel;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "0 50px",
    height: 225,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    padding: "1px 8px 6px",
  },
  item: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 #aaa, 0 3px 4px 0 #bbb",
    borderRadius: 4,
    cursor: "pointer",
    userSelect: "none",
  },
  imageBox: {
    height: 168,
    borderBottom: "1px solid #ddd",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    userDrag: "none",
  },
  textBox: {
    padding: "12px 16px",
    textAlign: "left",
    transition: ".12s ease-in",
  },
  id: {},
  button: {
    position: "absolute",
    top: "38%",
    width: 44,
    height: 44,
    backgroundColor: theme.palette.primary.main,
    "& *": {
      color: "white",
      fontSize: "20px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  next: {
    left: "-50px",
  },
  prev: {
    right: "-50px",
  },
}));
