import {
  BuyByPlanApi,
  BuyByPlanApiBuyByPlanListRequest,
  BuyByPlanApiBuyByPlanReadRequest,
  BuyByPlanApiBuyByPlanCreateRequest,
  BuyByPlanApiBuyByPlanPartialUpdateRequest,
  BuyByPlanApiBuyByPlanDeleteRequest,
} from "@schema/api";
import { axiosInstance } from "services/axios/axios";
import { createQuery, makeApi } from "./types";

const buyByPlanApiInstance = new BuyByPlanApi(undefined, undefined, axiosInstance);

export const buyByPlanAPI = makeApi({
  identifier: "buyByPlan",
  Query: {
    getList: createQuery(async (params: BuyByPlanApiBuyByPlanListRequest) => {
      const { data } = await buyByPlanApiInstance.buyByPlanList(params);
      return data;
    }),
    readDetail: createQuery(async (params: BuyByPlanApiBuyByPlanReadRequest) => {
      const { data } = await buyByPlanApiInstance.buyByPlanRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: BuyByPlanApiBuyByPlanCreateRequest) => {
      const { data } = await buyByPlanApiInstance.buyByPlanCreate(params);
      return data;
    },
    update: async (params: BuyByPlanApiBuyByPlanPartialUpdateRequest) => {
      const { data } = await buyByPlanApiInstance.buyByPlanPartialUpdate(params);
      return data;
    },
    delete: async (params: BuyByPlanApiBuyByPlanDeleteRequest) => {
      const { data } = await buyByPlanApiInstance.buyByPlanDelete(params);
      return data;
    },
  },
});
