import React, {useState} from "react";
import {actionsDrawerContext} from ".";

const ActionsDrawerContextProvider: React.FC = ({children}) => {
  const [openActionsDrawer, setOpenActionsDrawer] = useState<boolean>(false);
  const toggleActionsDrawer = () => setOpenActionsDrawer((prev) => !prev);
  return (
    <actionsDrawerContext.Provider value={{openActionsDrawer, setOpenActionsDrawer, toggleActionsDrawer}}>
      {children}
    </actionsDrawerContext.Provider>
  );
};

export default ActionsDrawerContextProvider;
