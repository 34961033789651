import {AxiosError} from "axios";
import {Path, UseFormSetError} from "react-hook-form";
import * as yup from "yup";

export type IFormSchema<T extends Record<string, any>> = {name: Path<T>; validations: any}[];

export function generateYupSchema<T extends {name: string; validations: any}>(formElements: T[]) {
  const schema = formElements.reduce((acc, val) => ({...acc, [val.name]: val.validations}), {});
  return yup.object(schema).required();
}

export function setFormErrors<T>(
  formFields: IFormSchema<T>,
  setter: UseFormSetError<T>,
  error: AxiosError
) {
  formFields
    .map(({name}) => name)
    .forEach((fieldName) => {
      if (Object.keys(error.response?.data).includes(fieldName)) {
        const fieldError = error.response?.data[fieldName];
        setter(fieldName, {
          message:
            typeof fieldError === "string"
              ? fieldError
              : Array.isArray(fieldError)
              ? fieldError.join(", ")
              : "این فیلد معتبر نیست",
        });
      }
    });
}
