import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import {YouTube, Instagram, Telegram} from "@material-ui/icons";
import clsx from "clsx";
import {palette} from "@constant/colors";
import Span from "@elements/Span";
import {useExperimentalQuery} from "@hook/react-query/useQuery";

const SocialMedia = () => {
  const classes = useStyle();

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  const socialMedia = {
    telegram: "https://www.t.me/parsdream",
    instagram: "https://www.instagram.com/parsdream",
    youtube: "https://www.youtube.com/channel/UCpBfOlnWASzXKY6XRGWWNQA",
  };

  return (
    <Grid container direction='column' className={classes.container}>
      <Link to='/'>
        <img src={data?.logo} alt='پارس ایمیجز' height={65} />
      </Link>
      <Grid item container className={classes.socialMedia}>
        <a target='_blank' rel='noreferrer' href={socialMedia.telegram}>
          <Span className={clsx(classes.socialLink, classes.telegram)}>
            <Telegram className={classes.icon} />
          </Span>
        </a>
        <a target='_blank' rel='noreferrer' href={socialMedia.instagram}>
          <Span className={clsx(classes.socialLink, classes.instagram)}>
            <Instagram className={classes.icon} />
          </Span>
        </a>
        <a target='_blank' rel='noreferrer' href={socialMedia.youtube}>
          <Span className={clsx(classes.socialLink, classes.youtube)}>
            <YouTube className={classes.icon} />
          </Span>
        </a>
      </Grid>
    </Grid>
  );
};

export default SocialMedia;

const useStyle = makeStyles((theme) => ({
  container: {
    gap: "2rem",
    alignItems: "flex-end",
    paddingRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  socialMedia: {
    gap: "4px",
    width: "auto",
  },
  socialLink: {
    width: "4.5rem",
    height: "4.5rem",
    borderRadius: "5px",
    backgroundColor: palette.darkGrey.A200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: ".1s",
  },
  icon: {
    color: theme.palette.background.paper,
    fontSize: "3rem",
  },
  telegram: {
    "&:hover": {
      backgroundColor: palette.blue.A100,
    },
  },
  instagram: {
    "&:hover": {
      backgroundColor: palette.pink[200],
    },
  },
  youtube: {
    "&:hover": {
      backgroundColor: "#F34423",
    },
  },
}));
