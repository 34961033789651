import React, {useState} from "react";
import {navbarContext} from ".";

const NavbarContextProvider: React.FC = ({children}) => {
  const [openNavbar, setOpenNavbar] = useState<boolean>(false);
  const toggleNavbar = () => setOpenNavbar((prev) => !prev);
  return <navbarContext.Provider value={{openNavbar, setOpenNavbar, toggleNavbar}}>{children}</navbarContext.Provider>;
};

export default NavbarContextProvider;
