import {ApiNamespaces} from "@api";
import {routes} from "@constant/routes";
import {useAlert} from "@context/AlertContext";
import Spinner from "@elements/Spinner";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {getUserLastLocationFromLs, removeUserLastLocationFromLs} from "@util/userLastLocation";
import React from "react";
import {useQueryClient} from "react-query";
import {useHistory} from "react-router";

export const Loading = () => {
  const [isReady, setIsReady] = React.useState<boolean>(false);
  const alert = useAlert();
  const history = useHistory();
  const {Authority, Status} = useGetQueryString();
  const {landing} = routes;
  const queryClient = useQueryClient();

  const redirect = () => {
    const lastLocation = getUserLastLocationFromLs();
    if (lastLocation) {
      history.replace(lastLocation);
      removeUserLastLocationFromLs();
    } else {
      history.replace(landing.account);
    }
  };

  React.useEffect(() => {
    if (Status === "NOK") {
      alert.error({text: "عملیات خرید موفقیت‌آمیز نبود"}).then(() => redirect());
    }
  }, [Status]);

  useExperimentalQuery("transactions", "status", {
    enabled: !!Authority && Status === "OK",
    variables: {
      authority: Authority,
    },
    onError: () => {
      alert
        .error({
          text:
            "مشکلی پیش آمده، در صورتی که پول از حساب شما کسر شده اما خریدتان کامل نشده لطفا با پشتیبانی تماس بگیرید",
          persist: true,
        })
        .then(() => redirect());
    },
    onSuccess: (res) => {
      setIsReady(true);
      const isOk = ((res?.is_ok as unknown) as boolean) || undefined || null;
      console.log(isOk);
      if (isOk === true) {
        queryClient.invalidateQueries(ApiNamespaces.auth.Query.getUser);
        alert.success({text: "خرید با موفقیت انجام شد."}).then(() => redirect());
      } else {
        alert.error({text: "عملیات خرید موفقیت‌آمیز نبود"}).then(() => redirect());
      }
    },
  });

  return isReady ? <div style={{paddingTop: "100vh"}} /> : <Spinner />;
};

export default Loading;
