import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {useExperimentalQuery} from "@hook/react-query/useQuery";

const TopBanner = () => {
  const classes = useStyle();

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  // @ts-ignore
  return data && data["main_page_banner"] ? (
    <Box
      className={classes.container}
      style={{
        // @ts-ignore
        backgroundImage: `url(${data["main_page_banner"]})`,
      }}
    ></Box>
  ) : null;
};

export default TopBanner;

const useStyle = makeStyles((theme) => ({
  container: {
    height: 60,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down(600)]: {
      height: 40,
    },
  },
}));
