class Global {
  static entries<T>(t: T) {
    return Object.entries(t) as [keyof T, T[keyof T]][];
  }

  static keys<T>(t: T) {
    return Object.keys(t) as (keyof T)[];
  }

  static values<T>(t: T) {
    return Object.values(t);
  }
}

interface EnumUtils<T> {
  getKey(value: T[keyof T]): keyof T;
  getKeyOr<V, O>(value: V, or: O): V extends T[keyof T] ? keyof T : O;
  // Don't know why eslint doesn't recognize param `key`
  // eslint-disable-next-line no-undef
  getValue(key: keyof T): T[typeof key];
  getValueOr<K, O>(key: K, or: O): K extends keyof T ? T[keyof T] : O;
  getEntry(key: keyof T): [keyof T, T[keyof T]];
  getEntry(value: T[keyof T]): [keyof T, T[keyof T]];
  getKeys(): (keyof T)[];
  getValues(): T[keyof T][];
  getEntries(): [keyof T, T[keyof T]][];
  hasKey(key: any): boolean;
  hasValue(value: any): boolean;
}

class EnumClass<T> implements EnumUtils<T> {
  private readonly t: T;

  constructor(t: T) {
    this.t = t;
  }

  getKey(value: T[keyof T]): keyof T {
    const index = Global.values(this.t).indexOf(value);
    return Global.entries(this.t)[index]?.[0]!;
  }

  getKeyOr<V, O>(value: V, or: O): V extends T[keyof T] ? keyof T : O {
    // @ts-ignore
    const entry = Global.entries(this.t).find(([, value1]) => value === value1);
    // @ts-ignore
    return entry?.[0] || or;
  }

  getValue(key: keyof T): T[typeof key] {
    return this.t[key];
  }

  getValueOr<K, O>(key: K, or: O): K extends keyof T ? T[keyof T] : O {
    // @ts-ignore
    const entry = Global.entries(this.t).find(([key1]) => key === key1);
    // @ts-ignore
    return entry?.[1] || or;
  }

  getKeys(): (keyof T)[] {
    return Global.keys(this.t);
  }

  getValues(): T[keyof T][] {
    return Global.values(this.t);
  }

  getEntries(): [keyof T, T[keyof T]][] {
    return Global.entries(this.t);
  }

  getEntry(value: T[keyof T]): [keyof T, T[keyof T]];
  getEntry(key: keyof T): [keyof T, T[keyof T]];
  getEntry(kv: any) {
    if (kv in this.t) {
      const k = kv as keyof T;
      return [k, this.t[k]];
    } else {
      const v = kv as T[keyof T];
      const k = this.getKey(v);
      return [k, v];
    }
  }

  hasKey(key: any): boolean {
    return !!this.getValueOr(key, false);
  }

  hasValue(value: any): boolean {
    return !!this.getKeyOr(value, false);
  }
}

export function Enum<T>(t: T) {
  return new EnumClass(t);
}
