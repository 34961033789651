import { useEffect } from "react";
interface Prop {
  value: any;
  label: any;
  [key: string]: any;
}

/**
 *  check if there is some duplication in items array. It's not a generic function, for now, only checks label and value
 */
export const useValidateDistinction = (items: Array<Prop>) => {
  useEffect(() => {
    const labels = new Set(items.map((item) => item.label));
    const values = new Set(items.map((item) => item.value));
    if (labels.size < items.length || values.size < items.length) {
      throw new Error(
        "There is some duplication in your items array. All values and labels should be unique. Please check again."
      );
    }
  }, [items]);
};