/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    price: string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    signature_credit: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    essential_credit: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    days: number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    scope?: PlanScopeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PlanScopeEnum {
    Bot = 'BOT',
    Web = 'WEB',
    Gen = 'GEN'
}



