import {palette} from "@constant/colors";
import {images} from "@constant/images";
import {useActionsDrawerContext} from "@context/ActionsDrawerContext";
import {useNavbarContext} from "@context/NavbarContext";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {NavbarParentItem} from "@layouts/MainLayout";
import {Box, CardMedia, Grid, Hidden, useMediaQuery} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Menu as MenuIcon, PersonRounded} from "@material-ui/icons";
import SearchBar from "@modules/SearchBar";
import React, {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import ActionsList from "./ActionsList";
import {headerHeightBig, headerHeightSmall} from "./constant";
import Navbar from "./Navbar";
import SubList from "./SubList";

export const actionsListBreakpoint = 1000;

interface IProps {
  navbarItems: NavbarParentItem[];
  noDefaultSearchBar?: boolean;
}

const Header = (props: IProps) => {
  const {navbarItems, noDefaultSearchBar} = props;
  const [activeItem, setActiveItem] = useState<NavbarParentItem | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const {toggleNavbar} = useNavbarContext();
  const {toggleActionsDrawer} = useActionsDrawerContext();
  const classes = useStyle();
  const mediumUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up(actionsListBreakpoint));

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  /** renders activeItem's children in the white box */
  const renderChildren = useCallback((item: NavbarParentItem) => {
    setActiveItem(item);
    setVisible(true);
  }, []);

  const handleCloseSubItemsListBox = () => {
    setActiveItem(null);
    setVisible(false);
  };

  return (
    <Box component='header'>
      {/* black box */}
      <Grid container alignItems='center' className={classes.upperBox}>
        <Grid container alignItems='center' item xs={6} className={classes.heightFull}>
          <Hidden smDown>
            <Navbar
              navbarItems={navbarItems}
              activeItem={activeItem}
              renderChildren={renderChildren}
            />
          </Hidden>
          <Hidden mdUp>
            <MenuIcon onClick={() => toggleNavbar()} className={classes.menuIcon} />
          </Hidden>
        </Grid>
        <Grid item xs={2} className={classes.logoBox} onMouseEnter={handleCloseSubItemsListBox}>
          <Link to='/'>
            {/* TODO remove ts-ignore when open api updated */}
            {/* @ts-ignore */}
            <img src={data?.logo} alt='پارس ایمیجز' height='100%' />
          </Link>
        </Grid>
        <Grid
          container
          justify='flex-end'
          alignItems='center'
          item
          xs={6}
          className={classes.heightFull}
        >
          {mediumUp ? (
            <ActionsList />
          ) : (
            <PersonRounded className={classes.accountIcon} onClick={() => toggleActionsDrawer()} />
          )}
        </Grid>
      </Grid>
      {/* white box for subMenus */}
      <Box
        className={classes.subItemsListBox}
        style={{display: visible ? "flex" : "none"}}
        onMouseLeave={handleCloseSubItemsListBox}
      >
        <CardMedia
          component='img'
          src={images.icons.closeDarkThin}
          className={classes.closeIcon}
          onClick={handleCloseSubItemsListBox}
        />
        <Box component='ul' className={classes.subItemsList}>
          {activeItem?.items?.map((item, index) => (
            <SubList
              key={item.name.concat(index.toString())}
              item={item}
              onClick={handleCloseSubItemsListBox}
            />
          ))}
        </Box>
      </Box>
      {!noDefaultSearchBar && <SearchBar />}
    </Box>
  );
};

export default Header;

const useStyle = makeStyles((theme) => ({
  heightFull: {
    height: "100%",
  },
  upperBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: headerHeightBig,
    backgroundColor: palette.darkGrey.A700,
    padding: "0 1.6rem",
    fontSize: "1.6rem",
    position: "relative",
    transition: ".15s",
    [theme.breakpoints.down("sm")]: {
      height: headerHeightSmall,
    },
  },
  logoBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& > *": {
      height: 70,
      [theme.breakpoints.down("sm")]: {
        height: 55,
      },
    },
  },
  logo: {
    display: "block",
  },
  logoDescription: {
    marginTop: "-1.3rem",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subItemsListBox: {
    position: "absolute",
    zIndex: 2000,
    width: "100%",
    height: "38rem",
    backgroundColor: theme.palette.background.paper,
    padding: "4.4rem",
  },
  subItemsList: {
    width: "100%",
    maxWidth: "115rem",
    margin: "0 auto",
    display: "flex",
    gap: "3.4rem",
  },
  closeIcon: {
    height: "1.6rem",
    width: "1.6rem",
    cursor: "pointer",
    position: "absolute",
    top: "2.2rem",
    right: "2.2rem",
  },
  menuIcon: {
    color: "white",
    fontSize: "3.4rem",
    display: "inline-block",
    margin: "auto 0",
    cursor: "pointer",
    "&:hover": {
      color: palette.green[200],
    },
  },
  accountIcon: {
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
    padding: ".6rem",
    fontSize: "2.4rem",
    color: "white",
    cursor: "pointer",
    "&:hover": {
      color: palette.green[200],
    },
  },
}));
