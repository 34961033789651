import {useAlert} from "@context/AlertContext";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {CreateImageTypeEnum as ImageTypeEnum, ImageTypeEnumPersian} from "@schema";
import {Enum} from "@util/EnumUtils";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Checkbox, FormControlLabel, Grid, IconButton} from "@material-ui/core";
import Typography from "@elements/Typography";
import {Close, DateRange, Fingerprint, Stars} from "@material-ui/icons";
import AutoCompleteTextField from "@elements/panel/AutoCompleteTextField";
import AutoCompleteCheckbox from "@elements/panel/AutoCompleteCheckbox";
import clsx from "clsx";
import RadioGroup from "@elements/panel/RadioGroup";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import UploadButton from "@elements/panel/UploadButton";
import TextInput from "@elements/panel/TextInput";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {getBase64} from "@util/getBase64";
import {routes} from "@constant/routes";
import useDebouncedValue from "@hook/useDebouncedValue";
import ProgressWithLabel from "@elements/ProgressWithLabel";

const AddImage = () => {
  const classes = useStyle();
  const {t} = useTranslation("pages.panel");
  const history = useHistory();
  const alert = useAlert();

  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  let image = imageFile && URL.createObjectURL(imageFile);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = React.useState(ImageTypeEnum.Photo);
  const [collectionsState, setCollectionsState] = React.useState<number[]>([]);
  const [collectionsSearchValue, setCollectionsSearchValue] = React.useState("");
  const [tagsValue, setTagsValue] = React.useState<string[]>([]);
  const [tagsInputValue, setTagsInputValue] = React.useState("");
  const [categoriesState, setCategoriesState] = React.useState<number[]>([]);
  const [isPremium, setIsPremium] = React.useState<boolean>(false);
  const [price, setPrice] = React.useState("");
  const [credit, setCredit] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const debouncedTagsSearchValue = useDebouncedValue(tagsInputValue, 500);
  const debouncedCollectionsSearchValue = useDebouncedValue(collectionsSearchValue, 500);

  const {data: collections} = useExperimentalQuery("collections", "getList", {
    variables: {pageSize: 500, search: debouncedCollectionsSearchValue},
  });
  const {data: tags} = useExperimentalQuery("tags", "getList", {
    variables: {pageSize: 40, search: debouncedTagsSearchValue},
  });
  const {data: categories} = useExperimentalQuery("categories", "getList", {
    variables: {pageSize: 100},
  });
  const {mutate, isLoading} = useExperimentalMutation("images", "createImage");

  const getVariables = async () => {
    const categories = (categoriesState.map((item) => item) as unknown) as Set<number>;
    const collections = (collectionsState.map((item) => item) as unknown) as Set<number>;
    return {
      data: {
        type: Enum(ImageTypeEnum).getValueOr(type, undefined),
        title: (title ? title : undefined) as string,
        price: price ? price : undefined,
        description,
        summary,
        tags: tagsValue || [],
        categories,
        collections,
        file: imageFile ? await getBase64(imageFile) : undefined,
        is_premium: isPremium,
        credit: credit ? Number(credit) : undefined,
        is_active: isActive,
      },
      axiosConfig: {
        onUploadProgress: (e: any) => {
          const progress = (e.loaded / e.total) * 100;
          setUploadProgress(Math.round(progress));
        },
      },
    };
  };

  const handleCreate = async () => {
    mutate({
      variables: await getVariables(),
      onSuccess: () => {
        alert.success({});
        history.replace(routes.panel.images);
      },
      onError: () => alert.error({}),
      onSettled: () => setUploadProgress(null),
    });
  };
  const handleCreateAndStartOver = async () => {
    mutate({
      variables: await getVariables(),
      onSuccess: () => {
        alert.success({});
        setImageFile(null);
        setTitle("");
        setSummary("");
        setDescription("");
        setType(ImageTypeEnum.Photo);
        setTagsValue([]);
        setIsPremium(false);
        setPrice("");
        setCredit("");
        setCategoriesState([]);
      },
      onError: () => alert.error({}),
      onSettled: () => setUploadProgress(null),
    });
  };

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid container className={classes.gap}>
        <Grid item xs={9} className={classes.imageBox}>
          {image ? (
            <>
              <img src={image} alt='' className={classes.image} />
              <IconButton className={classes.deleteIconBox} onClick={() => setImageFile(null)}>
                <Close className={classes.deleteIcon} />
              </IconButton>
            </>
          ) : (
            <UploadButton onChange={(file) => setImageFile(file)} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container justify='space-between' alignItems='center' style={{gap: 8}}>
            <Box style={{display: "flex", alignItems: "center"}}>
              <Fingerprint className={classes.icon} />
              <Typography size={1.4} span color='#757575' className={classes.margin}>
                {t("images.common.imageCode")} {t("images.add-image.imageCodePlaceHolder")}
              </Typography>
            </Box>
            <Box style={{display: "flex", alignItems: "center"}}>
              <DateRange className={classes.icon} />
              <Typography size={1.4} span color='#757575' className={classes.margin}>
                {t("images.common.uploadDate")} {t("images.add-image.uploadDatePlaceHolder")}
              </Typography>
            </Box>
          </Grid>
          <Grid container direction='column' className={classes.textBox}>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography span color='#757575'>
                {t("images.common.title")}
              </Typography>
              <TextInput
                value={title}
                onChange={(v) => !v.split("").includes("-") && setTitle(v)}
              />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography span color='#757575'>
                {t("images.common.summary")}
              </Typography>
              <TextInput value={summary} onChange={(v) => setSummary(v)} multiline />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography span color='#757575'>
                {t("images.common.description")}
              </Typography>
              <TextInput value={description} onChange={(v) => setDescription(v)} multiline />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={clsx(classes.inputsBox, classes.gap)}>
        <Grid item container xs={4} wrap='wrap'>
          <div style={{width: "100%", marginBottom: "3rem"}}>
            <AutoCompleteCheckbox
              label='کالکشن‌'
              placeholder={collectionsState.length ? "" : "کالکشن‌ عکس"}
              options={collections?.results.map(({name, id}) => ({label: name, value: id!})) || []}
              value={collectionsState}
              onChange={(value) => setCollectionsState(value)}
              // onChangeInputValue={(value) => setCollectionsSearchValue(value)}
            />
          </div>
          <Grid container wrap='wrap'>
            <Grid item xs={7}>
              <RadioGroup
                label={t("images.common.type")}
                items={Enum(ImageTypeEnum)
                  .getEntries()
                  .map(([key, value]) => ({value, label: ImageTypeEnumPersian[key]}))}
                value={type}
                onChange={(type) => setType(type as ImageTypeEnum)}
              />
            </Grid>
            <Grid item xs={5} className={classes.childrenStyles}>
              <Grid container direction='column' style={{gap: ".5rem"}}>
                <Typography color='#757575'>قیمت</Typography>
                <TextInput value={price} onChange={(v) => setPrice(v)} type='number' />
              </Grid>
              <Grid container direction='column' style={{gap: ".5rem"}}>
                <Typography color='#757575'>اعتبار</Typography>
                <TextInput value={credit} onChange={(v) => setCredit(v)} type='number' />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.isPremium}>
              <FormControlLabel
                onChange={(_, checked) => setIsPremium(checked)}
                label={
                  <Typography size={1.8}>
                    عکس پرمیوم
                    <Stars className={classes.premiumIcon} />
                  </Typography>
                }
                control={
                  <Checkbox color='primary' className={classes.checkbox} checked={isPremium} />
                }
              />
            </Grid>
            <FormControlLabel
              style={{marginTop: 10}}
              onChange={(_, checked) => {
                alert.prompt({}).then(({result}) => result && setIsActive(checked));
              }}
              label={<Typography size={1.8}>فعال</Typography>}
              control={<Checkbox color='primary' className={classes.checkbox} checked={isActive} />}
            />
          </Grid>
        </Grid>
        <Grid item container xs={9} className={classes.bottomRightBox}>
          <Grid item xs={6}>
            <AutoCompleteTextField
              label={t("images.common.tags")}
              placeholder={tagsValue.length ? "" : t("images.common.typeTagName")}
              options={tags?.results.map(({name}) => name) || []}
              value={tagsValue}
              onChange={(value) => setTagsValue(value)}
              onChangeInputValue={(value) => setTagsInputValue(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoCompleteCheckbox
              label={t("images.common.categories")}
              placeholder={categoriesState.length ? "" : t("images.common.typeCategoryName")}
              options={categories?.results.map(({name, id}) => ({label: name, value: id!})) || []}
              value={categoriesState}
              onChange={(value) => setCategoriesState(value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify='center' className={classes.marginAuto}>
        <Button
          color='primary'
          variant='contained'
          className={classes.submitButton}
          disabled={!image || isLoading}
          onClick={handleCreateAndStartOver}
        >
          ثبت تصویر و ایجاد تصویر بعدی
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.submitButton}
          disabled={!image || isLoading}
          onClick={handleCreate}
        >
          {t("images.add-image.saveImage")}
        </Button>
      </Grid>
      {!!uploadProgress && <ProgressWithLabel value={uploadProgress} />}
    </Grid>
  );
};

export default AddImage;

const useStyle = makeStyles(() => ({
  container: {
    height: "100%",
    overflow: "hidden",
    alignItems: "stretch",
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "solid 1px #ccc",
    position: "relative",
    borderRadius: 4,
    "&:hover $deleteIconBox": {
      display: "block",
    },
  },
  image: {
    maxHeight: 420,
    maxWidth: "100%",
    borderRadius: 4,
  },
  icon: {
    fontSize: 19,
    color: "#757575",
  },
  textBox: {
    marginTop: 28,
    gap: 12,
    height: "calc(100% - 50px)",
    justifyContent: "space-between",
  },
  wrapper: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.42)",
    padding: "9.5px 0 9.5px ",
    gap: 11,
  },
  inputsBox: {
    marginTop: 46,
    paddingLeft: 2,
  },
  margin: {
    margin: "0 15px 0 10px",
  },
  gap: {
    gap: 28,
  },
  bottomRightBox: {
    gap: 20,
  },
  marginAuto: {
    marginTop: "auto",
    gap: 20,
    paddingTop: "6rem",
  },
  submitButton: {
    paddingRight: "4rem",
    paddingLeft: "4rem",
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
    },
  },
  deleteIconBox: {
    display: "none",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.4)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.6) !important",
    },
  },
  deleteIcon: {
    width: 25,
    height: 25,
    color: "white",
  },
  isPremium: {
    marginTop: 25,
    paddingLeft: 1,
    "& * ": {
      display: "flex",
      alignItems: "center",
    },
    "&:hover * ": {
      backgroundColor: "transparent",
    },
  },
  checkbox: {
    transform: "scale(1.5)",
    marginLeft: 2,
  },
  childrenStyles: {
    "& > *:not(:last-child) ": {
      marginBottom: 20,
    },
  },
  premiumIcon: {
    fontSize: 24,
    margin: "3px 5px 0",
    color: "gold",
  },
}));
