import {palette} from "@constant/colors";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CategoriesSection from "@modules/pages/home/CategoriesSection";
import GetFreeSection from "@modules/pages/home/GetFreeSection";
import HeadlinesSection from "@modules/pages/home/HeadlinesSection";
import MetaTags from "@modules/pages/home/MetaTags";
import Hero from "@modules/pages/home/Hero";
import React from "react";

const Home = () => {
  const classes = useStyle();
  return (
    <>
      <MetaTags />
      <Box className={classes.container}>
        <Hero />
        <Box className={classes.maxWidth}>
          <HeadlinesSection />
          <CategoriesSection />
          <GetFreeSection />
        </Box>
      </Box>
    </>
  );
};

export default Home;

const useStyle = makeStyles(() => ({
  container: {
    backgroundColor: palette.lightGrey[500],
  },
  maxWidth: {
    maxWidth: "114.6rem",
    margin: "0 auto",
  },
}));
