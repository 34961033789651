import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Checkbox as MaterialCheckBox} from "@material-ui/core";
import clsx from "clsx";
import {palette} from "@constant/colors";
import Span from "@elements/Span";
import Typography from "@elements/Typography";

export interface ICheckboxProps {
  label?: string;
  icon?: JSX.Element;
  checked?: boolean;
  onChange?: (checked: boolean, label?: string) => void;
  activeColor?: string;
}

function Checkbox(props: ICheckboxProps) {
  const {label, checked: controlledValue, onChange: handleChange, icon, activeColor = palette.green[200]} = props;
  const [checked, setChecked] = useState<boolean>(false);
  const classes = useStyle({activeColor});

  useEffect(() => {
    controlledValue !== undefined && setChecked(controlledValue);
  }, [controlledValue]);

  const labelElement = (
    <Span className={classes.label}>
      <Typography span color='white' size={1.4}>
        {label}
      </Typography>
      {icon && <Span className={classes.outerIconBox}>{icon}</Span>}
    </Span>
  );

  const controlElement = (
    <MaterialCheckBox
      color='default'
      disableRipple
      classes={{root: classes.root}}
      className={classes.default}
      checked={checked}
      onClick={() => setChecked((prev) => !prev)}
      icon={<Span className={classes.icon} />}
      checkedIcon={<Span className={clsx(classes.icon, classes.checkedIcon)} />}
    />
  );

  return (
    <FormControlLabel
      className={classes.container}
      onChange={(_, checked) => handleChange?.(checked, label)}
      label={labelElement}
      control={controlElement}
    />
  );
}

export default Checkbox;

const useStyle = makeStyles((theme) => ({
  root: {
    height: "2.4rem",
    margin: ".8rem .8rem .8rem 0",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  container: {
    width: "100%",
    userSelect: "none",
    margin: 0,
    "& > *:nth-child(2)": {
      width: "100%",
    },
    "&:hover $checkedIcon": {
      border: `1px solid ${palette.darkGrey[200]}`,
    },
    "&:hover $icon::after, &:hover $icon::before, &:hover $checkedIcon::after ,&:hover $checkedIcon::before": {
      backgroundColor: palette.darkGrey[200],
    },
  },
  label: {
    color: theme.palette.background.paper,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  default: {
    paddingRight: 0,
  },
  icon: {
    border: `1px solid ${palette.darkGrey[200]}`,
    cursor: "pointer",
    height: "19px",
    width: "19px",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      right: "6.5px",
      bottom: "6px",
      height: "5px",
      width: "1.5px",
      transform: "rotate(45deg)",
      transformOrigin: "bottom left",
      backgroundColor: "transparent",
      transition: ".2s ease",
    },
    "&::before": {
      position: "absolute",
      content: '""',
      right: "6px",
      bottom: "6px",
      height: "9px",
      width: "1.5px",
      transform: "rotate(-45deg)",
      transformOrigin: "bottom right",
      backgroundColor: "transparent",
      transition: ".2s ease",
    },
  },
  checkedIcon: ({activeColor}: {activeColor?: string}) => ({
    border: `1px solid ${activeColor}`,
    "&::after, &::before": {
      backgroundColor: activeColor,
    },
  }),
  outerIconBox: {
    width: "2.2rem",
    height: "2.2rem",
    // some icon's don't appear completely on zoom 100%, don't know why, this will fix the problem
    transform: "scale(.97)",
  },
}));
