import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {palette} from "@constant/colors";
import {Span} from "@elements/Span";

interface IAdornmentProps {
  onClick?: () => void;
}

export const Close = (props: IAdornmentProps) => {
  const {onClick: handleClick} = props;
  const classes = useStyle();
  return (
    <Span className={classes.iconBox} onClick={handleClick}>
      <svg viewBox='0 0 48 48' className={classes.icon}>
        <path d='M32.75 31.55l-7.6-7.6 7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z' />
      </svg>
    </Span>
  );
};

export const Search = (props: IAdornmentProps) => {
  const {onClick: handleClick} = props;
  const classes = useStyle();
  return (
    <Span className={clsx(classes.iconBox, classes.searchIconBox)} onClick={handleClick}>
      <svg viewBox='0 0 48 48' className={classes.icon}>
        <path d='M39.4 38.1l-7.2-7.2c2.1-2.4 3.4-5.6 3.4-9 0-7.3-6.4-13.7-13.7-13.7-7.6 0-13.7 6.1-13.7 13.7s6.1 13.7 13.7 13.7c3.5 0 6.6-1.3 9.1-3.5l7.2 7.2c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7zm-17.5-4.3c-6.6 0-12-5.4-12-11.9 0-6.6 5.4-12 12-12 6.4 0 12 5.6 12 12-.1 6.6-5.4 11.9-12 11.9z' />
      </svg>
    </Span>
  );
};

const useStyle = makeStyles((theme) => ({
  iconBox: {
    height: "100%",
    width: "5.8rem",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: palette.darkGrey[300],
    flexShrink: 0,
    "&:hover": {
      color: "black",
    },
    [theme.breakpoints.down("xs")]: {
      width: "3.2rem",
    },
  },
  searchIconBox: {
    width: "6.8rem",
    paddingLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: ".5rem",
      width: "3.2rem",
    },
  },
  icon: {
    width: "4.7rem",
    height: "4.7rem",
    fill: palette.darkGrey[300],
    "&:hover": {
      fill: palette.darkGrey.A700,
    },
    [theme.breakpoints.down("xs")]: {
      width: "3.2rem",
      height: "3.5rem",
    },
  },
}));
