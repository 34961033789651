import Typography from "@elements/Typography";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import {useTranslation} from "react-i18next";
import {darkGrey, lightGrey} from "@constant/colors";

const useStyles = makeStyles(() => ({
  root: {
    // FIXME not in palette
    boxShadow: "inset 0 0 0 3px #e1e1e1, 0 0 1px rgb(0 0 0 / 0%)",
    backgroundColor: "white",
    border: `1px solid ${lightGrey.A800}`,
    height: "5.1rem",
    width: "5.1rem",
  },
  selected: {
    backgroundColor: lightGrey.A800,
  },
}));

export interface SizeButtonProps {
  onClick?: () => void;
  onHover?: (hoveredIn: boolean) => void;
  size: "S" | "M" | "L" | "XL" | "XXL";
  selected?: boolean;
}

export function SizeButton({size, onHover: handleHover, onClick: handleClick, selected}: SizeButtonProps) {
  const classes = useStyles();
  const {t} = useTranslation("elements");
  return (
    <Button
      onMouseEnter={() => handleHover?.(true)}
      onMouseLeave={() => handleHover?.(false)}
      onClick={handleClick}
      className={clsx([classes.root, selected ? classes.selected : ""])}
    >
      <Typography size={1.4} color={selected ? "white" : darkGrey[300]}>
        {t(`button.sizeButton.${size}` as const)}
      </Typography>
    </Button>
  );
}

export default SizeButton;
