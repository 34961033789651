import Typography from "@elements/Typography";
import {CardMedia, Button, Grid, ButtonProps} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import {lightGrey} from "@constant/colors";

const useStyles = makeStyles(() => ({
  root: {
    border: `1px solid ${lightGrey.A500}`,
    backgroundColor: "white",
    paddingLeft: "1.6rem",
    paddingRight: "1.6rem",
    borderRadius: 5,
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "&:hover": {
      backgroundColor: lightGrey.A500,
    },
  },
  gap2: {
    gap: "0.4rem",
  },
  icon: {
    width: "1.2rem",
  },
  externalIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface IProps extends ButtonProps {
  icon: string | React.ReactElement;
  noIcon?: boolean;
}

export function ButtonWithIcon(props: IProps) {
  const {children, icon, className, noIcon, ...rest} = props;
  const classes = useStyles();
  return (
    <Button variant='contained' className={clsx([classes.root, className || ""])} {...rest}>
      <Grid container className={classes.gap2} alignItems='center' justify='center'>
        <Grid item style={{width: "100%"}}>
          <Typography noWrap size={1.4} style={{textTransform: "lowercase"}}>
            {children}
          </Typography>
        </Grid>
        {!noIcon && (
          <Grid item>
            {typeof icon === "string" ? (
              <CardMedia component='img' alt='icon' src={icon} className={classes.icon} />
            ) : (
              <CardMedia className={classes.externalIcon}>{icon}</CardMedia>
            )}
          </Grid>
        )}
      </Grid>
    </Button>
  );
}

export default ButtonWithIcon;
