import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import AuthCard from "./AuthCard";
import PhoneNumberInput from "./TextInput/PhoneNumberInput";
import {MainButton} from "./button";
import {MaterialUiPhoneNumberProps} from "material-ui-phone-number";
import {darkGrey} from "@constant/colors";
import {useTranslation} from "react-i18next";
import GreenLink from "./GreenLink";
import {useAlert} from "@context/AlertContext";
import {routes} from "@constant/routes";
import {useGetQueryString} from "@hook/qs/useGetQueryString";

export interface IConfirmMobileCardProps
  extends Pick<MaterialUiPhoneNumberProps, "value" | "onChange"> {
  onSubmit?: () => void;
  disabled?: boolean;
  loginLink?: boolean;
  error?: string;
}

const ConfirmMobileCard = (props: IConfirmMobileCardProps) => {
  const {value: controlledValue, onChange, onSubmit, disabled, loginLink, error} = props;
  const [value, setValue] = useState(controlledValue || "");
  const classes = useStyle();
  const {t} = useTranslation("elements");
  const alert = useAlert();
  const {mode} = useGetQueryString();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {charCode} = event;
    if (!(charCode >= 0 && charCode <= 127)) {
      alert.error({text: "لطفا زبان کیبورد خود را به انگلیسی تغییر دهید."});
    }
  };

  return (
    <AuthCard error={error}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit?.();
        }}
      >
        <Typography size={2.4}>{t("confirmMobileCard.title")}</Typography>
        <Grid container direction='column' className={classes.wrapper}>
          {loginLink && (
            <Box className={classes.marginTop}>
              <Typography span size={1.4} color={darkGrey.A100}>
                {t("confirmMobileCard.alreadyMember")}&nbsp;
              </Typography>
              <GreenLink
                size='small'
                // passing mode=redirect(to help user to being redirected back after authenticating) if it's exist in the url already
                to={routes.landing.login.concat(mode === "redirect" ? `?mode=${mode}` : "")}
              >
                {t("confirmMobileCard.signIn")}
              </GreenLink>
            </Box>
          )}
          <PhoneNumberInput
            autoFocus
            value={value}
            inputProps={{onKeyPress: handleKeyPress}}
            defaultCountry='ir'
            onChange={(v) => {
              setValue(v);
              onChange?.(v);
            }}
          />
          <MainButton color='green' fullWidth type='submit' disabled={disabled}>
            {t("confirmMobileCard.continue")}
          </MainButton>
        </Grid>
      </form>
    </AuthCard>
  );
};

export default ConfirmMobileCard;

const useStyle = makeStyles(() => ({
  wrapper: {
    marginTop: "1.5rem",
    gap: "1rem",
  },
  marginTop: {
    marginTop: "-1rem",
    marginBottom: "4rem",
  },
}));
