import {useValidateDistinction} from "@hook/useValidateDistinction";
import React, {useEffect, useState} from "react";
import {IValueLabelPair} from "@type/global";
import Checkbox from "./Checkbox";

export interface ICheckboxGroupProps<T> {
  items: (IValueLabelPair<T> & {icon?: JSX.Element})[];
  onChange?: (value: T[]) => void;
  value?: T[];
}

function CheckboxGroup<T>(props: ICheckboxGroupProps<T>) {
  const {onChange, value: controlledValue, items} = props;
  const [value, setValue] = useState<T[]>([]);
  useValidateDistinction(items);

  useEffect(() => {
    controlledValue && setValue(controlledValue);
  }, [controlledValue]);

  const handleChange = (checked: boolean, label?: string) => {
    const item = items.find((item) => item.label === label);
    const itemValue = item!.value;
    const newValue = checked ? [...value, itemValue] : value.filter((v) => v !== itemValue);
    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <>
      {items.map((item) => (
        <Checkbox
          key={item.label}
          onChange={handleChange}
          checked={!!value.find((v) => item.value === v)}
          label={item.label}
          icon={item.icon}
        />
      ))}
    </>
  );
}

export default CheckboxGroup;
