import {
  MessagesApi,
  MessagesApiMessagesReadRequest,
  MessagesApiMessagesListRequest,
  MessagesApiMessagesCreateRequest,
  MessagesApiMessagesPartialUpdateRequest,
  MessagesApiMessagesDeleteRequest,
} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const messagesApiInstance = new MessagesApi(undefined, undefined, axiosInstance);

export const messagesAPI = makeApi({
  identifier: "messages",
  Query: {
    getList: createQuery(async (params: MessagesApiMessagesListRequest) => {
      const {data} = await messagesApiInstance.messagesList(params);
      return data;
    }),
    readDetail: createQuery(async (params: MessagesApiMessagesReadRequest) => {
      const {data} = await messagesApiInstance.messagesRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: MessagesApiMessagesCreateRequest) => {
      const {data} = await messagesApiInstance.messagesCreate(params);
      return data;
    },
    update: async (params: MessagesApiMessagesPartialUpdateRequest) => {
      const {data} = await messagesApiInstance.messagesPartialUpdate(params);
      return data;
    },
    delete: async (params: MessagesApiMessagesDeleteRequest) => {
      const {data} = await messagesApiInstance.messagesDelete(params);
      return data;
    },
  },
});
