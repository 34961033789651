/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FAQCards
 */
export interface FAQCards {
    /**
     * 
     * @type {number}
     * @memberof FAQCards
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FAQCards
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCards
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCards
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQCards
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCards
     */
    scope?: FAQCardsScopeEnum;
    /**
     * 
     * @type {number}
     * @memberof FAQCards
     */
    priority?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FAQCardsScopeEnum {
    Extended = 'extended',
    Image = 'image',
    Plans = 'plans'
}



