/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse20011 } from '../servermodel';
// @ts-ignore
import { PlanDiscount } from '../servermodel';
/**
 * PlanDiscountApi - axios parameter creator
 * @export
 */
export const PlanDiscountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountCreate: async (data: PlanDiscount, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planDiscountCreate', 'data', data)
            const localVarPath = `/plan-discount/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planDiscountDelete', 'id', id)
            const localVarPath = `/plan-discount/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [isPublic] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountList: async (isPublic?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/plan-discount/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isPublic !== undefined) {
                localVarQueryParameter['is_public'] = isPublic;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountPartialUpdate: async (id: number, data: PlanDiscount, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planDiscountPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planDiscountPartialUpdate', 'data', data)
            const localVarPath = `/plan-discount/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountPlanDiscountByName: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planDiscountPlanDiscountByName', 'id', id)
            const localVarPath = `/plan-discount/{id}/plan_discount_by_name/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planDiscountRead', 'id', id)
            const localVarPath = `/plan-discount/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountUpdate: async (id: number, data: PlanDiscount, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planDiscountUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('planDiscountUpdate', 'data', data)
            const localVarPath = `/plan-discount/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanDiscountApi - functional programming interface
 * @export
 */
export const PlanDiscountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanDiscountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountCreate(data: PlanDiscount, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [isPublic] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountList(isPublic?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountList(isPublic, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountPartialUpdate(id: number, data: PlanDiscount, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountPlanDiscountByName(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountPlanDiscountByName(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountUpdate(id: number, data: PlanDiscount, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanDiscountApi - factory interface
 * @export
 */
export const PlanDiscountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanDiscountApiFp(configuration)
    return {
        /**
         * 
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountCreate(data: PlanDiscount, options?: any): AxiosPromise<PlanDiscount> {
            return localVarFp.planDiscountCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.planDiscountDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [isPublic] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountList(isPublic?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.planDiscountList(isPublic, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountPartialUpdate(id: number, data: PlanDiscount, options?: any): AxiosPromise<PlanDiscount> {
            return localVarFp.planDiscountPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountPlanDiscountByName(id: number, options?: any): AxiosPromise<PlanDiscount> {
            return localVarFp.planDiscountPlanDiscountByName(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountRead(id: number, options?: any): AxiosPromise<PlanDiscount> {
            return localVarFp.planDiscountRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan discount.
         * @param {PlanDiscount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountUpdate(id: number, data: PlanDiscount, options?: any): AxiosPromise<PlanDiscount> {
            return localVarFp.planDiscountUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for planDiscountCreate operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountCreateRequest
 */
export interface PlanDiscountApiPlanDiscountCreateRequest {
    /**
     * 
     * @type {PlanDiscount}
     * @memberof PlanDiscountApiPlanDiscountCreate
     */
    readonly data: PlanDiscount
}

/**
 * Request parameters for planDiscountDelete operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountDeleteRequest
 */
export interface PlanDiscountApiPlanDiscountDeleteRequest {
    /**
     * A unique integer value identifying this plan discount.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountDelete
     */
    readonly id: number
}

/**
 * Request parameters for planDiscountList operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountListRequest
 */
export interface PlanDiscountApiPlanDiscountListRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanDiscountApiPlanDiscountList
     */
    readonly isPublic?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for planDiscountPartialUpdate operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountPartialUpdateRequest
 */
export interface PlanDiscountApiPlanDiscountPartialUpdateRequest {
    /**
     * A unique integer value identifying this plan discount.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PlanDiscount}
     * @memberof PlanDiscountApiPlanDiscountPartialUpdate
     */
    readonly data: PlanDiscount
}

/**
 * Request parameters for planDiscountPlanDiscountByName operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountPlanDiscountByNameRequest
 */
export interface PlanDiscountApiPlanDiscountPlanDiscountByNameRequest {
    /**
     * A unique integer value identifying this plan discount.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountPlanDiscountByName
     */
    readonly id: number
}

/**
 * Request parameters for planDiscountRead operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountReadRequest
 */
export interface PlanDiscountApiPlanDiscountReadRequest {
    /**
     * A unique integer value identifying this plan discount.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountRead
     */
    readonly id: number
}

/**
 * Request parameters for planDiscountUpdate operation in PlanDiscountApi.
 * @export
 * @interface PlanDiscountApiPlanDiscountUpdateRequest
 */
export interface PlanDiscountApiPlanDiscountUpdateRequest {
    /**
     * A unique integer value identifying this plan discount.
     * @type {number}
     * @memberof PlanDiscountApiPlanDiscountUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PlanDiscount}
     * @memberof PlanDiscountApiPlanDiscountUpdate
     */
    readonly data: PlanDiscount
}

/**
 * PlanDiscountApi - object-oriented interface
 * @export
 * @class PlanDiscountApi
 * @extends {BaseAPI}
 */
export class PlanDiscountApi extends BaseAPI {
    /**
     * 
     * @param {PlanDiscountApiPlanDiscountCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountCreate(requestParameters: PlanDiscountApiPlanDiscountCreateRequest, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanDiscountApiPlanDiscountDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountDelete(requestParameters: PlanDiscountApiPlanDiscountDeleteRequest, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanDiscountApiPlanDiscountListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountList(requestParameters: PlanDiscountApiPlanDiscountListRequest = {}, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountList(requestParameters.isPublic, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanDiscountApiPlanDiscountPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountPartialUpdate(requestParameters: PlanDiscountApiPlanDiscountPartialUpdateRequest, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanDiscountApiPlanDiscountPlanDiscountByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountPlanDiscountByName(requestParameters: PlanDiscountApiPlanDiscountPlanDiscountByNameRequest, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountPlanDiscountByName(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanDiscountApiPlanDiscountReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountRead(requestParameters: PlanDiscountApiPlanDiscountReadRequest, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlanDiscountApiPlanDiscountUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountApi
     */
    public planDiscountUpdate(requestParameters: PlanDiscountApiPlanDiscountUpdateRequest, options?: any) {
        return PlanDiscountApiFp(this.configuration).planDiscountUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
