import React from "react";
import {Dialog, DialogProps, makeStyles, Grid, Theme, useMediaQuery} from "@material-ui/core";
import {images} from "@constant/images";
import Typography from "@elements/Typography";
import {modalBreakPoint} from "@modules/modals/constant";

interface IProps extends DialogProps {
  title: string;
  children?: React.ReactNode;
}

export default function PanelModalRapper(props: IProps) {
  const classes = useStyles();
  const {onClose: handleClose, title, children, ...rest} = props;
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up(modalBreakPoint));

  return (
    <Dialog
      {...rest}
      classes={{paper: classes.paper}}
      BackdropProps={{className: classes.backdrop}}
      onClose={handleClose}
      fullScreen={!isLarge}
      className={classes.root}
    >
      <img
        src={images.icons.closeGrey}
        alt='close'
        className={classes.closeIcon}
        onClick={() => handleClose?.({}, "escapeKeyDown")}
      />
      <Grid container direction='column' alignItems='center'>
        <Typography noWrap size={2.4} align='center' className={classes.title}>
          {title}
        </Typography>
        <Grid container justify='center'>
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& *::-webkit-scrollbar": {
      width: 10,
      backgroundColor: "#f0f0f0",
      borderRadius: 8,
    },
    "& *::-webkit-scrollbar-thumb": {
      backgroundColor: "#bbb",
      borderRadius: 8,
      border: "solid 2px #f0f0f0",
    },
  },
  paper: {
    direction: "ltr",
    minWidth: 340,
    maxHeight: 600,
    padding: "4.8rem 3.2rem 3.4rem",
    position: "relative",
    [theme.breakpoints.up(modalBreakPoint)]: {
      borderRadius: 5,
    },
  },
  title: {
    paddingBottom: "2.4rem",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  closeIcon: {
    left: "1em",
    top: "1em",
    position: "absolute",
    width: "3.6rem",
    height: "3.6rem",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
}));
