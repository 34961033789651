import {theme} from "@constant/theme";
import AlertContextProvider from "@context/AlertContext/Provider";
import {MuiThemeProvider, StylesProvider, jssPreset} from "@material-ui/core/styles";
import {create} from "jss";
import rtl from "jss-rtl";
import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import ActionsDrawerContextProvider from "./ActionsDrawerContext/Provider";
import NavbarContextProvider from "./NavbarContext/Provider";
import AuthContextProvider from "@context/AuthContext/Provider";
import SnackbarCustomProvider from "./SnackbarProvider";
import {ModalProvider} from "./ModalContext/Provider";
import Modals from "@modules/modals";
import {ErrorHandlerContext} from "./ErrorHandlerContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 10 * 60 * 1000,
    },
  },
});
export default function Providers({children}: {children: React.ReactNode}) {
  const jss = create({plugins: [...jssPreset().plugins, rtl()]});

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <StylesProvider jss={jss}>
            <SnackbarCustomProvider>
              <ModalProvider>
                <NavbarContextProvider>
                  <ActionsDrawerContextProvider>
                    <AuthContextProvider>
                      <ErrorHandlerContext>
                        <AlertContextProvider>{children}</AlertContextProvider>
                      </ErrorHandlerContext>
                    </AuthContextProvider>
                  </ActionsDrawerContextProvider>
                </NavbarContextProvider>
                <Modals />
              </ModalProvider>
            </SnackbarCustomProvider>
          </StylesProvider>
        </StylesProvider>
      </MuiThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
  );
}
