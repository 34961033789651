import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Divider} from "@material-ui/core";
import Typography from "@elements/Typography";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {palette} from "@constant/colors";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useQueryClient} from "react-query";
import {ApiNamespaces} from "@api";
import {useAlert} from "@context/AlertContext";
import Span from "@elements/Span";
import {routes} from "@constant/routes";
import {useAuth} from "@context/AuthContext";
import {useModalDispatch} from "@context/ModalContext";

export interface IBoardsDropdownProps {
  onClick?: () => void;
}

const BoardsDropdown = (props: IBoardsDropdownProps) => {
  const classes = useStyle();
  const {onClick: handleClick} = props;
  const {t} = useTranslation("modules.header");
  const alert = useAlert();
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const handleOpenBoardsModal = useModalDispatch("createBoard");

  const {data: recentBoardsList} = useExperimentalQuery("boards", "getRecentBoardsList", {
    variables: {},
    enabled: !!user,
    staleTime: Infinity,
  });

  const {mutate: mutateCreate} = useExperimentalMutation("boards", "create", {
    onError: (err) => alert.error({}),
    onSuccess: () => {
      alert.success({text: "بورد با موفقیت ایجاد شد"});
      queryClient.invalidateQueries(ApiNamespaces.boards.Query.getList.queryKey);
      queryClient.invalidateQueries(ApiNamespaces.boards.Query.getRecentBoardsList.queryKey);
    },
  });

  return (
    <Box className={classes.container}>
      <Typography
        weight={100}
        size={1.2}
        align='left'
        color={palette.darkGrey[300]}
        className={clsx(classes.margin, classes.padding)}
      >
        {t("boardsDropdown.recentBoards")}
      </Typography>
      {recentBoardsList && !recentBoardsList.length && (
        <Typography weight={500} align='left' noWrap className={classes.padding}>
          {t("boardsDropdown.haveNoBoardYet")}
        </Typography>
      )}
      {!!recentBoardsList?.length &&
        recentBoardsList
          .filter((_, index) => index < 5)
          .slice()
          .reverse()
          .map(({name, id, images_count}) => (
            <Link
              to={routes.landing.boardsDetail(id!)}
              key={name}
              className={clsx(classes.item, classes.recentItem, classes.padding)}
            >
              <Typography weight={500} noWrap>
                {name}
              </Typography>
              <Span className={classes.filesNumber}>{images_count || 0}</Span>
            </Link>
          ))}
      <Box className={classes.padding}>
        <Divider className={classes.divider} />
      </Box>
      <Link to='/boards'>
        <Typography
          weight={500}
          color={palette.green[200]}
          className={clsx(classes.item, classes.padding)}
        >
          {t("boardsDropdown.viewAllBoards")}
        </Typography>
      </Link>
      <Typography
        weight={500}
        color={palette.green[200]}
        className={clsx(classes.item, classes.cursor, classes.padding)}
        onClick={() => {
          handleClick?.();
          handleOpenBoardsModal({
            onSave: (name) => {
              mutateCreate({
                variables: {data: {name}},
              });
            },
          });
        }}
      >
        {t("boardsDropdown.createBoard")}
      </Typography>
    </Box>
  );
};

export default BoardsDropdown;

const useStyle = makeStyles(() => ({
  container: {
    backgroundColor: "white",
    border: `1px solid ${palette.lightGrey.A500}`,
    borderRadius: "2px",
    width: "26.5rem",
    padding: "1rem 0 1rem",
  },
  item: {
    lineHeight: "3rem",
    textAlign: "left",
    // borderRadius: 4,
    "&:hover": {
      backgroundColor: palette.blue[300],
      transition: ".3s cubic-bezier(0.25, 0.1, 0.25, 1)",
      // transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)'
    },
  },
  recentItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > * ": {
      lineHeight: "3rem",
    },
    "&:hover $filesNumber": {
      opacity: 1,
      visibility: "visible",
      marginRight: 0,
    },
  },
  filesNumber: {
    lineHeight: "1.4rem",
    backgroundColor: palette.green[200],
    borderRadius: "25px",
    color: "white",
    fontSize: "1.3rem",
    padding: "3px .8rem 0",
    visibility: "hidden",
    opacity: 0,
    transition: ".15s cubic-bezier(0.25, 0.1, 0.25, 1)",
    marginRight: "-.2rem",
    marginLeft: "1rem",
  },
  divider: {
    margin: "1rem 0",
    height: "2px",
    backgroundColor: palette.lightGrey.A700,
  },
  padding: {
    padding: "0 2.4rem",
  },
  margin: {
    marginBottom: ".7rem",
  },
  cursor: {
    cursor: "pointer",
  },
}));
