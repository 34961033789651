import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Divider, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {
  VerifiedUserTwoTone,
  SupervisorAccountTwoTone,
  AppsTwoTone,
  PhotoLibraryTwoTone,
  ListAltTwoTone,
  CategoryTwoTone,
  MonetizationOnTwoTone,
  GetAppTwoTone,
  MessageTwoTone,
  ReceiptTwoTone,
  ExitToApp,
} from "@material-ui/icons";
import {NavLink} from "react-router-dom";
import clsx from "clsx";
import Span from "@elements/Span";
import {NAVBAR_WIDTH} from "@layouts/PanelLayout";
import {useAuth} from "@context/AuthContext";
import ShouldRender, {Role} from "services/hoc/ShouldRender";
import {routes} from "@constant/routes";

const PanelNavbar = () => {
  const classes = useStyle();
  const {user} = useAuth();
  const {panel} = routes;

  const navbarItems = [
    {
      title: "داشبورد",
      link: panel.dashboard,
      icon: <AppsTwoTone />,
      allowedRolesToSee: [Role.Developer, Role.Owner],
    },
    {title: "کاربران", link: panel.users, icon: <SupervisorAccountTwoTone />},
    {
      title: "عکس‌ها",
      link: panel.images,
      icon: <PhotoLibraryTwoTone />,
      allowedRolesToSee: [Role.Developer, Role.Owner, Role.Admin, Role.Support, Role.Editor],
    },
    // the ShouldRender component uses a default value for allowedRoles => [Role.Developer, Role.Owner, Role.Admin, Role.Support]
    // so don't need to pass the prop if you need exactly as the same as default value
    {title: "پلن‌ها", link: panel.plans, icon: <ListAltTwoTone />},
    {title: "پلن‌های آرشیو شده", link: panel.archivedPlans, icon: <ListAltTwoTone />},
    {title: "موضوعات", link: panel.categories, icon: <CategoryTwoTone />},
    {title: "فاکتور پلن‌ها", link: panel.planInvoices, icon: <ReceiptTwoTone />},
    {title: "فاکتور عکس‌ها", link: panel.imageInvoices, icon: <ReceiptTwoTone />},
    {title: "خریدهای با پلن", link: panel.planPurchases, icon: <MonetizationOnTwoTone />},
    // {title: "دانلود‌ها", link: "/panel/downloads", icon: <GetAppTwoTone />},
    {title: "پیام‌ها", link: panel.messages, icon: <MessageTwoTone />, borderBottom: true},
  ];

  return (
    <Box className={classes.container}>
      <Grid container direction='column' justify='flex-end' className={classes.header}>
        <Grid container justify='space-between' alignItems='center'>
          <Typography size={1.4} weight='bold' className={classes.userName}>
            {!!user?.first_name ? `${user?.first_name} ${user?.last_name}` : user?.username}
          </Typography>
          <VerifiedUserTwoTone className={classes.verifiedIcon} />
        </Grid>
      </Grid>
      <Box className={classes.menu}>
        {navbarItems.map((item) => (
          <ShouldRender key={item.title} allowedRolesToSee={item.allowedRolesToSee}>
            <NavLink to={item.link} activeClassName={classes.active}>
              <Grid
                container
                justify='space-between'
                alignItems='center'
                className={clsx(classes.menuItem)}
              >
                <Typography size={1.4} className={classes.title} weight='bold'>
                  {item.title}
                </Typography>
                <Span className={classes.menuItemIcon}>{item.icon}</Span>
              </Grid>
            </NavLink>
            {item.borderBottom && <Divider className={classes.divider} />}
          </ShouldRender>
        ))}
        <NavLink to='/'>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            className={clsx(classes.menuItem)}
          >
            <Typography size={1.4} className={classes.title} weight='bold'>
              خروج از پنل
            </Typography>
            <Span className={classes.menuItemIcon}>
              <ExitToApp />
            </Span>
          </Grid>
        </NavLink>
      </Box>
    </Box>
  );
};

export default PanelNavbar;

const useStyle = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    paddingBottom: 10,
    width: NAVBAR_WIDTH,
    borderRight: "1px solid #e5e7eb",
    overflowY: "scroll",
    scrollbarWidth: "none" /* Firefox */,
    "-msOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  header: {
    padding: "20px 18px 14px 18px",
    gap: 23,
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  },
  userName: {
    color: "#353535 !important",
  },
  verifiedIcon: {
    fontSize: "25px",
    color: theme.palette.primary.main,
  },
  menu: {
    padding: "8px",
  },
  menuItem: {
    cursor: "pointer",
    padding: "3px 11px",
    marginBottom: 6,
    borderRadius: 4,
    color: "#353535 !important",
    transition: ".15s",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  active: {
    "& > *": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  title: {
    color: "inherit",
  },
  menuItemIcon: {
    "& *": {
      fontSize: "25px",
    },
  },
  divider: {
    margin: "15px 0",
  },
}));
