import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Switch} from "@material-ui/core";
import Pagination from "@elements/panel/Pagination";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useQueryClient} from "react-query";
import {useAlert} from "@context/AlertContext";
import {ApiNamespaces} from "@api";
import Table from "@elements/panel/Table";
import {useTranslation} from "react-i18next";
import Dropdown from "@elements/panel/Dropdown";
import {formatDate} from "@util/global";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import Typography from "@elements/Typography";
import Spinner from "@elements/Spinner";
import {Link} from "react-router-dom";
import Tooltip from "@elements/Tooltip";
import {BuyByPlanApiBuyByPlanPartialUpdateRequest as Data} from "@schema";
import {routes} from "@constant/routes";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInput from "@elements/panel/QuerySearchInput";
import DropdownFromDaysBefore from "@elements/panel/DropdownFromDaysBefore";

const PlanPurchases = () => {
  const {page, pageSize, search, fromDaysBefore, isActive} = useGetQueryString();
  const queryClient = useQueryClient();
  const alert = useAlert();
  const classes = useStyle();
  const {t} = useTranslation("pages.panel");

  const handleUpdateQueryString = useUpdateQueryString();

  const {data} = useExperimentalQuery("buyByPlan", "getList", {
    variables: {
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : 20,
      search,
      fromDaysBefore: fromDaysBefore ? Number(fromDaysBefore) : undefined,
      isActive,
    },
  });

  const {mutate: changeStatus} = useExperimentalMutation("buyByPlan", "update");

  const events = {
    onSuccess: () => {
      alert.success({});
      queryClient.invalidateQueries(ApiNamespaces.buyByPlan.Query.getList.queryKey);
    },
    onError: () => alert.error({}),
  };

  const handleSetActive = (id: number) => {
    changeStatus({variables: {id: String(id), data: {is_active: true} as Data["data"]}, ...events});
  };

  const handleSetInactive = (id: number) => {
    changeStatus({
      variables: {id: String(id), data: {is_active: false} as Data["data"]},
      ...events,
    });
  };

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid container justify='space-between' className={classes.inputsBox}>
        <Grid container alignItems='center' className={classes.borderLeft}>
          <QuerySearchInput />
        </Grid>
        <Grid container justify='flex-end' className={classes.gap}>
          <DropdownFromDaysBefore />
          <Dropdown
            style={{width: 170, flexShrink: 0}}
            fullWidth
            label={t("common.beActiveStatus")}
            items={[
              {label: t("common.all"), value: ""},
              {label: t("common.active"), value: "true"},
              {label: t("common.inactive"), value: "false"},
            ]}
            value={isActive}
            onChange={(v) => handleUpdateQueryString({isActive: v})}
          />
        </Grid>
      </Grid>
      {!data && <Spinner />}
      <Grid item>
        <Grid className={classes.invoicesBox}>
          {data?.results.length === 0 && <NoResult />}
          {!!data?.results.length && (
            <Table
              title={t("purchases.planPurchases")}
              firstRowTitles={[
                t("common.createDate"),
                t("common.mobileNumber"),
                t("common.image"),
                t("purchases.usedPlan"),
                t("common.active"),
              ]}
              rows={(data?.results || []).map((item) => ({
                id: item.id || 0,
                data: [
                  item.created ? (
                    <Tooltip title={item.created?.split("T")[1]?.slice(0, 8) || ""}>
                      <Typography>{formatDate(item.created)}</Typography>
                    </Tooltip>
                  ) : (
                    "-"
                  ),
                  item.owner ? `${item.owner.slice(1)}+` : "-",
                  <Link to={routes.panel.imagesDetail(item.image_info!.id!)}>
                    <div style={{height: "40px", display: "flex", justifyContent: "center"}}>
                      <img
                        src={item.image_info?.file}
                        alt={item.image_info?.title || ""}
                        style={{height: "100%"}}
                      />
                    </div>
                  </Link>,
                  item.plan_invoice,
                  <Box style={{textAlign: "center"}}>
                    <Switch
                      color='primary'
                      checked={item.is_active ? true : false}
                      onChange={(_, value) => {
                        alert.prompt({}).then(({result}) => {
                          if (result) {
                            value === true
                              ? handleSetActive(item.id || 0)
                              : handleSetInactive(item.id || 0);
                          }
                        });
                      }}
                    />
                  </Box>,
                ],
              }))}
            />
          )}
        </Grid>
      </Grid>
      <Pagination count={data?.count || 0} />
    </Grid>
  );
};

export default PlanPurchases;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  inputsBox: {
    marginBottom: 20,
    height: 56,
    gap: 20,
  },
  borderLeft: {
    borderRight: `solid 1px ${theme.palette.grey[200]}`,
    width: "auto",
  },
  gap: {
    gap: 6,
    maxWidth: 800,
  },
  invoicesBox: {
    paddingLeft: 3,
    paddingRight: 3,
  },
}));
