import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Close as MaterialCloseIcon} from "@material-ui/icons";
import {palette} from "@constant/colors";

interface ICloseProps {
  onClick?: () => void;
}

const Close = (props: ICloseProps) => {
  const classes = useStyle();
  const {onClick: handleClick} = props;

  return <MaterialCloseIcon className={classes.root} onClick={handleClick} />;
};

export default Close;

const useStyle = makeStyles(() => ({
  root: {
    color: palette.darkGrey[300],
    width: 26,
    height: 26,
    backgroundColor: "#2f3333", // not in palette
    cursor: "pointer",
    padding: ".4rem",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#474D4D", // not in palette
      color: palette.lightGrey.A800,
    },
  },
}));
