import {createQuery, makeApi} from "@api/types";
import {TagsApi, TagsApiTagsListRequest, TagsApiTagsReadRequest} from "@schema";
import {axiosInstance} from "services/axios/axios";

const tagsApiInstance = new TagsApi(undefined, undefined, axiosInstance);

export const tagsApi = makeApi({
  identifier: "tags",
  Query: {
    getList: createQuery(async (params: TagsApiTagsListRequest) => {
      const {data} = await tagsApiInstance.tagsList(params);
      return data;
    }),
    readDetail: createQuery(async (params: TagsApiTagsReadRequest) => {
      const {data} = await tagsApiInstance.tagsRead(params);
      return data;
    }),
  },
});
