import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import {palette} from "@constant/colors";
import clsx from "clsx";

interface ISentenceWithHighlightedQuery {
  sentence: string;
  query: string;
}
const SentenceWithHighlightedQuery = (props: ISentenceWithHighlightedQuery) => {
  const {sentence, query} = props;
  const classes = useStyle();

  return (
    <Box className={classes.gap}>
      {sentence.split(" ").map((word) =>
        word.toLocaleLowerCase().startsWith(query.toLocaleLowerCase()) ? (
          <Box key={word}>
            <Typography component='span' className={clsx(classes.highlighted, classes.fontSize)}>
              {word.slice(0, query.length)}
            </Typography>
            <Typography component='span' className={classes.fontSize}>
              {word.slice(query.length)}
            </Typography>
          </Box>
        ) : (
          <Typography component='span' key={word} className={classes.fontSize}>
            {word}
          </Typography>
        )
      )}
    </Box>
  );
};

export default SentenceWithHighlightedQuery;

const useStyle = makeStyles(() => ({
  gap: {
    display: "inline-flex",
    gap: ".6rem",
    flexDirection: "row-reverse",
  },
  highlighted: {
    color: palette.darkGrey[200], // the original color is #666e6e, but here, not very obvious probably because of our current font
    "&:hover": {
      color: palette.darkGrey[200],
    },
  },
  fontSize: {
    fontSize: "1.6rem",
  },
}));
