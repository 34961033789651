import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Link} from "react-router-dom";

export interface IListProps {
  title: string;
  items: {title: string; href: string; renderOriginalAnchor?: boolean}[];
}

const List = (props: IListProps) => {
  const classes = useStyle();
  const {title, items} = props;

  return (
    <Box>
      <Typography size={1.3} weight={500} color='white' className={classes.title}>
        {title}
      </Typography>
      <ul>
        {items.map((item) => (
          <li key={item.title}>
            {item.renderOriginalAnchor ? (
              <a key={item.title} href={item.href} target='_blank' rel='noreferrer'>
                <Typography span size={1.4} weight={500} color='white' className={classes.item}>
                  {item.title}
                </Typography>
              </a>
            ) : (
              <Link key={item.title} to={item.href}>
                <Typography span size={1.4} weight={500} color='white' className={classes.item}>
                  {item.title}
                </Typography>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default List;

const useStyle = makeStyles(() => ({
  title: {
    display: "inline-block",
    opacity: 0.6,
    marginBottom: "1.4rem",
    padding: ".15rem 0",
  },
  item: {
    display: "inline-block",
    opacity: 0.8,
    lineHeight: 1.6,
    padding: ".15rem 0",
    "&:hover": {
      color: palette.green[200],
    },
  },
}));
