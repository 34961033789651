import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import Typography from "@elements/Typography";
import AccountTable from "@elements/table/AccountTable";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {lightGrey} from "@constant/colors";
import Pagination from "@elements/Pagination";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {formatDate} from "@util/global";
import {Payment, Check} from "@material-ui/icons";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import Tooltip from "@elements/Tooltip";
import Spinner from "@elements/Spinner";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {useErrorHandlerContext} from "@context/ErrorHandlerContext";
import {useLocation} from "react-router-dom";
import {setUserLastLocationInLs} from "@util/userLastLocation";
import useApiErrorHandler from "@hook/useApiErrorHandler";

const PlanInvoices = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const alert = useAlert();
  const {page} = useGetQueryString();
  const serverErrorNotify = useServerErrorNotify();
  const {setErrorStatusCode} = useErrorHandlerContext();
  const apiErrorHandler = useApiErrorHandler();
  const pageSize = 20;
  const location = useLocation();

  const {data: planInvoices} = useExperimentalQuery("planInvoices", "getList", {
    variables: {
      pageSize,
      page: page ? Number(page) : undefined,
    },
    onError: (error) => {
      setErrorStatusCode(error);
      serverErrorNotify(error);
    },
  });

  const {mutate: buyPlan} = useExperimentalMutation("planInvoices", "createPay");
  const handleCreatePlanPay = (id: number) => {
    buyPlan({
      variables: {id: String(id), data: {}},
      onError: (error) => apiErrorHandler(error),
      onSuccess: (res) => {
        setUserLastLocationInLs(location.pathname);
        const response = (res as unknown) as {link: string};
        window.location.href = response.link;
      },
    });
  };

  return (
    <Box className={classes.container}>
      <Typography size={2.8} weight={500} className={classes.title}>
        {t("planInvoices")}
      </Typography>
      {!planInvoices && <Spinner style={{padding: "5rem"}} />}
      {!!planInvoices?.results.length && (
        <>
          <AccountTable
            titles={[
              t("orderNumber"),
              t("planName"),
              t("createDate"),
              t("purchaseDate"),
              t("expire"),
              t("invoicePrice"),
              t("pay"),
            ]}
            rows={planInvoices.results.map(
              ({
                id,
                value,
                plan_info,
                created,
                paid_date,
                invoice_expire_date,
                is_active,
                invoice_is_expired,
              }) => [
                <Typography>{id}</Typography>,
                <Typography>{plan_info?.name}</Typography>,
                <Tooltip title={created?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(created)}</Typography>
                </Tooltip>,
                <div>
                  {!!paid_date ? (
                    <Tooltip title={paid_date?.split("T")[1]?.slice(0, 8) || ""}>
                      <Typography>{formatDate(paid_date)}</Typography>
                    </Tooltip>
                  ) : (
                    "❌"
                  )}
                </div>,
                <Tooltip title={invoice_expire_date?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(invoice_expire_date)}</Typography>
                </Tooltip>,
                <Typography>{value}</Typography>,
                <Typography>
                  {!paid_date && is_active && !invoice_is_expired ? (
                    <Payment className={classes.icon} onClick={() => handleCreatePlanPay(id!)} />
                  ) : paid_date ? (
                    <Check htmlColor='seagreen' style={{fontSize: 28}} />
                  ) : (
                    "-"
                  )}
                </Typography>,
              ]
            )}
          />
          {planInvoices.count > pageSize && (
            <div className={classes.paginationBox}>
              <Pagination count={planInvoices?.count || 0} pageSize={pageSize} />
            </div>
          )}
        </>
      )}
      {planInvoices && !planInvoices.count && (
        <Box className={classes.noResultBox}>
          <Typography>{t("noInvoicesMessage")}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PlanInvoices;

const useStyle = makeStyles(() => ({
  container: {},
  title: {
    marginBottom: "5rem",
  },
  noResultBox: {
    padding: " 1.4rem 2rem 1.2rem 3rem",
    border: `1px solid ${lightGrey.A300}`,
    backgroundColor: "white",
  },
  paginationBox: {
    marginTop: "8rem",
  },
  icon: {
    cursor: "pointer",
    fontSize: "2.5rem",
    color: "gray",
  },
}));
