import {darkGrey} from "@constant/colors";
import GreyButton from "@elements/button/GreyButton";
import Typography from "@elements/Typography";
import {Divider, Grid} from "@material-ui/core";
import {LibraryAddCheckOutlined} from "@material-ui/icons";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

interface SelectButtonProps {
  selected: number;
  itemsLength: number;
  onSelectAll?: () => void;
  onDeselect?: () => void;
}

export default function SelectButton({
  onDeselect: handleDeselect,
  onSelectAll: handleSelectAll,
  selected,
  itemsLength,
}: SelectButtonProps) {
  const classes = useStyles();
  const {t} = useTranslation("pages.boards.[id]");

  const selectAllButton = (
    <GreyButton icon={<LibraryAddCheckOutlined />} onClick={handleSelectAll}>
      {t("selectButton.selectAll")}
    </GreyButton>
  );

  const removeAllButton = (
    <Typography
      size={1.4}
      weight={500}
      color='inherit'
      className={classes.clickable}
      onClick={handleDeselect}
    >
      {t("selectButton.clearSelection")}
    </Typography>
  );

  if (!selected) {
    return selectAllButton;
  }
  return (
    <Grid container alignItems='center' justify='center' className={classes.root}>
      <Typography size={1.4} color='inherit'>
        {t("selectButton.selected", {count: selected})}
      </Typography>
      <Divider className={classes.divider} />
      {selected < itemsLength ? selectAllButton : removeAllButton}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    color: darkGrey[300],
    // FIXME not in palette
    backgroundColor: "#f2f5f5",
    borderRadius: 2,
    height: "3.5rem",
    gap: "0.6rem",
    width: "fit-content",
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    "&:hover": {
      backgroundColor: "#f2f5f5",
    },
  },
  clickable: {
    cursor: "pointer",
  },
  divider: {
    height: "1.84rem",
    backgroundColor: darkGrey[300],
    width: 1,
  },
}));
