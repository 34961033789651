import {TransactionsApi, TransactionsApiTransactionsStatusRequest} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const transactionsApiInstance = new TransactionsApi(undefined, undefined, axiosInstance);

export const transactionsAPI = makeApi({
  identifier: "transactions",
  Query: {
    status: createQuery(async (params: TransactionsApiTransactionsStatusRequest) => {
      const {data} = await transactionsApiInstance.transactionsStatus(params);
      return data;
    }),
  },
});
