const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const persianNumbersToEnglish = (value: string) =>
  value
    .split("")
    .map((char) => {
      const index = persianNumbers.indexOf(char);
      if (index !== -1) {
        return englishNumbers[index];
      } else {
        return char;
      }
    })
    .join("");

export const englishNumbersToPersian = (value: string) =>
  value
    .split("")
    .map((char) => {
      const index = englishNumbers.indexOf(char);
      if (index !== -1) {
        return persianNumbers[index];
      } else {
        return char;
      }
    })
    .join("");
