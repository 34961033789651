import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import CollectionCard from "./CollectionCard";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";

const CollectionSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.pricing");
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(600));
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(440));

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  return (
    <Box className={classes.container}>
      <Typography size={xxsDown ? 1.8 : xsDown ? 2 : 2.4} align='center' className={classes.title}>
        {t("collectionSection.title")}
      </Typography>
      <Grid container justify='center' className={classes.cardsBox}>
        <CollectionCard
          title={t("collectionSection.signatureCollectionTitle")}
          description={t("collectionSection.signatureCollectionDescription")}
          image={data?.premium_plan_image || ""}
          className={classes.card}
        />
        <CollectionCard
          title={t("collectionSection.essentialCollectionTitle")}
          description={t("collectionSection.essentialCollectionDescription")}
          image={data?.subscription_plan_image || ""}
          className={classes.card}
        />
      </Grid>
    </Box>
  );
};

export default CollectionSection;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "4.8rem 0 4.8rem",
    maxWidth: "110rem",
    [theme.breakpoints.down(400)]: {
      padding: "3rem 0 4rem",
    },
  },
  title: {
    marginBottom: "3.2rem",
  },
  cardsBox: {
    gap: 8,
    [theme.breakpoints.down(500)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  card: {
    border: `1px solid ${palette.lightGrey.A800}`,
    borderRadius: "2px",
  },
}));
