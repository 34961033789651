import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, useMediaQuery, Theme} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {Check, Close} from "@material-ui/icons";

const CompareTable = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.pricing");
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(600));

  return (
    <Box className={classes.container}>
      <Typography size={2.4}>مقایسه انواع اشتراک</Typography>
      <Typography style={{marginTop: "1.6rem"}}>
        قبل از خرید حتما از جدول زیر مشخصات اشتراک مورد نظرت رو ببین!
      </Typography>
      <Box className={classes.tableBox}>
        <table className={classes.table}>
          <thead>
            <tr>
              {["", t("compareTable.essential"), t("compareTable.premium")].map((item) => (
                <Typography component='th' size={xsDown ? 1.4 : 1.6} weight={700} key={item}>
                  {item}
                </Typography>
              ))}
            </tr>
          </thead>
          <tbody>
            {[
              [t("compareTable.accessability"), false, true],
              [t("compareTable.quality"), false, true],
              [t("compareTable.price"), true, false],
            ].map((row, index) => (
              <tr key={index.toString()}>
                {row.map((item, index) => (
                  <Typography component='td' size={xsDown ? 1.4 : 1.6} key={index.toString()}>
                    {item === true ? (
                      <Check />
                    ) : item === false ? (
                      <Close style={{color: "#bbb"}} />
                    ) : (
                      item
                    )}
                  </Typography>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default CompareTable;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "3rem 0 5rem",
    "& > *": {
      textAlign: "center",
    },
    [theme.breakpoints.down(600)]: {
      padding: "1rem 0 4rem",
    },
  },
  tableBox: {
    marginTop: "3rem",
  },
  table: {
    borderCollapse: "collapse",
    margin: "0 auto",
    "& svg": {
      fontSize: 22,
    },
    "& th, & td": {
      borderBottom: "1px solid #e8eded",
      padding: "1.5rem 4rem",
      [theme.breakpoints.down(600)]: {
        padding: "1rem 2rem",
      },
      "&:first-of-type": {
        paddingLeft: "1rem",
        paddingRight: "8rem",
        [theme.breakpoints.down(600)]: {
          paddingRight: "4rem",
        },
      },
    },
  },
}));
