import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import PanelNavbar from "@elements/panel/PanelNavbar";
import {useHistory} from "react-router-dom";
import {routes} from "@constant/routes";

export const NAVBAR_WIDTH = 230;
const PanelLayout = ({children}: React.PropsWithChildren<{}>) => {
  const classes = useStyle();
  const history = useHistory();

  return (
    <Grid container className={classes.container}>
      <Box className={classes.navbarBox}>
        <PanelNavbar />
      </Box>
      <Box
        className={classes.contentBox}
        style={{
          backgroundColor:
            history.location.pathname === routes.panel.dashboard ? "#efefef" : "#fff",
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default PanelLayout;

const useStyle = makeStyles(() => ({
  container: {
    width: "100%",
  },
  navbarBox: {
    flexShrink: 0,
    width: NAVBAR_WIDTH,
    backgroundColor: "white",
    position: "relative",
    height: "100vh",
  },
  contentBox: {
    minHeight: "101vh",
    width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    padding: "2rem 1.8rem",
  },
}));
