import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, useMediaQuery} from "@material-ui/core";
import GreenLink from "@elements/GreenLink";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {customMedium} from "@modules/mainLayout/breakpoints";
import {routes} from "@constant/routes";

const PlansAndPricing = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.home");
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const fontSize = xsDown ? 1.6 : smDown ? 1.8 : 2.1;

  return (
    <Box className={classes.container}>
      <Typography span size={fontSize} weight={500} color='white' align='center'>
        {t("plansAndPricing.regular")}
      </Typography>
      <GreenLink to={routes.landing.pricing} size={fontSize}>
        {t("plansAndPricing.link")}
      </GreenLink>
    </Box>
  );
};

export default PlansAndPricing;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "3.5rem 1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(12,13,13,0.7)",
    gap: ".5rem",
    [theme.breakpoints.down(customMedium)]: {
      flexDirection: "column",
      padding: "1.5rem 1rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "1.5rem 1rem",
    },
    [theme.breakpoints.down(420)]: {
      padding: "1rem .5rem",
    },
  },
}));
