import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Switch} from "@material-ui/core";
import Pagination from "@elements/panel/Pagination";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useQueryClient} from "react-query";
import {useAlert} from "@context/AlertContext";
import {ApiNamespaces} from "@api";
import Table from "@elements/panel/Table";
import {useTranslation} from "react-i18next";
import Dropdown from "@elements/panel/Dropdown";
import {formatDate} from "@util/global";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import Typography from "@elements/Typography";
import {Link} from "react-router-dom";
import Spinner from "@elements/Spinner";
import Tooltip from "@elements/Tooltip";
import {ImageInvoicesApiImageInvoicesPartialUpdateRequest as Data} from "@schema";
import AddIcon from "@elements/panel/AddIcon";
import ImageInvoiceCreateModal from "@elements/panel/modals/ImageInvoiceCreateModal";
import ShouldRender, {Role} from "services/hoc/ShouldRender";
import {routes} from "@constant/routes";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInput from "@elements/panel/QuerySearchInput";
import DropdownFromDaysBefore from "@elements/panel/DropdownFromDaysBefore";

const ImageInvoices = () => {
  const {page, pageSize, search, fromDaysBefore, isActive, isPaid, isExpired} = useGetQueryString();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const alert = useAlert();
  const classes = useStyle();
  const {t} = useTranslation("pages.panel");

  const handleUpdateQueryString = useUpdateQueryString();

  const {data} = useExperimentalQuery("imagesInvoices", "getList", {
    variables: {
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : 20,
      search,
      fromDaysBefore: fromDaysBefore ? Number(fromDaysBefore) : undefined,
      isActive,
      isPaid,
      isExpired,
    },
  });

  const {mutate: changeStatus} = useExperimentalMutation("imagesInvoices", "update");

  const events = {
    onSuccess: () => {
      alert.success({});
      queryClient.invalidateQueries(ApiNamespaces.imagesInvoices.Query.getList.queryKey);
    },
    onError: () => alert.error({}),
  };

  const handleSetPaid = (id: number) => {
    changeStatus({variables: {id: String(id), data: {is_paid: true} as Data["data"]}, ...events});
  };
  const handleSetNotPaid = (id: number) => {
    changeStatus({variables: {id: String(id), data: {is_paid: false} as Data["data"]}, ...events});
  };

  const handleSetActive = (id: number) => {
    changeStatus({variables: {id: String(id), data: {is_active: true} as Data["data"]}, ...events});
  };
  const handleSetInactive = (id: number) => {
    changeStatus({
      variables: {id: String(id), data: {is_active: false} as Data["data"]},
      ...events,
    });
  };

  return (
    <>
      <Grid container direction='column' className={classes.container}>
        <Grid container justify='space-between' className={classes.inputsBox}>
          <Grid container alignItems='center' className={classes.borderLeft}>
            <QuerySearchInput />
          </Grid>
          <Grid container justify='flex-end' className={classes.gap}>
            <DropdownFromDaysBefore />
            <Dropdown
              style={{width: 170, flexShrink: 0}}
              fullWidth
              label={t("invoices.paymentStatus")}
              items={[
                {label: t("common.all"), value: ""},
                {label: t("invoices.paid"), value: "true"},
                {label: t("invoices.notPaid"), value: "false"},
              ]}
              value={isPaid}
              onChange={(v) => handleUpdateQueryString({isPaid: v})}
            />
            <Dropdown
              style={{width: 140, flexShrink: 0}}
              fullWidth
              label={t("common.beActiveStatus")}
              items={[
                {label: t("common.all"), value: ""},
                {label: t("common.active"), value: "true"},
                {label: t("common.inactive"), value: "false"},
              ]}
              value={isActive}
              onChange={(v) => handleUpdateQueryString({isActive: v})}
            />
            <Dropdown
              style={{width: 140, flexShrink: 0}}
              fullWidth
              label={t("invoices.expireStatus")}
              items={[
                {label: t("common.all"), value: ""},
                {label: t("invoices.expired"), value: "true"},
                {label: t("invoices.notExpired"), value: "false"},
              ]}
              value={isExpired}
              onChange={(v) => handleUpdateQueryString({isExpired: v})}
            />
          </Grid>
        </Grid>
        {!data && <Spinner />}
        <Grid item>
          <Grid className={classes.invoicesBox}>
            {data?.results.length === 0 && <NoResult />}
            {!!data?.results.length && (
              <Table
                title={t("invoices.imageInvoices")}
                headerPayload={<AddIcon size='sm' onClick={() => setModalIsOpen(true)} />}
                firstRowTitles={[
                  t("invoices.orderNumber"),
                  t("common.createDate"),
                  t("common.mobileNumber"),
                  t("common.image"),
                  t("invoices.invoicePrice"),
                  t("invoices.purchaseDate"),
                  t("invoices.paymentStatus"),
                  t("invoices.expire"),
                  t("common.active"),
                ]}
                rows={(data?.results || []).map((item) => ({
                  id: item.id || 0,
                  data: [
                    item.id || "",
                    item.created ? (
                      <Tooltip title={item.created?.split("T")[1]?.slice(0, 8) || ""}>
                        <Typography>{formatDate(item.created)}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    ),
                    item.owner ? `${item.owner.slice(1)}+` : "-",
                    <Link to={routes.panel.imagesDetail(item.image_info!.id!)}>
                      <div style={{height: "40px", display: "flex", justifyContent: "center"}}>
                        <img
                          src={item.image_info?.file}
                          alt={item.image_info?.title || ""}
                          style={{height: "100%"}}
                        />
                      </div>
                    </Link>,
                    <ShouldRender
                      allowedRolesToSee={[Role.Developer, Role.Owner, Role.Admin]}
                      fallback='-'
                    >
                      {item.value || ""}
                    </ShouldRender>,
                    <div>
                      {!!item.paid_date ? (
                        <Tooltip title={item.paid_date?.split("T")[1]?.slice(0, 8) || ""}>
                          <Typography>{formatDate(item.paid_date)}</Typography>
                        </Tooltip>
                      ) : (
                        "❌"
                      )}
                    </div>,
                    <Box style={{textAlign: "center"}}>
                      <Switch
                        color='primary'
                        checked={item.paid_date ? true : false}
                        onChange={(_, value) => {
                          alert.prompt({}).then(({result}) => {
                            if (result) {
                              value === true
                                ? handleSetPaid(item.id || 0)
                                : handleSetNotPaid(item.id || 0);
                            }
                          });
                        }}
                      />
                    </Box>,
                    item.invoice_expire_date ? (
                      <Tooltip title={item.invoice_expire_date?.split("T")[1]?.slice(0, 8) || ""}>
                        <Typography>{formatDate(item.invoice_expire_date)}</Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    ),
                    <Box style={{textAlign: "center"}}>
                      <Switch
                        color='primary'
                        checked={item.is_active ? true : false}
                        onChange={(_, value) => {
                          alert.prompt({}).then(({result}) => {
                            if (result) {
                              value === true
                                ? handleSetActive(item.id || 0)
                                : handleSetInactive(item.id || 0);
                            }
                          });
                        }}
                      />
                    </Box>,
                  ],
                }))}
                rowsSize={[2, 3, 4, 3, 2, 3, 2, 3, 2]}
              />
            )}
          </Grid>
        </Grid>
        <Pagination count={data?.count || 0} />
      </Grid>
      <ImageInvoiceCreateModal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onSubmit={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default ImageInvoices;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  inputsBox: {
    marginBottom: 20,
    height: 56,
    gap: 20,
  },
  borderLeft: {
    borderRight: `solid 1px ${theme.palette.grey[200]}`,
    width: "auto",
  },
  gap: {
    gap: 6,
    maxWidth: 800,
  },
  invoicesBox: {
    paddingLeft: 3,
    paddingRight: 3,
  },
}));
