import {imagesAPI} from "./images";
import {categoriesAPI} from "./categories";
import {authAPI} from "./auth";
import {plansAPI} from "./plans";
import {tagsApi} from "./tags";
import {usersAPI} from "./users";
import {planInvoicesAPI} from "./planInvoices";
import {buyByPlanAPI} from "./buyByPlan";
import {imagesInvoicesAPI} from "./imageInvoices";
import {transactionsAPI} from "./transactions";
import {faqsAPI} from "./faqs";
import {boardsAPI} from "./boards";
import {messagesAPI} from "./messages";
import {panelAPI} from "./panel";
import {promoCodeByNameAPI} from "./promoCodeByName";
import {promoCodeAPI} from "./promoCode";
import {collectionsAPI} from "./collections";
import {aboutAPI} from "./about";
import {privacyAndPolicyAPI} from "./privacyAndPolicy";
import {blogAPI} from "./blog";

const ApiNamespaces = {
  images: imagesAPI,
  buyByPlan: buyByPlanAPI,
  imagesInvoices: imagesInvoicesAPI,
  auth: authAPI,
  blog: blogAPI,
  categories: categoriesAPI,
  plans: plansAPI,
  planInvoices: planInvoicesAPI,
  tags: tagsApi,
  collections: collectionsAPI,
  users: usersAPI,
  transactions: transactionsAPI,
  faqs: faqsAPI,
  boards: boardsAPI,
  messages: messagesAPI,
  panel: panelAPI,
  promoCode: promoCodeAPI,
  promoCodeByName: promoCodeByNameAPI,
  about: aboutAPI,
  privacyAndPolicy: privacyAndPolicyAPI,
};
export {ApiNamespaces};
