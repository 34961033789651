import {fontNormalizeFactor} from "@elements/Typography/constant";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, InputBase} from "@material-ui/core";
import {useOnClickOutside} from "@hook/useOutsideClicker";
import PhraseHistory from "./PhraseHistory";
import {Close, Search} from "./Adornments";
import {localStPhraseHistoryAdd, localStPhraseHistoryGet} from "./utils";
import AutoComplete from "./AutoComplete";
import useDebouncedValue from "@hook/useDebouncedValue";
import {palette} from "@constant/colors";
import clsx from "clsx";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";

export interface ISearchInputProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  opacity?: boolean;
  withHistoryAndAutoComplete?: boolean;
  inputClassName?: string;
  badgesClassName?: string;
  badges?: React.ReactNode[];
}

const SearchInput = (props: ISearchInputProps) => {
  const {
    value: controlledValue,
    onChange,
    onSearch,
    opacity,
    placeholder = "آغاز جستجوی تو...",
    withHistoryAndAutoComplete = true,
    badges,
    inputClassName,
    badgesClassName,
  } = props;
  const [value, setValue] = useState("");
  const [showHistoryAndAutoCompleteBox, setShowHistoryAndAutoCompleteBox] = useState<boolean>(
    false
  );
  const [history, setHistory] = useState<string[]>([]);
  const classes = useStyle({opacity});
  const debouncedValue = useDebouncedValue(value, 500);
  const handleUpdateQueryString = useUpdateQueryString();
  const historyAndAutoCompleteBoxRef = useOnClickOutside<HTMLDivElement>(() =>
    setShowHistoryAndAutoCompleteBox(false)
  );

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  useEffect(() => {
    setHistory(localStPhraseHistoryGet());
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = event.target;
    setValue(value);
    onChange?.(value);
    if (!value && !history.length) {
      setShowHistoryAndAutoCompleteBox(false);
    } else {
      setShowHistoryAndAutoCompleteBox(true);
    }
  };

  const handleSearch = (val: string = value) => {
    if (val && withHistoryAndAutoComplete) {
      localStPhraseHistoryAdd(val);
      setHistory(localStPhraseHistoryGet());
      setShowHistoryAndAutoCompleteBox(false);
    }
    setValue(val);
    onSearch?.(val);
    onChange?.(val);
  };

  const handleRemove = () => {
    setValue("");
    onChange?.("");
    setShowHistoryAndAutoCompleteBox(false);
    handleUpdateQueryString({search: ""});
  };

  const handleFocus = () => {
    if (!value && history.length) {
      setShowHistoryAndAutoCompleteBox(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  let historyAndAutoComplete = null;
  if (value) {
    historyAndAutoComplete = (
      <AutoComplete
        value={value}
        debouncedValue={debouncedValue}
        onClick={(value) => handleSearch(value)}
      />
    );
  }
  if (!value && history.length) {
    historyAndAutoComplete = (
      <PhraseHistory
        history={history}
        setShowHistory={setShowHistoryAndAutoCompleteBox}
        setHistory={setHistory}
        onClick={(value) => handleSearch(value)}
      />
    );
  }

  const startAdornment = (
    <>
      <Search onClick={() => handleSearch()} />
      {!!badges?.length && (
        <Box className={clsx(classes.badgesBox, badgesClassName)}>
          {badges?.map((item, index) => (
            <Box key={index.toString()} className={classes.badge}>
              {item}
            </Box>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <Grid className={classes.container} ref={historyAndAutoCompleteBoxRef}>
      <InputBase
        fullWidth
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={clsx(classes.textInput, inputClassName)}
        classes={{input: classes.input}}
        startAdornment={startAdornment}
        endAdornment={value && <Close onClick={handleRemove} />}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
      {withHistoryAndAutoComplete && showHistoryAndAutoCompleteBox && (
        <Box className={classes.historyAndAutoCompleteBox}>{historyAndAutoComplete}</Box>
      )}
    </Grid>
  );
};

export default SearchInput;

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  textInput: {
    width: " 100%",
    height: "100%",
  },
  input: {
    height: "100%",
    padding: ".7rem",
    color: palette.darkGrey.A700,
    fontSize: `${2.1 * fontNormalizeFactor}rem`,
    "&::placeholder": {
      fontSize: `${1.6 * fontNormalizeFactor}rem`,
      color: palette.darkGrey[900],
      fontWeight: 500,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: `${1.8 * fontNormalizeFactor}rem`,
      "&::placeholder": {
        fontSize: `${1.4 * fontNormalizeFactor}rem`,
      },
    },
  },
  historyAndAutoCompleteBox: ({opacity}: {opacity?: boolean}) => ({
    width: "100%",
    padding: "0 1.5rem 1rem 8rem",
    position: "absolute",
    border: `1.6px solid ${palette.lightGrey.A300}`,
    borderTop: "none",
    top: "calc(100% + 1px )",
    backgroundColor: opacity ? "rgba(255,255,255,.9)" : "white",
    zIndex: 1000,
    maxHeight: "32rem",
    overflow: "hidden",
    [theme.breakpoints.down(400)]: {
      padding: "0 1.5rem 1rem 5rem",
    },
    [theme.breakpoints.down(340)]: {
      padding: "0 0 1rem 2.5rem",
    },
    [theme.breakpoints.down(270)]: {
      padding: "0 1.5rem 1rem 1rem",
    },
  }),
  badgesBox: {
    display: "flex",
  },
  badge: {
    margin: "0 .3rem",
  },
}));
