import Typography from "@elements/Typography";
import {Dialog, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {modalBreakPoint} from "@modules/modals/constant";
import React from "react";
import {HelpOutline as Help} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {AlertContext, AlertProps, PromptResult} from ".";
import {useSnackbar} from "notistack";

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
}
export default function AlertContextProvider({children}: IProps) {
  const [type, setType] = React.useState<"info" | "error" | "success" | "prompt" | null>(null);
  const [promptOpen, setPromptOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const promptPromise = React.useRef<(value: PromptResult | PromiseLike<PromptResult>) => void>();
  const alertPromise = React.useRef<(value: boolean | PromiseLike<boolean>) => void>();
  const {t} = useTranslation("elements");
  const classes = useStyles({type});
  const snackbar = useSnackbar();

  React.useEffect(() => {
    if (!promptOpen) {
      promptPromise.current = undefined;
    }
  }, [promptOpen]);

  const info = ({text}: AlertProps) => {
    setType("info");
    snackbar.enqueueSnackbar(text, {
      variant: "info",
      onExit: () => alertPromise.current?.(true),
    });
    return new Promise<boolean>((resolve) => {
      alertPromise.current = resolve;
    });
  };

  const success = ({text = "عملیات با موفقیت انجام شد", persist}: AlertProps) => {
    setType("success");
    snackbar.enqueueSnackbar(text, {
      variant: "success",
      persist,
      onExit: () => alertPromise.current?.(true),
    });
    return new Promise<boolean>((resolve) => {
      alertPromise.current = resolve;
    });
  };

  const error = ({text = "مشکلی به وجود آمده است", persist}: AlertProps) => {
    setType("error");
    snackbar.enqueueSnackbar(text, {
      variant: "error",
      persist,
      onExit: () => alertPromise.current?.(false),
    });
    return new Promise<boolean>((resolve) => {
      alertPromise.current = resolve;
    });
  };

  const prompt = React.useCallback(({text = "آیا از درخواست خود مطمئنید؟"}: AlertProps) => {
    setType("prompt");
    setText(text);
    setPromptOpen(true);
    return new Promise<PromptResult>((resolve) => {
      promptPromise.current = resolve;
    });
  }, []);

  const handleCloseDialog = () => {
    promptPromise.current?.({result: false, cancelReason: "dismiss"});
    alertPromise.current?.(true);
    setPromptOpen(false);
  };
  const handleClickConfirm = () => {
    promptPromise.current?.({result: true});
    setPromptOpen(false);
  };
  const handleClickRefuse = () => {
    promptPromise.current?.({result: false, cancelReason: "refuse"});
    setPromptOpen(false);
  };

  return (
    <AlertContext.Provider value={{info, success, error, prompt}}>
      {type === "prompt" && (
        <Dialog open={promptOpen} onClose={handleCloseDialog} classes={{paper: classes.paper}}>
          <div className={classes.iconBox}>
            <Help />
          </div>
          <Grid container direction='column' className={classes.bottomBox}>
            <Typography size={1.8} align='center' className={classes.text}>
              {text}
            </Typography>
            <Grid container justify='center' className={classes.buttonsBox}>
              <button className={classes.button} onClick={handleClickConfirm}>
                <Typography size={1.8} noWrap align='center'>
                  {t("alert.confirm")}
                </Typography>
              </button>
              <button className={classes.button} onClick={handleClickRefuse}>
                <Typography size={1.8} noWrap align='center'>
                  {t("alert.reject")}
                </Typography>
              </button>
            </Grid>
          </Grid>
        </Dialog>
      )}
      {children}
    </AlertContext.Provider>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "35rem",
    direction: "ltr",
    [theme.breakpoints.up(modalBreakPoint)]: {
      borderRadius: 4,
    },
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "7.75rem",
    padding: "2rem 0",
    marginBottom: "1.25rem",
    backgroundColor: "#89c2d9",
    "& svg": {
      color: "white",
      fontSize: 55,
    },
  },
  bottomBox: {
    alignItems: "center",
    width: "100%",
    padding: "1rem 1rem 2rem",
  },
  text: {
    padding: "1rem",
    marginBottom: "1rem",
    width: "100%",
  },
  buttonsBox: {
    gap: "1rem",
    paddingBottom: 1,
  },
  button: {
    border: "none",
    marginTop: "2rem",
    height: "4.5rem",
    width: "10rem",
    cursor: "pointer",
    borderRadius: 4,
    "&:first-of-type": {
      backgroundColor: "#4293e4",
    },
    "&:nth-of-type(2)": {
      backgroundColor: "#AAAAAA",
    },
    "& *": {
      color: "white",
    },
    "&:hover": {
      opacity: 0.9,
    },
  },
}));
