import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {darkGrey, lightGrey} from "@constant/colors";
import {ExpandMore, GetApp} from "@material-ui/icons";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import Pagination from "@elements/Pagination";
import {formatDate} from "@util/global";
import {Link} from "react-router-dom";
import {baseUrl} from "@constant/baseUrl";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import Tooltip from "@elements/Tooltip";
import Spinner from "@elements/Spinner";
import {routes} from "@constant/routes";
import createImageUrl from "@util/createImageUrl";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {useErrorHandlerContext} from "@context/ErrorHandlerContext";

const DownloadByPlan = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const {page, fromDaysBefore} = useGetQueryString();
  const serverErrorNotify = useServerErrorNotify();
  const {setErrorStatusCode} = useErrorHandlerContext();
  const handleUpdateQueryString = useUpdateQueryString();
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const xxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(500));
  const pageSize = 20;

  const {data} = useExperimentalQuery("buyByPlan", "getList", {
    variables: {
      pageSize,
      page: page ? Number(page) : undefined,
      fromDaysBefore: fromDaysBefore ? Number(fromDaysBefore) : undefined,
    },
    onError: (error) => {
      setErrorStatusCode(error);
      serverErrorNotify(error);
    },
  });

  return (
    <>
      <Grid container direction={xxs ? "column" : "row"} className={classes.marginBottom}>
        <Box className={classes.selectBox}>
          <ExpandMore />
          <select
            className={classes.select}
            value={fromDaysBefore ?? ""}
            onChange={(e) => handleUpdateQueryString({fromDaysBefore: e.target.value})}
          >
            <option value=''>همه</option>
            <option value='10'>۱۰ روز اخیر</option>
            <option value='20'>۲۰ روز اخیر</option>
            <option value='50'>۵۰ روز اخیر</option>
          </select>
        </Box>
      </Grid>

      {!data && <Spinner style={{padding: "3rem"}} />}
      {!!data?.results.length && (
        <>
          <Box component='table' className={classes.table}>
            <Grid container component='tbody' wrap='wrap'>
              {data?.results.map(({id, image_info, created}, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  direction={sm ? "column" : "row"}
                  alignItems={sm ? "center" : "flex-end"}
                  component='tr'
                  className={classes.tr}
                  key={index.toString()}
                >
                  <Box component='td'>
                    <Link
                      to={createImageUrl(
                        image_info?.type || "",
                        image_info?.title || "",
                        image_info?.id_code || ""
                      )}
                    >
                      <Box className={classes.imageBox}>
                        <img src={image_info?.file} alt={image_info?.title} />
                      </Box>
                    </Link>
                    <Box className={classes.textBox}>
                      <Grid
                        container
                        direction={xs ? "column" : "row"}
                        alignItems={xs ? "center" : "flex-end"}
                        justify='space-between'
                        className={classes.rowHeading}
                      >
                        <Tooltip title={created?.split("T")[1]?.slice(0, 8) || ""}>
                          <Typography size={2}>{formatDate(created)}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid
                        container
                        direction={xs ? "column" : "row"}
                        justify='space-between'
                        alignItems={xs ? "center" : "flex-end"}
                      >
                        <Grid container className={classes.details} wrap='wrap'>
                          <Typography>{t("fileId")}</Typography>
                          <Typography size={1.6}>{image_info?.id}</Typography>
                          <Typography>{t("collection")}</Typography>
                          <Typography color={darkGrey[300]}>
                            {image_info?.is_premium ? "پرمیوم" : "عادی"}
                          </Typography>
                        </Grid>
                        <GetApp
                          className={classes.downloadIcon}
                          onClick={() => {
                            const form = document.createElement("form");
                            form.action = `${baseUrl}images/${image_info?.id}/download/`;
                            form.method = "post";
                            document.body.appendChild(form);
                            form.submit();
                            form.remove();
                          }}
                        />
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          {data.count > pageSize && (
            <div className={classes.paginationBox}>
              <Pagination count={data.count || 0} pageSize={pageSize} />
            </div>
          )}
        </>
      )}
      {data && !data.count && (
        <Box className={classes.noResultBox}>
          <Typography>{t("noResultsMessage")}</Typography>
        </Box>
      )}
    </>
  );
};

export default DownloadByPlan;

const useStyle = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  tr: {
    padding: "1.8rem",
    border: `1px solid ${lightGrey.A300}`,
    backgroundColor: "white",
    [theme.breakpoints.down(700)]: {
      "&:not(:nth-child(1))": {
        borderTop: "none",
      },
      "&:nth-child(even)": {
        backgroundColor: lightGrey[200],
      },
    },
  },
  imageBox: {
    width: "17rem",
    height: "12.2rem",
    flexShrink: 0,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down(700)]: {
      margin: "0 auto",
      marginBottom: "2rem",
    },
  },
  textBox: {
    width: "100%",
  },
  rowHeading: {
    borderBottom: `solid 1px ${lightGrey.A300}`,
    paddingBottom: "1rem",
    marginBottom: "1rem",
  },
  details: {
    maxWidth: 400,
    "& > *": {
      width: "50%",
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: "auto",
      "& > *": {
        textAlign: "center",
      },
    },
  },
  downloadIcon: {
    cursor: "pointer",
    fontSize: "2.5rem",
    color: "gray",
    [theme.breakpoints.down(700)]: {
      margin: "2rem 0 1rem",
    },
  },
  noResultBox: {
    padding: " 1.4rem 2rem 1.2rem 3rem",
    border: `1px solid ${lightGrey.A300}`,
    textAlign: "center",
    backgroundColor: "white",
  },
  selectBox: {
    position: "relative",
    width: "25.5rem",
    maxWidth: "100%",
    height: "6rem",

    marginRight: "4rem",
    "& svg": {
      fontSize: "2.2rem",
      position: "absolute",
      top: "50%",
      right: "1rem",
      transform: "translateY(-50%)",
    },
  },
  select: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    padding: "0 4rem 0 2rem",
    border: "1px solid black",
    appearance: "none",
    MozAppearance: "none",
    WebkitAppearance: "none",
    fontSize: 16 * fontNormalizeFactor,
    "& *": {
      fontSize: 13 * fontNormalizeFactor,
    },
  },
  marginBottom: {
    marginBottom: "4.5rem",
  },
  downloadsCount: {
    [theme.breakpoints.down(500)]: {
      marginTop: "2rem",
    },
  },
  paginationBox: {
    marginTop: "8rem",
  },
  icon: {
    cursor: "pointer",
    fontSize: "2.5rem",
    color: "gray",
  },
}));
