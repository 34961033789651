import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import Wallpaper from "@elements/Wallpaper";
import AuthCard from "@elements/AuthCard";
import GreenLink from "@elements/GreenLink";
import {TextInput} from "@elements/TextInput";
import {MainButton} from "@elements/button";
import {useTranslation} from "react-i18next";
import {darkGrey} from "@constant/colors";
import Copyright from "@elements/Copyright";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAuth} from "@context/AuthContext";
import {useHistory} from "react-router-dom";
import {useAlert} from "@context/AlertContext";
import {routes} from "@constant/routes";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {getUserLastLocationFromLs, removeUserLastLocationFromLs} from "@util/userLastLocation";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {generateYupSchema, IFormSchema, setFormErrors} from "@util/reactHookFormUtils";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import {Helmet} from "react-helmet";
import {useNavbarContext} from "@context/NavbarContext";

interface IFormValueTypes {
  username: string;
  password: string;
}

const Login = () => {
  const classes = useStyle();
  const {t} = useTranslation("pages.signIn");
  const {login, user} = useAuth();
  const history = useHistory();
  // with the help of mode=redirect user will be redirected back to his last location after authenticating
  const {mode} = useGetQueryString();
  const alert = useAlert();
  const apiErrorHandler = useApiErrorHandler();
  const {setOpenNavbar} = useNavbarContext();

  const schema: IFormSchema<IFormValueTypes> = [
    {name: "username", validations: yup.string()},
    {name: "password", validations: yup.string()},
  ];

  const {control, handleSubmit, setError} = useForm<IFormValueTypes>({
    resolver: yupResolver(generateYupSchema(schema)),
  });

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  useEffect(() => {
    if (user) {
      history.replace("/");
    }
  }, [history, user]);

  const {mutate} = useExperimentalMutation("auth", "login");

  const onSubmit: SubmitHandler<IFormValueTypes> = (values) => {
    mutate({
      variables: {
        data: {
          username: values.username,
          password: values.password,
        },
      },
      onSuccess: (data) => {
        alert.success({text: "ورود شما با موفقیت انجام شد"});
        const lastLocation = getUserLastLocationFromLs();
        history.replace(mode === "redirect" && lastLocation ? lastLocation : "/");
        // @TODO: change data.user to user
        // @ts-ignore
        login(data["user"]);
        removeUserLastLocationFromLs();
        setOpenNavbar(false);
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          setFormErrors(schema, setError, error);
        }
        apiErrorHandler(error);
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>ورود - پارس ایمیجز</title>
        <meta name='title' content='ورود - پارس ایمیجز' />
      </Helmet>
      <Grid container justify='center' className={classes.container}>
        <Box className={classes.cardBox}>
          <AuthCard>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography size={2.4}>{t("signIn")}</Typography>
              <Grid container direction='column' className={classes.paddingAndGap}>
                <Box>
                  <Typography span size={1.4} color={darkGrey.A100}>
                    {t("notMember")}&nbsp;
                  </Typography>
                  <GreenLink
                    size='small'
                    // passing mode=redirect(to help user to being redirected back after authenticating) if it's exist in the url already
                    to={routes.landing.register.concat(mode === "redirect" ? `?mode=${mode}` : "")}
                  >
                    {t("join")}
                  </GreenLink>
                </Box>
                <Controller
                  name='username'
                  control={control}
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      {...field}
                      placeholder={t("userName")}
                      autoFocus
                      maxLength={20}
                      isEnglish
                      error={!!error}
                      errorMessage={error?.message}
                      trim
                    />
                  )}
                />
                <Controller
                  name='password'
                  control={control}
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      {...field}
                      placeholder={t("password")}
                      isPassword
                      isEnglish
                      error={!!error}
                      errorMessage={error?.message}
                      trim
                    />
                  )}
                />
                <MainButton color='green' fullWidth type='submit'>
                  {t("signIn")}
                </MainButton>
              </Grid>
              <Grid container justify='center'>
                <GreenLink
                  size='small'
                  to={routes.landing.forgotPassword.concat(mode ? `?mode=${mode}` : "")}
                >
                  {t("forgotPassword")}
                </GreenLink>
              </Grid>
            </form>
          </AuthCard>
        </Box>
        <Copyright />
      </Grid>
      <Wallpaper image={data?.login_image || ""} backgroundColor='#186676' />
    </>
  );
};

export default Login;

const useStyle = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    position: "relative",
    padding: "20vh 1rem 20vh 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem .5rem 20vh .5rem",
    },
  },
  cardBox: {
    width: "42.5rem",
  },
  paddingAndGap: {
    padding: ".5rem 0 2rem",
    gap: "1rem",
  },
}));
