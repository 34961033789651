import {ApiNamespaces} from "@api";
import {useAlert} from "@context/AlertContext";
import TextInput from "../TextInput";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {Box, Button, Checkbox, DialogProps, FormControlLabel, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Save} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {useState} from "react";
import useDebouncedValue from "@hook/useDebouncedValue";
import PanelModalRapper from "./PanelModalWrapper";

interface PlanModalProps extends Omit<DialogProps, "id"> {
  onSubmit?: () => void;
}

export default function ImageInvoiceCreateModal({onSubmit, ...props}: PlanModalProps) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [imageId, setImageId] = useState("");
  const [userId, setUserId] = useState("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const debouncedImageId = useDebouncedValue(imageId, 1000);
  const debouncedUserId = useDebouncedValue(userId, 1000);
  const alert = useAlert();
  const {t} = useTranslation("elements");

  const {data: image} = useExperimentalQuery("images", "readDetail", {
    variables: {
      id: Number(debouncedImageId),
    },
    enabled: !!debouncedImageId,
    retry: 0,
    onError: (err) => {
      if (err.response?.status === 404) {
        alert.error({text: "آیدی عکس وارد شده در سرور وجود ندارد"});
      }
    },
  });
  const {data: user} = useExperimentalQuery("users", "readDetail", {
    variables: {
      id: Number(debouncedUserId),
    },
    enabled: !!debouncedUserId,
    retry: 0,
    onError: (err) => {
      if (err.response?.status === 404) {
        alert.error({text: "آیدی کاربر وارد شده در سرور وجود ندارد"});
      }
    },
  });
  const formIsValid = !!Number(imageId) && !!Number(userId) && !!image && !!user;

  const {mutate: create} = useExperimentalMutation("imagesInvoices", "createInvoiceByAdmin");
  const handleSubmit = () => {
    create({
      variables: {
        data: {
          owner: Number(userId),
          image: Number(imageId),
          is_active: isActive,
          is_paid: isPaid,
        },
      },
      onSuccess: () => {
        alert.success({});
        queryClient.invalidateQueries(ApiNamespaces.imagesInvoices.Query.getList.queryKey);
      },
      onError: () => alert.error({}),
      onSettled: () => {
        setUserId("");
        setImageId("");
        setIsActive(false);
        setIsPaid(false);
        onSubmit?.();
      },
    });
  };

  return (
    <PanelModalRapper title='ایجاد فاکتور عکس' {...props}>
      <Grid container direction='column' alignItems='center' className={classes.root}>
        <Box className={classes.container}>
          <Grid container direction='column' className={classes.wrapper}>
            <Typography color='#757575'>{t("panel.invoiceCreateModal.userId")}</Typography>
            <TextInput value={userId} onChange={(v) => setUserId(v)} type='number' />
            {user && (
              <Typography span>{`${user.username} به شماره همراه ${user.mobile_number?.slice(
                1
              )}+`}</Typography>
            )}
          </Grid>
          <Grid container direction='column' className={classes.wrapper}>
            <Typography color='#757575'>{t("panel.invoiceCreateModal.imageId")}</Typography>
            <TextInput value={imageId} onChange={(v) => setImageId(v)} type='number' />
            {image?.file && (
              <div className={classes.imageBox}>
                <img src={image.file} alt={image.title || ""} />
              </div>
            )}
            {image && !image.file && (
              <Typography>برای این آیدی عکسی برای نمایش وجود ندارد</Typography>
            )}
          </Grid>
          <Box className={classes.flex}>
            <Typography color='#757575'>{t("panel.invoiceCreateModal.invoiceStatus")}</Typography>
            <FormControlLabel
              onChange={(_, checked) => setIsActive(checked)}
              label={<Typography size={1.8}>{t("panel.invoiceCreateModal.isActive")}</Typography>}
              control={<Checkbox color='primary' className={classes.checkbox} checked={isActive} />}
            />
            <FormControlLabel
              onChange={(_, checked) => setIsPaid(checked)}
              label={<Typography size={1.8}>{t("panel.invoiceCreateModal.isPaid")}</Typography>}
              control={<Checkbox color='primary' className={classes.checkbox} checked={isPaid} />}
            />
          </Box>
          <Grid container className={classes.buttonBox}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              disabled={!formIsValid}
              onClick={handleSubmit}
            >
              {t("panel.common.save")}
              <Save />
            </Button>
          </Grid>
        </Box>
      </Grid>
    </PanelModalRapper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
  },
  container: {
    width: "100%",
    height: "100%",
  },
  wrapper: {
    padding: "10px 0",
    gap: 10,
    borderBottom: "1px solid #a2d3fa",
    "&:last-of-type": {
      paddingBottom: 45,
      borderBottom: "none",
      "& *": {
        fontSize: 18 * fontNormalizeFactor,
      },
    },
  },
  buttonBox: {
    marginTop: 50,
    gap: 20,
    "& button": {
      height: 40,
      border: "none",
      borderRadius: 2,
      boxShadow: "0 1px 5px 0 #ccc, 0 2px 3px 0 #ddd",
      "& *": {
        fontSize: 15 * fontNormalizeFactor,
      },
      "& svg": {
        marginLeft: 8,
      },
    },
  },
  flex: {
    paddingTop: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  checkbox: {
    transform: "scale(1.5)",
    marginLeft: 2,
  },
  imageBox: {
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ddd",
    overflow: "hidden",
    borderRadius: 4,
    marginBottom: "1rem",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 4,
    },
  },
}));
