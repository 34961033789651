import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, ButtonGroup as MaterialButtonGroup} from "@material-ui/core";
import clsx from "clsx";
import Typography from "@elements/Typography";
import {palette} from "@constant/colors";
import {useTranslation} from "react-i18next";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {useGetQueryString} from "@hook/qs/useGetQueryString";

const ButtonGroup = () => {
  const [value, setValue] = useState("");
  const {t} = useTranslation("modules.pages.search");
  const classes = useStyle();
  const {isPremium} = useGetQueryString();
  const handleUpdateQueryString = useUpdateQueryString();

  const buttons = [
    {value: "", title: t("buttonGroup.all")},
    {
      value: "essential",
      title: t("buttonGroup.essential"),
      description: t("buttonGroup.leastPrice"),
    },
    {
      value: "signature",
      title: t("buttonGroup.signature"),
      description: t("buttonGroup.bestQuality"),
    },
  ];

  useEffect(() => {
    if (isPremium !== undefined) {
      setValue(isPremium === "true" ? "signature" : "essential");
    }
  }, [isPremium]);

  return (
    <MaterialButtonGroup fullWidth className={classes.container}>
      {buttons.map(({value: buttonValue, title, description}) => (
        <Button
          key={title}
          className={clsx(classes.button, buttonValue === value ? classes.active : undefined)}
          onClick={() => {
            setValue(buttonValue);
            handleUpdateQueryString({
              isPremium:
                buttonValue === "essential" ? "false" : buttonValue === "signature" ? "true" : "",
            });
          }}
        >
          <Box className={classes.flex}>
            <Typography weight={700} span>
              {title}
            </Typography>
            {description && (
              <Typography span size={1.4} weight={500} className={classes.description}>
                {description}
              </Typography>
            )}
          </Box>
        </Button>
      ))}
    </MaterialButtonGroup>
  );
};

export default ButtonGroup;

const useStyle = makeStyles((theme) => ({
  container: {
    maxWidth: "53.5rem",
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    height: "6.1rem",
    borderRadius: "4px",
    border: `1px solid ${palette.lightGrey.A300}`,
    transition: "box-shadow .25s ease-in-out",
    textTransform: "capitalize", // temp
    "&:hover": {
      backgroundColor: "white",
      boxShadow: `inset 0 0 0 3px ${palette.lightGrey[500]}`,
    },
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  description: {
    color: palette.darkGrey[300],
    // marginTop:
  },
  active: {
    backgroundColor: palette.lightGrey.A300,
    "&:hover": {
      backgroundColor: palette.lightGrey.A300, // search for something else
      boxShadow: "none",
    },
  },
}));
