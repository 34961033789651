export const placeholderItems = [
  {width: 1.8, height: 1},
  {width: 0.8, height: 1},
  {width: 1.45, height: 1},
  {width: 1, height: 1},
  {width: 0.7, height: 1},
  {width: 1.25, height: 1},
  {width: 1.4, height: 1},
  {width: 0.65, height: 1},
  {width: 1.67, height: 1},
  {width: 0.85, height: 1},
  {width: 1.25, height: 1},
  {width: 1.8, height: 1},
  {width: 0.8, height: 1},
  {width: 1.45, height: 1},
  {width: 1, height: 1},
  {width: 0.7, height: 1},
  {width: 1.25, height: 1},
  {width: 1.4, height: 1},
  {width: 0.65, height: 1},
  {width: 1.67, height: 1},
  {width: 0.85, height: 1},
  {width: 1.25, height: 1},
  {width: 1.67, height: 1},
  {width: 0.85, height: 1},
  {width: 0.85, height: 1},
  {width: 1.25, height: 1},
  {width: 1.8, height: 1},
  {width: 0.8, height: 1},
  {width: 1.45, height: 1},
  {width: 1, height: 1},
  {width: 1, height: 1},
  {width: 0.7, height: 1},
  {width: 1.25, height: 1},
  {width: 1.4, height: 1},
  {width: 0.65, height: 1},
  {width: 1.67, height: 1},
  {width: 0.85, height: 1},
  {width: 1.25, height: 1},
  {width: 1.67, height: 1},
  {width: 0.85, height: 1},
  {width: 0.85, height: 1},
  {width: 1.25, height: 1},
  {width: 1.8, height: 1},
  {width: 0.8, height: 1},
  {width: 1.45, height: 1},
  {width: 1, height: 1},
];
