import {ColumnNames, ColumnOrderPair, SortOrders} from "./types";
import {Box, Grid} from "@material-ui/core";
import React from "react";
import Header from "./header";
import Row, {BoardsTableRowProps} from "./row";

export interface BoardsTableProps {
  children: React.ReactElement<BoardsTableRowProps> | React.ReactElement<BoardsTableRowProps>[];
}

export default function BoardsTable({children}: BoardsTableProps) {
  React.Children.forEach(children, (child) => {
    if (child.type !== Row) {
      throw Error("children of BoardsTable should be only BoardsTable.Row");
    }
  });
  const [sortByState, setSortByState] = React.useState<ColumnOrderPair>({
    columnName: "lastUpdate",
    sortOrder: "descending",
  });

  return (
    <Grid container direction='column'>
      <Header
        onSortChange={(columnName: ColumnNames, sortOrder: SortOrders) => setSortByState({columnName, sortOrder})}
      />
      <Box>
        {children instanceof Array
          ? children
              .slice()
              .sort(({props: a}, {props: b}) => {
                switch (sortByState.columnName) {
                  case "name":
                    if (sortByState.sortOrder === "ascending") {
                      return a.name.localeCompare(b.name);
                    } else {
                      return b.name.localeCompare(a.name);
                    }
                  case "files":
                    if (sortByState.sortOrder === "ascending") {
                      return a.files - b.files;
                    } else {
                      return b.files - a.files;
                    }
                  case "lastUpdate":
                    if (sortByState.sortOrder === "ascending") {
                      return a.lastUpdate.getTime() - b.lastUpdate.getTime();
                    } else {
                      return b.lastUpdate.getTime() - a.lastUpdate.getTime();
                    }
                  default:
                    return 0;
                }
              })
              .map((value, index) => <React.Fragment key={index.toString()}>{value}</React.Fragment>)
          : children}
      </Box>
    </Grid>
  );
}

BoardsTable.Header = Header;
BoardsTable.Row = Row;
