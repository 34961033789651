import {images} from "@constant/images";
import {boardsTableBreakpoint} from "@elements/table/boards/constant";
import {ExpandMore} from "@material-ui/icons";
import {ColumnNames, ColumnOrderPair, SortOrders} from "./types";
import Typography from "@elements/Typography";
import {Box, Grid, NativeSelect, useMediaQuery} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";

interface BoardsTableHeaderCommonProps {
  defaultSortOrder?: SortOrders;
}

interface TableHeaderFullSort extends BoardsTableHeaderCommonProps {
  sortBy?: undefined;
  sortedBy?: ColumnOrderPair;
  onSortChange?: (columnName: ColumnNames, sortOrder: SortOrders) => void;
  defaultSortedBy?: ColumnNames;
}

interface TableHeaderCustomSort<U extends ColumnNames, T extends U[]>
  extends BoardsTableHeaderCommonProps {
  sortBy: ColumnNames[];
  sortedBy?: ColumnOrderPair<T[number]>;
  onSortChange?: (columnName: T[number], sortOrder: SortOrders) => void;
  defaultSortedBy?: T[number];
}

export type BoardsTableHeaderProps<U extends ColumnNames = ColumnNames, T extends U[] = U[]> =
  | TableHeaderFullSort
  | TableHeaderCustomSort<U, T>;

export default function BoardsTableHeader<T extends ColumnNames>({
  defaultSortOrder = "descending",
  sortBy = ["lastUpdate", "name", "files"],
  onSortChange: handleSortChange,
  sortedBy,
  defaultSortedBy = sortBy[0],
}: BoardsTableHeaderProps<T, T[]>) {
  const classes = useStyles();
  const responsive = useResponsiveStyles();
  const {t} = useTranslation("elements");
  const [sortByState, setSortByState] = React.useState(
    sortedBy || {columnName: defaultSortedBy, sortOrder: defaultSortOrder}
  );
  const columns: ColumnNames[] = ["name", "lastUpdate", "files"];
  const handleChangeSortOrder = (name: ColumnNames) => {
    if (!sortBy.includes(name)) {
      return;
    }
    setSortByState((prevState) => {
      const sortOrder =
        prevState.columnName === name
          ? prevState.sortOrder === "descending"
            ? "ascending"
            : "descending"
          : "descending";
      handleSortChange?.(name as T, sortOrder);
      return {
        columnName: name,
        sortOrder,
      };
    });
  };
  const handleChangeSortOrderResponsive = (name: string) => {
    const [columnName, sortOrder] = name.split(",") as [ColumnNames, SortOrders];
    setSortByState({columnName, sortOrder});
    handleSortChange?.(columnName as T, sortOrder);
  };
  const renderResponsive = !useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up(boardsTableBreakpoint)
  );
  if (renderResponsive) {
    return (
      <NativeSelect
        className={responsive.root}
        disableUnderline
        IconComponent={ExpandMore}
        onChange={({target: {value}}) => handleChangeSortOrderResponsive(value)}
        value={Object.values(sortByState)}
      >
        {sortBy?.map((columnName) => (
          <>
            <option
              key={`${columnName}descending`}
              value={[columnName, "descending"]}
              className={responsive.option}
            >
              {t(`table.boards.header.${columnName}` as const)} (
              {t("table.boards.header.descending")})
            </option>
            <option
              key={`${columnName}ascending`}
              value={[columnName, "ascending"]}
              className={responsive.option}
            >
              {t(`table.boards.header.${columnName}` as const)} (
              {t("table.boards.header.ascending")})
            </option>
          </>
        ))}
      </NativeSelect>
    );
  }
  return (
    <Box>
      <Grid container alignItems='center' className={classes.root}>
        <Grid item className={classes.thumbnail} />
        <>
          {columns.map((name) => (
            <Box
              key={name}
              className={clsx([classes[name], {[classes.sortableColumn]: sortBy.includes(name)}])}
              onClick={() => handleChangeSortOrder(name)}
            >
              <Grid item container alignItems='center'>
                <Typography
                  noWrap
                  align='right'
                  color='white'
                  weight={100}
                  className={classes.column}
                >
                  {t(`table.boards.header.${name}` as const)}
                </Typography>
                {sortByState.columnName === name && (
                  <img
                    alt='down/up'
                    src={
                      sortByState.sortOrder === "descending"
                        ? images.icons.arrowDownWhite
                        : images.icons.arrowUpWhite
                    }
                    className={classes.arrow}
                  />
                )}
              </Grid>
            </Box>
          ))}
        </>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // FIXME NOT IN PALETTE
    backgroundColor: "#333",
    borderBottom: "1px solid #ccc",
    minHeight: "3rem",
    padding: ".5rem 0",
    paddingLeft: "1.6rem",
    lineHeight: "4rem",
  },
  column: {
    fontSize: `${1.6 * fontNormalizeFactor}rem`,
    textAlign: "start",
  },
  thumbnail: {
    width: "14rem",
  },
  name: {
    width: "35%",
    paddingLeft: "4.5rem",
  },
  lastUpdate: {
    width: "35%",
  },
  // owner: {
  //   width: "10%",
  // },
  // comments: {
  //   width: "13%",
  // },
  files: {
    width: "30%",
  },
  sortableColumn: {
    cursor: "pointer",
  },
  arrow: {
    height: "2.5rem",
  },
}));

const useResponsiveStyles = makeStyles(() => ({
  root: {
    // FIXME NOT IN PALETTE
    backgroundColor: "#333",
    height: "3em",
    "&>select": {
      height: "100%",
      padding: "0 1rem",
      "&.MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
        backgroundColor: "#333",
        fontSize: `${1.1 * fontNormalizeFactor}rem`,
      },
    },
    "&>svg": {
      color: "white",
      top: "auto",
      right: "0.5rem",
      fontSize: "2.5rem",
    },
    color: "white",
    fontSize: `${1.4 * fontNormalizeFactor}rem`,
  },
  option: {
    fontSize: 13 * fontNormalizeFactor,
  },
}));
