import moment from 'moment-jalaali';

export function formatDate(
	date: string | Date | undefined,
	format = 'jYYYY/jMM/jDD'
) {
	if (!date) {
		return '';
	}
	return moment(new Date(date)).format(format);
}

export function formatDateTime(
	dateTime: string | Date | undefined,
	format = 'jYYYY/jMM/jDD HH:mm'
) {
	if (!dateTime) {
		return '';
	}
	return moment(new Date(dateTime)).format(format);
}

export function toLowerFirst(input: string | undefined) {
	if (input === undefined) {
		return undefined;
	}
	return input.charAt(0).toLowerCase() + input.slice(1);
}

export function toUpperFirst(input: string | undefined) {
	if (input === undefined) {
		return undefined;
	}
	return input.charAt(0).toUpperCase() + input.slice(1);
}
