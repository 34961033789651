import React from "react";
import {Box, Grid, InputBase, makeStyles} from "@material-ui/core";
import {palette} from "@constant/colors";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useAlert} from "@context/AlertContext";
import Typography from "@elements/Typography";
import {MainButton} from "@elements/button";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import Spinner from "@elements/Spinner";
import {PlanDiscount} from "@schema";
import {useAuth} from "@context/AuthContext";
import {convertToNumberFormatWithComma} from "@util/convertToNumberFormatWithComma";
import {removeUserLastLocationFromLs} from "@util/userLastLocation";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import GreenLink from "@elements/GreenLink";
import {routes} from "@constant/routes";
import Divider from "@elements/Divider";
import {NotificationsActive as Notification} from "@material-ui/icons";
import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";

export default function Pay() {
  const [isAgree, setIsAgree] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState("");
  const [discount, setDiscount] = React.useState<PlanDiscount | null>(null);
  const classes = useStyles();
  const alert = useAlert();
  const {type, planId, mode} = useGetQueryString();
  const {user} = useAuth();
  const apiErrorHandler = useApiErrorHandler();

  React.useEffect(() => {
    // prevents user to be redirected after purchasing if mode is not equal to redirect
    // this scenario happens only when user last location was stored in local storage
    // but hasn't been used and hasn't been removed so it may cause a mistake redirect after purchasing
    // check loading route redirect condition
    mode !== "redirect" && removeUserLastLocationFromLs();
  }, [mode]);

  const {data: plan, isLoading: isLoadingPlan} = useExperimentalQuery("plans", "readDetail", {
    variables: {
      id: Number(planId),
    },
    enabled: type === "plan" && !!planId,
  });

  const {mutate: getPromoCodeInfo} = useExperimentalMutation("promoCodeByName", "readDetail");
  const {mutate: createInvoice} = useExperimentalMutation("planInvoices", "create");
  const {mutate: payPlan} = useExperimentalMutation("planInvoices", "createPay");

  const handleCheckPromoCode = () => {
    if (!promoCode) {
      return;
    }
    getPromoCodeInfo({
      variables: {queryKey: ["", {name: promoCode}]},
      onSuccess: (res) => {
        setDiscount(res);
      },
      onError: (err) => {
        alert.error({text: "کد تخفیف معتبر نیست"});
        setDiscount(null);
      },
    });
  };

  const handleSubmit = () => {
    createInvoice({
      variables: {
        data: {
          plan: Number(planId),
          discount_codes: discount ? ([discount.id!] as unknown as Set<number>) : undefined,
        },
      },
      onError: (error) => apiErrorHandler(error),
      onSuccess: (response) => {
        payPlan({
          variables: {id: String(response.id), data: {}},
          onError: (error) => apiErrorHandler(error),
          onSuccess: (res) => {
            const response = res as unknown as {link: string};
            window.location.href = response.link;
          },
        });
      },
    });
  };

  const rows = [
    {title: "نام پلن", value: plan?.name},
    {title: "اعتبار عادی", value: plan?.essential_credit},
    {title: "اعتبار پرمیوم", value: plan?.signature_credit},
    {title: "مهلت استفاده", value: `${plan?.days} روز`},
  ];

  return (
    <>
      <Helmet>
        <title>{defaultPageTitle}</title>
      </Helmet>
      <BoxWithWallpaper color={palette.lightGrey[500]} className={classes.container}>
        {isLoadingPlan ? (
          <Spinner />
        ) : (
          <Grid container justify='center' className={classes.maxWidth}>
            <Box className={classes.invoiceBox}>
              <Typography size={2.5} className={classes.title}>
                خرید پلن
              </Typography>
              <Grid container direction='column' alignItems='center' className={classes.body}>
                {rows.map(({title, value}) => (
                  <Grid item key={title} container className={classes.row}>
                    <Typography>{title}: </Typography>
                    <Typography>{value}</Typography>
                  </Grid>
                ))}
                <Divider style={{marginTop: ".3rem"}} />
                <Typography align='center' style={{marginTop: "2rem"}}>
                  {plan?.description}
                </Typography>
                <Box className={classes.promoBox}>
                  <Typography color={palette.green[200]}>کد تخفیف دارید؟</Typography>
                  <Box className={classes.inputBox}>
                    <InputBase
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      className={classes.input}
                      startAdornment={<MainButton onClick={handleCheckPromoCode}>اعمال</MainButton>}
                    />
                  </Box>
                </Box>
                <Grid container justify='center'>
                  <Typography>مبلغ فاکتور: </Typography>
                  <Typography
                    size={1.8}
                    className={classes.marginLeft}
                    style={{
                      textDecoration: discount ? "line-through" : "underline",
                      fontWeight: discount ? 400 : 700,
                    }}
                  >
                    {plan?.price ? convertToNumberFormatWithComma(Number(plan.price)) : ""} تومان
                  </Typography>
                </Grid>
                {!!discount && (
                  <>
                    <Grid container justify='center'>
                      <Typography>تخفیف: </Typography>
                      <Typography size={1.8} className={classes.marginLeft}>
                        {convertToNumberFormatWithComma(
                          Number(Number(discount.percentage) * Number(plan?.price)) / 100
                        )}{" "}
                        تومان
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justify='center'
                      alignItems='center'
                      style={{
                        marginTop: "1.5rem",
                      }}
                    >
                      <Typography>مبلغ نهایی: </Typography>
                      <Typography
                        size={1.8}
                        weight={700}
                        className={classes.marginLeft}
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {Number(plan?.price) -
                          Number(Number(discount.percentage) * Number(plan?.price)) / 100}{" "}
                        تومان
                      </Typography>
                    </Grid>
                  </>
                )}
                <Divider style={{marginTop: "2.5rem"}} />
                {!!user && !!Number(user.debit) && (
                  <Typography color='red' align='center' style={{marginTop: "2rem"}}>
                    <span>
                      <Notification
                        style={{fontSize: 22, transform: "translateY(6px)", marginLeft: 4}}
                      />
                    </span>
                    {`بدهی قبلی شما به مبلغ ${user?.debit} تومان به مبلغ ${
                      discount ? "نهایی" : "فاکتور"
                    } اضافه می‌شود.`}
                  </Typography>
                )}
                <div className={classes.agreementBox}>
                  <input
                    type='checkbox'
                    checked={isAgree}
                    onChange={(e) => setIsAgree(e.target.checked)}
                  />
                  <Typography>
                    <GreenLink size={1.6} to={routes.landing.privacyAndPolicy} target='_blank'>
                      شرایط و قوانین &nbsp;
                    </GreenLink>
                    سایت را مطالعه کرده‌ام و با آن موافقم.
                  </Typography>
                </div>
                <Box className={classes.buttonBox}>
                  <MainButton fullWidth onClick={handleSubmit} disabled={!isAgree}>
                    پرداخت
                  </MainButton>
                </Box>
              </Grid>
            </Box>
          </Grid>
        )}
      </BoxWithWallpaper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "5rem",
    paddingBottom: "10rem",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "5rem",
    },
  },
  maxWidth: {
    maxWidth: "108.6rem",
    margin: "0 auto",
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem",
    },
  },
  invoiceBox: {
    backgroundColor: "white",
    border: "solid 2px #777",
    width: 400,
    maxWidth: "100%",
  },
  title: {
    backgroundColor: palette.darkGrey.A700,
    color: "white",
    textAlign: "center",
    padding: "2rem 0",
    width: "100%",
  },
  body: {
    padding: "3rem 2rem 2rem",
  },
  row: {
    marginBottom: "1rem",
    gap: "1rem",
    "& *": {
      width: "50%",
    },
  },
  promoBox: {
    textAlign: "center",
    margin: "4rem 0 2.5rem",
  },
  inputBox: {
    marginTop: ".75rem",
    height: "3.75rem",
    "& button": {
      height: "100%",
      paddingRight: "1rem",
      paddingLeft: "1rem",
      "& *": {
        fontSize: 16 * fontNormalizeFactor,
      },
    },
  },
  input: {
    backgroundColor: "#e8e8e8",
    border: "1px solid #d5d5d5",
    height: "100%",
    borderRadius: 4,
    width: 200,
    maxWidth: "100%",
    padding: ".1rem",
    paddingRight: "1rem",
    fontSize: 16 * fontNormalizeFactor,
    "& input": {
      direction: "rtl",
    },
  },
  marginLeft: {
    marginLeft: "1rem",
  },
  agreementBox: {
    marginTop: "4rem",
    display: "flex",
    alignItems: "flex-start",
    gap: ".5rem",
    "& input": {
      display: "inline-block",
      marginTop: 5,
      cursor: "pointer",
      accentColor: palette.green["300"],
    },
  },
  buttonBox: {
    width: "100%",
    textAlign: "center",
    marginTop: "4rem",
    "& button": {
      maxWidth: 300,
      margin: "0 auto",
    },
  },
}));
