import {maxPossibleImagesInOneBoard} from "@constant/boards";
import {darkGrey, lightGrey} from "@constant/colors";
import Tooltip from "@elements/Tooltip";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Box, CardMedia, Grid, IconButton, InputBase} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Create, Delete} from "@material-ui/icons";
import clsx from "clsx";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export interface BoardsTableRowProps {
  name: string;
  thumbnail?: string;
  lastUpdate: Date; // Not sure of i18n way yet
  // owner: string;
  files: number;
  // comments: number;
  onDelete?: () => void;
  onShare?: () => void;
  onNameChange?: (name: string) => void;
  onSubmitName?: (name: string) => void;
  to: string;
}

export default function BoardsTableRow({
  lastUpdate,
  name,
  files,
  onDelete,
  onNameChange,
  onSubmitName,
  onShare: handleShare,
  thumbnail,
  to,
  ...rest
}: BoardsTableRowProps) {
  const [editing, setEditing] = React.useState(false);
  const classes = useStyles({editing});
  const [nameState, setNameState] = React.useState(name);
  const {t} = useTranslation("elements");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleNameChange = (name: string) => {
    setNameState(name);
    onNameChange?.(name);
  };
  React.useEffect(() => {
    if (name !== undefined) {
      setNameState(name);
    }
  }, [name]);

  const handleEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setEditing(true);
    inputRef.current?.focus();
  };

  const handleBlur = () => {
    setEditing(false);
    if (name !== nameState) {
      onSubmitName?.(nameState);
    }
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setEditing(false);
      onSubmitName?.(nameState);
    }
  };

  return (
    <Link to={to}>
      <Grid container alignItems='center' className={classes.root}>
        <Grid item className={classes.thumbnail}>
          <CardMedia
            component='img'
            src={thumbnail}
            className={clsx(classes.thumbnailImage, {[classes.hidden]: !thumbnail})}
          />
        </Grid>
        <Grid container wrap='wrap'>
          <Grid item container alignItems='center' className={classes.name}>
            <Box className={classes.pencilWrapper} onClick={handleEdit}>
              <Create className={classes.pencil} />
            </Box>
            <Box>
              <InputBase
                className={classes.editing}
                value={nameState}
                onChange={(e) => handleNameChange(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeydown}
                onClick={(e) => e.preventDefault()}
                inputRef={inputRef}
                onFocus={() => setEditing(true)}
              />
            </Box>
          </Grid>
          <Grid item container alignItems='center' className={classes.dateWrapper}>
            <Typography noWrap weight={500} size={1.4} color='black'>
              {t("table.boards.row.lastUpdate", {date: lastUpdate})}
            </Typography>
          </Grid>
          <Grid item container alignItems='center' className={classes.filesWrapper}>
            <Typography noWrap weight={500} size={1.4} color='black'>
              {`${files}/${maxPossibleImagesInOneBoard}`}
              <Typography span className={classes.fileText}>
                {t("table.boards.row.file")}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.delete}>
          <Tooltip title={t("table.boards.row.removeBoard")}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                onDelete?.();
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Link>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: "white",
    minHeight: "15.2rem",
    paddingLeft: "1.6rem",
    border: `1px solid ${lightGrey.A800}`,
    borderTop: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f7f7f7",
    },
    [theme.breakpoints.down(500)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  thumbnail: {
    width: "14rem",
    backgroundColor: "transparent",
    flexShrink: 0,
  },
  thumbnailImage: {
    verticalAlign: "middle",
    border: "none",
    maxHeight: "12rem",
    width: "12rem",
    [theme.breakpoints.down(500)]: {
      margin: "1rem 0 1rem",
    },
  },
  summary: {
    padding: "3.6rem 0 0 2rem",
    position: "absolute",
    left: "13.6rem",
    width: "80%",
  },
  name: {
    width: "35%",
    paddingRight: "1rem",
    [theme.breakpoints.down(500)]: {
      width: "100%",
    },
  },
  nameWrapper: {
    maxWidth: "80%",
  },
  pencilWrapper: {
    marginRight: "0.5em",
    "&:hover $pencil": {
      color: lightGrey.A900,
    },
  },
  pencil: {
    color: lightGrey.A400,
  },
  dateWrapper: {
    width: "35%",
    [theme.breakpoints.down(700)]: {
      width: "100%",
    },
  },
  filesWrapper: {
    width: "30%",
    [theme.breakpoints.down(700)]: {
      width: "100%",
    },
  },
  fileText: {
    display: "inline-block",
    marginLeft: ".5rem",
    marginBottom: "1rem",
    [theme.breakpoints.up(700)]: {
      display: "none",
    },
  },
  editingOuter: {
    zIndex: 220,
  },
  editing: ({editing}: {editing: boolean}) => ({
    height: "3rem",
    borderRadius: 0,
    color: darkGrey.A700,
    border: editing ? `1px solid ${darkGrey[300]}` : "none",
    boxShadow: "none",
    padding: "1px 0.85rem",
    width: "100%",
    "&.Mui-focused": {
      borderWidth: 2,
    },
    "& *": {
      fontSize: `${1.8 * fontNormalizeFactor}rem`,
    },
    [theme.breakpoints.down(500)]: {
      width: "100%",
      padding: "1px 0.1rem",
    },
  }),
  hidden: {
    visibility: "hidden",
  },
  hide: {
    display: "none",
  },
  deleteIcon: {
    width: "3.6rem",
    height: "3.6rem",
    top: 0,
    backgroundColor: "transparent",
    zIndex: 500,
    position: "absolute",
    right: 0,
    cursor: "pointer",
  },
  delete: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "2.6rem",
    zIndex: 200,
    "& > *": {
      padding: 8,
      "&  svg": {
        fontSize: 24,
      },
    },
  },
}));
