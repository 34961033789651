import React, {useState} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import FiltersDrawer from "@modules/FiltersDrawer";
import {Grid, Hidden, useMediaQuery} from "@material-ui/core";
import {ButtonsCarousel} from "@elements/Carousel";
import {palette} from "@constant/colors";
import FiltersButton from "@modules/pages/search/FiltersButton";
import ButtonGroup from "@modules/pages/search/ButtonGroup";
import ChangeSortButton from "@modules/pages/search/ChangeSortButton";
import Gallery from "@modules/Gallery";
import BackDrop from "@modules/mainLayout/Backdrop";
import Pagination from "@elements/Pagination";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import SearchBar from "@modules/SearchBar";
import {searchBarHeightBig} from "@modules/SearchBar/constant";
import Typography from "@elements/Typography";
import clsx from "clsx";
import {Close} from "@material-ui/icons";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {routes} from "@constant/routes";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {useErrorHandlerContext} from "@context/ErrorHandlerContext";
import MetaTags from "@modules/pages/search/MetaTags";
import throttle from "lodash/throttle";

const filtersDrawerWidth = 26.5;
const transitionDuration = 0.15;

const Search = () => {
  const [showDrawer, setShowDrawer] = useState(true);
  const [shouldPrefetchNextPage, setShouldPrefetchNextPage] = useState(false);
  const [isNextPageFetched, setIsNextPageFetched] = useState(false);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const classes = useStyle({showDrawer, smDown});
  const handleUpdateQueryString = useUpdateQueryString();
  const serverErrorNotify = useServerErrorNotify();
  const {setErrorStatusCode} = useErrorHandlerContext();

  const handleClick = () => setShowDrawer((prev) => !prev);

  React.useEffect(() => {
    smDown && setShowDrawer(false);
  }, [smDown]);

  const {
    search,
    type,
    isPremium,
    page,
    orientation,
    fromDaysBefore,
    tags,
    categories,
    color,
    similarImages,
    collections,
    sort,
    gray,
  } = useGetQueryString();
  const pageSize = "100";
  const pageQuery = page ? Number(page) : 1;

  React.useEffect(() => {
    setShouldPrefetchNextPage(false);
    setIsNextPageFetched(false);
  }, [pageQuery]);

  React.useEffect(() => {
    const listener = throttle(() => {
      if (!isNextPageFetched && window.scrollY > document.body.scrollHeight / 2) {
        setShouldPrefetchNextPage(true);
      }
    }, 250);
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, [isNextPageFetched]);

  const apiCommonParams = {
    search,
    pageSize: Number(pageSize),
    type,
    isPremium,
    fromDaysBefore: fromDaysBefore ? Number(fromDaysBefore) : undefined,
    orientation,
    tags,
    categories,
    color,
    similarImages,
    collections: collections,
    greyImages: gray,
    order: sort ? "-".concat(sort) : undefined,
  };

  const {data} = useExperimentalQuery("images", "getList", {
    variables: {
      page: pageQuery,
      ...apiCommonParams,
    },
    staleTime: 15 * 60 * 1000,
    onError: (error) => {
      setErrorStatusCode(error);
      serverErrorNotify(error);
    },
  });

  useExperimentalQuery("images", "getList", {
    variables: {
      page: pageQuery + 1,
      ...apiCommonParams,
    },
    enabled: !!data?.next && shouldPrefetchNextPage && !isNextPageFetched,
    staleTime: 15 * 60 * 1000,
    onSuccess: () => {
      setIsNextPageFetched(true);
    },
  });

  const {data: tagsData} = useExperimentalQuery("tags", "getList", {
    variables: {search},
    enabled: !!search,
  });
  const {data: category} = useExperimentalQuery("categories", "readDetail", {
    // all categories are number except one, category = null which is for images without any specified category
    variables: {id: categories as unknown as number},
    enabled: !!categories,
  });
  const {data: tag} = useExperimentalQuery("tags", "readDetail", {
    variables: {id: Number(tags)},
    enabled: !!tags,
  });
  const {data: collection} = useExperimentalQuery("collections", "readDetail", {
    variables: {id: Number(collections)},
    enabled: !!collections,
  });
  const {data: image} = useExperimentalQuery("images", "readDetail", {
    variables: {id: Number(similarImages)},
    enabled: !!similarImages,
  });

  let badges: {onClick: () => void; text: string; imageSrc?: string}[] = [];
  category &&
    badges.push({
      onClick: () => handleUpdateQueryString({categories: ""}),
      text: `دسته:  ${category.name}`,
    });
  tag &&
    badges.push({
      onClick: () => handleUpdateQueryString({tags: ""}),
      text: `برچسب:  ${tag.name}`,
    });
  collections &&
    badges.push({
      onClick: () => handleUpdateQueryString({collections: ""}),
      text: `کالکشن:  ${collection?.name}`,
    });
  image &&
    badges.push({
      onClick: () => handleUpdateQueryString({similarImages: ""}),
      text: "عکس‌های مشابه",
      imageSrc: image.file,
    });

  const typeAsUnion = type as "Photo" | "Illustration" | "Vector";
  const typeNameMap: {[key in typeof typeAsUnion]: string} = {
    Illustration: "طرح‌ها",
    Vector: "وکتورها",
    Photo: "عکس‌ها",
  };

  return (
    <>
      <MetaTags images={data?.results} />
      <div className={classes.container}>
        <Grid container className={classes.sticky}>
          <FiltersButton
            className={classes.filtersButton}
            open={showDrawer}
            onClick={handleClick}
          />
          <SearchBar
            updateUrl
            searchWithDropdownChange
            resultNumber={data?.count}
            badges={badges.map(({onClick: handleClick, text, imageSrc}) => (
              <div key={text} className={classes.badge} onClick={handleClick} title='حذف فیلتر'>
                {imageSrc && image && <img src={imageSrc} alt={image.title} />}
                <Typography size={1.3} style={{padding: "0 2px"}}>
                  {text}
                </Typography>
                <Close />
              </div>
            ))}
          />
        </Grid>
        <Grid container className={classes.middleContent}>
          <aside className={clsx(classes.drawerBox, xsDown && classes.drawerBoxMobileSize)}>
            <FiltersDrawer
              onCloseClick={handleClick}
              className={clsx(classes.drawer, xsDown && classes.drawerMobileSize)}
            />
          </aside>
          <Grid container direction='column' className={classes.mainContent}>
            <div className={classes.buttonGroupBox}>
              <ButtonGroup />
            </div>
            <Grid container className={classes.gap}>
              <Hidden xsDown>
                <ChangeSortButton />
              </Hidden>
              {tagsData && !!tagsData.results.length && (
                <div className={classes.carouselBox}>
                  <ButtonsCarousel
                    drawerIsOpen={!smDown && showDrawer}
                    items={tagsData.results.map(({name}) => ({
                      title: name,
                      href: `${routes.landing.search}?search=${name}`,
                    }))}
                  />
                </div>
              )}
            </Grid>
            <div className={classes.galleryBox}>
              <Gallery
                drawerIsOpen={!smDown && showDrawer}
                images={data?.results}
                renderSimilarButton
              />
              {data && !data.count && (
                <div className={classes.flexBox}>
                  <div className={classes.noResultMessageBox}>
                    {type ? (
                      <>
                        <Typography
                          size={2}
                        >{`در بین ${typeNameMap[typeAsUnion]} نتیجه‌ای یافت نشد`}</Typography>
                        <Typography color={palette.blue.A300} size={2} style={{marginTop: "1rem"}}>
                          می‌توانید در انواع دیگر تصاویر جستجو کنید
                        </Typography>
                      </>
                    ) : (
                      <Typography size={2.2}>نتیجه‌ای یافت نشد</Typography>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={classes.paginationBox}>
              <Pagination
                count={data?.count || 0}
                pageSize={Number(pageSize)}
                withScrollToTopButton
              />
            </div>
          </Grid>
        </Grid>
        {smDown && showDrawer && <BackDrop fixed onClick={() => setShowDrawer(false)} />}
      </div>
    </>
  );
};

export default Search;

interface StylesProps {
  showDrawer: boolean;
  smDown: boolean;
}

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: palette.lightGrey[500],
  },
  sticky: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  middleContent: {
    position: "relative",
  },
  mainContent: {
    minHeight: "70vh",
    padding: "3.2rem 2.4rem",
    [theme.breakpoints.down(750)]: {
      padding: "3.2rem 1.2rem",
    },
  },
  resultDescription: {
    marginBottom: "1.2rem",
  },
  carouselBox: ({showDrawer, smDown}: StylesProps) => ({
    width:
      smDown || !showDrawer ? `calc(100vw - 23rem)` : `calc(100vw - ${filtersDrawerWidth + 23}rem)`, //temp
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }),
  drawerBox: ({showDrawer}: StylesProps) => ({
    backgroundColor: palette.darkGrey.A200,
    height: `calc(100vh - 60px)`,
    flexShrink: 0,
    position: "sticky",
    overflowX: "hidden",
    paddingBottom: "10rem",
    width: showDrawer ? `${filtersDrawerWidth}rem` : 0,
    top: searchBarHeightBig,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: 0,
      zIndex: 3500,
      height: "100vh",
    },
    "&::-webkit-scrollbar": {
      width: 8,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999",
      borderRadius: 3,
    },
  }),
  drawerBoxMobileSize: ({showDrawer}: StylesProps) => ({
    width: showDrawer ? "100vw" : 0,
  }),
  drawer: ({showDrawer, smDown}: StylesProps) => ({
    width: "100%",
    position: "absolute",
    left: showDrawer ? 0 : `-${filtersDrawerWidth}rem`,
    transition: showDrawer || smDown ? `${transitionDuration}s` : undefined,
    marginBottom: "10rem",
    marginTop: "-1px",
  }),
  drawerMobileSize: ({showDrawer}: StylesProps) => ({
    left: showDrawer ? 0 : "-100vw",
  }),
  filtersButton: ({showDrawer}: StylesProps) => ({
    width: showDrawer ? `${filtersDrawerWidth}rem` : "21.6rem",
    transition: `${transitionDuration}s`,
    borderBottom: `1px solid ${palette.darkGrey.A700}`,
    [theme.breakpoints.down("sm")]: {
      width: "7rem !important",
      padding: 0,
      justifyContent: "center !important",
      "& #filters-text": {
        display: "none",
      },
    },
    [theme.breakpoints.down(500)]: {
      width: "5rem !important",
    },
  }),
  buttonGroupBox: {
    marginBottom: "4.4rem",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  gap: {
    gap: ".8rem",
  },
  galleryBox: {
    marginBottom: "3.5rem",
    marginTop: "2.5rem",
    position: "relative",
    zIndex: 120,
  },
  paginationBox: {
    padding: "3.2rem 2.4rem",
    marginTop: "auto",
    [theme.breakpoints.down(750)]: {
      padding: "3.2rem 0",
    },
  },
  flexBox: {
    display: "flex",
    justifyContent: "center",
    padding: "8rem 0 2rem",
  },
  noResultMessageBox: {
    border: `solid 2px ${palette.blue[500]}`,
    boxShadow: "-2px 2px 5px #aaa",
    textAlign: "center",
    padding: "1rem 2rem",
    borderRadius: 4,
    backgroundColor: "white",
  },
  badge: {
    backgroundColor: "#f4f6f6",
    paddingRight: 8,
    height: "3.6rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ccd3d3",
    color: "#000",
    fontSize: ".875rem",
    borderRadius: 5,
    whiteSpace: "nowrap",
    cursor: "pointer",
    overflow: "hidden",
    "&:hover": {
      border: "1px solid #000",
      "& svg": {
        fill: "#000",
      },
    },
    "& p": {
      marginLeft: 8,
    },
    "& img": {
      maxHeight: "100%",
    },
    "& svg": {
      marginLeft: 8,
      width: 14,
      fill: "#889291",
    },
  },
}));
