import {PanelApi} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const panelApiInstance = new PanelApi(undefined, undefined, axiosInstance);

export const panelAPI = makeApi({
  identifier: "panel",
  Query: {
    getMenu: createQuery(async () => {
      const {data} = await panelApiInstance.panelMenuList();
      return data;
    }),
    adminControl: createQuery(async () => {
      const {data} = await panelApiInstance.panelAdminControlList();
      return data;
    }),
    getStats: createQuery(async () => {
      const {data} = await panelApiInstance.panelStatisticsList();
      return data;
    }),
  },
});
