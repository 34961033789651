import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Grid, Switch} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Edit} from "@material-ui/icons";
import {useAlert} from "@context/AlertContext";
// import Shiitake from "shiitake";

export interface IPlanCardProps {
  title: string;
  price: string;
  premiumCredit: string;
  normalCredit: string;
  days: string;
  description: string;
  id: number;
  isActive: boolean;
  onClickEdit?: (id: number) => void;
  onSetActive?: (id: number) => void;
  onSetInactive?: (id: number) => void;
}

const PlanCard = (props: IPlanCardProps) => {
  const classes = useStyle();
  const {
    title,
    isActive,
    onSetActive,
    onSetInactive,
    price,
    premiumCredit,
    normalCredit,
    days,
    description,
    id,
    onClickEdit,
  } = props;
  const {t} = useTranslation("elements");
  const alert = useAlert();

  const rows = [
    // {title: t("panel.common.title"), value: title},
    {title: t("panel.planCard.price"), value: price},
    {title: t("panel.planCard.premiumCredit"), value: premiumCredit},
    {title: t("panel.planCard.normalCredit"), value: normalCredit},
    {title: t("panel.planCard.planDays"), value: days},
  ];

  return (
    <Box className={classes.container}>
      {/* <Grid container key={title}> */}
      <Typography className={classes.title} size={1.6} weight={700}>
        {title}
      </Typography>
      {/* </Grid> */}
      {rows.map(({title, value}) => (
        <Grid container className={classes.wrapper} key={title}>
          <Typography color='#757575' size={1.6}>
            {title}:
          </Typography>
          <Typography size={1.7}>{value}</Typography>
        </Grid>
      ))}
      <Grid container direction='column' className={classes.wrapper}>
        <Typography color='#757575'>{t("panel.common.description")}:</Typography>
        {/* <Shiitake lines={3}>{description}</Shiitake> */}
        <Typography size={1.7}>{description}</Typography>
      </Grid>
      <Button
        color='primary'
        variant='contained'
        fullWidth
        className={classes.button}
        onClick={() => onClickEdit?.(id)}
      >
        {t("panel.common.edit")}
        <Edit />
      </Button>
      <Switch
        color='primary'
        checked={isActive}
        onChange={(_, value) => {
          alert.prompt({}).then(({result}) => {
            if (result) {
              value === true ? onSetActive?.(id) : onSetInactive?.(id);
            }
          });
        }}
        className={classes.switch}
      />
    </Box>
  );
};

export default PlanCard;

const useStyle = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,.2)",
    boxShadow: "0 4px 4px 0 #aaa",
    padding: "9px 15px 21.5px 15px",
    position: "relative",
  },
  wrapper: {
    padding: "10px 7px",
    gap: 10,
    borderBottom: "1px solid #a2d3fa",
    "&:last-of-type": {
      borderBottom: "none",
      paddingBottom: 40,
      "& *": {
        // fontSize: 18 * fontNormalizeFactor,
      },
    },
  },
  title: {
    padding: "10px 7px 15px",
  },
  button: {
    height: 40,
    // backgroundColor: "#E3F2FD",
    marginTop: "auto",
    border: "none",
    borderRadius: 2,
    boxShadow: "0 1px 5px 0 #ccc, 0 2px 3px 0 #ddd",
    "& *": {
      fontSize: 15 * fontNormalizeFactor,
    },
    "& svg": {
      marginLeft: 8,
    },
  },
  switch: {
    position: "absolute",
    right: 4,
    top: 12,
  },
}));
