import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import CollapseGroup from "@elements/CollapseGroup";
import {palette} from "@constant/colors";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import EditorContentWrapper from "@elements/EditorContentWrapper";
import CheckOurFaq from "@elements/CheckOurFaq";
import {FAQCardsScopeEnum} from "@schema";

const FaqQuestion = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.pricing");

  const {data} = useExperimentalQuery("faqs", "getList", {
    variables: {},
    staleTime: Infinity,
  });

  return (
    <BoxWithWallpaper color={palette.blue[100]} className={classes.container}>
      <Typography size={2.4} className={classes.title}>
        {t("faqSection.title")}
      </Typography>
      <Box>
        {data && (
          <CollapseGroup
            items={data?.results
              .filter((item) => item.scope === FAQCardsScopeEnum.Plans)
              .slice()
              .sort((a, b) => (a.priority || 0) - (b.priority || 0))
              .map(({title, content}) => ({
                title,
                value: <EditorContentWrapper content={content} />,
              }))}
          />
        )}
      </Box>
      <CheckOurFaq className={classes.moreQuestion} />
    </BoxWithWallpaper>
  );
};

export default FaqQuestion;

const useStyle = makeStyles((theme) => ({
  container: {
    paddingTop: "3rem",
  },
  title: {
    marginBottom: "2rem",
    textAlign: "center",
    fontSize: "2.4rem",
  },
  moreQuestion: {
    padding: "5rem 0 4.5rem",
  },
}));
