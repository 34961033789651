import {useState} from "react";

interface ModalState {
  visible: boolean;
  activeId: number | null;
}

export default function useModalState() {
  return useState<ModalState>({
    visible: false,
    activeId: null,
  });
}
