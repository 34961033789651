import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ExpandMore as ExpandMoreIcon, ArrowDropDown as ArrowDownIcon} from "@material-ui/icons";
import clsx from "clsx";
import {palette} from "@constant/colors";
import {Link} from "react-router-dom";
import Typography from "@elements/Typography";
import {routes} from "@constant/routes";
import {NavbarParentItem} from "@layouts/MainLayout";

export interface INavbarProps {
  navbarItems: NavbarParentItem[];
  activeItem: NavbarParentItem | null;
  renderChildren: (item: NavbarParentItem) => void;
}

const Navbar = (props: INavbarProps) => {
  const {navbarItems, activeItem, renderChildren} = props;
  const [hoveredItem, setHoveredItem] = useState<NavbarParentItem | null>(null);
  const classes = useStyle();

  useEffect(() => {
    const timeout = setTimeout(() => {
      hoveredItem !== null && renderChildren(hoveredItem);
    }, 150);
    return () => clearTimeout(timeout);
  }, [renderChildren, hoveredItem]);

  return (
    <nav className={classes.heightFull}>
      <ul className={classes.heightFull}>
        {navbarItems.map((item, index) => (
          <li
            key={item.name.concat(index.toString())}
            style={{display: "inline-block", height: "100%"}}
          >
            <span
              className={clsx(classes.item, activeItem === item ? classes.active : undefined)}
              onMouseEnter={() => setHoveredItem(item)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Typography color='inherit' className={classes.title}>
                {item.name}
                {/* this icon has to be here to appear exactly in the middle of title width, when li is hovered */}
                <ArrowDownIcon className={classes.ArrowDownIcon} />
              </Typography>
              <ExpandMoreIcon className={classes.expandMoreIcon} />
            </span>
          </li>
        ))}
        <li className={classes.item} style={{pointerEvents: "none"}}>
          <Typography color='inherit'>ویدیو</Typography>
          <Typography color={palette.green[200]} size={1.1} style={{paddingRight: 5}}>
            (به زودی)
          </Typography>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

const useStyle = makeStyles((theme) => ({
  heightFull: {
    height: "100%",
  },
  item: {
    position: "relative",
    color: palette.lightGrey.A600,
    height: "100%",
    padding: "0 1.2rem",
    cursor: "pointer",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
  },
  active: {
    "& , & $expandMoreIcon": {
      color: palette.green[200],
    },
    "& $expandMoreIcon": {
      transform: "rotate(180deg)",
    },
    "& $ArrowDownIcon": {
      display: "block",
    },
  },
  expandMoreIcon: {
    color: theme.palette.background.paper,
    marginLeft: ".3rem",
    marginTop: ".7rem",
    fontSize: "2.4rem",
  },
  ArrowDownIcon: {
    color: theme.palette.background.paper,
    position: "absolute",
    bottom: "-.8rem",
    left: "50%",
    transform: "translateX(-50%) scale(6) rotate(180deg)",
    display: "none",
  },
  title: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));
