import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, GridProps} from "@material-ui/core";
import Typography from "@elements/Typography";
import Tooltip from "@elements/Tooltip";

export interface ITableProps {
  title: string;
  firstRowTitles: string[];
  rows: {
    id: number;
    data: (string | number | JSX.Element)[];
  }[];
  rowsSize?: GridProps["xs"][];
  headerPayload?: React.ReactNode;
}

const Table = (props: ITableProps) => {
  const {title, firstRowTitles, rows, rowsSize, headerPayload} = props;
  const classes = useStyle();
  const styles = {padding: "0 2px"} as const;

  return (
    <Box className={classes.container}>
      <Grid container justify='space-between' alignItems='center' className={classes.header}>
        <Typography size={1.8}>{title}</Typography>
        {headerPayload}
      </Grid>
      <Grid container alignItems='center' className={classes.row}>
        {firstRowTitles.map((title, index) => (
          <Grid item key={title} style={styles} xs={rowsSize?.[index] || 3}>
            <Typography size={1.6} color='#757575' align='center'>
              {title}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {!!rows.length &&
        rows.map((row) => (
          <Grid container alignItems='center' className={classes.row} key={row.id}>
            {row.data.map((item, index) =>
              typeof item === "string" || typeof item === "number" ? (
                <Grid item key={index.toString()} style={styles} xs={rowsSize?.[index] || 3}>
                  <Tooltip title={String(item)}>
                    <Typography size={1.6} align='center' noWrap>
                      {item}
                    </Typography>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item key={index.toString()} style={styles} xs={rowsSize?.[index] || 3}>
                  <Typography component='div' size={1.6} align='center' noWrap>
                    {item}
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
        ))}
    </Box>
  );
};

export default Table;

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    boxShadow: "0 2px 4px 0 #aaa, 0 3px 4px 0 #bbb",
    borderRadius: 4,
    backgroundColor: "white",
  },
  header: {
    height: 64,
    padding: "0 25px",
    backgroundColor: "#f5f5f5",
  },
  row: {
    width: "100%",
    height: 56,
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  },
}));
