import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, CardMedia, Grid} from "@material-ui/core";
import {palette} from "@constant/colors";
import BoardImageCard from "@modules/pages/boards/[id]/BoardImageCard";
import BoardInfoSection from "@modules/pages/boards/[id]/BoardInfoSection";
import Typography from "@elements/Typography";
import SelectButton from "@modules/pages/boards/[id]/SelectButton";
import {GreyButton, MainButton} from "@elements/button";
import {Delete} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {images as projectImages} from "@constant/images";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import SearchInput from "@modules/SearchBar/SearchInput";
import {searchBarHeightBig, searchBarHeightSmall} from "@modules/SearchBar/constant";
import {useHistory, useParams} from "react-router-dom";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import Spinner from "@elements/Spinner";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {ApiNamespaces} from "@api";
import {useAlert} from "@context/AlertContext";
import {useQueryClient} from "react-query";
import {routes} from "@constant/routes";
import {useAuth} from "@context/AuthContext";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import createImageUrl from "@util/createImageUrl";
import Pagination from "@elements/Pagination";
import GreenLink from "@elements/GreenLink";
import {localStMediaTypeHistoryGet} from "@modules/SearchBar/utils";
import ProgressWithLabel from "@elements/ProgressWithLabel";
import {useModalDispatch} from "@context/ModalContext";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {useErrorHandlerContext} from "@context/ErrorHandlerContext";
import {formatDateTime} from "@util/global";
import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";

const Board = () => {
  const classes = useStyle();
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const {t} = useTranslation("pages.boards.[id]");
  const {id} = useParams<{id: string}>();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const {user, loadedUser} = useAuth();
  const history = useHistory();
  const {search, page} = useGetQueryString();
  const handleUpdateQueryString = useUpdateQueryString();
  const type = localStMediaTypeHistoryGet();
  const handleOpenBoardsModal = useModalDispatch("createBoard");
  const handleOpenChooseBoardOptionsModal = useModalDispatch("chooseBoardOptions");
  const handleCloseModal = useModalDispatch(null);
  const apiErrorHandler = useApiErrorHandler();
  const serverErrorNotify = useServerErrorNotify();
  const {setErrorStatusCode} = useErrorHandlerContext();

  React.useEffect(() => {
    loadedUser && !user && history.replace("/");
  });

  // it's temporary, creating set from array returns empty object on sending request !!!!
  const convertToNumberSet = (value: number[]) => value as unknown as Set<number>;

  const invalidate = () => {
    queryClient.invalidateQueries([
      ApiNamespaces.boards.Query.readDetail.queryKey,
      {id: Number(id)},
    ]);
    queryClient.invalidateQueries([ApiNamespaces.images.Query.getList.queryKey, {boards: id}]);
    queryClient.invalidateQueries(ApiNamespaces.boards.Query.getRecentBoardsList.queryKey);
  };

  const {data, isLoading} = useExperimentalQuery("boards", "readDetail", {
    variables: {id: Number(id)},
    enabled: !!id,
    onError: (error) => {
      serverErrorNotify(error);
      setErrorStatusCode(error);
    },
  });
  const {data: boardImages, isLoading: isLoadingImages} = useExperimentalQuery(
    "images",
    "getList",
    {
      variables: {
        boards: id,
        search,
        pageSize: 20,
        page: page ? Number(page) : 1,
      },
      enabled: !!id,
      onError: (error) => {
        serverErrorNotify(error);
      },
    }
  );

  const {mutate: preDownload} = useExperimentalMutation("boards", "preDownload");

  const {mutate: mutateDownload, isLoading: isLoadingDownload} = useExperimentalMutation(
    "boards",
    "download",
    {
      onError: (err) => apiErrorHandler(err),
      onSuccess: (res) => {
        const blob = new Blob([res as any], {type: "octet/stream"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `Board-${data?.name}-${formatDateTime(
          new Date(),
          "jYYYY-jMM-jDD-HHmmss"
        )}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        setSelectedImages([]);
      },
      onSettled: () => setDownloadProgress(null),
    }
  );

  const handleDownload = () => {
    if (!boardImages?.results.length) {
      return;
    }
    const images = convertToNumberSet(
      selectedImages.length ? selectedImages : boardImages.results.map(({id}) => id!)
    );

    const handleDownload = () =>
      mutateDownload({
        variables: {
          id: Number(id),
          data: {images},
          axiosConfig: {
            responseType: "arraybuffer",
            onDownloadProgress: (e: any) => {
              const progress = (e.loaded / e.total) * 100;
              setDownloadProgress(Math.round(progress));
            },
          },
        },
      });

    preDownload({
      variables: {id: Number(id), data: {images}},
      onError: (error) => {
        const errorData = error.response?.data as {code: number; error: string};
        // user has no plan or his plan as no enough credit to buy the remaining images
        if (errorData?.code === 22) {
          alert.prompt({text: errorData.error}).then(({result}) => result && handleDownload());
        } else {
          alert.error({text: errorData.error});
        }
      },
      onSuccess: () => handleDownload(),
    });
  };

  const {mutate: createNewBoard} = useExperimentalMutation("boards", "create");
  const {mutate: addToBoard} = useExperimentalMutation("boards", "addToBoard", {
    onError: (err) => apiErrorHandler(err),
    onSuccess: () => {
      queryClient.invalidateQueries(ApiNamespaces.boards.Query.getRecentBoardsList.queryKey);
      alert.success({text: "با موفقیت در بورد دیگر کپی شد"});
      setSelectedImages([]);
    },
  });
  const {mutate: removeFromBoard, isLoading: isRemoving} = useExperimentalMutation(
    "boards",
    "removeFromBoard",
    {
      onError: (err) => apiErrorHandler(err),
      onSuccess: () => {
        invalidate();
        alert.success({text: "بورد با موفقیت ویرایش شد"});
        setSelectedImages([]);
      },
    }
  );
  const handleRemoveFromBoards = (images: number[]) => {
    removeFromBoard({
      variables: {id: Number(id), data: {images: convertToNumberSet(images)}},
    });
  };

  const {mutate: moveToBoard} = useExperimentalMutation("boards", "moveToAnotherBoard", {
    onError: (err) => apiErrorHandler(err),
    onSuccess: () => {
      invalidate();
      alert.success({text: "با موفقیت به بورد دیگر منتقل شد"});
      setSelectedImages([]);
    },
  });

  // create new board => add to new board
  const handleCopyToNewBoard = (name: string) => {
    createNewBoard({
      variables: {data: {name}},
      onError: (err) => apiErrorHandler(err),
      onSuccess: (res) => {
        addToBoard({
          variables: {id: res.id || 0, data: {images: convertToNumberSet(selectedImages)}},
        });
      },
    });
  };

  // create new board => move to new board
  const handleMoveToNewBoard = (name: string) => {
    createNewBoard({
      variables: {data: {name}},
      onError: (err) => apiErrorHandler(err),
      onSuccess: (res) => {
        moveToBoard({
          variables: {
            id: Number(id),
            data: {board: res.id!, images: convertToNumberSet(selectedImages)},
          },
        });
      },
    });
  };

  // ui handlers
  const handleChecked = (checked: boolean, id: number) => {
    const newSelectedItems = checked
      ? [...selectedImages, id]
      : selectedImages?.filter((item) => item !== id);
    setSelectedImages(newSelectedItems);
  };
  const handleSelectAll = () => {
    boardImages?.count && setSelectedImages(boardImages.results?.map(({id}) => id!));
  };
  const handleDeleteImages = () =>
    alert.prompt({}).then(({result}) => result && handleRemoveFromBoards(selectedImages));

  const downloadButtonChildren =
    selectedImages.length === 0 || selectedImages.length === boardImages?.count
      ? t("downloadAll")
      : t("downloadImages", {imagesCount: selectedImages.length});

  return (
    <>
      <Helmet>
        <title>{defaultPageTitle}</title>
      </Helmet>
      <Box className={classes.container}>
        <Box className={classes.searchInputBox}>
          <SearchInput
            withHistoryAndAutoComplete={false}
            inputClassName={classes.input}
            placeholder='جستجو در بورد...'
            onSearch={(v) => handleUpdateQueryString({search: v})}
          />
        </Box>
        <Box className={classes.maxWidth}>
          <BoardInfoSection data={data} />
          <Grid
            container
            justify='space-between'
            alignItems='flex-end'
            className={classes.controls}
          >
            <Grid container className={classes.buttonsBox}>
              <MainButton
                color='green'
                classes={{green: classes.greenButton}}
                disabled={isLoadingDownload}
                onClick={handleDownload}
                title='حداکثر مجاز به دانلود ۱۰ عکس یکجا'
              >
                <CardMedia
                  component='img'
                  alt='download'
                  src={projectImages.icons.downloadWhite}
                  className={classes.downloadIcon}
                />
                {downloadButtonChildren}
              </MainButton>
              <SelectButton
                itemsLength={boardImages?.count || 0}
                selected={selectedImages.length}
                onSelectAll={handleSelectAll}
                onDeselect={() => setSelectedImages([])}
              />
              <Grid container className={classes.gap}>
                <GreyButton
                  disabled={!selectedImages.length || isRemoving}
                  icon={<Delete onClick={handleDeleteImages} />}
                />
                <MainButton
                  disabled={!selectedImages.length}
                  classes={{green: classes.greenButton}}
                  onClick={() =>
                    handleOpenChooseBoardOptionsModal({
                      filesCount: selectedImages.length,
                      onCreatingNewBoard: (type) => {
                        handleOpenBoardsModal({
                          onSave: (name) => {
                            handleCloseModal();
                            type === "copy"
                              ? handleCopyToNewBoard(name)
                              : handleMoveToNewBoard(name);
                          },
                        });
                      },
                      onCopyToRecentBoards: (boardId) => {
                        addToBoard({
                          variables: {
                            id: boardId,
                            data: {images: convertToNumberSet(selectedImages)},
                          },
                        });
                      },
                      onMoveToRecentBoards: (boardId) => {
                        moveToBoard({
                          variables: {
                            id: Number(id),
                            data: {board: boardId, images: convertToNumberSet(selectedImages)},
                          },
                        });
                      },
                    })
                  }
                >
                  {t("chooseAnAction")}
                </MainButton>
              </Grid>
            </Grid>
            <Typography size={1.4} color={palette.darkGrey[300]} className={classes.noFlexShrink}>
              {`${data?.images_count ?? ""} ${t("file")}`}
            </Typography>
          </Grid>
          {(isLoading || isLoadingImages) && <Spinner />}
          <Grid container wrap='wrap'>
            <div style={{textAlign: "center", width: "100%"}}>
              {data && !data.images_count && (
                <>
                  <Typography size={2} style={{margin: "3rem 0"}}>
                    در این بورد عکسی وجود ندارد
                  </Typography>
                  <GreenLink
                    to={routes.landing.search.concat(type ? `?type=${type}` : "")}
                    size={2}
                  >
                    افزودن تصویر به این بورد
                  </GreenLink>
                </>
              )}
              {!!data?.images_count && search && !boardImages?.count && (
                <Typography size={2} style={{margin: "3rem 0"}}>
                  نتیجه‌ای مطابق با این عبارت یافت نشد
                </Typography>
              )}
            </div>
            {boardImages?.results?.map(
              ({is_premium, id, file, title, type, id_code, user_bought}) => (
                <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
                  <BoardImageCard
                    src={file || ""}
                    id={id!}
                    idCode={Number(id_code)}
                    checked={selectedImages.includes(id!)}
                    collection={is_premium ? t("signature") : t("essential")}
                    to={createImageUrl(type || "", title || "", id_code || "")}
                    onChecked={handleChecked}
                    disabledRemoveButton={isRemoving}
                    userBought={!!user_bought}
                    onRemove={(imageId) =>
                      alert
                        .prompt({})
                        .then(({result}) => result && handleRemoveFromBoards([imageId]))
                    }
                  />
                </Grid>
              )
            )}
          </Grid>
          <Pagination
            style={{marginTop: "4rem"}}
            pageSize={20}
            count={boardImages?.count || 0}
            withScrollToTopButton={false}
          />
        </Box>
        {!!downloadProgress && <ProgressWithLabel value={downloadProgress} />}
      </Box>
    </>
  );
};

export default Board;

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: palette.lightGrey[500],
  },
  maxWidth: {
    padding: "1.2rem 2.8rem 10rem",
    maxWidth: "140rem", // temp
    margin: "0 auto",
    [theme.breakpoints.down(700)]: {
      padding: "1.2rem 1rem 7rem",
    },
  },
  searchInputBox: {
    height: searchBarHeightBig,
    borderBottom: `1px solid ${palette.lightGrey.A900}`,
    boxShadow: "0 6px 5px -5px rgba(12,13,13,0.05)",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      height: searchBarHeightSmall,
    },
  },
  input: {
    paddingLeft: "1.5rem",
  },
  // badgesBox: {
  //   margin: "0 2rem 0 1rem",
  // },
  controls: {
    marginTop: "1.2rem",
    paddingBottom: "2rem",
    [theme.breakpoints.down(300)]: {
      flexDirection: "column",
    },
  },
  buttonsBox: {
    gap: "1rem",
    "& *": {
      flexShrink: 0,
    },
    [theme.breakpoints.down(630)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  gap: {
    gap: "1rem",
  },
  greenButton: {
    height: "4rem",
    padding: "0 1.6rem",
    "& *": {
      fontSize: `${1.4 * fontNormalizeFactor}rem`,
    },
  },
  downloadIcon: {
    width: "2.3rem",
    marginRight: "1rem",
  },
  noFlexShrink: {
    flexShrink: 0,
  },
}));
