/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FAQCards } from '../servermodel';
// @ts-ignore
import { InlineResponse2006 } from '../servermodel';
/**
 * FaqsApi - axios parameter creator
 * @export
 */
export const FaqsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsCreate: async (data: FAQCards, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('faqsCreate', 'data', data)
            const localVarPath = `/faqs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqsDelete', 'id', id)
            const localVarPath = `/faqs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsList: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/faqs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsPartialUpdate: async (id: number, data: FAQCards, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('faqsPartialUpdate', 'data', data)
            const localVarPath = `/faqs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqsRead', 'id', id)
            const localVarPath = `/faqs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsUpdate: async (id: number, data: FAQCards, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('faqsUpdate', 'data', data)
            const localVarPath = `/faqs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqsApi - functional programming interface
 * @export
 */
export const FaqsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsCreate(data: FAQCards, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQCards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsList(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsList(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsPartialUpdate(id: number, data: FAQCards, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQCards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQCards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqsUpdate(id: number, data: FAQCards, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQCards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FaqsApi - factory interface
 * @export
 */
export const FaqsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqsApiFp(configuration)
    return {
        /**
         * 
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsCreate(data: FAQCards, options?: any): AxiosPromise<FAQCards> {
            return localVarFp.faqsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.faqsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsList(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.faqsList(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsPartialUpdate(id: number, data: FAQCards, options?: any): AxiosPromise<FAQCards> {
            return localVarFp.faqsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsRead(id: number, options?: any): AxiosPromise<FAQCards> {
            return localVarFp.faqsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this faq card.
         * @param {FAQCards} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqsUpdate(id: number, data: FAQCards, options?: any): AxiosPromise<FAQCards> {
            return localVarFp.faqsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for faqsCreate operation in FaqsApi.
 * @export
 * @interface FaqsApiFaqsCreateRequest
 */
export interface FaqsApiFaqsCreateRequest {
    /**
     * 
     * @type {FAQCards}
     * @memberof FaqsApiFaqsCreate
     */
    readonly data: FAQCards
}

/**
 * Request parameters for faqsDelete operation in FaqsApi.
 * @export
 * @interface FaqsApiFaqsDeleteRequest
 */
export interface FaqsApiFaqsDeleteRequest {
    /**
     * A unique integer value identifying this faq card.
     * @type {number}
     * @memberof FaqsApiFaqsDelete
     */
    readonly id: number
}

/**
 * Request parameters for faqsList operation in FaqsApi.
 * @export
 * @interface FaqsApiFaqsListRequest
 */
export interface FaqsApiFaqsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof FaqsApiFaqsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FaqsApiFaqsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for faqsPartialUpdate operation in FaqsApi.
 * @export
 * @interface FaqsApiFaqsPartialUpdateRequest
 */
export interface FaqsApiFaqsPartialUpdateRequest {
    /**
     * A unique integer value identifying this faq card.
     * @type {number}
     * @memberof FaqsApiFaqsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FAQCards}
     * @memberof FaqsApiFaqsPartialUpdate
     */
    readonly data: FAQCards
}

/**
 * Request parameters for faqsRead operation in FaqsApi.
 * @export
 * @interface FaqsApiFaqsReadRequest
 */
export interface FaqsApiFaqsReadRequest {
    /**
     * A unique integer value identifying this faq card.
     * @type {number}
     * @memberof FaqsApiFaqsRead
     */
    readonly id: number
}

/**
 * Request parameters for faqsUpdate operation in FaqsApi.
 * @export
 * @interface FaqsApiFaqsUpdateRequest
 */
export interface FaqsApiFaqsUpdateRequest {
    /**
     * A unique integer value identifying this faq card.
     * @type {number}
     * @memberof FaqsApiFaqsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FAQCards}
     * @memberof FaqsApiFaqsUpdate
     */
    readonly data: FAQCards
}

/**
 * FaqsApi - object-oriented interface
 * @export
 * @class FaqsApi
 * @extends {BaseAPI}
 */
export class FaqsApi extends BaseAPI {
    /**
     * 
     * @param {FaqsApiFaqsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsCreate(requestParameters: FaqsApiFaqsCreateRequest, options?: any) {
        return FaqsApiFp(this.configuration).faqsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaqsApiFaqsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsDelete(requestParameters: FaqsApiFaqsDeleteRequest, options?: any) {
        return FaqsApiFp(this.configuration).faqsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaqsApiFaqsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsList(requestParameters: FaqsApiFaqsListRequest = {}, options?: any) {
        return FaqsApiFp(this.configuration).faqsList(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaqsApiFaqsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsPartialUpdate(requestParameters: FaqsApiFaqsPartialUpdateRequest, options?: any) {
        return FaqsApiFp(this.configuration).faqsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaqsApiFaqsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsRead(requestParameters: FaqsApiFaqsReadRequest, options?: any) {
        return FaqsApiFp(this.configuration).faqsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FaqsApiFaqsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqsApi
     */
    public faqsUpdate(requestParameters: FaqsApiFaqsUpdateRequest, options?: any) {
        return FaqsApiFp(this.configuration).faqsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
