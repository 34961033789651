import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Radio} from "@material-ui/core";
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import clsx from "clsx";
import {palette} from "@constant/colors";
import {Span} from "@elements/Span";

interface IProps {
  name: string;
  onChange?: (value: string) => void;
  value?: string;
  items: {
    value: string;
    label: React.ReactNode;
    tooltipText?: string;
  }[];
}

const RadioButtonGroupForPricingPage = (props: IProps) => {
  const {name, items, value: controlledValue, onChange} = props;
  const [value, setValue] = useState("");
  const classes = useStyle();

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  const handleChange = (value: string) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <RadioGroup name={name} value={value} onChange={(_, value) => handleChange(value)}>
      {items.map(({value: iValue, label, tooltipText}) => (
        <FormControlLabel
          className={clsx(classes.row, iValue === value ? classes.selectedRow : undefined)}
          key={iValue}
          value={iValue}
          label={label}
          title={tooltipText}
          control={
            <Radio
              disableRipple
              color='default'
              classes={{root: classes.root}}
              icon={<Span className={classes.icon} />}
              checkedIcon={<Span className={clsx(classes.icon, classes.checkedIcon)} />}
            />
          }
        />
      ))}
    </RadioGroup>
  );
};

export default RadioButtonGroupForPricingPage;

const useStyle = makeStyles(() => ({
  root: {
    height: "3.2rem",
    padding: ".8rem .8rem .8rem 0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  row: {
    margin: 0,
    padding: "1rem",
    maxHeight: "4.7rem",
    width: "100%",
    borderBottom: `1px solid ${palette.lightGrey.A300}`,
    "&:hover": {
      boxShadow: `inset 0 0 0 2px ${palette.lightGrey.A300}`,
    },
    "&:last-child": {
      borderBottom: "1px solid transparent",
    },
    "& > *:nth-child(2)": {
      width: "100%",
    },
  },
  selectedRow: {
    backgroundColor: palette.lightGrey[500],
  },
  icon: {
    border: `1px solid ${palette.darkGrey.A700}`,
    borderRadius: "100%",
    cursor: "pointer",
    height: "22px",
    width: "22px",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      top: "6px",
      left: "6px",
      height: "8px",
      width: "8px",
      borderRadius: "100%",
      backgroundColor: "transparent",
      transition: ".2s ease",
    },
  },
  checkedIcon: {
    border: `1px solid ${palette.darkGrey.A700}`,
    "&::after": {
      backgroundColor: palette.darkGrey.A700,
    },
  },
}));
