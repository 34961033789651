import {PlanDiscountByNameApi, PlanDiscountByNameApiPlanDiscountByNameRequest} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const promoCodeByNameApiInstance = new PlanDiscountByNameApi(undefined, undefined, axiosInstance);

export const promoCodeByNameAPI = makeApi({
  identifier: "promoCodeByName",
  Query: {},
  Mutation: {
    readDetail: createQuery(async (params: PlanDiscountByNameApiPlanDiscountByNameRequest) => {
      const {data} = await promoCodeByNameApiInstance.planDiscountByName(params);
      return data;
    }),
  },
});
