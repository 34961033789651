import DialogSkeleton, {DialogSkeletonDefaultProps, DialogSkeletonProps} from "./DialogSkeleton";
import React from "react";
import {useModalDispatch, useModalState} from "@context/ModalContext";
import {modalsPayload} from "@context/ModalContext/types";

export type DialogBodyProps<T> = T & Pick<DialogSkeletonDefaultProps, "open" | "onClose">;

export default function ModalWrapper(
  DialogBody: React.ElementType,
  name: keyof modalsPayload,
  dialogCommonProps?: Omit<DialogSkeletonProps, "open" | "onClose">
) {
  const Component = () => {
    const openModal = useModalState();
    const handleCloseModal = useModalDispatch(null);
    const open = openModal?.modal === name;
    const handleClose = () => handleCloseModal();
    const payload = openModal?.payload || {};
    return (
      <DialogSkeleton {...dialogCommonProps} open={open} onClose={handleClose}>
        <DialogBody {...payload} />
      </DialogSkeleton>
    );
  };
  return () => Component();
}
