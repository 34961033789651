import {green} from "@constant/colors";
import Typography from "@elements/Typography";
import React from "react";
import {LinkProps, Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover > *": {
      color: green[500],
    },
  },
}));

export interface GreenLinkProps extends Omit<LinkProps, "component"> {
  size: number | "tiny" | "small" | "medium" | "large" | string;
  component?: React.ElementType;
}

export default function GreenLink({size, component, children, ...props}: GreenLinkProps) {
  const classes = useStyles({size});
  return (
    <Link {...props} className={classes.root}>
      <Typography
        component={component}
        span
        weight={500}
        color={green[200]}
        size={
          size === "tiny"
            ? 1.2
            : size === "small"
            ? 1.4
            : size === "medium"
            ? 1.6
            : size === "large"
            ? 2.1
            : size
        }
      >
        {children}
      </Typography>
    </Link>
  );
}
