import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, IconButton, Switch} from "@material-ui/core";
import Pagination from "@elements/panel/Pagination";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useQueryClient} from "react-query";
import {useAlert} from "@context/AlertContext";
import {ApiNamespaces} from "@api";
import Table from "@elements/panel/Table";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import Spinner from "@elements/Spinner";
import {Info} from "@material-ui/icons";
import UserInfoModal from "@elements/panel/modals/UserInfoModal";
import React from "react";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInputFullWidth from "@elements/panel/QuerySearchInputFullWidth";
import useModalState from "@hook/useModalState";

const Users = () => {
  const [modalState, setModalState] = useModalState();
  const {search, page, pageSize} = useGetQueryString();
  const queryClient = useQueryClient();
  const alert = useAlert();
  const classes = useStyle();
  const {t} = useTranslation("pages.panel");

  const {data} = useExperimentalQuery("users", "getList", {
    variables: {
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : 20,
      search,
    },
  });

  const {mutate: changeStatus} = useExperimentalMutation("users", "changeStatus");

  const events = {
    onSuccess: () => {
      alert.success({});
      queryClient.invalidateQueries(ApiNamespaces.users.Query.getList.queryKey);
    },
    onError: () => alert.error({}),
  };

  const handleSetActive = (id: number) => {
    changeStatus({variables: {data: {users: [id], be_active: true}}, ...events});
  };

  const handleSetInactive = (id: number) => {
    changeStatus({variables: {data: {users: [id], be_active: false}}, ...events});
  };

  return (
    <>
      {/* <Grid container direction='column' className={classes.container}> */}
      <Grid className={classes.container}>
        <QuerySearchInputFullWidth renderAddIcon={false} />
        {!data && <Spinner />}
        {/* <Grid item> */}
        {data?.results.length === 0 && <NoResult />}
        {!!data?.results.length && (
          <Grid className={classes.usersBox}>
            <Table
              title={t("users.users")}
              firstRowTitles={[
                t("common.id"),
                t("common.userName"),
                t("users.role"),
                t("common.mobileNumber"),
                t("common.userStatus"),
                t("users.completeInfo"),
              ]}
              rows={(data?.results || []).map(({id, username, mobile_number, is_active, role}) => ({
                id: id || 0,
                data: [
                  id || "",
                  username || "",
                  role || "",
                  mobile_number ? `${mobile_number.slice(1)}+` : "",
                  <Box style={{textAlign: "center"}}>
                    <Switch
                      color='primary'
                      checked={is_active ? true : false}
                      onChange={(_, value) => {
                        alert.prompt({}).then(({result}) => {
                          if (result) {
                            value === true ? handleSetActive(id || 0) : handleSetInactive(id || 0);
                          }
                        });
                      }}
                    />
                  </Box>,
                  <IconButton onClick={() => setModalState({visible: true, activeId: id || 0})}>
                    <Info style={{fontSize: 24, color: "#888"}} />
                  </IconButton>,
                ],
              }))}
              rowsSize={[2, 4, 3, 4, 2, 2]}
            />
          </Grid>
        )}
        {/* </Grid> */}
        <Pagination count={data?.count || 0} />
      </Grid>
      <UserInfoModal
        open={modalState.visible}
        id={modalState.activeId}
        onClose={() => setModalState({visible: false, activeId: null})}
      />
    </>
  );
};

export default Users;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  inputRoot: {
    width: "100%",
    height: 65,
    backgroundColor: "#E3F2FD",
    marginBottom: 20,
    borderRadius: 4,
    paddingLeft: 60,
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
    },
  },

  usersBox: {
    paddingLeft: 3,
    paddingRight: 3,
  },
}));
