import Custom404 from "@pages/404";
import {AxiosError} from "axios";
import React from "react";
import {useHistory} from "react-router-dom";

const ErrorStatusContext = React.createContext<{
  setErrorStatusCode: (error: AxiosError) => void;
}>({setErrorStatusCode: () => {}});

/** returns a custom error component in the current route (for now covers only 404) */
export const ErrorHandlerContext = ({children}: {children: React.ReactNode}) => {
  const history = useHistory();
  const [errorStatusCodeState, setErrorStatusCodeState] = React.useState<number | null>(null);

  // removing status code whenever the user navigates to a new URL
  React.useEffect(() => {
    // Listen for changes to the current location.
    const unListen = history.listen(() => setErrorStatusCodeState(null));
    // cleanup the listener on unmount
    return unListen;
  }, [history]);

  const setErrorStatusCode = (error: AxiosError) => {
    setErrorStatusCodeState(error.response?.status || null);
  };
  return (
    <ErrorStatusContext.Provider value={{setErrorStatusCode}}>
      {errorStatusCodeState === 404 ? <Custom404 /> : children}
    </ErrorStatusContext.Provider>
  );
};

/** returns a 404 page if status code === 404 */
export const useErrorHandlerContext = () => React.useContext(ErrorStatusContext);
