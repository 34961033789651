import {
  ImageInvoicesApi,
  ImageInvoicesApiImageInvoicesListRequest,
  ImageInvoicesApiImageInvoicesReadRequest,
  ImageInvoicesApiImageInvoicesCreateRequest,
  ImageInvoicesApiImageInvoicesPartialUpdateRequest,
  ImageInvoicesApiImageInvoicesDeleteRequest,
  ImageInvoicesApiImageInvoicesPayRequest,
  ImageInvoicesApiImageInvoicesAdminCreateRequest,
} from "@schema/api";
import { axiosInstance } from "services/axios/axios";
import { createQuery, makeApi } from "./types";

const imageInvoicesApiInstance = new ImageInvoicesApi(undefined, undefined, axiosInstance);

export const imagesInvoicesAPI = makeApi({
  identifier: "imageInvoices",
  Query: {
    getList: createQuery(async (params: ImageInvoicesApiImageInvoicesListRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesList(params);
      return data;
    }),
    readDetail: createQuery(async (params: ImageInvoicesApiImageInvoicesReadRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: ImageInvoicesApiImageInvoicesCreateRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesCreate(params);
      return data;
    },
    createPay: async (params: ImageInvoicesApiImageInvoicesPayRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesPay(params);
      return data;
    },
    update: async (params: ImageInvoicesApiImageInvoicesPartialUpdateRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesPartialUpdate(params);
      return data;
    },
    delete: async (params: ImageInvoicesApiImageInvoicesDeleteRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesDelete(params);
      return data;
    },
    createInvoiceByAdmin: async (params: ImageInvoicesApiImageInvoicesAdminCreateRequest) => {
      const { data } = await imageInvoicesApiInstance.imageInvoicesAdminCreate(params);
      return data;
    },
  },
});
