import {AddToBoardModal} from "./AddToBoardModal";
import {ChooseBoardsOptionsModal} from "./ChooseBoardOptionsModal";
import {CreateBoardModal} from "./CreateBoardModal";
import {ModifyBoardMessageModal} from "./ModifyBoardMessageModal";

export const Modals = () => (
  <>
    <CreateBoardModal />
    <ChooseBoardsOptionsModal />
    <AddToBoardModal />
    <ModifyBoardMessageModal />
  </>
);

export default Modals;
