/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse20014 } from '../servermodel';
// @ts-ignore
import { Plan } from '../servermodel';
/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansCreate: async (data: Plan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('plansCreate', 'data', data)
            const localVarPath = `/plans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plansDelete', 'id', id)
            const localVarPath = `/plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [isActive] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansList: async (isActive?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansPartialUpdate: async (id: number, data: Plan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plansPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('plansPartialUpdate', 'data', data)
            const localVarPath = `/plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plansRead', 'id', id)
            const localVarPath = `/plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansUpdate: async (id: number, data: Plan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plansUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('plansUpdate', 'data', data)
            const localVarPath = `/plans/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansCreate(data: Plan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [isActive] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansList(isActive?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansList(isActive, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansPartialUpdate(id: number, data: Plan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansUpdate(id: number, data: Plan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlansApiFp(configuration)
    return {
        /**
         * 
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansCreate(data: Plan, options?: any): AxiosPromise<Plan> {
            return localVarFp.plansCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.plansDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [isActive] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansList(isActive?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.plansList(isActive, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansPartialUpdate(id: number, data: Plan, options?: any): AxiosPromise<Plan> {
            return localVarFp.plansPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansRead(id: number, options?: any): AxiosPromise<Plan> {
            return localVarFp.plansRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this plan.
         * @param {Plan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansUpdate(id: number, data: Plan, options?: any): AxiosPromise<Plan> {
            return localVarFp.plansUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for plansCreate operation in PlansApi.
 * @export
 * @interface PlansApiPlansCreateRequest
 */
export interface PlansApiPlansCreateRequest {
    /**
     * 
     * @type {Plan}
     * @memberof PlansApiPlansCreate
     */
    readonly data: Plan
}

/**
 * Request parameters for plansDelete operation in PlansApi.
 * @export
 * @interface PlansApiPlansDeleteRequest
 */
export interface PlansApiPlansDeleteRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof PlansApiPlansDelete
     */
    readonly id: number
}

/**
 * Request parameters for plansList operation in PlansApi.
 * @export
 * @interface PlansApiPlansListRequest
 */
export interface PlansApiPlansListRequest {
    /**
     * 
     * @type {string}
     * @memberof PlansApiPlansList
     */
    readonly isActive?: string

    /**
     * A search term.
     * @type {string}
     * @memberof PlansApiPlansList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PlansApiPlansList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PlansApiPlansList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for plansPartialUpdate operation in PlansApi.
 * @export
 * @interface PlansApiPlansPartialUpdateRequest
 */
export interface PlansApiPlansPartialUpdateRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof PlansApiPlansPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Plan}
     * @memberof PlansApiPlansPartialUpdate
     */
    readonly data: Plan
}

/**
 * Request parameters for plansRead operation in PlansApi.
 * @export
 * @interface PlansApiPlansReadRequest
 */
export interface PlansApiPlansReadRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof PlansApiPlansRead
     */
    readonly id: number
}

/**
 * Request parameters for plansUpdate operation in PlansApi.
 * @export
 * @interface PlansApiPlansUpdateRequest
 */
export interface PlansApiPlansUpdateRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof PlansApiPlansUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Plan}
     * @memberof PlansApiPlansUpdate
     */
    readonly data: Plan
}

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI {
    /**
     * 
     * @param {PlansApiPlansCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansCreate(requestParameters: PlansApiPlansCreateRequest, options?: any) {
        return PlansApiFp(this.configuration).plansCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlansApiPlansDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansDelete(requestParameters: PlansApiPlansDeleteRequest, options?: any) {
        return PlansApiFp(this.configuration).plansDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlansApiPlansListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansList(requestParameters: PlansApiPlansListRequest = {}, options?: any) {
        return PlansApiFp(this.configuration).plansList(requestParameters.isActive, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlansApiPlansPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansPartialUpdate(requestParameters: PlansApiPlansPartialUpdateRequest, options?: any) {
        return PlansApiFp(this.configuration).plansPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlansApiPlansReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansRead(requestParameters: PlansApiPlansReadRequest, options?: any) {
        return PlansApiFp(this.configuration).plansRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlansApiPlansUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansUpdate(requestParameters: PlansApiPlansUpdateRequest, options?: any) {
        return PlansApiFp(this.configuration).plansUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
