/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Board } from '../servermodel';
// @ts-ignore
import { BoardDownload } from '../servermodel';
// @ts-ignore
import { BoardMove } from '../servermodel';
// @ts-ignore
import { BoardUpdate } from '../servermodel';
// @ts-ignore
import { InlineResponse2002 } from '../servermodel';
// @ts-ignore
import { RetrieveBoard } from '../servermodel';
// @ts-ignore
import { ThinListBoard } from '../servermodel';
/**
 * BoardsApi - axios parameter creator
 * @export
 */
export const BoardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsAddImages: async (id: number, data: BoardUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsAddImages', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsAddImages', 'data', data)
            const localVarPath = `/boards/{id}/add_images/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsCreate: async (data: Board, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsCreate', 'data', data)
            const localVarPath = `/boards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsDelete', 'id', id)
            const localVarPath = `/boards/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardDownload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsDownload: async (id: number, data: BoardDownload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsDownload', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsDownload', 'data', data)
            const localVarPath = `/boards/{id}/download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsList: async (search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/boards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardMove} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsMove: async (id: number, data: BoardMove, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsMove', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsMove', 'data', data)
            const localVarPath = `/boards/{id}/move/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsPartialUpdate: async (id: number, data: Board, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsPartialUpdate', 'data', data)
            const localVarPath = `/boards/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardDownload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsPreDownload: async (id: number, data: BoardDownload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsPreDownload', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsPreDownload', 'data', data)
            const localVarPath = `/boards/{id}/pre_download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsRead', 'id', id)
            const localVarPath = `/boards/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsRemoveImages: async (id: number, data: BoardUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsRemoveImages', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsRemoveImages', 'data', data)
            const localVarPath = `/boards/{id}/remove_images/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsThinListRead: async (search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/boards/thin_list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsUpdate: async (id: number, data: Board, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('boardsUpdate', 'data', data)
            const localVarPath = `/boards/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardsApi - functional programming interface
 * @export
 */
export const BoardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsAddImages(id: number, data: BoardUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsAddImages(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsCreate(data: Board, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Board>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardDownload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsDownload(id: number, data: BoardDownload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardDownload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsDownload(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsList(search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsList(search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardMove} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsMove(id: number, data: BoardMove, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardMove>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsMove(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsPartialUpdate(id: number, data: Board, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Board>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardDownload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsPreDownload(id: number, data: BoardDownload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardDownload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsPreDownload(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveBoard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsRemoveImages(id: number, data: BoardUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsRemoveImages(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsThinListRead(search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThinListBoard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsThinListRead(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsUpdate(id: number, data: Board, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Board>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardsApi - factory interface
 * @export
 */
export const BoardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsAddImages(id: number, data: BoardUpdate, options?: any): AxiosPromise<BoardUpdate> {
            return localVarFp.boardsAddImages(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsCreate(data: Board, options?: any): AxiosPromise<Board> {
            return localVarFp.boardsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.boardsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardDownload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsDownload(id: number, data: BoardDownload, options?: any): AxiosPromise<BoardDownload> {
            return localVarFp.boardsDownload(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsList(search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.boardsList(search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardMove} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsMove(id: number, data: BoardMove, options?: any): AxiosPromise<BoardMove> {
            return localVarFp.boardsMove(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsPartialUpdate(id: number, data: Board, options?: any): AxiosPromise<Board> {
            return localVarFp.boardsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardDownload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsPreDownload(id: number, data: BoardDownload, options?: any): AxiosPromise<BoardDownload> {
            return localVarFp.boardsPreDownload(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsRead(id: number, options?: any): AxiosPromise<RetrieveBoard> {
            return localVarFp.boardsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {BoardUpdate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsRemoveImages(id: number, data: BoardUpdate, options?: any): AxiosPromise<BoardUpdate> {
            return localVarFp.boardsRemoveImages(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsThinListRead(search?: string, options?: any): AxiosPromise<Array<ThinListBoard>> {
            return localVarFp.boardsThinListRead(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this board.
         * @param {Board} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsUpdate(id: number, data: Board, options?: any): AxiosPromise<Board> {
            return localVarFp.boardsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for boardsAddImages operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsAddImagesRequest
 */
export interface BoardsApiBoardsAddImagesRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsAddImages
     */
    readonly id: number

    /**
     * 
     * @type {BoardUpdate}
     * @memberof BoardsApiBoardsAddImages
     */
    readonly data: BoardUpdate
}

/**
 * Request parameters for boardsCreate operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsCreateRequest
 */
export interface BoardsApiBoardsCreateRequest {
    /**
     * 
     * @type {Board}
     * @memberof BoardsApiBoardsCreate
     */
    readonly data: Board
}

/**
 * Request parameters for boardsDelete operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsDeleteRequest
 */
export interface BoardsApiBoardsDeleteRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsDelete
     */
    readonly id: number
}

/**
 * Request parameters for boardsDownload operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsDownloadRequest
 */
export interface BoardsApiBoardsDownloadRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsDownload
     */
    readonly id: number

    /**
     * 
     * @type {BoardDownload}
     * @memberof BoardsApiBoardsDownload
     */
    readonly data: BoardDownload
}

/**
 * Request parameters for boardsList operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsListRequest
 */
export interface BoardsApiBoardsListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof BoardsApiBoardsList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BoardsApiBoardsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BoardsApiBoardsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for boardsMove operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsMoveRequest
 */
export interface BoardsApiBoardsMoveRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsMove
     */
    readonly id: number

    /**
     * 
     * @type {BoardMove}
     * @memberof BoardsApiBoardsMove
     */
    readonly data: BoardMove
}

/**
 * Request parameters for boardsPartialUpdate operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsPartialUpdateRequest
 */
export interface BoardsApiBoardsPartialUpdateRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Board}
     * @memberof BoardsApiBoardsPartialUpdate
     */
    readonly data: Board
}

/**
 * Request parameters for boardsPreDownload operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsPreDownloadRequest
 */
export interface BoardsApiBoardsPreDownloadRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsPreDownload
     */
    readonly id: number

    /**
     * 
     * @type {BoardDownload}
     * @memberof BoardsApiBoardsPreDownload
     */
    readonly data: BoardDownload
}

/**
 * Request parameters for boardsRead operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsReadRequest
 */
export interface BoardsApiBoardsReadRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsRead
     */
    readonly id: number
}

/**
 * Request parameters for boardsRemoveImages operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsRemoveImagesRequest
 */
export interface BoardsApiBoardsRemoveImagesRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsRemoveImages
     */
    readonly id: number

    /**
     * 
     * @type {BoardUpdate}
     * @memberof BoardsApiBoardsRemoveImages
     */
    readonly data: BoardUpdate
}

/**
 * Request parameters for boardsThinListRead operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsThinListReadRequest
 */
export interface BoardsApiBoardsThinListReadRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof BoardsApiBoardsThinListRead
     */
    readonly search?: string
}

/**
 * Request parameters for boardsUpdate operation in BoardsApi.
 * @export
 * @interface BoardsApiBoardsUpdateRequest
 */
export interface BoardsApiBoardsUpdateRequest {
    /**
     * A unique integer value identifying this board.
     * @type {number}
     * @memberof BoardsApiBoardsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Board}
     * @memberof BoardsApiBoardsUpdate
     */
    readonly data: Board
}

/**
 * BoardsApi - object-oriented interface
 * @export
 * @class BoardsApi
 * @extends {BaseAPI}
 */
export class BoardsApi extends BaseAPI {
    /**
     * 
     * @param {BoardsApiBoardsAddImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsAddImages(requestParameters: BoardsApiBoardsAddImagesRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsAddImages(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsCreate(requestParameters: BoardsApiBoardsCreateRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsDelete(requestParameters: BoardsApiBoardsDeleteRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsDownload(requestParameters: BoardsApiBoardsDownloadRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsDownload(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsList(requestParameters: BoardsApiBoardsListRequest = {}, options?: any) {
        return BoardsApiFp(this.configuration).boardsList(requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsMoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsMove(requestParameters: BoardsApiBoardsMoveRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsMove(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsPartialUpdate(requestParameters: BoardsApiBoardsPartialUpdateRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsPreDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsPreDownload(requestParameters: BoardsApiBoardsPreDownloadRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsPreDownload(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsRead(requestParameters: BoardsApiBoardsReadRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsRemoveImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsRemoveImages(requestParameters: BoardsApiBoardsRemoveImagesRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsRemoveImages(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsThinListReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsThinListRead(requestParameters: BoardsApiBoardsThinListReadRequest = {}, options?: any) {
        return BoardsApiFp(this.configuration).boardsThinListRead(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoardsApiBoardsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardsApi
     */
    public boardsUpdate(requestParameters: BoardsApiBoardsUpdateRequest, options?: any) {
        return BoardsApiFp(this.configuration).boardsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
