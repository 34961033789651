/* eslint-disable react/destructuring-assignment */
import {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Delete, Save} from "@material-ui/icons";
import TextInput from "./TextInput";
import clsx from "clsx";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useAlert} from "@context/AlertContext";
import useDebouncedValue from "@hook/useDebouncedValue";
import Spinner from "@elements/Spinner";

interface ICommonProps {
  onSubmit?: (values: CategoryCardReturnType) => void;
}

interface Create {
  type: "create";
}

interface Edit {
  title: string;
  image: number;
  order: number;
  description: string;
  type: "edit";
  id: number;
  onRemove?: (id: number) => void;
  isData?: boolean;
}

type IProps = (ICommonProps & Create) | (ICommonProps & Edit);

export interface CategoryCardReturnType {
  title: string;
  imageId: string;
  order: string;
  description: string;
}

const CategoryCardEditable = (props: IProps) => {
  const classes = useStyle();
  const {t} = useTranslation("elements");
  const {type, onSubmit} = props;
  const cTitle = "title" in props ? props.title : undefined;
  const cImageId = "image" in props ? props.image : undefined;
  const cOrder = "order" in props ? props.order : undefined;
  const cDescription = "description" in props ? props.description : undefined;
  const onRemove = "onRemove" in props ? props.onRemove : undefined;
  const id = "id" in props ? props.id : undefined;
  const isData = "isData" in props ? props.isData : undefined;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageId, setImageId] = useState("");
  const [order, setOrder] = useState("1");
  const alert = useAlert();
  const debouncedImageId = useDebouncedValue(imageId, 1000);

  const {data} = useExperimentalQuery("images", "readDetail", {
    variables: {
      id: Number(debouncedImageId),
    },
    enabled: !!debouncedImageId,
    retry: 0,
    onError: (err) => {
      if (err.response?.status === 404) {
        alert.error({text: "آیدی وارد شده در سرور وجود ندارد"});
      }
    },
  });

  useEffect(() => {
    cTitle && setTitle(cTitle);
    cDescription && setDescription(cDescription);
    cImageId && setImageId(String(cImageId));
  }, [cTitle, cImageId, cDescription]);

  return (
    <Box className={classes.container}>
      {type === "edit" && !isData ? (
        <Spinner />
      ) : (
        <>
          <Box className={classes.imageBox}>
            {data ? (
              <img src={data.file} alt='' className={classes.image} />
            ) : (
              <Typography align='center'>
                پس از وارد کردن آیدی، عکس مورد نظر اینجا نمایش داده می‌شود
              </Typography>
            )}
            {data?.file === null && (
              <Typography align='center'>برای این آیدی عکسی برای نمایش وجود ندارد</Typography>
            )}
          </Box>
          <Box>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography color='#757575'>{t("panel.common.title")}</Typography>
              <TextInput value={title} onChange={(v) => setTitle(v)} />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography color='#757575'>{t("panel.categoryCard.imageId")}</Typography>
              <TextInput value={imageId} onChange={(v) => setImageId(v)} type='number' />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography color='#757575'>ترتیب</Typography>
              <TextInput value={order} onChange={(v) => setOrder(v)} type='number' />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography color='#757575'>{t("panel.common.description")}</Typography>
              <TextInput value={description} onChange={(v) => setDescription(v)} multiline />
            </Grid>
            <Grid container className={classes.buttonBox}>
              <Button
                color='primary'
                variant='contained'
                fullWidth
                disabled={!(title && imageId && description)}
                className={clsx(classes.button, classes.bgBlue)}
                onClick={() =>
                  onSubmit?.({
                    title,
                    imageId,
                    order,
                    description,
                  })
                }
              >
                {t("panel.common.save")}
                <Save />
              </Button>
              {type === "edit" && (
                <Button
                  color='secondary'
                  variant='contained'
                  fullWidth
                  className={clsx(classes.button, classes.bgRed)}
                  onClick={() => id && onRemove?.(id)}
                >
                  {t("panel.common.delete")}
                  <Delete />
                </Button>
              )}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CategoryCardEditable;

const useStyle = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
  imageBox: {
    height: 345,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: 4,
    overflow: "hidden",
    "&:hover $deleteIconBox": {
      display: "block",
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 4,
  },
  padding: {
    padding: "11px 40px 26px",
  },
  wrapper: {
    padding: "10px 0px",
    gap: 10,
    borderBottom: "1px solid #a2d3fa",
    "&:last-of-type": {
      paddingBottom: 45,
      borderBottom: "none",
      "& *": {
        fontSize: 18 * fontNormalizeFactor,
      },
    },
  },
  buttonBox: {
    marginTop: 50,
    gap: 20,
  },
  button: {
    height: 40,
    border: "none",
    borderRadius: 2,
    boxShadow: "0 1px 5px 0 #ccc, 0 2px 3px 0 #ddd",
    "& *": {
      fontSize: 15 * fontNormalizeFactor,
    },
    "& svg": {
      marginLeft: 8,
    },
  },
  bgBlue: {
    // backgroundColor: "#E3F2FD",
  },
  bgRed: {
    // backgroundColor: "rgba(255,0,0,.15)",
  },
  deleteIconBox: {
    display: "none",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.4)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.6) !important",
    },
  },
  deleteIcon: {
    width: 25,
    height: 25,
    color: "white",
  },
}));
