import {
  UsersApi,
  UsersApiUsersDeleteRequest,
  UsersApiUsersListRequest,
  UsersApiUsersReadRequest,
  UsersApiUsersSetStatusRequest,
} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const usersApiInstance = new UsersApi(undefined, undefined, axiosInstance);

export const usersAPI = makeApi({
  identifier: "users",
  Query: {
    getList: createQuery(async (params: UsersApiUsersListRequest) => {
      const {data} = await usersApiInstance.usersList(params);
      return data;
    }),
    readDetail: createQuery(async (params: UsersApiUsersReadRequest) => {
      const {data} = await usersApiInstance.usersRead(params);
      return data;
    }),
  },
  Mutation: {
    changeStatus: async (params: UsersApiUsersSetStatusRequest) => {
      const {data} = await usersApiInstance.usersSetStatus(params);
      return data;
    },
    delete: async (params: UsersApiUsersDeleteRequest) => {
      const {data} = await usersApiInstance.usersDelete(params);
      return data;
    },
  },
});
