import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import Typography from "@elements/Typography";
import {palette} from "@constant/colors";
import {useActionsDrawerContext} from "@context/ActionsDrawerContext";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import Close from "./Close";
import {headerHeightSmall, headerHeightBig} from "@modules/Header/constant";
import {useAuth} from "@context/AuthContext";
import {actionsListBreakpoint} from "@modules/Header";
import {routes} from "@constant/routes";

const ActionsDrawer = () => {
  const classes = useStyle();
  const {setOpenActionsDrawer} = useActionsDrawerContext();
  const {t} = useTranslation("modules.mainLayout");
  const mediumDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(actionsListBreakpoint));
  const {user, logout} = useAuth();
  const {landing} = routes;

  const items = [
    {title: t("actionsDrawer.yourAccount"), to: landing.account, onlyOnSmall: false},
    {title: t("actionsDrawer.yourBoards"), to: landing.boards, onlyOnSmall: true},
    {title: t("actionsDrawer.plans"), to: landing.pricing, onlyOnSmall: true},
  ];

  return (
    <Box component='ul' className={classes.container}>
      <Grid container className={clsx(classes.item, classes.noCursor)}>
        <Close onClick={() => setOpenActionsDrawer(false)} />
      </Grid>
      {user &&
        items.map((item) =>
          !item.onlyOnSmall || (item.onlyOnSmall && mediumDown) ? (
            <Link to={item.to} key={item.title} onClick={() => setOpenActionsDrawer(false)}>
              <Grid container component='li' className={classes.item}>
                <Typography color='inherit'>{item.title}</Typography>
              </Grid>
            </Link>
          ) : null
        )}
      {user && (
        <Grid container component='li' className={classes.item} onClick={() => logout()}>
          <Typography color='inherit'>{t("actionsDrawer.logout")}</Typography>
        </Grid>
      )}
      {!user && (
        <>
          <Link to={routes.landing.login}>
            <Grid container component='li' className={classes.item}>
              <Typography color='inherit'>{t("actionsDrawer.signIn")}</Typography>
            </Grid>
          </Link>
          <Link to={routes.landing.register}>
            <Grid container component='li' className={classes.item}>
              <Typography color='inherit'>{t("actionsDrawer.signUp")}</Typography>
            </Grid>
          </Link>
        </>
      )}
    </Box>
  );
};

export default ActionsDrawer;

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: palette.darkGrey.A600,
  },
  item: {
    width: "100%",
    height: headerHeightBig,
    alignItems: "center",
    justifyContent: "flexStart",
    padding: "0 30px",
    borderBottom: `1px solid ${palette.darkGrey[300]}`,
    cursor: "pointer",
    color: "white",
    "&:hover": {
      color: palette.green[200],
    },
    [theme.breakpoints.down("sm")]: {
      height: headerHeightSmall,
    },
    [theme.breakpoints.down(300)]: {
      padding: "0 18px",
    },
  },
  noCursor: {
    cursor: "auto",
  },
}));
