import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Collapse} from "@material-ui/core";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import clsx from "clsx";

interface IProps {
  title?: string;
  collapsible?: boolean;
  open?: boolean;
}

const DrawerChildrenBox: React.FC<IProps> = (props) => {
  const {children, title, collapsible, open: controlledOpen} = props;
  const [open, setOpen] = useState(controlledOpen ? controlledOpen : !collapsible);
  const classes = useStyle({open});
  return (
    <section className={classes.container}>
      <Box className={classes.innerBox}>
        {title && (
          <Box
            className={clsx(classes.titleBox, collapsible && classes.pointer)}
            onClick={() => collapsible && setOpen((prev) => !prev)}
          >
            <Typography component='header' className={classes.title}>
              {title}
            </Typography>
            {collapsible && (
              <svg className={classes.arrow}>
                <path d='M13.455 1.273L12.182 0 6.727 5.454 1.273 0 0 1.273 6.727 8z' />
              </svg>
            )}
          </Box>
        )}
        <Collapse in={open} timeout={0}>
          {children}
        </Collapse>
      </Box>
    </section>
  );
};

export default DrawerChildrenBox;

const useStyle = makeStyles((theme) => ({
  container: {
    borderTop: `1px solid ${palette.darkGrey.A700}`,
  },
  innerBox: {
    borderTop: `1px solid #4D5454`,
    padding: "2rem 2rem 2rem 4rem",
    [theme.breakpoints.down(320)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
    [theme.breakpoints.down(280)]: {
      padding: "2rem 1rem 2rem 2rem",
    },
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.6rem",
    userSelect: "none",
  },
  pointer: {
    cursor: "pointer",
  },
  title: {
    color: theme.palette.background.paper,
  },
  arrow: ({open}: {open: boolean}) => ({
    fill: open ? theme.palette.background.paper : palette.blue[600],
    transform: open ? "rotate(180deg)" : undefined,
    width: "1.35rem",
    height: ".8rem",
  }),
}));
