import {Button} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {darkGrey, green, lightGrey} from "@constant/colors";
import Typography from "@elements/Typography";

const useStyles = makeStyles(() => ({
  root: {
    color: ({green: isGreen}: {green?: boolean}) => (isGreen ? green[200] : darkGrey[300]),
    // Fixme TDD
    height: "3.96rem",
    padding: "1rem",
    border: `solid ${lightGrey[900]} 1px`,
    borderRadius: 4,
    backgroundColor: lightGrey[200],
    "&:hover": {
      backgroundColor: lightGrey[50],
      color: darkGrey[300],
    },
    "& .MuiButton-label": {
      direction: "rtl",
      lineHeight: "3.5rem",
    },
  },
}));

export interface KeywordButtonProps {
  onClick?: () => void;
  green?: boolean;
  children: React.ReactText;
}

export function KeywordButton({green, onClick: handleClick, children}: KeywordButtonProps) {
  const classes = useStyles({green});
  return (
    <Button className={classes.root} onClick={handleClick}>
      <Typography span noWrap color='inherit' size={1.4}>
        {children}
      </Typography>
    </Button>
  );
}

export default KeywordButton;
