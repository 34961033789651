import {Responsive} from "react-alice-carousel";

export const buttonsResponsiveWithCloseDrawer: Responsive = {
  100: {items: 2},
  330: {items: 3},
  450: {items: 4},
  // carousel width decreases in device with > 600
  600: {items: 3},
  730: {items: 4},
  860: {items: 5},
  1060: {items: 6},
  1260: {items: 7},
  1460: {items: 8},
  1660: {items: 9},
  1860: {items: 10},
  2060: {items: 11},
  2260: {items: 12},
};
export const buttonsResponsiveWithOpenDrawer: Responsive = {
  100: {items: 2},
  330: {items: 3},
  450: {items: 4},
  // carousel width decreases in device with > 600
  600: {items: 3},
  730: {items: 4},
  860: {items: 5},
  // starring md size, when filters drawer is open, carousel with decreases
  960: {items: 3},
  1060: {items: 4},
  1230: {items: 5},
  1410: {items: 6},
  1610: {items: 7},
  1810: {items: 8},
  2010: {items: 9},
  2210: {items: 10},
};
