import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import {darkGrey, yellow} from "@constant/colors";
import {Link} from "react-router-dom";
import Typography from "./Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";

export interface ICardProps {
  error?: string | null;
  children: React.ReactNode;
}

const AuthCard = (props: ICardProps) => {
  const classes = useStyle();
  const {children, error} = props;

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        <Grid container justify='center' className={classes.header}>
          <Link to='/'>
            <img src={data?.logo} alt='پارس ایمیجز' height={65} />
          </Link>
        </Grid>
        {!!error?.length && (
          <Box className={classes.errorBox}>
            <Typography size={1.4} color={darkGrey.A100}>
              {error}
            </Typography>
          </Box>
        )}
        <Box className={classes.cardBody}>{children}</Box>
      </Box>
    </Box>
  );
};

export default AuthCard;

const useStyle = makeStyles((theme) => ({
  container: {
    border: "10px solid rgba(12,13,13,0.25)",
    borderRadius: 10,
  },
  card: {
    width: "100%",
    backgroundColor: "white",
  },
  header: {
    height: "8rem",
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  cardBody: {
    padding: "3rem 3rem 5rem",
    [theme.breakpoints.down(320)]: {
      padding: "3rem 1.5rem 5rem",
    },
  },
  errorBox: {
    backgroundColor: yellow[200],
    lineHeight: "1.8rem",
    padding: "1rem",
    transition: "all .3s ease-out",
    margin: "2rem 3rem 0rem",
    [theme.breakpoints.down(320)]: {
      margin: "2rem 1.5rem 0rem",
    },
  },
}));
