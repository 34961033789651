/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PlanDiscount } from '../servermodel';
/**
 * PlanDiscountByNameApi - axios parameter creator
 * @export
 */
export const PlanDiscountByNameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountByName: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('planDiscountByName', 'name', name)
            const localVarPath = `/plan-discount-by-name/{name}/`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanDiscountByNameApi - functional programming interface
 * @export
 */
export const PlanDiscountByNameApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanDiscountByNameApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planDiscountByName(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDiscount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planDiscountByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanDiscountByNameApi - factory interface
 * @export
 */
export const PlanDiscountByNameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanDiscountByNameApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planDiscountByName(name: string, options?: any): AxiosPromise<PlanDiscount> {
            return localVarFp.planDiscountByName(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for planDiscountByName operation in PlanDiscountByNameApi.
 * @export
 * @interface PlanDiscountByNameApiPlanDiscountByNameRequest
 */
export interface PlanDiscountByNameApiPlanDiscountByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof PlanDiscountByNameApiPlanDiscountByName
     */
    readonly name: string
}

/**
 * PlanDiscountByNameApi - object-oriented interface
 * @export
 * @class PlanDiscountByNameApi
 * @extends {BaseAPI}
 */
export class PlanDiscountByNameApi extends BaseAPI {
    /**
     * 
     * @param {PlanDiscountByNameApiPlanDiscountByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanDiscountByNameApi
     */
    public planDiscountByName(requestParameters: PlanDiscountByNameApiPlanDiscountByNameRequest, options?: any) {
        return PlanDiscountByNameApiFp(this.configuration).planDiscountByName(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }
}
