import React, {useEffect, useState} from "react";
import AliceCarousel, {EventObject, Responsive} from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {Link} from "react-router-dom";

export interface ICarouselProps {
  items: {element: JSX.Element; href?: string}[];
  responsive: Responsive;
  customNextButton: JSX.Element;
  customPrevButton: JSX.Element;
  onClick?: () => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  hiddenButtonsOnDisabled?: boolean;
}

export default function Carousel(props: ICarouselProps) {
  const [active, setActive] = useState(0);
  const [viewportWidth, setViewportWidth] = useState<number | null>(null);
  // with the help of dragging, we can call onClick handler only if user is not dragging the items.
  const dragging = React.useRef(false);
  const classes = useStyle();
  const {
    items,
    responsive,
    onClick,
    customNextButton,
    customPrevButton,
    onDragEnd,
    onDragStart,
    hiddenButtonsOnDisabled,
  } = props;

  const handleSliderChange = () => {
    dragging.current = true;
    onDragStart?.();
  };
  const handleSliderChanged = (event: EventObject) => {
    setActive(event.item);
    dragging.current = false;
    onDragEnd?.();
  };

  useEffect(() => {
    // viewportWidth is necessary to render fakeCards if it's needed.
    // document.body.clientWidth shows the real width - 15px, because of scrollbar width.
    setViewportWidth(document.body.clientWidth + 15);
    const listener = () => setViewportWidth(document.body.clientWidth + 15);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  //
  // choose the right responsive object and generating carousel items
  const itemsArray = items.map((item, index) =>
    "href" in item ? (
      <Box
        key={index.toString()}
        className={classes.cardBox}
        onMouseDown={(e) => {
          if (e.preventDefault) {
            e.preventDefault();
          }
        }}
      >
        <Link to={item.href || "#"} onClick={(e) => dragging.current && e.preventDefault()}>
          {item.element}
        </Link>
      </Box>
    ) : (
      <Box
        className={classes.cardBox}
        onMouseDown={(e) => {
          if (e.preventDefault) {
            e.preventDefault();
          }
        }}
        key={index.toString()}
        // eslint-disable-next-line consistent-return
        onClick={(e) => dragging.current && onClick?.()}
      >
        {item.element}
      </Box>
    )
  );

  //
  // GENERATING FAKE CARDS if there is not enough data in items (to prevent other buttons to grow horizontally)
  const breakpoints = Object.keys(responsive);
  const currentBreakpoint = breakpoints.reverse().find((item) => +item < (viewportWidth || 1000))!;
  // cardsNumber is the number that in current viewport width, MUST render on the screen
  const cardsNumber = responsive[currentBreakpoint]!.items;
  const hasEnoughData = itemsArray.length >= cardsNumber;
  if (!hasEnoughData) {
    const diff = cardsNumber - itemsArray.length;
    for (let a = 0; a < diff; a++) {
      itemsArray.push(<Box className={classes.cardBox} />);
    }
  }

  // making the carousel direction 'rtl' and calculate the correct active item to initialize
  // this useEffect is needed because, before executing the top useEffect to initialize the viewportWidth
  // cardsNumber is calculated base on our viewportWidth's default value (means 1000) and this leads
  // to wrong result in calculating the correct active item.
  useEffect(() => {
    setActive(items.length - cardsNumber);
  }, [cardsNumber, items.length]);

  //
  // RENDERING CUSTOM BUTTONS
  const onRenderNextButton = (isDisabled: boolean) => (
    <Box
      className={
        isDisabled ? (hiddenButtonsOnDisabled ? classes.hidden : classes.disabled) : undefined
      }
    >
      {customNextButton}
    </Box>
  );
  const onRenderPrevButton = (isDisabled: boolean) => (
    <Box
      className={
        isDisabled ? (hiddenButtonsOnDisabled ? classes.hidden : classes.disabled) : undefined
      }
    >
      {customPrevButton}
    </Box>
  );

  //
  // JSX
  return (
    <Box className={classes.root}>
      <Box className={classes.carouselContainer}>
        <AliceCarousel
          disableDotsControls
          mouseTracking
          activeIndex={active}
          renderNextButton={({isDisabled}) => onRenderNextButton(isDisabled)}
          renderPrevButton={({isDisabled}) => onRenderPrevButton(isDisabled)}
          items={itemsArray.reverse()}
          responsive={responsive}
          onSlideChange={handleSliderChange}
          onSlideChanged={handleSliderChanged}
        />
      </Box>
      <Box className={classes.originalButtonsCover} />
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  root: () => ({
    width: "calc(100% + .8rem)",
    transform: "translateX(-.4rem)",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginBottom: "-2.5rem", // this style hides the extra height which original buttons container has
  }),
  carouselContainer: {
    width: "100%",
  },
  cardBox: {
    userSelect: "none",
    userDrag: "none",
    margin: "0 .4rem",
  },
  // the original buttons of carousel remain still clickable, although they're not appear on the screen
  // also if we change the related global class name to hide them, the custom buttons will be hidden too
  // so I used a container to cover them.
  originalButtonsCover: {
    backgroundColor: "transparent",
    width: "100%",
    height: "3rem",
    position: "absolute",
    bottom: 0,
    zIndex: 100,
  },
  carouselButton: {
    width: "100%",
    height: "4rem",
  },
  fontSize: {
    fontSize: "1.6rem",
  },
  hidden: {
    display: "none",
  },
  disabled: {
    pointerEvents: "none",
    "& button, & button:hover": {
      backgroundColor: "#bbb",
    },
  },
}));
