import {
  AuthApi,
  AuthApiAuthLoginCreateRequest,
  AuthApiAuthPasswordChangeCreateRequest,
  AuthApiAuthPasswordResetConfirmCreateRequest,
  AuthApiAuthPasswordResetCreateRequest,
  AuthApiAuthPasswordResetOtpConfirmCreateRequest,
  AuthApiAuthSignupCreateRequest,
  AuthApiAuthSignupOtpConfirmCreateRequest,
  AuthApiAuthSignupOtpCreateRequest,
  AuthApiAuthUserPartialUpdateRequest,
} from "@schema/api";
import { axiosInstance } from "services/axios/axios";
import { createQuery, makeApi } from "./types";
import Cookie from "js-cookie";

const authApiInstance = new AuthApi(undefined, undefined, axiosInstance);

export const authAPI = makeApi({
  identifier: "auth",
  Query: {
    getUser: createQuery(async () => {
      const { data } = await authApiInstance.authUserRead();
      return data;
    }),
  },
  Mutation: {
    login: async (params: AuthApiAuthLoginCreateRequest) => {
      const {
        data,
        headers: { token, "token-age": tokenAge },
      } = await authApiInstance.authLoginCreate(params);
      Cookie.set("csrftoken", token, { expires: tokenAge / (60 * 60 * 24) });
      return data;
    },
    register: async (params: AuthApiAuthSignupCreateRequest) => {
      const {
        data,
        headers: { token, "token-age": tokenAge },
      } = await authApiInstance.authSignupCreate(params);
      Cookie.set("csrftoken", token, { expires: tokenAge / (60 * 60 * 24) });
      return data;
    },
    otp: async (params: AuthApiAuthSignupOtpCreateRequest) => {
      const { data } = await authApiInstance.authSignupOtpCreate(params);
      return data;
    },
    otpConfirm: async (params: AuthApiAuthSignupOtpConfirmCreateRequest) => {
      const { data } = await authApiInstance.authSignupOtpConfirmCreate(params);
      return data;
    },
    passwordReset: async (params: AuthApiAuthPasswordResetCreateRequest) => {
      const { data } = await authApiInstance.authPasswordResetCreate(params);
      return data;
    },
    passwordResetOtpConfirm: async (params: AuthApiAuthPasswordResetOtpConfirmCreateRequest) => {
      const { data } = await authApiInstance.authPasswordResetOtpConfirmCreate(params);
      return data;
    },
    passwordResetConfirm: async (params: AuthApiAuthPasswordResetConfirmCreateRequest) => {
      const { data } = await authApiInstance.authPasswordResetConfirmCreate(params);
      return data;
    },
    passwordChange: async (params: AuthApiAuthPasswordChangeCreateRequest) => {
      const { data } = await authApiInstance.authPasswordChangeCreate(params);
      return data;
    },
    getUser: async () => {
      const { data } = await authApiInstance.authUserRead();
      return data;
    },
    logout: async () => {
      const { data } = await authApiInstance.authLogoutCreate();
      return data;
    },
    update: async (params: AuthApiAuthUserPartialUpdateRequest) => {
      const { data } = await authApiInstance.authUserPartialUpdate(params);
      return data;
    },
  },
});
