import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {Box, TextField, Chip} from "@material-ui/core";
import {fontNormalizeFactor} from "@elements/Typography/constant";

interface IAutoCompleteTextFieldProps {
  label: string;
  placeholder?: string;
  options: string[];
  onChange?: (value: string[]) => void;
  onChangeInputValue?: (value: string) => void;
  value?: string[];
}

export default function AutoCompleteTextField(props: IAutoCompleteTextFieldProps) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const {label, placeholder, options, onChange, onChangeInputValue, value} = props;

  return (
    <Box className={classes.container}>
      <Autocomplete
        onChange={(_, value) => onChange?.(value)}
        value={value}
        multiple
        disableCloseOnSelect
        filterSelectedOptions
        classes={{option: classes.option, tag: classes.tag, paper: classes.paper}}
        options={options.map((option) => option)}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option}
              variant='outlined'
              label={option}
              {...getTagProps({index})}
              classes={{deleteIcon: classes.deleteIcon}}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              onChangeInputValue?.(e.target.value);
            }}
            variant='outlined'
            {...params}
            label={label}
            placeholder={placeholder}
            className={classes.input}
          />
        )}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflowY: "scroll",
    maxHeight: 410,
    paddingTop: 10,
    marginTop: -10,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },

  paper: {
    bottom: 0,
    padding: "4px",
    boxShadow: "0 2px 3px 0 #888, 0 1px 3px 0 #777",
    "& *::-webkit-scrollbar": {
      width: 10,
      backgroundColor: theme.palette.grey[200],
    },
    "& *::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[800],
      border: `solid 2px ${theme.palette.grey[200]}`,
      borderRadius: 8,
    },
  },
  option: {
    fontSize: 16 * fontNormalizeFactor,
    direction: "ltr",
    textAlign: "left",
    padding: "2px 15px ",
    cursor: "pointer",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  tag: {
    color: "white",
    border: "none",
    backgroundColor: theme.palette.primary.dark,
    maxWidth: "15vw",
  },
  deleteIcon: {
    "& *": {
      color: "white",
    },
  },
  input: {
    "& *": {
      fontSize: 16 * fontNormalizeFactor,
    },
  },
}));
