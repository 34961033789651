import React, {useEffect, useState} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {InputBase, Grid, Box, useMediaQuery, IconButton} from "@material-ui/core";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "./Typography/constant";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import clsx from "clsx";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import ScrollTopButton from "./ScrollTopButton";
import {CommonProps} from "@type/global";

export interface IPaginationProps extends CommonProps {
  count: number;
  pageSize: number;
  withScrollToTopButton?: boolean;
}

const Pagination = (props: IPaginationProps) => {
  const classes = useStyle();
  const {count, pageSize, withScrollToTopButton = true, className, style} = props;
  const mobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down(650));

  const availablePages = Math.ceil(count / pageSize);
  const {page: _page} = useGetQueryString();
  const page = _page ? Number(_page) : 1;
  const [pageState, setPageState] = useState(String(page));

  useEffect(() => {
    setPageState(String(page));
  }, [page]);

  const handleUpdateQueryString = useUpdateQueryString();
  const handleNext = () => {
    handleUpdateQueryString({page: String(page + 1)});
  };
  const handlePrev = () => {
    handleUpdateQueryString({page: String(page - 1)});
  };

  if (count <= pageSize) {
    return null;
  }

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={clsx(classes.container, className)}
      style={style}
    >
      <IconButton
        className={clsx(classes.button, classes.next)}
        onClick={handleNext}
        disabled={availablePages <= 1 || page === availablePages}
      >
        <ChevronRight />
        {!mobileSize && (
          <Typography span color='inherit'>
            بعدی
          </Typography>
        )}
      </IconButton>
      <Box>
        <InputBase
          type='number'
          className={classes.input}
          value={pageState}
          onChange={(e) => {
            setPageState(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (Number(pageState) > availablePages) {
                return;
              }
              handleUpdateQueryString({page: pageState});
            }
          }}
        />
        <Typography span size={1.6} weight={500} color='#666e6e'>
          از {!!availablePages ? availablePages : " "}
        </Typography>
      </Box>
      <IconButton
        className={clsx(classes.button, classes.prev)}
        onClick={handlePrev}
        disabled={page < 2}
      >
        {!mobileSize && (
          <Typography span color='inherit'>
            قبلی
          </Typography>
        )}
        <ChevronLeft />
      </IconButton>
      {withScrollToTopButton && <ScrollTopButton className={classes.scrollButton} />}
    </Grid>
  );
};

export default Pagination;

const useStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
    gap: 50,
    [theme.breakpoints.down(650)]: {
      gap: 30,
      "& button": {
        width: 50,
        height: 50,
      },
    },
    [theme.breakpoints.down(450)]: {
      gap: 15,
      "& button": {
        width: 40,
        height: 40,
      },
    },
    [theme.breakpoints.down(350)]: {
      gap: 10,
      "& button": {
        width: 30,
      },
    },
  },
  button: {
    border: "1px solid #ccd3d3",
    borderRadius: 4,
    backgroundColor: "white",
    transition: ".3s ease-in-out",
    color: "#666e6e",
    width: 155,
    height: 60,
    "&:hover": {
      color: "black",
      boxShadow: "inset 0 0 0 3px #E8EDED",
      backgroundColor: "white",
    },
    "& svg": {
      fontSize: 20 * fontNormalizeFactor,
    },
    "& *": {
      fontSize: 16 * fontNormalizeFactor,
      fontWeight: 500,
    },
  },
  prev: {
    paddingLeft: 20,
    [theme.breakpoints.down(650)]: {
      padding: 0,
    },
  },
  next: {
    paddingRight: 20,
    [theme.breakpoints.down(650)]: {
      padding: 0,
    },
  },
  input: {
    backgroundColor: "white",
    fontSize: 16 * fontNormalizeFactor,
    padding: 4,
    width: 40,
    height: 40,
    borderRadius: 4,
    border: "1px solid #ccd3d3",
    marginRight: 5,
    "& *": {
      textAlign: "center",
    },
  },
  scrollButton: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
}));
