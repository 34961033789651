import React from "react";
import {Box, makeStyles} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {routes} from "@constant/routes";
import {images} from "@constant/images";
import {Link} from "react-router-dom";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {Helmet} from "react-helmet";

export default function PromoCode() {
  const {t} = useTranslation("modules.pages.promoCode");
  const classes = useStyles();
  const serverErrorNotify = useServerErrorNotify();

  const {data} = useExperimentalQuery("promoCode", "getList", {
    variables: {
      isPublic: "true",
    },
    onError: (error) => serverErrorNotify(error),
  });

  return (
    <>
      <Helmet>
        <title>کد تخفیف - پارس ایمیجز</title>
        <meta name='title' content='کد تخفیف - پارس ایمیجز' />
      </Helmet>
      <Box className={classes.container}>
        <Box className={classes.maxWidth}>
          <Typography component='h1' size={2.8} color='#fff' className={classes.title}>
            {t("title")}
          </Typography>
          <Box className={classes.description}>
            <Typography color='#fff' size={1.8}>
              {t("description")}
            </Typography>
          </Box>
          <Box className={classes.codeBox}>
            {data?.results.map(({name, value, percentage}, index) => (
              <Box key={index.toString()} style={{marginTop: index > 0 ? "2rem" : 0}}>
                <Typography color='#fff' weight={700} size={2.6}>
                  {t("percentageOff", {content: percentage})}
                </Typography>
                <Typography size={1.8} color='#fff'>
                  {t("withPromoCode", {content: name})}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box>
            <Link to={routes.landing.pricing} className={classes.goToPricing}>
              <Typography color='#fff'>{t("showPricing")}</Typography>
            </Link>
          </Box>
        </Box>
        <Box
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#CCA19C",
            backgroundImage: `url(${images.base.promoCodePageWallpaper})`,
            backgroundSize: "cover",
            backgroundPosition: "center 5%",
            filter: "brightness(80%)",
          }}
        />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    paddingBottom: "15rem",

    "& > *": {
      color: "#fff",
      textAlign: "center",
    },
  },
  maxWidth: {
    position: "relative",
    zIndex: 2,
    maxWidth: "108.6rem",
    margin: "0 auto",
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down(270)]: {
      padding: "0 .75rem",
    },
  },
  title: {
    padding: "37px 1.5625% 30px",
    width: "100%",
    float: "left",
  },
  description: {
    padding: "0 1.5625% 2.5rem",
    width: "100%",
    float: "left",
  },
  codeBox: {
    backgroundColor: "hsla(0,0%,100%,.2)",
    borderRadius: "5px",
    margin: "0 auto",
    padding: "20px",
    width: "100%",
    maxWidth: "35rem",
    "& > *:first-child": {
      lineHeight: "6rem",
    },
    "& > *:nth-child(2)": {
      lineHeight: "3rem",
    },
  },
  goToPricing: {
    backgroundColor: "transparent",
    border: "1px solid #f4f6f6",
    borderRadius: 4,
    boxShadow: "inset 0 0 2px 0 hsl(0deg 0% 100% / 50%)",
    cursor: "pointer",
    display: "inline-block",
    margin: "30px 0",
    padding: "12px 24px",
    transition: "all .15s linear",
    "&:hover": {
      backgroundColor: "rgba(12,13,13,.4)",
    },
  },
}));
