import i18n from "i18next";
import elements from "./fa/elements.json";
import {initReactI18next} from "react-i18next";
import {pages} from "./fa/pages";
import {modules} from "./fa/modules";
import moment from "moment-jalaali";

moment.locale("fa");
moment.loadPersian({dialect: "persian-modern"});

const defaultJalaaliFormat = "jYYYY/jMM/jDD"; // Not sure of this, just making the structure available

export const resources = {
  fa: {
    ...pages,
    ...modules,
    elements,
    test: {
      a: {
        clear: "حذف فیلتر",
      },
    },
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "fa",
  ns: Object.keys(resources.fa),
  resources,
  interpolation: {
    format: (value, format) => (value instanceof Date ? moment(value).format(format || defaultJalaaliFormat) : value),
  },
});
