import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, BoxProps} from "@material-ui/core";
import clsx from "clsx";

export interface IBoxWithWallpaperProps extends Omit<BoxProps, "color"> {
  color: string;
}

const BoxWithWallpaper = (props: IBoxWithWallpaperProps) => {
  const classes = useStyle(props);
  const {children, className, ...rest} = props;

  return (
    <Box className={clsx(classes.container, className)} {...rest}>
      <Box className={classes.zIndex}>{children}</Box>
      <Box className={classes.wallpaper} />
    </Box>
  );
};

export default BoxWithWallpaper;

const useStyle = makeStyles(() => ({
  container: {
    position: "relative",
  },
  zIndex: {
    position: "relative",
    zIndex: 1,
  },
  wallpaper: ({color}: {color: string}) => ({
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: color,
    transform: "scaleX(5)",
  }),
}));
