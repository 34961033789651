import {green} from "@constant/colors";
import EditorContentWrapper from "@elements/EditorContentWrapper";
import Spinner from "@elements/Spinner";
import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import MetaTags from "@modules/pages/blog/[id]/MetaTags";
import SocialMedia from "@modules/pages/blog/[id]/SocialMedia";
import {formatDate} from "@util/global";
import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

const BlogPostDetail = () => {
  const classes = useStyle();
  const {t} = useTranslation("pages.blog.id");
  const {id} = useParams<{id: string}>();
  const serverErrorNotify = useServerErrorNotify();
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(500));

  const {data, isLoading} = useExperimentalQuery("blog", "readDetail", {
    variables: {id: Number(id.split("=").pop())},
    staleTime: Infinity,
    onError: (error) => serverErrorNotify(error),
  });

  return (
    <>
      <MetaTags data={data} />
      <Box className={classes.container}>
        {isLoading && <Spinner />}
        {data && (
          <>
            <Typography size={xxsDown ? 2.2 : 2.8} weight={700} className={classes.title}>
              {data.title}
            </Typography>
            <Grid
              container
              justify='space-between'
              alignItems='flex-end'
              className={classes.fullWidth}
            >
              <Box>
                <Typography span>
                  {formatDate(new Date(data.created!).toLocaleDateString())}
                </Typography>
                <Typography span height={1.2} className={classes.separator} size={4}>
                  .
                </Typography>
                <Typography span height={1.2}>{`${data.min_to_read} ${t("minute")}`}</Typography>
              </Box>
              <span />
            </Grid>
            <EditorContentWrapper content={data.content} />
          </>
        )}
      </Box>
    </>
  );
};

export default BlogPostDetail;

const useStyle = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    padding: "5rem 3rem 12rem",
    [theme.breakpoints.down("xs")]: {
      padding: "4rem 1.5rem 7rem",
    },
  },
  title: {
    paddingLeft: "2rem",
    borderLeft: `10px solid ${green[300]}`,
  },
  separator: {
    display: "inline-block",
    padding: "0 .3rem 1.5rem",
  },
  mediaBox: {
    marginBottom: "5rem",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  iframeBox: {
    position: "relative",
    paddingTop: "50%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  fullWidth: {
    width: "100%",
    padding: "2rem 0",
    [theme.breakpoints.down(500)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "1.5rem",
      "& *": {
        gap: "1.2rem",
      },
    },
  },
}));
