/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminRetrieveImage } from '../servermodel';
// @ts-ignore
import { CreateImage } from '../servermodel';
// @ts-ignore
import { InlineResponse2009 } from '../servermodel';
// @ts-ignore
import { ListImage } from '../servermodel';
// @ts-ignore
import { RetrieveImage } from '../servermodel';
// @ts-ignore
import { UpdateImage } from '../servermodel';
/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesAdminRetrieveRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesAdminRetrieveRead', 'id', id)
            const localVarPath = `/images/{id}/admin_retrieve/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesBulkUpdate: async (data: UpdateImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesBulkUpdate', 'data', data)
            const localVarPath = `/images/bulk_update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesCreate: async (data: CreateImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesCreate', 'data', data)
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesDelete', 'id', id)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {object} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDownloadCreate: async (id: number, data: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesDownloadCreate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesDownloadCreate', 'data', data)
            const localVarPath = `/images/{id}/download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDownloadRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesDownloadRead', 'id', id)
            const localVarPath = `/images/{id}/download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesImageByIdcode: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesImageByIdcode', 'id', id)
            const localVarPath = `/images/{id}/image_by_idcode/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesImageByTitle: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesImageByTitle', 'id', id)
            const localVarPath = `/images/{id}/image_by_title/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [owner] 
         * @param {string} [isPremium] 
         * @param {string} [tags] 
         * @param {string} [size] 
         * @param {string} [categories] 
         * @param {string} [collections] 
         * @param {string} [boards] 
         * @param {string} [notPreviwable] 
         * @param {string} [manualEdit] 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [orientation] 
         * @param {string} [type] 
         * @param {string} [color] 
         * @param {string} [similarImages] 
         * @param {string} [greyImages] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesList: async (owner?: string, isPremium?: string, tags?: string, size?: string, categories?: string, collections?: string, boards?: string, notPreviwable?: string, manualEdit?: string, fromDaysBefore?: number, order?: string, orientation?: string, type?: string, color?: string, similarImages?: string, greyImages?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (isPremium !== undefined) {
                localVarQueryParameter['is_premium'] = isPremium;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (collections !== undefined) {
                localVarQueryParameter['collections'] = collections;
            }

            if (boards !== undefined) {
                localVarQueryParameter['boards'] = boards;
            }

            if (notPreviwable !== undefined) {
                localVarQueryParameter['not_previwable'] = notPreviwable;
            }

            if (manualEdit !== undefined) {
                localVarQueryParameter['manual_edit'] = manualEdit;
            }

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orientation !== undefined) {
                localVarQueryParameter['orientation'] = orientation;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (color !== undefined) {
                localVarQueryParameter['color'] = color;
            }

            if (similarImages !== undefined) {
                localVarQueryParameter['similar_images'] = similarImages;
            }

            if (greyImages !== undefined) {
                localVarQueryParameter['grey_images'] = greyImages;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPartialUpdate: async (id: number, data: UpdateImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesPartialUpdate', 'data', data)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesRead', 'id', id)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesSimilarImages: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesSimilarImages', 'id', id)
            const localVarPath = `/images/{id}/similar_images/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesUpdate: async (id: number, data: UpdateImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesUpdate', 'data', data)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesAdminRetrieveRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRetrieveImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesAdminRetrieveRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesBulkUpdate(data: UpdateImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UpdateImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesBulkUpdate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesCreate(data: CreateImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {object} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesDownloadCreate(id: number, data: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesDownloadCreate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesDownloadRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesDownloadRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesImageByIdcode(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesImageByIdcode(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesImageByTitle(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesImageByTitle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [owner] 
         * @param {string} [isPremium] 
         * @param {string} [tags] 
         * @param {string} [size] 
         * @param {string} [categories] 
         * @param {string} [collections] 
         * @param {string} [boards] 
         * @param {string} [notPreviwable] 
         * @param {string} [manualEdit] 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [orientation] 
         * @param {string} [type] 
         * @param {string} [color] 
         * @param {string} [similarImages] 
         * @param {string} [greyImages] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesList(owner?: string, isPremium?: string, tags?: string, size?: string, categories?: string, collections?: string, boards?: string, notPreviwable?: string, manualEdit?: string, fromDaysBefore?: number, order?: string, orientation?: string, type?: string, color?: string, similarImages?: string, greyImages?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesList(owner, isPremium, tags, size, categories, collections, boards, notPreviwable, manualEdit, fromDaysBefore, order, orientation, type, color, similarImages, greyImages, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesPartialUpdate(id: number, data: UpdateImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesSimilarImages(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesSimilarImages(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesUpdate(id: number, data: UpdateImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesAdminRetrieveRead(id: number, options?: any): AxiosPromise<AdminRetrieveImage> {
            return localVarFp.imagesAdminRetrieveRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesBulkUpdate(data: UpdateImage, options?: any): AxiosPromise<Array<UpdateImage>> {
            return localVarFp.imagesBulkUpdate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesCreate(data: CreateImage, options?: any): AxiosPromise<CreateImage> {
            return localVarFp.imagesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.imagesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {object} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDownloadCreate(id: number, data: object, options?: any): AxiosPromise<object> {
            return localVarFp.imagesDownloadCreate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDownloadRead(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.imagesDownloadRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesImageByIdcode(id: number, options?: any): AxiosPromise<RetrieveImage> {
            return localVarFp.imagesImageByIdcode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesImageByTitle(id: number, options?: any): AxiosPromise<RetrieveImage> {
            return localVarFp.imagesImageByTitle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [owner] 
         * @param {string} [isPremium] 
         * @param {string} [tags] 
         * @param {string} [size] 
         * @param {string} [categories] 
         * @param {string} [collections] 
         * @param {string} [boards] 
         * @param {string} [notPreviwable] 
         * @param {string} [manualEdit] 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [orientation] 
         * @param {string} [type] 
         * @param {string} [color] 
         * @param {string} [similarImages] 
         * @param {string} [greyImages] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesList(owner?: string, isPremium?: string, tags?: string, size?: string, categories?: string, collections?: string, boards?: string, notPreviwable?: string, manualEdit?: string, fromDaysBefore?: number, order?: string, orientation?: string, type?: string, color?: string, similarImages?: string, greyImages?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.imagesList(owner, isPremium, tags, size, categories, collections, boards, notPreviwable, manualEdit, fromDaysBefore, order, orientation, type, color, similarImages, greyImages, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPartialUpdate(id: number, data: UpdateImage, options?: any): AxiosPromise<UpdateImage> {
            return localVarFp.imagesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesRead(id: number, options?: any): AxiosPromise<RetrieveImage> {
            return localVarFp.imagesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesSimilarImages(id: number, options?: any): AxiosPromise<Array<ListImage>> {
            return localVarFp.imagesSimilarImages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this image.
         * @param {UpdateImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesUpdate(id: number, data: UpdateImage, options?: any): AxiosPromise<UpdateImage> {
            return localVarFp.imagesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for imagesAdminRetrieveRead operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesAdminRetrieveReadRequest
 */
export interface ImagesApiImagesAdminRetrieveReadRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesAdminRetrieveRead
     */
    readonly id: number
}

/**
 * Request parameters for imagesBulkUpdate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesBulkUpdateRequest
 */
export interface ImagesApiImagesBulkUpdateRequest {
    /**
     * 
     * @type {UpdateImage}
     * @memberof ImagesApiImagesBulkUpdate
     */
    readonly data: UpdateImage
}

/**
 * Request parameters for imagesCreate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesCreateRequest
 */
export interface ImagesApiImagesCreateRequest {
    /**
     * 
     * @type {CreateImage}
     * @memberof ImagesApiImagesCreate
     */
    readonly data: CreateImage
}

/**
 * Request parameters for imagesDelete operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesDeleteRequest
 */
export interface ImagesApiImagesDeleteRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesDelete
     */
    readonly id: number
}

/**
 * Request parameters for imagesDownloadCreate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesDownloadCreateRequest
 */
export interface ImagesApiImagesDownloadCreateRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesDownloadCreate
     */
    readonly id: number

    /**
     * 
     * @type {object}
     * @memberof ImagesApiImagesDownloadCreate
     */
    readonly data: object
}

/**
 * Request parameters for imagesDownloadRead operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesDownloadReadRequest
 */
export interface ImagesApiImagesDownloadReadRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesDownloadRead
     */
    readonly id: number
}

/**
 * Request parameters for imagesImageByIdcode operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesImageByIdcodeRequest
 */
export interface ImagesApiImagesImageByIdcodeRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesImageByIdcode
     */
    readonly id: number
}

/**
 * Request parameters for imagesImageByTitle operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesImageByTitleRequest
 */
export interface ImagesApiImagesImageByTitleRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesImageByTitle
     */
    readonly id: number
}

/**
 * Request parameters for imagesList operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesListRequest
 */
export interface ImagesApiImagesListRequest {
    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly owner?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly isPremium?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly tags?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly size?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly categories?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly collections?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly boards?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly notPreviwable?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly manualEdit?: string

    /**
     * 
     * @type {number}
     * @memberof ImagesApiImagesList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly orientation?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly color?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly similarImages?: string

    /**
     * 
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly greyImages?: string

    /**
     * A search term.
     * @type {string}
     * @memberof ImagesApiImagesList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ImagesApiImagesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ImagesApiImagesList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for imagesPartialUpdate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesPartialUpdateRequest
 */
export interface ImagesApiImagesPartialUpdateRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateImage}
     * @memberof ImagesApiImagesPartialUpdate
     */
    readonly data: UpdateImage
}

/**
 * Request parameters for imagesRead operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesReadRequest
 */
export interface ImagesApiImagesReadRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesRead
     */
    readonly id: number
}

/**
 * Request parameters for imagesSimilarImages operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesSimilarImagesRequest
 */
export interface ImagesApiImagesSimilarImagesRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesSimilarImages
     */
    readonly id: number
}

/**
 * Request parameters for imagesUpdate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesUpdateRequest
 */
export interface ImagesApiImagesUpdateRequest {
    /**
     * A unique integer value identifying this image.
     * @type {number}
     * @memberof ImagesApiImagesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateImage}
     * @memberof ImagesApiImagesUpdate
     */
    readonly data: UpdateImage
}

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @param {ImagesApiImagesAdminRetrieveReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesAdminRetrieveRead(requestParameters: ImagesApiImagesAdminRetrieveReadRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesAdminRetrieveRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesBulkUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesBulkUpdate(requestParameters: ImagesApiImagesBulkUpdateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesBulkUpdate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesCreate(requestParameters: ImagesApiImagesCreateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesDelete(requestParameters: ImagesApiImagesDeleteRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesDownloadCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesDownloadCreate(requestParameters: ImagesApiImagesDownloadCreateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesDownloadCreate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesDownloadReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesDownloadRead(requestParameters: ImagesApiImagesDownloadReadRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesDownloadRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesImageByIdcodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesImageByIdcode(requestParameters: ImagesApiImagesImageByIdcodeRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesImageByIdcode(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesImageByTitleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesImageByTitle(requestParameters: ImagesApiImagesImageByTitleRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesImageByTitle(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesList(requestParameters: ImagesApiImagesListRequest = {}, options?: any) {
        return ImagesApiFp(this.configuration).imagesList(requestParameters.owner, requestParameters.isPremium, requestParameters.tags, requestParameters.size, requestParameters.categories, requestParameters.collections, requestParameters.boards, requestParameters.notPreviwable, requestParameters.manualEdit, requestParameters.fromDaysBefore, requestParameters.order, requestParameters.orientation, requestParameters.type, requestParameters.color, requestParameters.similarImages, requestParameters.greyImages, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesPartialUpdate(requestParameters: ImagesApiImagesPartialUpdateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesRead(requestParameters: ImagesApiImagesReadRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesSimilarImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesSimilarImages(requestParameters: ImagesApiImagesSimilarImagesRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesSimilarImages(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesUpdate(requestParameters: ImagesApiImagesUpdateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
