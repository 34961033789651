import React from "react";
import {Box, Theme, Grid, makeStyles, useMediaQuery} from "@material-ui/core";
import GreenLink from "@elements/GreenLink";
import {palette} from "@constant/colors";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import EditorContentWrapper from "@elements/EditorContentWrapper";
import CollapseGroup from "@elements/CollapseGroup";
import {Envelop} from "components/svg";
import Spinner from "@elements/Spinner";
import {routes} from "@constant/routes";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {Helmet} from "react-helmet";

export default function Faq() {
  const {t} = useTranslation("pages.faq");
  const classes = useStyles();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const serverErrorNotify = useServerErrorNotify();

  const {data, isLoading} = useExperimentalQuery("faqs", "getList", {
    variables: {
      pageSize: 100,
    },
    staleTime: Infinity,
    onError: (error) => serverErrorNotify(error),
  });

  return (
    <>
      <Helmet>
        <title>سوالات متداول - پارس ایمیجز</title>
        <meta name='title' content='سوالات متداول - پارس ایمیجز' />
      </Helmet>
      <BoxWithWallpaper color={palette.lightGrey[500]} className={classes.container}>
        <Grid container direction='column' alignItems='center' className={classes.maxWidth}>
          <Typography size={xsDown ? 2.5 : 3} weight={500} className={classes.title}>
            {t("frequentlyAskedQuestions")}
          </Typography>
          {isLoading && <Spinner />}
          <Box>
            {data && (
              <CollapseGroup
                items={data?.results
                  .slice()
                  .sort((a, b) => (a.priority || 0) - (b.priority || 0))
                  .map(({title, content}) => ({
                    title,
                    value: <EditorContentWrapper content={content} />,
                  }))}
              />
            )}
          </Box>
          <Grid container direction='column' alignItems='center' style={{marginTop: "4rem"}}>
            <Typography size={xsDown ? 2 : 2.5}>{t("haveMoreQuestions")}</Typography>
            <Grid container justify='center' alignItems='center' className={classes.contactUsBox}>
              <Envelop />
              <GreenLink size={1.8} to={routes.landing.contactUs}>
                {t("contactUs")}
              </GreenLink>
            </Grid>
          </Grid>
        </Grid>
      </BoxWithWallpaper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "1rem",
    paddingBottom: "10rem",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "5rem",
    },
  },
  maxWidth: {
    maxWidth: "108.6rem",
    margin: "0 auto",
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down(270)]: {
      padding: "0 .75rem",
    },
  },
  title: {
    margin: "3rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0 3rem",
    },
  },
  contactUsBox: {
    marginTop: "1.5rem",
    gap: ".25rem",
    "& svg": {
      transform: "scale(.7)",
    },
  },
}));
