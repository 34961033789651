import {images} from "@constant/images";
import Typography from "@elements/Typography";
import {CardMedia, Button, Grid} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {darkGrey, lightGrey} from "@constant/colors";

const useStyles = makeStyles(() => ({
  root: {
    height: "3.2rem",
    minWidth: "3.6rem",
    maxWidth: "24.3rem",
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    borderRadius: 4,
    borderColor: `1px solid ${lightGrey.A300}`,
    backgroundColor: lightGrey[200],
    "&:hover": {
      //FIXME not in palette
      backgroundColor: "#eee",
    },
  },
  gapX1_6r: {
    columnGap: "1.6rem",
  },
  icon: {
    width: "0.8rem",
    height: "0.8rem",
  },
}));

export interface FilterButtonProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactText;
}

export function FilterButton({children, onClick: handleClick, className}: FilterButtonProps) {
  const classes = useStyles();
  return (
    <Button variant='contained' className={clsx([classes.root, className || ""])} onClick={handleClick}>
      <Grid container justify='space-between' alignItems='center' className={classes.gapX1_6r}>
        <Grid item>
          <CardMedia component='img' src={images.icons.about} alt='close' className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography color={darkGrey.A200} size={1.2}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
}

export default FilterButton;
