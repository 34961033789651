import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

export interface IWallpaperProps {
  image: string;
  backgroundColor?: string;
}

const Wallpaper = ({backgroundColor, ...props}: IWallpaperProps) => {
  const classes = useStyle(props);

  return <Box className={classes.wallpaper} style={{backgroundColor: backgroundColor}} />;
};

export default Wallpaper;

const useStyle = makeStyles(() => ({
  wallpaper: ({image}: IWallpaperProps) => ({
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundImage: `url(${image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    zIndex: -1,
  }),
}));
