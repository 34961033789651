import React from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";

import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import BlogCard from "@modules/Blog/BlogCard";
import {routes} from "@constant/routes";
import Spinner from "@elements/Spinner";

const Blog = () => {
  const classes = useStyle();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const serverErrorNotify = useServerErrorNotify();

  const {data, isLoading} = useExperimentalQuery("blog", "getList", {
    variables: {
      pageSize: 20,
    },
    staleTime: Infinity,
    onError: (error) => serverErrorNotify(error),
  });

  return (
    <>
      <Helmet>
        <title>پارس ایمیجز | بلاگ</title>
        <meta name='title' content='بلاگ | پارس ایمیجز' />
      </Helmet>
      <Box className={classes.container}>
        <Box className={classes.maxWidth}>
          <Typography size={xsDown ? 2.2 : 2.8} align='center' className={classes.title}>
            آخرین مطالب پارس‌ ایمیجز
          </Typography>
          {isLoading && <Spinner />}
          <Grid container wrap='wrap' justify='center' alignItems='stretch'>
            {data?.results.map(({title, id, short_description, image}) => (
              <Grid item xs={12} sm={6} md={4} className={classes.itemBox}>
                <Link
                  to={routes.landing.blogDetail(title.split(" ").join("-").concat(`-id=${id}`))}
                >
                  <BlogCard src={image || ""} title={title} body={short_description || ""} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Blog;

const useStyle = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
  },
  maxWidth: {
    maxWidth: "106rem",
    padding: "0 1.5rem 10rem",
    margin: "0 auto",
    [theme.breakpoints.down(400)]: {
      padding: "0 1rem 8rem",
    },
  },
  title: {
    padding: "3.7rem 0 3rem",
  },
  itemBox: {
    padding: "1.5rem",
    margin: "0 auto",
    [theme.breakpoints.down(400)]: {
      padding: "1rem .5rem",
    },
  },
}));
