import {makeStyles} from "@material-ui/core/styles";
import {Grid, IconButton} from "@material-ui/core";
import Pagination from "@elements/panel/Pagination";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {Check, Info} from "@material-ui/icons";
import Table from "@elements/panel/Table";
import {useTranslation} from "react-i18next";
import Dropdown from "@elements/panel/Dropdown";
import {formatDate} from "@util/global";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import Typography from "@elements/Typography";
import Spinner from "@elements/Spinner";
import MessageDetailModal from "@elements/panel/modals/MessageDetailModal";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInput from "@elements/panel/QuerySearchInput";
import useModalState from "@hook/useModalState";
import DropdownFromDaysBefore from "@elements/panel/DropdownFromDaysBefore";

const Messages = () => {
  const [modalState, setModalState] = useModalState();
  const {page, pageSize, search, isSeen, fromDaysBefore} = useGetQueryString();
  const classes = useStyle();
  const {t} = useTranslation("pages.panel");

  const handleUpdateQueryString = useUpdateQueryString();

  const {data} = useExperimentalQuery("messages", "getList", {
    variables: {
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : 20,
      search,
      fromDaysBefore: fromDaysBefore ? Number(fromDaysBefore) : undefined,
      isSeen,
    },
  });

  return (
    <>
      <Grid container direction='column' className={classes.container}>
        <Grid container justify='space-between' className={classes.inputsBox}>
          <Grid container alignItems='center' className={classes.borderLeft}>
            <QuerySearchInput />
          </Grid>
          <Grid container justify='flex-end' className={classes.gap}>
            <DropdownFromDaysBefore />
            <Dropdown
              style={{width: 200, flexShrink: 0}}
              fullWidth
              label={t("messages.readStatus")}
              items={[
                {label: t("common.all"), value: ""},
                {label: t("messages.hasBeenRead"), value: "true"},
                {label: t("messages.hasNotBeenRead"), value: "false"},
              ]}
              value={isSeen}
              onChange={(v) => handleUpdateQueryString({isSeen: v})}
            />
          </Grid>
        </Grid>
        {!data && <Spinner />}
        <Grid item>
          <Grid className={classes.messagesBox}>
            {data?.results.length === 0 && <NoResult />}
            {!!data?.results.length && (
              <Table
                title={t("messages.title")}
                firstRowTitles={[
                  t("messages.date"),
                  t("messages.time"),
                  t("messages.nameAndFamilyName"),
                  t("messages.content"),
                  t("messages.hasBeenRead"),
                  t("messages.completeInfo"),
                ]}
                rows={(data?.results || []).map((item) => ({
                  id: item.id || 0,
                  data: [
                    formatDate(item.created),
                    item.created?.split("T")[1]?.slice(0, 8) || "",
                    item.name || "",
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <Typography noWrap style={{maxWidth: "27vw"}}>
                        {item.content}
                      </Typography>
                    </div>,
                    item.seen ? <Check htmlColor='seagreen' style={{fontSize: 28}} /> : "-",
                    <IconButton
                      onClick={() => setModalState({visible: true, activeId: item.id || 0})}
                    >
                      <Info style={{fontSize: 24, color: "#888"}} />
                    </IconButton>,
                  ],
                }))}
                rowsSize={[3, 2, 4, 9, 2, 3]}
              />
            )}
          </Grid>
        </Grid>
        <Pagination count={data?.count || 0} />
      </Grid>
      <MessageDetailModal
        open={modalState.visible}
        id={modalState.activeId}
        onClose={() => setModalState({visible: false, activeId: null})}
      />
    </>
  );
};

export default Messages;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  inputsBox: {
    marginBottom: 20,
    height: 56,
    gap: 20,
  },
  borderLeft: {
    borderRight: `solid 1px ${theme.palette.grey[200]}`,
    width: "auto",
  },
  gap: {
    gap: 6,
    maxWidth: 800,
  },
  messagesBox: {
    paddingLeft: 3,
    paddingRight: 3,
  },
}));
