import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import clsx from "clsx";

interface IBackDropProps {
  fixed?: boolean;
  className?: string;
  onClick?: () => void;
}
const BackDrop = (props: IBackDropProps) => {
  const {onClick: handleClick, className, fixed} = props;
  const classes = useStyle({fixed});

  return <Box className={clsx(classes.container, className)} onClick={handleClick} />;
};

export default BackDrop;

const useStyle = makeStyles(() => ({
  container: ({fixed}: {fixed?: boolean}) => ({
    position: fixed ? "fixed" : "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "black",
    opacity: 0.3,
    zIndex: 3000,
  }),
}));
