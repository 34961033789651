import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import GreenLink from "@elements/GreenLink";
import Span from "@elements/Span";
import {green, palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {RetrieveImage, RetrieveImageTypeEnum} from "@schema";
import {routes} from "@constant/routes";
import {localStMediaTypeHistoryGet} from "@modules/SearchBar/utils";

const Details = ({data}: {data: RetrieveImage}) => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.images.id");
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(470));
  const type = localStMediaTypeHistoryGet();

  const imageDetails = [
    {
      name: "dimension",
      title: t("details.dimensions"),
      value:
        data.type === RetrieveImageTypeEnum.Vector
          ? t("details.scalable")
          : `${data.width}x${data.height}`,
    },
    {
      name: "size",
      title: t("details.size"),
      value: data.volume ? `${(+data.volume / (1024 * 1024)).toFixed(2)} مگابایت ` : "-",
    },
    {
      name: "format",
      title: t("details.format"),
      value: data.ext?.toUpperCase(),
      color: data.ext === "jpg" ? green["300"] : data.ext === "eps" ? "#e62e00" : undefined,
    },
    {name: "id", title: t("details.productId"), value: data.id_code},
  ];

  return (
    <Box component='section' className={classes.container}>
      <Grid container wrap='wrap' className={classes.imageDetails}>
        {imageDetails.map((item) => (
          <Grid item key={item.title} xs={xxsDown ? 12 : 6}>
            <Typography span color='#313131' size={1.5} weight={500} className={classes.margin}>
              {item.title}:
            </Typography>
            <Typography span size={1.4} weight={700} color={item.color}>
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Typography span size={1.4} weight={500} className={classes.margin}>
          {t("details.categories")} :
        </Typography>
        {data?.categories &&
          Array.from(data.categories).map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Span style={{margin: "0 3px"}}> | </Span>}
              <GreenLink
                size='small'
                to={`${routes.landing.search}?categories=${item.id}`.concat(
                  type ? `&type=${type}` : ""
                )}
              >
                {item.name}
              </GreenLink>
            </React.Fragment>
          ))}
      </Box>
      <Box style={{marginTop: "1rem"}}>
        <Typography span size={1.4} weight={500} className={classes.margin}>
          کالکشن‌ها :
        </Typography>
        {data?.collections &&
          Array.from(data.collections).map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Span style={{margin: "0 3px"}}> | </Span>}
              <GreenLink size='small' to={`${routes.landing.search}?collections=${item.id}`}>
                {item.name}
              </GreenLink>
            </React.Fragment>
          ))}
      </Box>
    </Box>
  );
};

export default Details;

const useStyle = makeStyles(() => ({
  container: {
    paddingTop: "3rem ",
  },
  artist: {
    gap: "1.3rem",
    marginBottom: "2rem",
  },
  avatar: {
    width: "6.1rem",
    height: "6.1rem",
    borderRadius: "50%",
    border: `1px solid ${palette.lightGrey.A800} `,
  },
  imageDetails: {
    marginBottom: "1.5rem",
  },
  margin: {
    marginRight: ".3rem",
  },
  halfWidth: {
    width: "50%",
  },
}));
