import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {darkGrey} from "@constant/colors";
import Divider from "@elements/Divider";
import {NavLink, Route, Switch} from "react-router-dom";
import DownloadByPlan from "./by-plan";
import DownloadByCash from "./cash";
import {routes} from "@constant/routes";

const Downloads = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const {landing} = routes;

  return (
    <Box className={classes.container}>
      <Typography size={2.8} weight={500} className={classes.title}>
        {t("downloads")}
      </Typography>
      <Grid container direction={xs ? "column" : "row"} className={classes.purchaseTypeBox}>
        <NavLink activeClassName={classes.active} to={`${landing.account}/downloads/by-plan`} exact>
          <Typography size={1.4} color={darkGrey[300]} className={classes.marginBottom}>
            {t("subscriptionDownloads")}
          </Typography>
        </NavLink>
        <NavLink activeClassName={classes.active} to={`${landing.account}/downloads/cash`}>
          <Typography size={1.4} color={darkGrey[300]}>
            {t("cashDownloads")}
          </Typography>
        </NavLink>
      </Grid>
      <Divider className={classes.divider} />
      <Switch>
        <Route path={`${landing.account}/downloads/by-plan`} component={DownloadByPlan} />
        <Route exact path={`${landing.account}/downloads/cash`} component={DownloadByCash} />
      </Switch>
    </Box>
  );
};

export default Downloads;

const useStyle = makeStyles((theme) => ({
  container: {},
  title: {
    marginBottom: "5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  active: {
    "& *": {
      color: darkGrey.A700,
    },
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  purchaseTypeBox: {
    "& > *": {
      cursor: "pointer",
      padding: ".6rem 3rem .6rem 0",
      fontWeight: 500,
    },
  },
}));
