import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, CardMedia, Hidden} from "@material-ui/core";
import {palette} from "@constant/colors";
import {ExpandMore as ExpandMoreIcon, PersonRounded} from "@material-ui/icons";
import {Link} from "react-router-dom";
import BoardsDropdown from "./BoardsDropdown";
import {images} from "@constant/images";
import clsx from "clsx";
import {Span} from "@elements/Span";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useActionsDrawerContext} from "@context/ActionsDrawerContext";
import {useAuth} from "@context/AuthContext";
import {routes} from "@constant/routes";

const ActionsList = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const classes = useStyle({showDropdown});
  const {toggleActionsDrawer} = useActionsDrawerContext();
  const {t} = useTranslation("modules.header");
  const {user} = useAuth();

  const handleCloseDropdown = () => setShowDropdown(false);

  return (
    <Grid container component='ul' justify='flex-end' className={classes.container}>
      <Link to={routes.landing.pricing}>
        <Typography component='li' color='inherit' className={classes.item}>
          {t("actionsList.plans")}
        </Typography>
      </Link>
      <Span className={classes.separator} />
      {user && (
        <Box
          className={classes.boards}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <Box className={classes.boardIconBox}>
            <CardMedia
              component='img'
              src={images.icons.boardsGreen}
              className={clsx(classes.boardIcon, classes.boardIconGreen)}
            />
            <CardMedia component='img' src={images.icons.boards} className={classes.boardIcon} />
          </Box>
          <Typography component='li' className={classes.item}>
            {t("actionsList.boards")}
          </Typography>
          <ExpandMoreIcon className={classes.expandMoreIcon} />
          <Box
            className={clsx(
              classes.dropdownBoxHidden,
              showDropdown ? classes.dropdownBoxVisible : undefined
            )}
          >
            <BoardsDropdown onClick={handleCloseDropdown} />
          </Box>
        </Box>
      )}
      {user ? (
        <Typography component='li' className={classes.item} onClick={() => toggleActionsDrawer()}>
          <Hidden smDown>{t("actionsList.account")}</Hidden>
          <PersonRounded className={classes.accountIcon} />
        </Typography>
      ) : (
        <>
          <Link to={routes.landing.login}>
            <Typography component='li' color='inherit' className={classes.item}>
              {t("actionsList.signIn")}
            </Typography>
          </Link>
          <Link to={routes.landing.register}>
            <Typography component='li' color='inherit' className={classes.item}>
              {t("actionsList.signUp")}
            </Typography>
          </Link>
        </>
      )}
    </Grid>
  );
};

export default ActionsList;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
    textAlign: "right",
    color: "white",
    gap: "1.5rem",
    paddingRight: ".8rem",
  },
  item: {
    height: "100%",
    color: "inherit",
    padding: "0 1.2rem",
    cursor: "pointer",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: palette.green[200],
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 .5rem",
    },
  },
  separator: {
    height: "2.4rem",
    margin: "auto 0",
    borderRight: `1px solid ${palette.darkGrey[300]}`,
    display: "flex",
    alignItems: "center",
  },
  boards: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: palette.green[200],
    },
    "&:hover $boardIconGreen": {
      visibility: "visible",
    },
    "&:hover $expandMoreIcon": {
      visibility: "visible",
      transform: "rotate(180deg)",
    },
  },
  dropdownBoxHidden: {
    position: "absolute",
    right: 0,
    zIndex: 3500,
    visibility: "hidden",
    top: "calc(100% - 1.4rem)",
    opacity: 0,
    transition: ".15s cubic-bezier(0.25, 0.1, 0.25, 1)",
  },
  dropdownBoxVisible: {
    visibility: "visible",
    top: "calc(100% - .7rem)",
    opacity: 1,
  },
  expandMoreIcon: {
    color: "inherit",
    marginLeft: "-.2rem",
    marginTop: ".3rem",
    fontSize: "2.4rem",
  },
  boardIconBox: {
    marginRight: ".3rem",
    display: "flex",
    alignItems: "center",
  },
  boardIcon: {
    width: "1.7rem",
    height: "1.7rem",
    marginTop: ".3rem",
  },
  boardIconGreen: {
    transform: "translateX(100%)",
    visibility: "hidden",
  },
  accountIcon: {
    fontSize: "2.4rem",
    [theme.breakpoints.up("sm")]: {
      marginLeft: ".7rem",
    },
  },
}));
