import Typography from "@elements/Typography";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function NoResult() {
  const {t} = useTranslation("pages.panel");
  const classes = useStyle();

  return (
    <Typography color='#777' size={2} align='center' className={classes.noResultMsg}>
      {t("common.noResults")}
    </Typography>
  );
}
const useStyle = makeStyles((theme) => ({
  noResultMsg: {
    marginTop: 40,
  },
}));
