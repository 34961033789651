import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { palette } from "@constant/colors";
import { useTranslation } from "react-i18next";
import Typography from "@elements/Typography";

interface IClearFiltersButton {
  onClick: () => void
}

const ClearFiltersButton = (props: IClearFiltersButton) => {
  const classes = useStyle();
  const { onClick: handleClick } = props;
  const { t } = useTranslation("modules.filtersDrawer");
  return (
    <Button className={classes.root} classes={{ label: classes.label }} onClick={handleClick}>
      <Typography span size={1.4} weight={500} color='inherit'>
        {t("clearFiltersButton.clearFilters")}
      </Typography>
    </Button>
  );
};

export default ClearFiltersButton;

const useStyle = makeStyles((theme) => ({
  root: {
    border: `solid 1px ${palette.darkGrey[300]}`,
    height: "4.1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "18.5rem",
    marginBottom: "0.5rem",
    cursor: "pointer",
    whiteSpace: "nowrap",
    color: palette.lightGrey.A800,
    padding: "3px 12px 4px 12px",
    borderRadius: "4px",
    transition: ".15s",
    boxShadow: "0 0 2px rgba(255,255,255,.5)",
    "&:hover": {
      color: "white",
      backgroundColor: palette.darkGrey[300],
    },
    [theme.breakpoints.down(280)]: {
      width: "16.5rem",
    },
  },
  label: {
    fontSize: "1.4rem",
    fontWeight: 500,
  },
}));
