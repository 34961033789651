import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import Gallery from "@modules/Gallery";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import GreenLink from "@elements/GreenLink";
import {routes} from "@constant/routes";
import {localStMediaTypeHistoryGet} from "@modules/SearchBar/utils";

const SimilarSection = ({id, imageType}: {id?: number; imageType: string}) => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.images.id");
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(300));
  const type = localStMediaTypeHistoryGet();

  const {data} = useExperimentalQuery("images", "getList", {
    variables: {
      similarImages: String(id),
      type: imageType,
    },
    staleTime: Infinity,
    enabled: !!id,
  });

  if (data && !data.count) {
    return null;
  }

  return (
    <BoxWithWallpaper
      component='section'
      color={palette.lightGrey[700]}
      className={classes.container}
    >
      <Grid container className={classes.heading}>
        <Typography component='h2' size={xxsDown ? 1.6 : 1.8}>
          {t("similarSection.similar")}
        </Typography>
        <GreenLink
          // to={`${routes.landing.search}?similarImages=${id}`.concat(type ? `&type=${type}` : "")}
          to={`${routes.landing.search}?similarImages=${id}`.concat(type ? `&type=${type}` : "")}
          size={xxsDown ? 1.6 : 1.8}
        >
          {"نمایش همه >"}
        </GreenLink>
      </Grid>
      <Gallery images={data?.results} placeholderCounts={10} />
      {data && data.count > 20 && (
        <GreenLink
          // to={`${routes.landing.search}?similarImages=${id}`.concat(type ? `&type=${type}` : "")}
          to={`${routes.landing.search}?similarImages=${id}`.concat(type ? `&type=${type}` : "")}
          size={xxsDown ? 1.6 : 1.8}
        >
          {`+${data.count - 20} عکس بیشتر`}
        </GreenLink>
      )}
      {/* <Divider style={{marginTop: "3rem"}} /> */}
    </BoxWithWallpaper>
  );
};

export default SimilarSection;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "1.8rem 0 2.8rem",
  },
  heading: {
    gap: "4.8rem",
    marginBottom: "2rem",
    [theme.breakpoints.down(400)]: {
      gap: 0,
      justifyContent: "space-between",
    },
  },
}));
