import React from "react";
import iro from "@jaames/iro";

function IroColorPicker(props) {
  const ref = React.useRef(null);
  const pickerRef = React.useRef(null);
  // const [remainingTimeToAppear, setRemainingTime] = React.useState(0);
  // const intervalRef = React.useRef();

  // React.useEffect(() => {
  //   if (remainingTimeToAppear < 3000) {
  //     intervalRef.current = setInterval(() => setRemainingTime((prev) => prev + 500), 500);
  //   }
  //   return () => intervalRef.current && clearInterval(intervalRef.current);
  // }, [remainingTimeToAppear]);

  React.useEffect(() => {
    ref.current.innerHTML = "";
    pickerRef.current = new iro.ColorPicker(ref.current, {
      borderWidth: 1,
      ...props,
    });
    pickerRef.current.on("input:end", (color) => {
      if (props.onChange) {
        props.onChange(color);
      }
    });
    const {color, ...colorPickerState} = props;
    if (color) {
      pickerRef.current.color.set(color);
    }
    pickerRef.current.setState(colorPickerState);
  }, [props]);

  return (
    <div
      ref={ref}
      style={{
        // visibility: remainingTimeToAppear < 1000 ? "hidden" : "visible",
        // height: remainingTimeToAppear < 1000 ? 227 : "auto",
        height: 227,
        overflow: "hidden",
      }}
    />
  );
}

export default IroColorPicker;
