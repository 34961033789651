import React from "react";
import {makeStyles, Grid, Box} from "@material-ui/core";
import {CommonProps} from "@type/global";
import clsx from "clsx";
import GreenLink from "./GreenLink";
import Typography from "./Typography";
import {routes} from "@constant/routes";

export default function CheckOurFaq({className, style}: CommonProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction='row-reverse'
      justify='center'
      alignItems='center'
      className={clsx(classes.container, className)}
      style={style}
    >
      <Typography span align='center'>
        به جوابت نرسیدی؟
      </Typography>
      <Box style={{direction: "rtl"}}>
        <Typography span align='center'>
          حتما بخش
        </Typography>
        <GreenLink to={routes.landing.faq} size='medium' style={{padding: "0 3px"}}>
          FAQ
        </GreenLink>
        <Typography span align='center'>
          رو ببین!
        </Typography>
      </Box>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    gap: ".5rem",
    direction: "rtl",
    [theme.breakpoints.down(430)]: {
      flexDirection: "column",
    },
  },
}));
