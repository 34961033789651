import {
  PlanInvoicesApi,
  PlanInvoicesApiPlanInvoicesListRequest,
  PlanInvoicesApiPlanInvoicesCreateRequest,
  PlanInvoicesApiPlanInvoicesPayRequest,
  PlanInvoicesApiPlanInvoicesPartialUpdateRequest,
  PlanInvoicesApiPlanInvoicesAdminCreateRequest,
  PlanInvoicesApiPlanInvoicesReadRequest,
} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const planInvoicesApiInstance = new PlanInvoicesApi(undefined, undefined, axiosInstance);

export const planInvoicesAPI = makeApi({
  identifier: "planInvoices",
  Query: {
    getList: createQuery(async (params: PlanInvoicesApiPlanInvoicesListRequest) => {
      const {data} = await planInvoicesApiInstance.planInvoicesList(params);
      return data;
    }),
    readDetail: createQuery(async (params: PlanInvoicesApiPlanInvoicesReadRequest) => {
      const {data} = await planInvoicesApiInstance.planInvoicesRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: PlanInvoicesApiPlanInvoicesCreateRequest) => {
      const {data} = await planInvoicesApiInstance.planInvoicesCreate(params);
      return data;
    },
    createPay: async (params: PlanInvoicesApiPlanInvoicesPayRequest) => {
      const {data} = await planInvoicesApiInstance.planInvoicesPay(params);
      return data;
    },
    update: async (params: PlanInvoicesApiPlanInvoicesPartialUpdateRequest) => {
      const {data} = await planInvoicesApiInstance.planInvoicesPartialUpdate(params);
      return data;
    },
    createInvoiceByAdmin: async (params: PlanInvoicesApiPlanInvoicesAdminCreateRequest) => {
      const {data} = await planInvoicesApiInstance.planInvoicesAdminCreate(params);
      return data;
    },
  },
});
