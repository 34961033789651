import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import PlansAndPricing from "./PlansAndPricing";
import {learnMore, company, support} from "./listItems";
import List from "./List";
import Copyright from "./Copyright";
import SocialMedia from "./SocialMedia";
import {palette} from "@constant/colors";
import {useAuth} from "@context/AuthContext";
import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {convertToNumberFormatWithComma} from "@util/convertToNumberFormatWithComma";
import {Link} from "react-router-dom";
import {routes} from "@constant/routes";

const Footer = () => {
  const classes = useStyle();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const {user, loadedUser} = useAuth();

  const {data: images} = useExperimentalQuery("images", "getList", {
    // page and order have been set to prevent invalidate conflicts
    variables: {pageSize: 1, page: 1, order: "created"},
    staleTime: Infinity,
  });

  const {data: adminControl} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  const contactUsInfo = adminControl?.contact_us_info as
    | {
        mobile?: string;
        phone1?: string;
        phone2?: string;
        telegram?: string;
      }
    | undefined;

  return (
    <Grid component='footer' className={classes.container}>
      {loadedUser && !user?.plans?.length && <PlansAndPricing />}
      <Box className={classes.padding}>
        <Grid container className={classes.mainContent} wrap={smDown ? "wrap" : "nowrap"}>
          <Grid item xs={12} sm={6} md={4} className={classes.wrapper}>
            <List title='بیشتر بدانید' items={learnMore} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.wrapper}>
            <List
              title='شرکت'
              items={[
                ...company,
                {
                  title: contactUsInfo?.phone1 || "",
                  href: `tel:${contactUsInfo?.phone1?.split("-").join("")}`,
                  renderOriginalAnchor: true,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.wrapper}>
            <List
              title='پشتیبانی'
              items={[
                {
                  title: "Parsdream",
                  href: `https://t.me${contactUsInfo?.telegram}`,
                  renderOriginalAnchor: true,
                },
                ...support,
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.wrapper}>
            <SocialMedia />
          </Grid>
        </Grid>
        <Grid container justify='center' alignItems='center' className={classes.stats}>
          <Typography color='inherit'>تعداد عکس‌های سایت: </Typography>
          <Typography color='inherit' style={{minWidth: 55}}>
            <Link to={routes.landing.search}>
              {images && convertToNumberFormatWithComma(images.count)}
            </Link>
          </Typography>
        </Grid>
        <Copyright />
      </Box>
    </Grid>
  );
};

export default Footer;

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: palette.darkGrey.A600,
  },
  padding: {
    padding: "4.6rem 3.5rem 3.5rem",
    [theme.breakpoints.down(400)]: {
      padding: "4.6rem .5rem 2.5rem",
    },
  },
  mainContent: {
    fontSize: ".1.4rem",
    lineHeight: "1.8rem",
    margin: "0 auto",
    maxWidth: "110rem",
    width: "100%",
    paddingBottom: "1rem",
  },
  wrapper: {
    padding: "0 1.5rem 4rem",
    flexGrow: 1,
  },
  stats: {
    marginBottom: "1.5rem",
    gap: 6,
    color: "white",
  },
}));
