/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Collection } from '../servermodel';
// @ts-ignore
import { InlineResponse2005 } from '../servermodel';
/**
 * CollectionsApi - axios parameter creator
 * @export
 */
export const CollectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCreate: async (data: Collection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('collectionsCreate', 'data', data)
            const localVarPath = `/collections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsDelete', 'id', id)
            const localVarPath = `/collections/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsList: async (search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsPartialUpdate: async (id: number, data: Collection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('collectionsPartialUpdate', 'data', data)
            const localVarPath = `/collections/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsRead', 'id', id)
            const localVarPath = `/collections/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsUpdate: async (id: number, data: Collection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectionsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('collectionsUpdate', 'data', data)
            const localVarPath = `/collections/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionsApi - functional programming interface
 * @export
 */
export const CollectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCreate(data: Collection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsList(search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsList(search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsPartialUpdate(id: number, data: Collection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsUpdate(id: number, data: Collection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionsApi - factory interface
 * @export
 */
export const CollectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCreate(data: Collection, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.collectionsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsList(search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.collectionsList(search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsPartialUpdate(id: number, data: Collection, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsRead(id: number, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this collection.
         * @param {Collection} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsUpdate(id: number, data: Collection, options?: any): AxiosPromise<Collection> {
            return localVarFp.collectionsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for collectionsCreate operation in CollectionsApi.
 * @export
 * @interface CollectionsApiCollectionsCreateRequest
 */
export interface CollectionsApiCollectionsCreateRequest {
    /**
     * 
     * @type {Collection}
     * @memberof CollectionsApiCollectionsCreate
     */
    readonly data: Collection
}

/**
 * Request parameters for collectionsDelete operation in CollectionsApi.
 * @export
 * @interface CollectionsApiCollectionsDeleteRequest
 */
export interface CollectionsApiCollectionsDeleteRequest {
    /**
     * A unique integer value identifying this collection.
     * @type {number}
     * @memberof CollectionsApiCollectionsDelete
     */
    readonly id: number
}

/**
 * Request parameters for collectionsList operation in CollectionsApi.
 * @export
 * @interface CollectionsApiCollectionsListRequest
 */
export interface CollectionsApiCollectionsListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof CollectionsApiCollectionsList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CollectionsApiCollectionsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CollectionsApiCollectionsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for collectionsPartialUpdate operation in CollectionsApi.
 * @export
 * @interface CollectionsApiCollectionsPartialUpdateRequest
 */
export interface CollectionsApiCollectionsPartialUpdateRequest {
    /**
     * A unique integer value identifying this collection.
     * @type {number}
     * @memberof CollectionsApiCollectionsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Collection}
     * @memberof CollectionsApiCollectionsPartialUpdate
     */
    readonly data: Collection
}

/**
 * Request parameters for collectionsRead operation in CollectionsApi.
 * @export
 * @interface CollectionsApiCollectionsReadRequest
 */
export interface CollectionsApiCollectionsReadRequest {
    /**
     * A unique integer value identifying this collection.
     * @type {number}
     * @memberof CollectionsApiCollectionsRead
     */
    readonly id: number
}

/**
 * Request parameters for collectionsUpdate operation in CollectionsApi.
 * @export
 * @interface CollectionsApiCollectionsUpdateRequest
 */
export interface CollectionsApiCollectionsUpdateRequest {
    /**
     * A unique integer value identifying this collection.
     * @type {number}
     * @memberof CollectionsApiCollectionsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Collection}
     * @memberof CollectionsApiCollectionsUpdate
     */
    readonly data: Collection
}

/**
 * CollectionsApi - object-oriented interface
 * @export
 * @class CollectionsApi
 * @extends {BaseAPI}
 */
export class CollectionsApi extends BaseAPI {
    /**
     * 
     * @param {CollectionsApiCollectionsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public collectionsCreate(requestParameters: CollectionsApiCollectionsCreateRequest, options?: any) {
        return CollectionsApiFp(this.configuration).collectionsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionsApiCollectionsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public collectionsDelete(requestParameters: CollectionsApiCollectionsDeleteRequest, options?: any) {
        return CollectionsApiFp(this.configuration).collectionsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionsApiCollectionsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public collectionsList(requestParameters: CollectionsApiCollectionsListRequest = {}, options?: any) {
        return CollectionsApiFp(this.configuration).collectionsList(requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionsApiCollectionsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public collectionsPartialUpdate(requestParameters: CollectionsApiCollectionsPartialUpdateRequest, options?: any) {
        return CollectionsApiFp(this.configuration).collectionsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionsApiCollectionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public collectionsRead(requestParameters: CollectionsApiCollectionsReadRequest, options?: any) {
        return CollectionsApiFp(this.configuration).collectionsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CollectionsApiCollectionsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    public collectionsUpdate(requestParameters: CollectionsApiCollectionsUpdateRequest, options?: any) {
        return CollectionsApiFp(this.configuration).collectionsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
