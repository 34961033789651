import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ExpandLess} from "@material-ui/icons";
import clsx from "clsx";
import Span from "./Span";

const ScrollTopButton = ({className}: {className?: string}) => {
  const classes = useStyle();

  const handleClick = () => window.scrollTo({top: 0, behavior: "smooth"});

  return (
    <Span className={clsx(classes.container, className)} onClick={handleClick}>
      <ExpandLess />
    </Span>
  );
};

export default ScrollTopButton;

const useStyle = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    border: "1px solid #a8b2b1",
    borderRadius: "10%",
    cursor: "pointer",
    backgroundColor: "white",
    transition: ".3s ease-in-out",
    color: "#555",
    "&:hover": {
      color: "#222",
      boxShadow: "inset 0 0 0 3px #E8EDED",
    },
    "& svg": {
      fontSize: 26,
    },
  },
}));
