import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, FormControlLabel, RadioGroup, Radio} from "@material-ui/core";
import clsx from "clsx";
import {useValidateDistinction} from "@hook/useValidateDistinction";
import {palette} from "@constant/colors";
import Span from "@elements/Span";
import Typography from "@elements/Typography";

export interface IRadioButtonGroupProps {
  name: string;
  color: "light" | "dark";
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  items: {
    value: string;
    label: string;
    description?: string;
  }[];
}

const RadioButtonGroup = (props: IRadioButtonGroupProps) => {
  const {name, items, value: controlledValue, onChange, color, disabled} = props;
  const [value, setValue] = useState("");
  const lightColorStyle = useLightColorStyle();
  const darkColorStyle = useDarkColorStyle();
  const classes = color === "light" ? lightColorStyle : darkColorStyle;
  useValidateDistinction(items);

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  const handleChange = (value: string) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <RadioGroup name={name} value={value} onChange={(_, value) => handleChange(value)}>
      {items.map(({label, description, value: iValue}) => (
        <FormControlLabel
          className={clsx(classes.row, iValue === value ? classes.selectedRow : undefined)}
          key={iValue}
          value={iValue}
          disabled={disabled}
          classes={{disabled: classes.disabled}}
          label={
            <Box className={classes.flex}>
              <Typography
                span
                color={
                  color === "dark"
                    ? palette.darkGrey.A700
                    : iValue === value
                    ? "white"
                    : palette.lightGrey.A400
                }
                size={color === "dark" ? undefined : 1.4}
                weight={color === "dark" ? 700 : undefined}
              >
                {label}
              </Typography>
              {description && (
                <Typography span size={1.4} color={palette.darkGrey.A200}>
                  {description}
                </Typography>
              )}
            </Box>
          }
          control={
            <Radio
              disableRipple
              color='default'
              classes={{root: classes.root}}
              icon={<Span className={classes.icon} />}
              checkedIcon={<Span className={clsx(classes.icon, classes.checkedIcon)} />}
            />
          }
        />
      ))}
    </RadioGroup>
  );
};

export default RadioButtonGroup;

const useLightColorStyle = makeStyles((theme) => ({
  root: {
    height: "3.2rem",
    padding: ".8rem .8rem .8rem 0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  row: {
    margin: 0,
    "&:hover $checkedIcon": {
      border: `1px solid ${palette.darkGrey[200]}`,
    },
    "&:hover $icon::after, &:hover $checkedIcon::after": {
      backgroundColor: palette.darkGrey[200],
    },
    "&:hover $label": {
      color: theme.palette.background.paper,
    },
  },
  icon: {
    border: `1px solid ${palette.darkGrey[200]}`,
    borderRadius: "100%",
    cursor: "pointer",
    height: "19px",
    width: "19px",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      // top: "50%", left: "50%", transform: "..." => surprisingly doesn't work correctly in zoom 100% or smaller, don't know why
      top: "3px",
      left: "3px",
      height: "11px",
      width: "11px",
      borderRadius: "100%",
      backgroundColor: "transparent",
      transition: ".2s ease",
    },
  },
  checkedIcon: {
    border: `1px solid ${palette.green[200]}`,
    "&::after": {
      backgroundColor: palette.green[200],
    },
  },
  flex: {}, // these empty classes prevent typescript error, this was the simplest way
  selectedRow: {},
  disabled: {
    "& *, & *::after": {
      color: "#ccc",
      backgroundColor: "transparent",
      borderColor: "#ccc",
    },
  },
}));

const useDarkColorStyle = makeStyles(() => ({
  root: {
    height: "3.2rem",
    padding: ".8rem .8rem .8rem 0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  row: {
    margin: 0,
    padding: "2.1rem 0 2.1rem 1.9rem",
    minHeight: "6.6rem",
    borderTop: "1px solid transparent",
    borderBottom: "1px solid transparent",
    transition: "box-shadow 0.25s ease-in-out",
    "&:hover": {
      boxShadow: `inset 0 0 0 5px ${palette.lightGrey.A300}`,
    },
  },
  selectedRow: {
    backgroundColor: palette.lightGrey[600],
    borderTop: `1px solid ${palette.lightGrey.A200}`,
    borderBottom: "1px solid white",
  },
  icon: {
    border: `1px solid ${palette.darkGrey.A700}`,
    borderRadius: "100%",
    cursor: "pointer",
    height: "23px",
    width: "23px",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      top: "4px",
      left: "4px",
      height: "13px",
      width: "13px",
      borderRadius: "100%",
      backgroundColor: "transparent",
      transition: ".2s ease",
    },
  },
  checkedIcon: {
    border: `1px solid ${palette.darkGrey.A700}`,
    "&::after": {
      backgroundColor: palette.darkGrey.A700,
    },
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  white: {},
  disabled: {
    "& *, & *::after": {
      color: "#ccc",
      backgroundColor: "transparent",
      borderColor: "#ccc",
    },
  },
}));
