import {fontNormalizeFactor} from "@elements/Typography/constant";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CardMedia, InputBase, Box} from "@material-ui/core";
import {images} from "@constant/images";
import {palette} from "@constant/colors";
import clsx from "clsx";

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
export interface ITextInputProps {
  value?: string;
  onChange?: (value: string, event: ChangeEvent) => void;
  onSubmit?: (value: string) => void;
  fontSize?: number;
  multiline?: boolean;
  maxLength?: number;
  placeholder?: string;
  containerClassName?: string;
}

const TextInput = (props: ITextInputProps) => {
  const [value, setValue] = useState("");
  const {
    value: controlledValue,
    onChange,
    onSubmit,
    fontSize = 1.6,
    multiline,
    maxLength = 256,
    placeholder,
    containerClassName,
  } = props;
  const classes = useStyle({fontSize: fontSize * fontNormalizeFactor});
  const hasDataChanged = value !== controlledValue;

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  const handleChange = (event: ChangeEvent) => {
    const {value} = event.target;
    setValue(value);
    onChange?.(value, event);
  };

  const handleBlur = (event: ChangeEvent) => hasDataChanged && onSubmit?.(value);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === "Enter") {
      hasDataChanged && onSubmit?.(value);
    }
  };

  return (
    <Box className={classes.container}>
      <InputBase
        value={value}
        onChange={handleChange}
        className={clsx(classes.inputBox, containerClassName)}
        classes={{input: classes.input, focused: classes.focused}}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        inputProps={{maxLength: maxLength}}
        multiline={multiline}
        rows={2}
        rowsMax={4}
        placeholder={placeholder}
      />
      <CardMedia component='img' src={images.icons.edit} className={classes.editIcon} />
    </Box>
  );
};

export default TextInput;

const useStyle = makeStyles(() => ({
  container: {
    position: "relative",
    maxHeight: "20rem",
    "&,& *": {
      height: "100%",
      width: "100%",
    },
    "& *::-webkit-scrollbar": {
      width: 0,
    },
  },
  inputBox: {
    padding: "0 3px",
    "&:hover": {
      backgroundColor: palette.lightGrey[200],
    },
    "&:hover ~ $editIcon": {
      display: "block",
    },
  },
  input: ({fontSize}: {fontSize: number}) => ({
    height: "100%",
    fontSize: `${fontSize}rem`,
    color: palette.darkGrey[900],
    lineHeight: `${(fontSize * 135) / 100}rem`,
    textOverflow: "ellipsis",
  }),
  focused: {
    backgroundColor: palette.lightGrey[200],
  },
  editIcon: {
    width: "1.3rem",
    height: "1.3rem",
    position: "absolute",
    top: ".7rem",
    left: "-1.8rem",
    display: "none",
  },
}));
