import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {theme} from "@constant/theme";
import {IconButton} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import clsx from "clsx";

export interface IAddIconProps {
  className?: string;
  onClick?: () => void;
  size?: "sm" | "md";
}

const AddIcon = (props: IAddIconProps) => {
  const {onClick: handleClick, className, size = "md"} = props;
  const length = size === "sm" ? 55 : 65;
  const classes = useStyle({length});

  return (
    <IconButton className={clsx(classes.addIcon, className)} onClick={handleClick}>
      <Add />
    </IconButton>
  );
};

export default AddIcon;

const useStyle = makeStyles(() => ({
  addIcon: ({length}: {length: number}) => ({
    width: length,
    height: length,
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      opacity: 0.8,
    },
    "& *": {
      color: "white",
      fontSize: "21px",
    },
  }),
}));
