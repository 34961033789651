import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import Wallpaper from "@elements/Wallpaper";
import AuthCard from "@elements/AuthCard";
import {TextInput} from "@elements/TextInput";
import {MainButton} from "@elements/button";
import {useTranslation} from "react-i18next";
import Copyright from "@elements/Copyright";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAuth} from "@context/AuthContext";
import {useHistory} from "react-router-dom";
import ConfirmMobileCard from "@elements/ConfirmMobileCard";
import OtpCard from "@elements/OtpCard";
import {useAlert} from "@context/AlertContext";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {getUserLastLocationFromLs, removeUserLastLocationFromLs} from "@util/userLastLocation";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {generateYupSchema, IFormSchema, setFormErrors} from "@util/reactHookFormUtils";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import {Helmet} from "react-helmet";

interface IFormValueTypes {
  username: string;
  password: string;
  password2: string;
  email: string;
}

const Register = () => {
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [mobileNumber, setMobileNumber] = useState("");
  const [uuid, setUuid] = useState("");
  const [remainingTime, setRemainingTime] = useState(0);
  const classes = useStyle();
  const {login, user} = useAuth();
  const history = useHistory();
  const {t} = useTranslation("pages.signUp");
  const alert = useAlert();
  const intervalRef = useRef<NodeJS.Timeout | undefined>();
  // with the help of mode=redirect user will be redirected back to his last location after authenticating
  const {mode} = useGetQueryString();
  const apiErrorHandler = useApiErrorHandler();

  const yupCommonValidation = yup
    .string()
    .min(8, "این فیلد باید حداقل ۸ رقم باشد")
    .required("پر کردن این فیلد الزامی‌ است");
  const schema: IFormSchema<IFormValueTypes> = [
    {name: "username", validations: yupCommonValidation},
    {
      name: "password",
      validations: yupCommonValidation.matches(
        new RegExp(/^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/),
        "رمز عبور فقط می‌تواند شامل حروف انگلیسی، اعداد و سمبل باشد"
      ),
    },
    {name: "password2", validations: yupCommonValidation},
    {name: "email", validations: yup.string().email("لطفا یک ایمیل صحیح وارد کنید")},
  ];

  const {control, handleSubmit, setError} = useForm<IFormValueTypes>({
    resolver: yupResolver(generateYupSchema(schema)),
  });

  const mobileIsValid = mobileNumber.startsWith("+98")
    ? mobileNumber.length === 13
    : mobileNumber.length > 8;

  useEffect(() => {
    if (user) {
      history.replace("/");
    }
  }, [history, user]);

  useEffect(() => {
    if (remainingTime > 0) {
      intervalRef.current = setInterval(() => setRemainingTime((prev) => prev - 1), 973);
    }
    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [remainingTime]);

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  const {mutate: mutateOtp} = useExperimentalMutation("auth", "otp", {
    variables: {data: {mobile_number: mobileNumber}},
    onSuccess: () => {
      setStep(2);
      setRemainingTime(120);
    },
    onError: (err) => {
      const errorData = err.response?.data as any;
      if (!!errorData["detail"]["remaining_time"]) {
        setRemainingTime(errorData.detail.remaining_time);
        alert.info({
          text: `کد قبلی هنوز به مدت ${errorData.detail.remaining_time} ثانیه معتبر است`,
        });
        setStep(2);
      } else {
        apiErrorHandler(err);
      }
    },
  });
  const handleSubmitMobile = () => mutateOtp();

  const {mutate: mutateOtpConfirm} = useExperimentalMutation("auth", "otpConfirm");
  const handleSubmitOtpConfirm = (otp: string) => {
    mutateOtpConfirm({
      variables: {
        data: {
          mobile_number: mobileNumber,
          otp_code: otp,
        },
      },
      onSuccess: (_data) => {
        const data = (_data as unknown) as {detail: {uuid: string}};
        setUuid(data.detail.uuid);
        intervalRef.current && clearInterval(intervalRef.current);
        setRemainingTime(0);
        setStep(3);
      },
      onError: (err) => {
        apiErrorHandler(err);
      },
    });
  };

  const {mutate} = useExperimentalMutation("auth", "register");
  const handleSubmitSignUp: SubmitHandler<IFormValueTypes> = (values) => {
    if (values.password !== values.password2) {
      alert.error({text: "تکرار رمز عبور با رمز عبور مطابقت ندارد"});
      return;
    }
    const data = {
      mobile_number: mobileNumber,
      username: values.username,
      password1: values.password,
      password2: values.password2,
      uuid,
    };
    mutate({
      variables: {
        data: values.email ? {...data, email: values.email} : data,
      },
      onSuccess: (data) => {
        alert.success({text: "ثبت‌نام شما با موفقیت انجام شد"});
        const lastLocation = getUserLastLocationFromLs();
        history.replace(mode === "redirect" && lastLocation ? lastLocation : "/");
        login(data);
        removeUserLastLocationFromLs();
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          setFormErrors(schema, setError, error);
        }
        apiErrorHandler(error);
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>ثبت‌نام - پارس ایمیجز</title>
        <meta name='title' content='ثبت‌نام - پارس ایمیجز' />
      </Helmet>
      <Grid container justify='center' className={classes.container}>
        <Box className={classes.cardBox}>
          {step === 1 ? (
            <ConfirmMobileCard
              value={mobileNumber}
              onChange={(v) => setMobileNumber(v)}
              loginLink
              disabled={!mobileIsValid}
              onSubmit={handleSubmitMobile}
            />
          ) : step === 2 ? (
            <OtpCard
              mobileNumber={mobileNumber}
              remaining={remainingTime}
              onEdit={() => setStep(1)}
              onSubmit={handleSubmitOtpConfirm}
              onRetry={handleSubmitMobile}
            />
          ) : (
            <AuthCard>
              <form onSubmit={handleSubmit(handleSubmitSignUp)}>
                <Typography size={2.4}>{t("moreInformation")}</Typography>
                <Grid container direction='column' className={classes.paddingAndGap}>
                  <Controller
                    name='username'
                    control={control}
                    render={({field, fieldState: {error}}) => (
                      <TextInput
                        {...field}
                        label={t("userNameLabel")}
                        placeholder={t("userNamePlaceholder")}
                        // tooltipText={t("userNameTooltip")}
                        isEnglish
                        autoFocus
                        error={!!error}
                        errorMessage={error?.message}
                        trim
                      />
                    )}
                  />
                  <Controller
                    name='email'
                    control={control}
                    render={({field, fieldState: {error}}) => (
                      <TextInput
                        {...field}
                        label={t("emailLabel")}
                        placeholder={t("emailPlaceholder")}
                        isEnglish
                        optional
                        error={!!error}
                        errorMessage={error?.message}
                        trim
                      />
                    )}
                  />
                  <Controller
                    name='password'
                    control={control}
                    render={({field, fieldState: {error}}) => (
                      <TextInput
                        {...field}
                        label={t("passwordLabel")}
                        placeholder={t("passwordPlaceholder")}
                        // tooltipText={t("passwordTooltip")}
                        isEnglish
                        isPassword
                        error={!!error}
                        errorMessage={error?.message}
                        trim
                      />
                    )}
                  />
                  <Controller
                    name='password2'
                    control={control}
                    render={({field, fieldState: {error}}) => (
                      <TextInput
                        {...field}
                        placeholder={t("confirmPasswordPlaceholder")}
                        isPassword
                        isEnglish
                        error={!!error}
                        errorMessage={error?.message}
                        trim
                      />
                    )}
                  />
                  <MainButton color='green' fullWidth type='submit'>
                    {t("signUp")}
                  </MainButton>
                </Grid>
              </form>
            </AuthCard>
          )}
        </Box>
        <Copyright />
      </Grid>
      <Wallpaper image={data?.signup_image || ""} backgroundColor='#186676' />
    </>
  );
};

export default Register;

const useStyle = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    position: "relative",
    padding: "20vh 1rem 20vh 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem .5rem 20vh .5rem",
    },
  },
  cardBox: {
    width: "42.5rem",
  },
  paddingAndGap: {
    padding: "1.5rem 0 2rem",
    gap: "1rem",
  },
}));
