import {BlogsApi, BlogsApiBlogsListRequest, BlogsApiBlogsReadRequest} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const blogApiInstance = new BlogsApi(undefined, undefined, axiosInstance);

export const blogAPI = makeApi({
  identifier: "blog",
  Query: {
    getList: createQuery(async (params: BlogsApiBlogsListRequest) => {
      const {data} = await blogApiInstance.blogsList(params);
      return data;
    }),

    readDetail: createQuery(async (params: BlogsApiBlogsReadRequest) => {
      const {data} = await blogApiInstance.blogsRead(params);
      return data;
    }),
  },
  Mutation: {},
});
