import {palette} from "@constant/colors";
import {MainButton, TextButton} from "@elements/button";
import {Grid, InputBase, makeStyles} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAlert} from "@context/AlertContext";
import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {maxPossibleBoards, maxPossibleImagesInOneBoard} from "@constant/boards";
import Wrapper from "./Wrapper";
import {useModalDispatch} from "@context/ModalContext";

export interface AddToBoardModalProps {
  src: string;
  alt: string;
  onAddToNewBoard?: (name: string) => void;
  onAddToPreviousBoard?: (name: string, boardId: number) => void;
}

/** unlike panel modals, in landing modals, mutation are outside the modal itself some times to prevent duplication, some time for different scenarios of using one modal and for unification */
function ModalBody(props: AddToBoardModalProps) {
  const classes = useStyles();
  const {t} = useTranslation("modules.modals");
  const {onAddToNewBoard, onAddToPreviousBoard, src, alt} = props;
  const [name, setName] = React.useState("");
  const [isCreatingNewBoard, setIsCreatingNewBoard] = React.useState(false);
  const alert = useAlert();
  const handleCloseModal = useModalDispatch(null);

  const {data: boards} = useExperimentalQuery("boards", "getList", {
    variables: {pageSize: maxPossibleBoards},
    onError: () => alert.error({}),
  });
  const {data: recentBoardsList} = useExperimentalQuery("boards", "getRecentBoardsList", {
    variables: {},
    staleTime: Infinity,
  });
  React.useEffect(() => {
    !recentBoardsList?.length && setIsCreatingNewBoard(true);
  }, [recentBoardsList?.length]);

  const areMaxPossibleBoardsCreated = boards && boards.results.length >= maxPossibleBoards;

  return (
    // <ModalWrapper
    //   title={isCreatingNewBoard ? t("addToBoard.saveOnNewBoard") : t("addToBoard.chooseBoard")}
    //   onClose={handleClose}
    //   {...rest}
    // >
    <Grid container className={classes.container}>
      <Grid container alignItems='center' justify='center' className={classes.imageBox}>
        <img src={src} alt={alt} />
      </Grid>
      <div className={classes.form}>
        <InputBase
          fullWidth
          autoFocus
          placeholder={
            isCreatingNewBoard ? t("addToBoard.newBoardName") : t("addToBoard.boardName")
          }
          value={name}
          onChange={({target: {value}}) => setName(value)}
          startAdornment={
            !isCreatingNewBoard && (
              <span className={classes.searchIconBox}>
                <svg viewBox='0 0 48 48'>
                  <path d='M39.4 38.1l-7.2-7.2c2.1-2.4 3.4-5.6 3.4-9 0-7.3-6.4-13.7-13.7-13.7-7.6 0-13.7 6.1-13.7 13.7s6.1 13.7 13.7 13.7c3.5 0 6.6-1.3 9.1-3.5l7.2 7.2c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7zm-17.5-4.3c-6.6 0-12-5.4-12-11.9 0-6.6 5.4-12 12-12 6.4 0 12 5.6 12 12-.1 6.6-5.4 11.9-12 11.9z' />
                </svg>
              </span>
            )
          }
        />
        {!isCreatingNewBoard && (
          <ul className={classes.oldBoardBox}>
            {boards?.results
              .filter(({name: boardName}) => boardName?.startsWith(name))
              .map(({name, id, images_count}) => (
                <li
                  key={id?.toString()}
                  onClick={() => {
                    if ((images_count || 0) >= maxPossibleImagesInOneBoard) {
                      alert.error({text: t("common.maximumPossibleImageInBoard")});
                      return;
                    }
                    onAddToPreviousBoard?.(name || "", id || 0);
                    handleCloseModal();
                  }}
                >
                  <Typography noWrap>{name}</Typography>
                  <Typography span size={1.2} color={palette.green[200]} className={classes.badge}>
                    {t("addToBoard.submit")}
                  </Typography>
                </li>
              ))}
          </ul>
        )}
        {isCreatingNewBoard && (
          <>
            {areMaxPossibleBoardsCreated && (
              <Typography color='salmon' align='center'>
                {t("common.maximumPossibleBoards")}
              </Typography>
            )}
            <MainButton
              color='green'
              disabled={!name || areMaxPossibleBoardsCreated}
              fullWidth
              onClick={() => {
                onAddToNewBoard?.(name);
                handleCloseModal();
              }}
            >
              {t("addToBoard.submit")}
            </MainButton>
          </>
        )}
        {!!boards?.results.length && (
          <TextButton
            className={classes.changeStateButton}
            onClick={() => {
              setIsCreatingNewBoard((prev) => !prev);
              setName("");
            }}
          >
            {isCreatingNewBoard
              ? t("addToBoard.chooseAlreadyCreatedBoard")
              : t("addToBoard.createNewBoard")}
          </TextButton>
        )}
      </div>
    </Grid>
  );
}

export const AddToBoardModal = Wrapper(ModalBody, "addToBoard", {
  title: "انتخاب بورد",
});

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row-reverse",
    gap: 16,
    [theme.breakpoints.down(550)]: {
      flexDirection: "column",
      maxWidth: 280,
      margin: "0 auto",
    },
  },
  imageBox: {
    border: "1px solid #ccc",
    width: 136,
    height: 136,
    flexShrink: 0,
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down(550)]: {
      width: "100%",
    },
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // width: "100%",
    gap: "1.6rem",
    flexGrow: 1,
  },
  searchIconBox: {
    padding: "0 .7rem 0 .3rem",
    display: "flex",
    justifyContent: "center",
    "& svg": {
      fill: "#777",
      height: 28,
      width: 28,
    },
  },
  oldBoardBox: {
    width: "100%",
    height: "16.5rem",
    border: "1px solid #ccc",
    overflowY: "scroll",
    "& li": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      padding: ".8rem 1.6rem",
      "&:hover": {
        backgroundColor: "#f3f3f3",
      },
      "&:hover $badge": {
        display: "block",
      },
    },
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eee",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
    },
  },
  changeStateButton: {
    marginTop: "-1rem",
  },
  badge: {
    padding: "3px 10px",
    borderRadius: 5,
    backgroundColor: "#dee9f9",
    display: "none",
  },
}));
