import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Divider as MaterialDivider} from "@material-ui/core";
import clsx from "clsx";
import {CommonProps} from "@type/global";

export interface IDividerProps extends CommonProps {
  colors?: [string, string];
}

const Divider = (props: IDividerProps) => {
  const classes = useStyle(props);
  const {className, style} = props;
  return <MaterialDivider className={clsx(classes.root, className)} style={style} />;
};

export default Divider;

const useStyle = makeStyles(() => ({
  root: ({colors}: IDividerProps) => ({
    border: colors ? `solid 1px ${colors[0]}` : "solid #ccd3d3 1px",
    borderBottom: colors ? `solid 1px ${colors[1]}` : "solid #eee 1px",
    display: "block",
    width: "100%",
  }),
}));
