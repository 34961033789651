import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import AuthCard from "./AuthCard";
import {GreyButton, MainButton} from "./button";
import CodeInput from "./CodeInput";
import {darkGrey, green} from "@constant/colors";
import {useTranslation} from "react-i18next";
import {persianNumbersToEnglish} from "@util/persianNumbersToEnglish";

export interface IOtpCardProps {
  mobileNumber: string;
  remaining: number;
  onSubmit?: (otp: string) => void;
  onEdit?: () => void;
  onRetry?: () => void;
  error?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const OtpCard = (props: IOtpCardProps) => {
  const {
    mobileNumber,
    onSubmit,
    remaining,
    error,
    value,
    onChange,
    onEdit: handleEdit,
    onRetry: handleRetry,
  } = props;
  const classes = useStyle();
  const {t} = useTranslation("elements");

  return (
    <AuthCard error={error}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          value && onSubmit?.(value);
        }}
      >
        <Typography size={2.4}>{t("otpCard.confirmMobileMessage")}</Typography>
        <Grid container direction='column' className={classes.paddingAndGap}>
          <Typography span size={1.4} color={darkGrey.A100}>
            {t("otpCard.description", {mobile: `${mobileNumber.slice(1)}+`})}
          </Typography>
          <Box className={classes.margin}>
            <GreyButton
              classes={{root: classes.sendAgain}}
              disabled={remaining > 0}
              onClick={handleRetry}
            >
              {t("otpCard.sendAgain", {
                remaining: new Date(1000 * remaining).toISOString().substr(11, 8).slice(4),
              })}
            </GreyButton>
            <CodeInput
              fields={5}
              label='کد تایید'
              name='otp'
              errorMessage={error}
              value={value}
              onChange={(v) => {
                onChange?.(v);
                v.length === 5 && onSubmit?.(persianNumbersToEnglish(v));
              }}
            />
          </Box>
          <MainButton color='green' fullWidth type='submit' disabled={!!value && value.length < 5}>
            {t("otpCard.confirmAndContinue")}
          </MainButton>
        </Grid>
        <Grid container justify='center'>
          <Typography
            span
            weight={500}
            color={green[200]}
            size={1.4}
            className={classes.editButton}
            onClick={handleEdit}
          >
            {t("otpCard.editMobile")}
          </Typography>
        </Grid>
      </form>
    </AuthCard>
  );
};

export default OtpCard;

const useStyle = makeStyles(() => ({
  paddingAndGap: {
    padding: ".5rem 0 2rem",
    gap: "1rem",
  },
  sendAgain: {
    marginBottom: "-2.1rem",
    float: "right",
    position: "relative",
    zIndex: 100,
    backgroundColor: "transparent",
    height: "2rem",
    "& *": {
      fontWeight: 400,
    },
  },
  margin: {
    margin: "1rem 0 2rem",
  },

  editButton: {
    cursor: "pointer",
    "&:hover": {
      color: green[500],
    },
  },
}));
