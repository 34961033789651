import {useAlert} from "@context/AlertContext";
import {AxiosError} from "axios";

/** notifies user if api returns error with status >= 500 */
export default function useServerErrorNotify() {
  const alert = useAlert();

  return (error: AxiosError) => {
    const status = error.response?.status;
    if (status === 502) {
      alert.error({text: "خطای سرور، سایت در حال بروز رسانی‌است"});
    } else if ((status || 0) >= 500) {
      alert.error({text: "خطای سرور، لطفا پس از مدتی کوتاه دوباره امتحان کنید"});
    }
  };
}
