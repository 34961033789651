import {FaqsApi, FaqsApiFaqsCreateRequest, FaqsApiFaqsListRequest, FaqsApiFaqsReadRequest} from "@schema";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const faqsApiInstance = new FaqsApi(undefined, undefined, axiosInstance);

export const faqsAPI = makeApi({
  identifier: "faqs",
  Query: {
    getList: createQuery(async (params: FaqsApiFaqsListRequest) => {
      const {data} = await faqsApiInstance.faqsList(params);
      return data;
    }),
    detail: createQuery(async (params: FaqsApiFaqsReadRequest) => {
      const {data} = await faqsApiInstance.faqsRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: FaqsApiFaqsCreateRequest) => {
      const {data} = await faqsApiInstance.faqsCreate(params);
      return data;
    },
  },
});
