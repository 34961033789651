import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, CardMedia, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {images} from "@constant/images";

const WhyChooseUsSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.pricing");

  const data = [
    {
      text: t("whyChooseUsSection.benefit1"),
      src: images.icons.people,
    },
    {
      text: t("whyChooseUsSection.benefit2"),
      src: images.icons.creditCard,
    },
    {
      text: t("whyChooseUsSection.benefit3"),
      src: images.icons.images,
    },
    {
      text: t("whyChooseUsSection.benefit4"),
      src: images.icons.workflow,
    },
  ];

  return (
    <Box className={classes.container}>
      <Typography size={2.4} align='center' className={classes.title}>
        {t("whyChooseUsSection.title")}
      </Typography>
      <Grid container justify='center' alignItems='center' wrap='wrap' className={classes.cardBox}>
        {data.map((item) => (
          <Box className={classes.card} key={item.text}>
            <CardMedia component='img' src={item.src} className={classes.image} />
            <Typography size={1.4}>{item.text}</Typography>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyChooseUsSection;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "3.5rem 0 3rem",
  },
  title: {
    marginBottom: "1.5rem",
  },
  cardBox: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  card: {
    padding: "3.4rem 2rem 3rem",
    width: "25%",
    textAlign: "center",
    [theme.breakpoints.down(950)]: {
      width: "50%",
      maxWidth: "25rem",
      padding: "1.8rem 1rem 1.4rem",
    },
  },
  image: {
    height: "4rem",
    width: "auto",
    margin: "0 auto 2.5rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 1.5rem",
    },
  },
}));
