import {ApiNamespaces} from "@api";
import {useAlert} from "@context/AlertContext";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {DialogProps, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useQueryClient} from "react-query";
import PlanCardEditable, {PlanCardValues} from "../PlanCardEditable";
import PanelModalRapper from "./PanelModalWrapper";

interface PlanModalProps extends Omit<DialogProps, "id"> {
  id: number | null;
  isActive?: boolean;
  onSubmit?: () => void;
}

export default function PlanModal(props: PlanModalProps) {
  const {id, onSubmit, isActive = true, ...rest} = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const isNew = !id;
  const alert = useAlert();

  const events = {
    onSuccess: () => {
      alert.success({});
      queryClient.invalidateQueries(ApiNamespaces.plans.Query.getList.queryKey);
    },
    onError: () => alert.error({}),
    onSettled: () => onSubmit?.(),
  };

  const {data: detail} = useExperimentalQuery("plans", "readDetail", {
    variables: {
      id: id!,
    },
    enabled: !!id,
  });

  const {mutate: create} = useExperimentalMutation("plans", "create");
  const {mutate: update} = useExperimentalMutation("plans", "update");
  const {mutate: deleteReq} = useExperimentalMutation("plans", "delete");

  const handleSubmit = (values: PlanCardValues) => {
    const data = {
      name: values.title,
      days: Number(values.days),
      price: values.price,
      description: values.description,
      essential_credit: Number(values.normalCredit),
      signature_credit: Number(values.premiumCredit),
    };
    if (isNew) {
      create({
        variables: {
          data: {...data, is_active: isActive},
        },
        ...events,
      });
    } else {
      update({
        variables: {
          id: id!,
          data,
        },
        ...events,
      });
    }
  };

  const handleRemove = () => {
    deleteReq({
      variables: {
        id: id!,
      },
      ...events,
    });
  };

  return (
    <PanelModalRapper title={isNew ? "ایجاد پلن" : "ویرایش پلن"} {...rest}>
      <Grid container direction='column' alignItems='center' className={classes.root}>
        {isNew ? (
          <PlanCardEditable type='create' onSubmit={handleSubmit} />
        ) : (
          <PlanCardEditable
            type='edit'
            id={id!}
            title={detail?.name || ""}
            price={detail?.price || ""}
            premiumCredit={
              detail?.signature_credit !== undefined ? String(detail?.signature_credit) : ""
            }
            normalCredit={
              detail?.essential_credit !== undefined ? String(detail.essential_credit) : ""
            }
            days={detail?.days ? String(detail.days) : ""}
            description={detail?.description || ""}
            onRemove={() => alert.prompt({}).then(({result}) => result && handleRemove())}
            onSubmit={handleSubmit}
            isData={!!detail}
          />
        )}
      </Grid>
    </PanelModalRapper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 420,
  },
}));
