import {makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import GreenLink from "@elements/GreenLink";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {routes} from "@constant/routes";
import {localStMediaTypeHistoryGet} from "@modules/SearchBar/utils";

const TopCategoriesSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.images.id");
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(480));
  const type = localStMediaTypeHistoryGet();

  const {data: categories} = useExperimentalQuery("categories", "getList", {
    variables: {pageSize: 50},
    staleTime: Infinity,
  });

  return (
    <BoxWithWallpaper
      component='section'
      color={palette.lightGrey[700]}
      className={classes.container}
    >
      <Typography component='h2' size={1.8}>
        {t("topCategoriesSection.browse")}
      </Typography>
      <Grid component='ul' container wrap='wrap' className={classes.categoriesBox}>
        {categories?.results?.map((item) => (
          <Grid component='li' item xs={xxsDown ? 12 : 6} key={item.name}>
            <GreenLink
              component='span'
              size={1.5}
              to={`${routes.landing.search}?categories=${item.id}`.concat(
                type ? `&type=${type}` : ""
              )}
            >
              {item.name}
            </GreenLink>
          </Grid>
        ))}
      </Grid>
    </BoxWithWallpaper>
  );
};

export default TopCategoriesSection;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "2rem 0 7rem",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 3rem",
    },
  },
  categoriesBox: {
    marginTop: ".9rem",
  },
}));
