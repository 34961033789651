import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import {Link} from "react-router-dom";
import ImageCardResponsive from "./ImageCardResponsive";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import createImageUrl from "@util/createImageUrl";

const GetFreeSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.home");
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(400));
  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  return (
    <Box component='section'>
      <Box className={classes.innerBox}>
        <Box className={classes.headingsBox}>
          <Typography
            component='h2'
            size={xsDown ? 2.2 : 2.6}
            align='center'
            className={classes.marginBottom}
          >
            {t("getFreeSection.title")}
          </Typography>
          <Typography size={xsDown ? 1.6 : 1.8} align='center' color={palette.darkGrey.A100}>
            {t("getFreeSection.description")}
          </Typography>
        </Box>
        <Grid component='ul' container justify='center' wrap='wrap'>
          {data?.free_images &&
            Object.keys(data.free_images).map((imageType) => {
              const _image =
                data?.free_images?.[(imageType as unknown) as keyof typeof data.free_images] || {};
              const image = _image as {
                title: string;
                file: string;
                id_code: string;
              };

              return (
                <Grid
                  component='li'
                  key={image.title}
                  item
                  xs={xxsDown ? 12 : 6}
                  sm={4}
                  className={classes.image}
                >
                  <Link to={createImageUrl(imageType, image.title, image.id_code)}>
                    <ImageCardResponsive
                      image={image.file || ""}
                      title={`${
                        imageType === "photo" ? "عکس" : imageType === "vector" ? "وکتور" : "طرح"
                      } رایگان`}
                      ratio={0.665}
                      hoverScaleEffect
                    />
                  </Link>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default GetFreeSection;

const useStyle = makeStyles((theme) => ({
  innerBox: {
    padding: "4.2rem 1.2rem 8.2rem ",
    [theme.breakpoints.down("xs")]: {
      padding: "4.2rem 1.2rem 2.2rem ",
    },
  },
  headingsBox: {
    marginBottom: "2.4rem",
  },
  marginBottom: {
    marginBottom: "2rem",
  },
  image: {
    padding: ".6rem",
  },
}));
