import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {palette} from "@constant/colors";
import Span from "@elements/Span";

interface ICheckedProps {
  className?: string;
  style?: React.CSSProperties;
}
const Checked = (props: ICheckedProps) => {
  const {className, style} = props;
  const classes = useStyle();
  return <Span className={clsx(classes.container, className)} style={style} />;
};

export default Checked;

const useStyle = makeStyles(() => ({
  container: {
    display: "inline-block",
    width: "13px",
    height: "13px",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      bottom: "1px",
      height: "50%",
      width: "1.5px",
      left: "50%",
      transform: "rotate(45deg)",
      transformOrigin: "bottom left",
      backgroundColor: palette.green[200],
    },
    "&::before": {
      position: "absolute",
      content: '""',
      left: "50%",
      bottom: "1px",
      height: "90%",
      width: "1.5px",
      transform: "rotate(-45deg)",
      transformOrigin: "bottom right",
      backgroundColor: palette.green[200],
    },
  },
}));
