import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, CardMedia} from "@material-ui/core";
import Typography from "@elements/Typography";

export interface IBlogCardProps {
  src: string;
  title: string;
  body: string;
}

const BlogCard = (props: IBlogCardProps) => {
  const classes = useStyle();
  const {src, title, body} = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.imageBox}>
        <CardMedia component='img' src={src} className={classes.image} />
      </Box>
      <Box className={classes.textBox}>
        <Typography size={1.7} weight={500}>
          {title}
        </Typography>
        <Typography size={1.44}>{body}</Typography>
      </Box>
    </Box>
  );
};

export default BlogCard;

const useStyle = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    maxWidth: "60rem",
    border: "1px solid #ccd3d3",
    userSelect: "none",
    userDrag: "none",
    cursor: "pointer",
    backfaceVisibility: "hidden",
    margin: "0 auto",
    "&:hover $image": {
      opacity: ".8",
      transform: "scale(1.03)",
    },
  },
  imageBox: {
    overflow: "hidden",
    height: "70%",
  },
  image: {
    height: "100%",
    transition: ".2s ease",
    transform: "scale(1.005)",
  },
  textBox: {
    padding: "2rem 1rem",
    "& *": {
      padding: ".3rem 0",
    },
  },
}));
