import Typography from "@elements/Typography";
import MainLayout from "@layouts/MainLayout";
import {makeStyles} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";
import React from "react";

export default function Custom404() {
  const c = useStyles();
  const history = useHistory();
  console.log(history);

  return (
    <>
      <Helmet>
        <title>{defaultPageTitle}</title>
      </Helmet>
      <MainLayout noDefaultSearchBar>
        <div className={c.container}>
          <Typography className={c.status} size={10} weight={700} color='#212121'>
            404
          </Typography>
          <Typography className={c.text} size={1.8}>
            صفحه مورد نظر یافت نشد
          </Typography>
          <Typography
            color='#028A71'
            size={2.2}
            style={{marginBottom: "1.5rem", cursor: "pointer"}}
            onClick={() => history.goBack()}
          >
            رفتن به صفحه قبل
          </Typography>
          <Link to='/'>
            <Typography size={1.8} color='#028A71'>
              رفتن به صفحه اصلی
            </Typography>
          </Link>
        </div>
      </MainLayout>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 500,
    backgroundColor: "white",
    position: "relative",
    zIndex: 20,
  },
  status: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  },
  text: {
    margin: "-1rem 0 4.5rem",
  },
}));
