/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse20017 } from '../servermodel';
// @ts-ignore
import { UpdateListUser } from '../servermodel';
// @ts-ignore
import { User } from '../servermodel';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreate: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersCreate', 'data', data)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersDelete', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList: async (fromDaysBefore?: number, order?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPartialUpdate: async (id: number, data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersPartialUpdate', 'data', data)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersRead', 'id', id)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateListUser} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetStatus: async (data: UpdateListUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersSetStatus', 'data', data)
            const localVarPath = `/users/set_status/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdate: async (id: number, data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('usersUpdate', 'data', data)
            const localVarPath = `/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreate(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersList(fromDaysBefore?: number, order?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersList(fromDaysBefore, order, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPartialUpdate(id: number, data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateListUser} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSetStatus(data: UpdateListUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateListUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSetStatus(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdate(id: number, data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreate(data: User, options?: any): AxiosPromise<User> {
            return localVarFp.usersCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.usersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList(fromDaysBefore?: number, order?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.usersList(fromDaysBefore, order, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPartialUpdate(id: number, data: User, options?: any): AxiosPromise<User> {
            return localVarFp.usersPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRead(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.usersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateListUser} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetStatus(data: UpdateListUser, options?: any): AxiosPromise<UpdateListUser> {
            return localVarFp.usersSetStatus(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this کاربر.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdate(id: number, data: User, options?: any): AxiosPromise<User> {
            return localVarFp.usersUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersCreateRequest
 */
export interface UsersApiUsersCreateRequest {
    /**
     * 
     * @type {User}
     * @memberof UsersApiUsersCreate
     */
    readonly data: User
}

/**
 * Request parameters for usersDelete operation in UsersApi.
 * @export
 * @interface UsersApiUsersDeleteRequest
 */
export interface UsersApiUsersDeleteRequest {
    /**
     * A unique integer value identifying this کاربر.
     * @type {number}
     * @memberof UsersApiUsersDelete
     */
    readonly id: number
}

/**
 * Request parameters for usersList operation in UsersApi.
 * @export
 * @interface UsersApiUsersListRequest
 */
export interface UsersApiUsersListRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiUsersList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersList
     */
    readonly order?: string

    /**
     * A search term.
     * @type {string}
     * @memberof UsersApiUsersList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof UsersApiUsersList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof UsersApiUsersList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for usersPartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersPartialUpdateRequest
 */
export interface UsersApiUsersPartialUpdateRequest {
    /**
     * A unique integer value identifying this کاربر.
     * @type {number}
     * @memberof UsersApiUsersPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {User}
     * @memberof UsersApiUsersPartialUpdate
     */
    readonly data: User
}

/**
 * Request parameters for usersRead operation in UsersApi.
 * @export
 * @interface UsersApiUsersReadRequest
 */
export interface UsersApiUsersReadRequest {
    /**
     * A unique integer value identifying this کاربر.
     * @type {number}
     * @memberof UsersApiUsersRead
     */
    readonly id: number
}

/**
 * Request parameters for usersSetStatus operation in UsersApi.
 * @export
 * @interface UsersApiUsersSetStatusRequest
 */
export interface UsersApiUsersSetStatusRequest {
    /**
     * 
     * @type {UpdateListUser}
     * @memberof UsersApiUsersSetStatus
     */
    readonly data: UpdateListUser
}

/**
 * Request parameters for usersUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUpdateRequest
 */
export interface UsersApiUsersUpdateRequest {
    /**
     * A unique integer value identifying this کاربر.
     * @type {number}
     * @memberof UsersApiUsersUpdate
     */
    readonly id: number

    /**
     * 
     * @type {User}
     * @memberof UsersApiUsersUpdate
     */
    readonly data: User
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiUsersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreate(requestParameters: UsersApiUsersCreateRequest, options?: any) {
        return UsersApiFp(this.configuration).usersCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDelete(requestParameters: UsersApiUsersDeleteRequest, options?: any) {
        return UsersApiFp(this.configuration).usersDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersList(requestParameters: UsersApiUsersListRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).usersList(requestParameters.fromDaysBefore, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPartialUpdate(requestParameters: UsersApiUsersPartialUpdateRequest, options?: any) {
        return UsersApiFp(this.configuration).usersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersRead(requestParameters: UsersApiUsersReadRequest, options?: any) {
        return UsersApiFp(this.configuration).usersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersSetStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSetStatus(requestParameters: UsersApiUsersSetStatusRequest, options?: any) {
        return UsersApiFp(this.configuration).usersSetStatus(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdate(requestParameters: UsersApiUsersUpdateRequest, options?: any) {
        return UsersApiFp(this.configuration).usersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
