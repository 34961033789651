import React from "react";
import {PieChart as PieRechart, Pie, Cell, ResponsiveContainer} from "recharts";

export default function PieChart({data}: {data: {name: string; value: number}[]}) {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#7b2cbf", "#588157", "#ff4d6d"];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill='#222' textAnchor='middle' dominantBaseline='central'>
        {`(${(percent * 100).toFixed(0)}%) ${data[index]?.name}`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width='100%' height={350}>
      <PieRechart width={350} height={350}>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          labelLine
          label={renderCustomizedLabel}
          outerRadius={120}
          dataKey='value'
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index.toString()}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieRechart>
    </ResponsiveContainer>
  );
}
