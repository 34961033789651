import Typography from "@elements/Typography";
import {Button, ButtonProps} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {darkGrey, green, lightGrey, red} from "@constant/colors";
import {fontNormalizeFactor} from "@elements/Typography/constant";

interface MainButtonClasses {
  root?: string;
  red?: string;
  green?: string;
  transparent?: string;
  labelRed?: string;
  labelGreen?: string;
  labelTransparent?: string;
  disabled?: string;
}

export interface MainButtonProps extends Omit<ButtonProps, "color"> {
  color?: "green" | "red" | "transparent";
  classes?: MainButtonClasses;
}

export function MainButton({
  onClick: handleClick,
  children,
  color = "green",
  disabled,
  fullWidth,
  classes: externalClasses,
  ...rest
}: MainButtonProps) {
  const classes = useStyles();
  const labelClasses = {
    red: externalClasses?.labelRed,
    green: externalClasses?.labelGreen,
    transparent: externalClasses?.labelTransparent,
  };
  const labelSizes = {
    red: 1.9 * fontNormalizeFactor,
    green: 1.6 * fontNormalizeFactor,
    transparent: 1.6 * fontNormalizeFactor,
  };
  return (
    <Button
      fullWidth={fullWidth}
      classes={{label: clsx(classes[`label_${color}` as const], labelClasses[color])}}
      className={clsx(
        classes.root,
        externalClasses?.root,
        classes[color],
        externalClasses?.[color],
        {
          [clsx(classes.disabled, externalClasses?.disabled)]: disabled,
        }
      )}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    >
      <Typography
        noWrap
        color='inherit'
        className={classes.label}
        weight={color === "transparent" ? 400 : 500}
        size={labelSizes[color]}
      >
        {children}
      </Typography>
    </Button>
  );
}

export default MainButton;

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    height: "5.8rem",
    boxShadow: "inset 0 0 2px 0 rgb(255 255 255 / 50%)",
    "&:hover": {
      boxShadow: "none",
      "&.MuiTypography-root": {
        color: "white",
      },
    },
  },
  red: {
    backgroundColor: red[100],
    borderColor: red[200],
    paddingLeft: "2rem",
    paddingRight: "2rem",
    minWidth: "85%",
    "&:disabled": {
      backgroundColor: red[100],
      opacity: 0.4,
    },
    "&:hover": {
      backgroundColor: red[200],
    },
  },
  green: {
    backgroundColor: green[200],
    borderColor: green[300],
    paddingLeft: "3rem",
    paddingRight: "3rem",
    "&:disabled": {
      backgroundColor: green[200],
      opacity: 0.4,
    },
    "&:hover": {
      backgroundColor: green[300],
    },
  },
  transparent: {
    // buttonSecondary: {
    //   border: `1px solid ${palette.green[200]} !important`,
    //   backgroundColor: "white",
    //   "& *": {
    //     color: `${palette.green[200]} !important`,
    //   },
    //   "&:hover": {
    //     // backgroundColor: "rgba(2,163,136,.05)",
    //     color: "white !important",
    //   },
    // },
    backgroundColor: "transparent",
    borderColor: green[200],
    paddingLeft: "8rem",
    paddingRight: "8rem",
    "&:disabled": {
      backgroundColor: lightGrey.A800,
      opacity: 0.7,
      borderColor: darkGrey[50],
    },
    "&:hover": {
      backgroundColor: green[300],
      "& *": {
        color: "white",
      },
    },
  },
  label_red: {
    color: "white",
  },
  label_green: {
    color: "white",
  },
  label_transparent: {
    // FIXME not in palette
    color: green[200],
  },
  disabled: {
    opacity: 0.4,
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
