import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {palette} from "@constant/colors";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {useNavbarContext} from "@context/NavbarContext";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Close from "./Close";
import {headerHeightSmall} from "@modules/Header/constant";
import {NavbarParentItem, NavbarChildItem} from "@layouts/MainLayout";

interface IProps {
  navbarItems: NavbarParentItem[];
}

const NavbarTabletSize = ({navbarItems}: IProps) => {
  const [activeItem, setActiveItem] = useState<NavbarParentItem | null>(null);
  const [showChildrenList, setShowChildrenList] = useState<boolean>(false);
  const [activeChild, setActiveChild] = useState<NavbarChildItem | null>(null);
  const [showGrandChildrenList, setShowGrandChildrenList] = useState<boolean>(false);
  const {openNavbar, setOpenNavbar} = useNavbarContext();
  const classes = useStyle({showChildrenList, showGrandChildrenList});
  const {t} = useTranslation("modules.mainLayout");

  const handleClickParent = (item: NavbarParentItem) => {
    setActiveItem(item);
    setShowChildrenList(true);
  };

  const handleClickChild = (item: NavbarChildItem) => {
    setActiveChild(item);
    setShowGrandChildrenList(true);
  };

  useEffect(() => {
    if (!openNavbar) {
      setShowChildrenList(false);
      setShowGrandChildrenList(false);
    }
  }, [openNavbar]);

  return (
    <Box component='nav' className={classes.container}>
      {/* first level items will render in this container */}
      <Box component='ul'>
        <Grid container className={clsx(classes.item, classes.noCursor)}>
          <Close onClick={() => setOpenNavbar(false)} />
        </Grid>
        {navbarItems.map((item) => (
          <Grid
            container
            component='li'
            key={item.name}
            className={classes.item}
            onClick={() => handleClickParent(item)}
          >
            <Typography color='inherit'>{item.name}</Typography>
            <ChevronLeft className={classes.arrow} />
          </Grid>
        ))}
      </Box>
      {/* active item's children will render in this container */}
      <Box component='ul' className={clsx(classes.absolute, classes.childrenList)}>
        <Grid
          container
          className={clsx(classes.item, classes.back)}
          onClick={() => setShowChildrenList(false)}
        >
          <Typography color='inherit' style={{display: "flex", alignItems: "center"}}>
            <ChevronRight className={clsx(classes.arrow, classes.margin)} />
            {t("navbarTabletSize.back")}
          </Typography>
        </Grid>
        {activeItem?.items?.map((item, index) => (
          // key will change to item.title
          <Grid
            container
            component='li'
            key={index.toString()}
            className={clsx(classes.item, classes.subChildItem)}
            onClick={() => handleClickChild(item)}
          >
            <Typography color='inherit'>{item.name}</Typography>
            {!!item.items?.length && <ChevronLeft className={classes.arrow} />}
          </Grid>
        ))}
      </Box>
      {/* active child's children (means active item's grand children) will render in this container */}
      <Box component='ul' className={clsx(classes.absolute, classes.grandChildrenList)}>
        <Grid
          container
          className={clsx(classes.item, classes.back)}
          onClick={() => setShowGrandChildrenList(false)}
        >
          <Typography color='inherit' style={{display: "flex", alignItems: "center"}}>
            <ChevronRight className={clsx(classes.arrow, classes.margin)} />
            {activeItem?.name}
          </Typography>
        </Grid>
        {activeChild?.items?.map((item, index) => (
          // grand child items have styles exactly like child items
          <Link to={item.href} key={index.toString()} onClick={() => setOpenNavbar(false)}>
            <Grid container component='li' className={clsx(classes.item, classes.subChildItem)}>
              <Typography color='inherit'>{"name" in item ? item.name : item.term}</Typography>
            </Grid>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default NavbarTabletSize;

interface StyleProps {
  showChildrenList: boolean;
  showGrandChildrenList: boolean;
}

const useStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: palette.darkGrey.A600,
    zIndex: 3500,
  },
  item: {
    width: "100%",
    height: headerHeightSmall,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    borderBottom: `1px solid ${palette.darkGrey[300]}`,
    cursor: "pointer",
    color: "white",
    "&:hover": {
      color: palette.green[200],
    },
    [theme.breakpoints.down(300)]: {
      padding: "0 18px",
    },
  },
  absolute: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "white",
    transition: ".3s ease-in-out",
  },
  childrenList: ({showChildrenList}: StyleProps) => ({
    transform: showChildrenList ? undefined : `translateX(-100%)`,
  }),
  grandChildrenList: ({showGrandChildrenList}: StyleProps) => ({
    transform: showGrandChildrenList ? undefined : `translateX(-100%)`,
  }),
  subChildItem: {
    borderBottom: "none",
    color: palette.darkGrey.A700,
    height: "auto",
    margin: "30px 0",
  },
  arrow: {
    fontSize: "2.5rem",
    color: "inherit",
    marginRight: "-0.7rem",
  },
  noCursor: {
    cursor: "auto",
  },
  back: {
    borderBottom: `1px solid ${palette.lightGrey.A100}`,
    color: palette.darkGrey.A700,
  },
  margin: {
    marginRight: "0.7rem",
    marginLeft: "-0.7rem",
  },
}));
