import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, useMediaQuery, Theme} from "@material-ui/core";
import {fontNormalizeFactor} from "./Typography/constant";
import {green} from "@constant/colors";

export default function EditorContentWrapper({content}: {content: string}) {
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const classes = useStyle({xsDown});

  return <Box dangerouslySetInnerHTML={{__html: content}} className={classes.container} />;
}

const useStyle = makeStyles(() => ({
  container: ({xsDown}: {xsDown: boolean}) => ({
    "& *": {
      color: "#0C0D0D",
      fontSize: (xsDown ? 15 : 16) * fontNormalizeFactor,
    },
    "& p": {
      lineHeight: xsDown ? "2.8rem" : "3.2rem",
      marginBottom: ".75rem",
    },
    "& img": {
      display: "block",
      width: "100%",
      maxWidth: 800,
      aspectRatio: "16 / 9",
      margin: "1rem auto 2rem",
      maxHeight: "480px",
    },
    "& iframe": {
      display: "block",
      width: "100%",
      maxWidth: 800,
      aspectRatio: "16 / 9",
      margin: "1rem auto 2rem",
    },
    "& h1,h2,h3,h4,h5,h6": {
      fontWeight: "bolder",
    },
    "& h1": {
      fontSize: 32 * fontNormalizeFactor * 1.1,
    },
    "& h2": {
      fontSize: 24 * fontNormalizeFactor * 1.1,
    },
    "& h3": {
      fontSize: 18.72 * fontNormalizeFactor * 1.1,
    },
    "& h4": {
      fontSize: 16 * fontNormalizeFactor * 1.1,
    },
    "& h5": {
      fontSize: 13.28 * fontNormalizeFactor * 1.1,
    },
    "& h6": {
      fontSize: 10.72 * fontNormalizeFactor,
    },
    "& a": {
      color: green[500],
    },
    "& ul": {
      listStyleType: "auto",
      paddingLeft: 16,
    },
  }),
}));
