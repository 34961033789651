import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import clsx from "clsx";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";

export interface IImageCardProps {
  image: string;
  id?: number;
  title?: string;
}

const ImageCard = (props: IImageCardProps) => {
  const classes = useStyle(props);
  const {title, id} = props;

  return (
    <Box className={clsx(classes.container)} title={title}>
      <Box className={classes.innerBox}>
        <Typography
          component='h3'
          size={1.6}
          color='white'
          align='right'
          weight={500}
          className={classes.title}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default ImageCard;

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
    "&:hover $innerBox": {
      filter: "brightness(0.7)",
    },
    "&:hover $id": {
      visibility: "visible",
    },
  },
  innerBox: ({image}: Partial<IImageCardProps>) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
    transition: ".1s",
    border: ".1px transparent", // this forces the image to stay exactly in it's container
  }),
  positionAbsolute: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  title: {
    padding: "1.6rem",
    width: "100%",
    display: "block",
    background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(1,1,1,0.7) 70%)",
  },
  id: {
    margin: "1.6rem",
    position: "absolute",
    textShadow: "1px 1px 2px rgba(0,0,0,0.75)",
    top: 0,
    left: 0,
    visibility: "hidden",
    "&:hover": {
      color: palette.green[200],
    },
  },
}));
