import React, {useState} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import MainButton from "@elements/button/MainButton";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import {palette} from "@constant/colors";
import RadioButtonGroupForPricingPage from "./RadioButtonGroupForPricingPage";
import PlanCard from "./PlanCard";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import Spinner from "@elements/Spinner";
import {routes} from "@constant/routes";
import {useHistory} from "react-router-dom";
import {convertToNumberFormatWithComma} from "@util/convertToNumberFormatWithComma";
import useAuthenticateUser from "@hook/useAuthenticateUser";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import useServerErrorNotify from "@hook/useServerErrorNotify";

const ChoosePlanSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.pricing");
  const history = useHistory();
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(500));
  const handleAuthenticateUser = useAuthenticateUser();
  const {mode} = useGetQueryString();
  const serverErrorNotify = useServerErrorNotify();

  const [plan, setPlan] = useState({
    essential: "",
    signature: "",
    joint: "",
  });

  const {data: _data, isLoading} = useExperimentalQuery("plans", "getList", {
    variables: {
      isActive: "true",
    },
    staleTime: Infinity,
    onError: (error) => serverErrorNotify(error),
  });

  const handleSubmit = (id: string) => {
    handleAuthenticateUser().then(() => {
      id !== "" &&
        history.push(
          // passing mode=redirect(to help user to being redirected back after purchasing) if it's exist in the url already
          `${routes.landing.pay}?type=plan&planId=${id}`.concat(mode ? `&mode=${mode}` : "")
        );
    });
  };

  const results = _data?.results.sort((a, b) => a.days - b.days) || [];
  const essentialPlans = results?.filter((item) => item.signature_credit === 0);
  const signaturePlans = results?.filter((item) => item.essential_credit === 0);
  const jointPlans = results?.filter(
    (item) => item.essential_credit !== 0 && item.signature_credit !== 0
  );
  const plansInfo = [
    {
      name: "signature" as const,
      items: signaturePlans.map(({price, ...item}) => ({
        value: item.id ? String(item.id) : "",
        tooltipText: item.description,
        labelItems: [
          item.name,
          [item.signature_credit, `${t("choosePlanSection.credit")} پرمیوم`],
          [convertToNumberFormatWithComma(price ? Number(price) : 0), "تومان"],
        ],
      })),
      planCardProps: {
        title: t("choosePlanSection.signaturePlanTitle"),
        description: t("choosePlanSection.signaturePlanDescription"),
        headerClassName: classes.background,
      },
      buttonColor: "transparent" as const,
    },
    {
      name: "joint" as const,
      items: jointPlans.map(({price, ...item}) => ({
        value: item.id ? String(item.id) : "",
        tooltipText: item.description,
        labelItems: [
          item.name,
          [item.essential_credit, `${t("choosePlanSection.credit")} عادی`],
          [item.signature_credit, `${t("choosePlanSection.credit")} پرمیوم`],
          [convertToNumberFormatWithComma(price ? Number(price) : 0), "تومان"],
        ],
      })),
      planCardProps: {
        title: "پلن‌های مشترک",
        description: "برای دانلود عکس‌های عادی و پرمیوم",
        headerClassName: classes.bgGreen,
      },
      buttonColor: "green" as const,
    },

    {
      name: "essential" as const,
      items: essentialPlans.map(({price, ...item}) => ({
        value: item.id ? String(item.id) : "",
        tooltipText: item.description,
        labelItems: [
          item.name,
          [item.essential_credit, `${t("choosePlanSection.credit")} عادی`],
          [convertToNumberFormatWithComma(price ? Number(price) : 0), "تومان"],
        ],
      })),
      planCardProps: {
        title: t("choosePlanSection.essentialPlanTitle"),
        description: t("choosePlanSection.essentialPlanDescription"),
      },
      buttonColor: "transparent" as const,
    },
  ];

  return (
    <BoxWithWallpaper color={palette.blue[100]} className={classes.container}>
      <Typography size={2.8} align='center' className={classes.title}>
        {t("choosePlanSection.title")}
      </Typography>
      {!isLoading ? (
        <Grid container justify='center' wrap='wrap' className={classes.cardsBox}>
          {plansInfo.map((item, index) => (
            <PlanCard key={index.toString()} {...item.planCardProps}>
              <RadioButtonGroupForPricingPage
                name={item.name}
                onChange={(v) => setPlan({...plan, [item.name]: v})}
                items={item.items.map(({value, tooltipText, labelItems}, index) => ({
                  value,
                  tooltipText,
                  label: (
                    <Grid key={index.toString()} container className={classes.labelBox}>
                      {labelItems.map((item, index) =>
                        !Array.isArray(item) ? (
                          <Typography
                            key={index.toString()}
                            size={xxsDown ? 1.1 : 1.4}
                            weight={700}
                          >
                            {item}
                          </Typography>
                        ) : (
                          <Grid container key={index.toString()} style={{gap: 3, width: "auto"}}>
                            {item.map((i, index) => (
                              <Typography
                                span
                                key={index.toString()}
                                size={xxsDown ? 1.1 : 1.4}
                                weight={index === 0 ? 700 : 400}
                              >
                                {i}
                              </Typography>
                            ))}
                          </Grid>
                        )
                      )}
                    </Grid>
                  ),
                }))}
              />
              <Box className={classes.buttonBox}>
                <MainButton
                  fullWidth
                  color={item.buttonColor}
                  onClick={() => handleSubmit(plan[item.name])}
                >
                  {t("choosePlanSection.buyCredit")}
                </MainButton>
              </Box>
            </PlanCard>
          ))}
        </Grid>
      ) : (
        <Spinner />
      )}
    </BoxWithWallpaper>
  );
};

export default ChoosePlanSection;

const useStyle = makeStyles((theme) => ({
  container: {
    paddingBottom: "8rem",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "4rem",
    },
  },
  title: {
    padding: "6.3rem 0 3.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.4rem",
      padding: "4rem 0 2rem",
    },
  },
  cardsBox: {
    // flexDirection: "column",
    alignItems: "center",
    gap: "2.5rem",
    // gap: "1.7rem",
    // [theme.breakpoints.down(1050)]: {},
  },
  background: {
    backgroundColor: "#111",
  },
  buttonBox: {
    padding: "1.5rem",
  },
  labelBox: {
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  margin: {
    marginLeft: ".5rem",
  },
  marginTop: {
    marginTop: "-.5rem",
  },
  bgGreen: {
    backgroundColor: palette.green[200],
  },
}));
