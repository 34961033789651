import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import RadioButtonGroup from "@elements/RadioButtonGroup";
import {MainButton} from "@elements/button";
import Divider from "@elements/Divider";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {RetrieveImage as Image} from "@schema";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import GreenLink from "@elements/GreenLink";
import {useAuth} from "@context/AuthContext";
import {baseUrl} from "@constant/baseUrl";
import {routes} from "@constant/routes";
import useAuthenticateUser from "@hook/useAuthenticateUser";
import {setUserLastLocationInLs} from "@util/userLastLocation";
import {useLocation} from "react-router-dom";
import {palette} from "@constant/colors";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import ProgressWithLabel from "@elements/ProgressWithLabel";
import {useQueryClient} from "react-query";
import {ApiNamespaces} from "@api";
import {useParams} from "react-router-dom";

const Download = ({data}: {data: Image}) => {
  const {slug} = useParams<{slug: string}>();
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
  const [buyByPlan, setBuyByPlan] = useState<boolean | undefined>(
    data.is_free || !!data.user_bought ? false : undefined
  );
  const classes = useStyle();
  const alert = useAlert();
  const {user} = useAuth();
  const {t} = useTranslation("modules.pages.images.id");
  const queryClient = useQueryClient();
  const location = useLocation();
  const handleAuthenticateUser = useAuthenticateUser();
  const apiErrorHandler = useApiErrorHandler();

  const hasActivePlan = !!(user && user.plans?.length);

  const items = [
    {value: "cash", label: t("download.cashOptionLabel", {cost: data.price})},
    {value: "plan", label: t("download.planOptionLabel")},
  ];

  React.useEffect(() => {
    user && buyByPlan === undefined && setBuyByPlan(hasActivePlan ? true : false);
  }, [buyByPlan, hasActivePlan, user]);

  const {mutate: buyByPlanReq, isLoading: isLoadingBuyByPlan} = useExperimentalMutation(
    "buyByPlan",
    "create"
  );
  const {mutate: createInvoice, isLoading: isLoadingCreateInvoice} = useExperimentalMutation(
    "imagesInvoices",
    "create"
  );
  const {mutate: buyImage, isLoading: isLoadingPay} = useExperimentalMutation(
    "imagesInvoices",
    "createPay"
  );

  const {mutate: mutateDownload, isLoading: isLoadingDownload} = useExperimentalMutation(
    "images",
    "download",
    {
      onError: (err) => apiErrorHandler(err),
      onSuccess: (res) => {
        const blob = new Blob([res as any], {type: "octet/stream"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `Parsimages-${data.id_code}.${data.ext}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      onSettled: () => setDownloadProgress(null),
    }
  );

  const handleDownload = () => {
    mutateDownload({
      variables: {
        id: data.id!,
        axiosConfig: {
          responseType: "arraybuffer",
          onDownloadProgress: (e: any) => {
            const progress = (e.loaded / e.total) * 100;
            setDownloadProgress(Math.round(progress));
          },
        },
      },
    });
  };

  const handleCreateInvoice = () => {
    createInvoice({
      variables: {data: {image: Number(data.id)}},
      onError: (error) => apiErrorHandler(error),
      onSuccess: (data) => {
        buyImage({
          variables: {id: String(data.id!), data: {}},
          onError: (error) => apiErrorHandler(error),
          onSuccess: (res) => {
            setUserLastLocationInLs(location.pathname);
            const response = (res as unknown) as {link: string};
            window.location.href = response.link;
          },
        });
      },
    });
  };

  const handleBuyByPlan = () => {
    hasActivePlan &&
      buyByPlanReq({
        variables: {data: {image: Number(data.id)}},
        onSuccess: () => {
          queryClient.invalidateQueries([
            ApiNamespaces.images.Query.readDetailByCodeId.queryKey,
            {idCode: slug.split("-").slice(-1).toString()},
          ]);
          queryClient.invalidateQueries(ApiNamespaces.auth.Query.getUser);
          handleDownload();
        },
        onError: (err) => {
          const error = err.response?.data as {
            code: string;
          };
          // error.code === '4' => user has already bought this image
          error.code === "4" ? handleDownload() : apiErrorHandler(err);
        },
      });
  };

  return (
    <Box component='section'>
      <Typography
        span
        size={2.2}
        weight={500}
        color={data.user_bought ? palette.green[200] : undefined}
        style={{marginBottom: ".5rem"}}
      >
        {data.user_bought
          ? "خریداری شده"
          : `${data.credit} ${t("download.credit")} ${data.is_premium ? "پرمیوم" : ""}`}
      </Typography>
      {!data.user_bought && (
        <Typography
          span
          size={1.7}
          weight={500}
          color={data.is_free ? palette.green[200] : undefined}
          // style={{marginRight: ".75rem", textDecoration: data.is_free ? "line-through" : ""}}
          style={{marginRight: ".75rem"}}
        >
          {/* {t("download.fromPlan")} */}
          {data.is_free ? "(رایگان)" : "(کسر از پلن)"}
        </Typography>
      )}
      <Box style={{display: "flex", gap: ".25rem", margin: ".5rem 0"}}>
        <Typography span> {t("download.imageType")}:</Typography>
        <Typography span weight={700}>
          {data.is_premium ? t("download.premium") : t("download.essential")}
        </Typography>
      </Box>
      <Box className={classes.radioButtonGroupBox}>
        <RadioButtonGroup
          disabled={data.is_free || !!data.user_bought}
          color='dark'
          items={hasActivePlan ? items : items.filter((_, index) => index === 0)}
          value={buyByPlan !== undefined ? (buyByPlan ? "plan" : "cash") : undefined}
          name='payment'
          onChange={(v) => setBuyByPlan(v === "plan" ? true : false)}
        />
        <Divider />
      </Box>
      <Box className={classes.buttonBox}>
        <MainButton
          color='green'
          fullWidth
          classes={{labelRed: classes.labelRed}}
          disabled={
            isLoadingBuyByPlan || isLoadingCreateInvoice || isLoadingPay || isLoadingDownload
          }
          onClick={() =>
            handleAuthenticateUser().then(() => {
              data.is_free || data.user_bought
                ? handleDownload()
                : buyByPlan
                ? handleBuyByPlan()
                : handleCreateInvoice();
            })
          }
        >
          {data.is_free
            ? "دریافت رایگان"
            : data.user_bought
            ? "دریافت"
            : buyByPlan
            ? t("download.continueWithPlan")
            : t("download.continueWithPurchase")}
        </MainButton>
        {(!user || (!data.user_bought && !data.is_free && !hasActivePlan)) && (
          <GreenLink
            // with the help of mode=redirect and setting user location in localStorage user will be
            // redirected back after purchasing a new plan
            onClick={() => setUserLastLocationInLs(location.pathname)}
            to={{pathname: routes.landing.pricing, search: "mode=redirect"}}
            size={1.8}
          >
            {t("download.buyPlan")}
          </GreenLink>
        )}
      </Box>
      <Divider />
      {!!downloadProgress && <ProgressWithLabel value={downloadProgress} />}
    </Box>
  );
};

export default Download;

const useStyle = makeStyles((theme) => ({
  radioButtonGroupBox: {
    marginTop: "2rem",
  },
  sizeButtons: {
    marginTop: "2.5rem",
  },
  dimension: {
    marginTop: "1.5rem",
    direction: "rtl",
  },
  gap: {
    gap: ".5rem",
  },
  buttonBox: {
    margin: "1.5rem 0 4rem",
    textAlign: "center",
    "& > *:nth-child(2)": {
      display: "inline-block",
      marginTop: "2rem",
    },
  },
  labelRed: {
    [theme.breakpoints.down(360)]: {
      fontSize: "5.7vw",
    },
  },
}));
