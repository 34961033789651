import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Grid, Button} from "@material-ui/core";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {Span} from "@elements/Span";

export interface IFiltersButtonProps {
  open?: boolean;
  onClick?: () => void;
  className?: string;
  icon?: JSX.Element;
}
const FiltersButton = (props: IFiltersButtonProps) => {
  const {open, onClick: handleClick, className, icon} = props;
  const classes = useStyle({open});
  const {t} = useTranslation("modules.pages.search");
  return (
    <Button className={clsx(classes.button, className)} onClick={handleClick}>
      <Grid container justify='flex-start' className={classes.widthAuto}>
        <svg viewBox='-287.75 385.885 27.219 21.063' className={classes.icon}>
          <path d='M-262.542 389.734h-11.625c-.442-1.618-1.91-2.817-3.667-2.817-1.756 0-3.224 1.199-3.667 2.817h-4.234a1 1 0 100 2h4.234c.443 1.617 1.91 2.816 3.667 2.816s3.224-1.199 3.667-2.816h11.625a1 1 0 100-2zm-15.292 2.816a1.817 1.817 0 110-3.633 1.817 1.817 0 010 3.633zM-286.735 402.109a1 1 0 001 1h11.625c.443 1.617 1.91 2.816 3.667 2.816 1.756 0 3.224-1.199 3.667-2.816h4.234a1 1 0 100-2h-4.234c-.443-1.618-1.91-2.817-3.667-2.817-1.756 0-3.225 1.199-3.667 2.817h-11.625a1 1 0 00-1 1zm14.475 0a1.817 1.817 0 113.634 0 1.817 1.817 0 01-3.634 0z' />
        </svg>
        {/* typography's "id" property has been used in a selector in /pages/search */}
        <Typography span color='white' id='filters-text'>
          {t("filtersButton.filters")}
        </Typography>
      </Grid>
      {icon && <Span>{icon}</Span>}
    </Button>
  );
};

export default FiltersButton;

// !! ATTENTION: some styles comes from it's parent, because it was needed to change it's width related to another component width => pages/search
const useStyle = makeStyles((theme) => ({
  button: {
    backgroundColor: palette.darkGrey.A200,
    color: theme.palette.background.paper,
    padding: "0 2rem 0 4rem",
    minWidth: 40,
    borderRadius: 0,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    borderBottom: `1px solid ${palette.darkGrey.A700}`,
    "&:hover": {
      backgroundColor: palette.darkGrey.A200,
    },
    "&:hover $icon": {
      fill: palette.green[200],
      transform: " scaleX(-1) rotateY(180deg)",
    },
  },
  icon: ({open}: {open?: boolean}) => ({
    fill: open ? palette.green[200] : palette.darkGrey[200],
    transform: "scaleX(-1)",
    width: "2.7219rem",
    height: "2.1063rem",
  }),
  widthAuto: {
    gap: "1.6rem",
    width: "auto",
  },
}));
