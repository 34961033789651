import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import BoardsTable from "@elements/table/boards";
import {palette} from "@constant/colors";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import Spinner from "@elements/Spinner";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {ApiNamespaces} from "@api";
import {useQueryClient} from "react-query";
import {useAlert} from "@context/AlertContext";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import Pagination from "@elements/Pagination";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {routes} from "@constant/routes";
import {useAuth} from "@context/AuthContext";
import {useHistory} from "react-router-dom";
import {useModalDispatch} from "@context/ModalContext";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {useErrorHandlerContext} from "@context/ErrorHandlerContext";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";

const Boards = () => {
  const classes = useStyle();
  const {t} = useTranslation("pages.boards");
  const queryClient = useQueryClient();
  const alert = useAlert();
  const pageSize = 20;
  const {page} = useGetQueryString();
  const {user, loadedUser} = useAuth();
  const history = useHistory();
  const handleOpenBoardsModal = useModalDispatch("createBoard");
  const serverErrorNotify = useServerErrorNotify();
  const {setErrorStatusCode} = useErrorHandlerContext();
  const apiErrorHandler = useApiErrorHandler();

  React.useEffect(() => {
    loadedUser && !user && history.replace("/");
  });

  const revalidate = () => {
    queryClient.invalidateQueries(ApiNamespaces.boards.Query.getList.queryKey);
    queryClient.invalidateQueries(ApiNamespaces.boards.Query.getRecentBoardsList.queryKey);
  };

  const {data, isLoading} = useExperimentalQuery("boards", "getList", {
    variables: {
      pageSize,
      page: page ? Number(page) : 1,
    },
    onError: (error) => {
      setErrorStatusCode(error);
      serverErrorNotify(error);
    },
  });

  const {mutate: createBoard} = useExperimentalMutation("boards", "create", {
    onError: (err) => apiErrorHandler(err),
    onSuccess: () => {
      alert.success({text: "بورد با موفقیت ایجاد شد"});
      revalidate();
    },
  });

  const {mutate: updateBoard} = useExperimentalMutation("boards", "update", {
    onError: (err) => apiErrorHandler(err),
    onSuccess: () => {
      alert.success({text: "بورد با موفقیت ویرایش شد"});
      revalidate();
    },
  });

  const {mutate: deleteBoard, isLoading: isDeleting} = useExperimentalMutation("boards", "delete", {
    onError: (err) => apiErrorHandler(err),
    onSuccess: () => {
      alert.success({text: "بورد با موفقیت حذف شد"});
      revalidate();
    },
  });

  return (
    <>
      <Helmet>
        <title>{defaultPageTitle}</title>
      </Helmet>
      <Box className={classes.container}>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          className={classes.boardsContainer}
        >
          <Grid container direction='column' alignItems='center' className={classes.myBoards}>
            <Typography span size={2.4} weight={700} height={2.2}>
              {data?.count}
            </Typography>
            <Typography span size={1.4} weight={500}>
              {t("boardsInfo.myBoards")}
            </Typography>
          </Grid>
          <Typography
            span
            size={1.4}
            weight={700}
            color={palette.darkGrey.A100}
            className={classes.create}
            onClick={() =>
              handleOpenBoardsModal({
                onSave: (name) => {
                  createBoard({
                    variables: {data: {name}},
                  });
                },
              })
            }
          >
            {t("boardsInfo.createBoard")}
          </Typography>
        </Grid>

        {isLoading && <Spinner />}
        <Box className={classes.tableBox}>
          {!isLoading && !data?.count && (
            <Typography className={classes.noBoardMessage} size={2} align='center'>
              شما هنوز بوردی ایجاد نکرده‌اید
            </Typography>
          )}
          {data && !!data.count && (
            <>
              <BoardsTable>
                {data.results.map(({name, id, last_update, images_count, cover}) => (
                  <BoardsTable.Row
                    key={name}
                    name={name || ""}
                    files={images_count ? Number(images_count) : 0}
                    lastUpdate={last_update ? new Date(last_update) : new Date()}
                    thumbnail={cover}
                    to={routes.landing.boardsDetail(id!)}
                    onSubmitName={(name) =>
                      name && updateBoard({variables: {id: id || 0, data: {name}}})
                    }
                    onDelete={() =>
                      alert
                        .prompt({})
                        .then(({result}) => result && deleteBoard({variables: {id: id || 0}}))
                    }
                  />
                ))}
              </BoardsTable>
              {data.count > pageSize && (
                <div style={{marginTop: "8rem"}}>
                  <Pagination count={data?.count || 0} pageSize={pageSize} />
                </div>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Boards;

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: palette.lightGrey[500],
  },
  boardsContainer: {
    backgroundColor: "rgba(102,110,110,0.2)",
    height: "5.6rem",
  },
  myBoards: {
    width: "16rem",
  },
  create: {
    width: "13rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  tableBox: {
    padding: "7rem 3rem 15rem",
    minHeight: "48vh",
    maxWidth: "115rem",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 12rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 6rem",
    },
  },
  noBoardMessage: {
    padding: "4rem 1rem",
  },
}));
