import {
  CollectionsApi,
  CollectionsApiCollectionsCreateRequest,
  CollectionsApiCollectionsListRequest,
  CollectionsApiCollectionsReadRequest,
} from "@schema/api";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const collectionsApiInstance = new CollectionsApi(undefined, undefined, axiosInstance);

export const collectionsAPI = makeApi({
  identifier: "collections",
  Query: {
    getList: createQuery(async (params: CollectionsApiCollectionsListRequest) => {
      const {data} = await collectionsApiInstance.collectionsList(params);
      return data;
    }),
    readDetail: createQuery(async (params: CollectionsApiCollectionsReadRequest) => {
      const {data} = await collectionsApiInstance.collectionsRead(params);
      return data;
    }),
  },
  Mutation: {
    create: async (params: CollectionsApiCollectionsCreateRequest) => {
      const {data} = await collectionsApiInstance.collectionsCreate(params);
      return data;
    },
  },
});
