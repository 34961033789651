/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Delete, Save} from "@material-ui/icons";
import TextInput from "./TextInput";
import clsx from "clsx";
import Spinner from "@elements/Spinner";

interface ICommonProps {
  onSubmit?: (value: PlanCardValues) => void;
}

interface Create {
  type: "create";
}

interface Edit {
  type: "edit";
  title: string;
  price: string;
  premiumCredit: string;
  normalCredit: string;
  days: string;
  description: string;
  id: number;
  onRemove?: (id: number) => void;
  isData?: boolean;
}

type IProps = (ICommonProps & Create) | (ICommonProps & Edit);

export interface PlanCardValues {
  title: string;
  price: string;
  premiumCredit: string;
  normalCredit: string;
  days: string;
  description: string;
}

const PlanCardEditable = (props: IProps) => {
  const {type, onSubmit} = props;
  const cTitle = "title" in props ? props.title : undefined;
  const cPrice = "price" in props ? props.price : undefined;
  const cPremiumCredit = "premiumCredit" in props ? props.premiumCredit : undefined;
  const cNormalCredit = "normalCredit" in props ? props.normalCredit : undefined;
  const cDays = "days" in props ? props.days : undefined;
  const cDescription = "description" in props ? props.description : undefined;
  const onRemove = "onRemove" in props ? props.onRemove : undefined;
  const id = "id" in props ? props.id : undefined;
  const isData = "isData" in props ? props.isData : undefined;
  const {t} = useTranslation("elements");
  const classes = useStyle();
  const [values, setValues] = useState<PlanCardValues>({
    title: cTitle || "",
    price: cPrice || "",
    premiumCredit: cPremiumCredit || "",
    normalCredit: cNormalCredit || "",
    days: cDays || "",
    description: cDescription || "",
  });

  const isValid =
    values.title &&
    values.description &&
    values.price &&
    values.normalCredit &&
    values.premiumCredit &&
    values.days;

  useEffect(() => {
    cTitle && setValues((prev) => ({...prev, title: cTitle}));
    cPrice && setValues((prev) => ({...prev, price: cPrice}));
    cPremiumCredit && setValues((prev) => ({...prev, premiumCredit: cPremiumCredit}));
    cNormalCredit && setValues((prev) => ({...prev, normalCredit: cNormalCredit}));
    cDays && setValues((prev) => ({...prev, days: cDays}));
    cDescription && setValues((prev) => ({...prev, description: cDescription}));
  }, [cDays, cDescription, cNormalCredit, cPremiumCredit, cPrice, cTitle]);

  const rows = [
    {name: "title", title: t("panel.common.title")},
    {name: "price", title: t("panel.planCard.price"), type: "number"},
    {
      name: "premiumCredit",
      title: t("panel.planCard.premiumCredit"),
      type: "number",
    },
    {
      name: "normalCredit",
      title: t("panel.planCard.normalCredit"),
      type: "number",
    },
    {name: "days", title: t("panel.planCard.planDays"), type: "number"},
    {name: "description", title: t("panel.common.description"), multiline: true, rows: 3},
  ];

  const handleChange = (value: string, name: keyof typeof values) => {
    setValues((prev) => ({...prev, [name]: value}));
  };

  return (
    <Box className={classes.container}>
      {type === "edit" && !isData ? (
        <Spinner />
      ) : (
        <>
          {rows.map(({name: _name, title, ...rest}) => {
            const name = _name as keyof typeof values;
            return (
              <Grid container direction='column' className={classes.wrapper} key={title}>
                <Typography color='#757575'>{title}</Typography>
                <TextInput value={values[name]} onChange={(v) => handleChange(v, name)} {...rest} />
              </Grid>
            );
          })}
          <Grid container className={classes.buttonBox}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              className={clsx(classes.button, classes.bgBlue)}
              onClick={() => onSubmit?.(values)}
              disabled={!isValid}
            >
              {t("panel.common.save")}
              <Save />
            </Button>
            {type === "edit" && (
              <Button
                color='secondary'
                variant='contained'
                fullWidth
                className={clsx(classes.button, classes.bgRed)}
                onClick={() => id && onRemove?.(id)}
              >
                {t("panel.common.delete")}
                <Delete />
              </Button>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default PlanCardEditable;

const useStyle = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
  wrapper: {
    padding: "10px 0",
    gap: 10,
    borderBottom: "1px solid #a2d3fa",
    "&:last-of-type": {
      paddingBottom: 45,
      borderBottom: "none",
      "& *": {
        fontSize: 18 * fontNormalizeFactor,
      },
    },
  },
  buttonBox: {
    marginTop: 50,
    gap: 20,
  },
  button: {
    height: 40,
    border: "none",
    borderRadius: 2,
    boxShadow: "0 1px 5px 0 #ccc, 0 2px 3px 0 #ddd",
    "& *": {
      fontSize: 15 * fontNormalizeFactor,
    },
    "& svg": {
      marginLeft: 8,
    },
  },
  bgBlue: {
    // backgroundColor: "#E3F2FD",
  },
  bgRed: {
    // backgroundColor: "rgba(255,0,0,.15)",
  },
}));
