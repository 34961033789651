/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ThinListCategory } from './thin-list-category';
import { ThinListTag } from './thin-list-tag';
import { ThinRetrieveCollection } from './thin-retrieve-collection';

/**
 * 
 * @export
 * @interface AdminRetrieveImage
 */
export interface AdminRetrieveImage {
    /**
     * 
     * @type {number}
     * @memberof AdminRetrieveImage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    id_code?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    width?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    volume?: string;
    /**
     * 
     * @type {Array<ThinListTag>}
     * @memberof AdminRetrieveImage
     */
    tags?: Array<ThinListTag>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRetrieveImage
     */
    is_premium?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    type?: AdminRetrieveImageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    ext?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    orientation?: AdminRetrieveImageOrientationEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    price?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminRetrieveImage
     */
    credit?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    size?: AdminRetrieveImageSizeEnum;
    /**
     * 
     * @type {Array<ThinListCategory>}
     * @memberof AdminRetrieveImage
     */
    categories?: Array<ThinListCategory>;
    /**
     * 
     * @type {Array<ThinRetrieveCollection>}
     * @memberof AdminRetrieveImage
     */
    collections?: Array<ThinRetrieveCollection>;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof AdminRetrieveImage
     */
    dominant_colors?: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRetrieveImage
     */
    is_active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRetrieveImage
     */
    is_free?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminRetrieveImage
     */
    manual_edit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    last_edit_date?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRetrieveImage
     */
    prev?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminRetrieveImageTypeEnum {
    Photo = 'Photo',
    Vector = 'Vector',
    Illustration = 'Illustration'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminRetrieveImageOrientationEnum {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical',
    Square = 'Square',
    PanoramicHorizontal = 'Panoramic Horizontal',
    PanoramicVertical = 'Panoramic Vertical'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminRetrieveImageSizeEnum {
    Xl = 'XL',
    L = 'L',
    M = 'M',
    S = 'S'
}



