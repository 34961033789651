import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import DrawerChildrenBox from "./DrawerChildrenBox";
import RadioButtonGroup from "@elements/RadioButtonGroup";
import Checkbox from "@elements/Checkbox";
import CheckboxGroup from "@elements/CheckboxGroup";
import {CardMedia, Box, Hidden} from "@material-ui/core";
import {images} from "@constant/images";
import {palette} from "@constant/colors";
import ClearFiltersButton from "./ClearFiltersButton";
import {useTranslation} from "react-i18next";
import FiltersButton from "@modules/pages/search/FiltersButton";
import {Close} from "@material-ui/icons";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {CommonProps} from "@type/global";
import clsx from "clsx";
import ColorPicker from "@elements/ColorPicker";
import {useAuth} from "@context/AuthContext";
import {UserRoleEnum} from "@schema";
import {useLocation} from "react-router-dom";

interface IFiltersDrawerProps extends CommonProps {
  onCloseClick?: () => void;
}

const FiltersDrawer = (props: IFiltersDrawerProps) => {
  const classes = useStyle();
  const {onCloseClick: handleClick, className, style} = props;
  const {t} = useTranslation("modules.filtersDrawer");
  const {user} = useAuth();
  const userIsNotCustomer = user && user.role !== UserRoleEnum.Customer;
  const handleUpdateQueryString = useUpdateQueryString();
  const {isPremium, orientation, fromDaysBefore, sort} = useGetQueryString();
  const location = useLocation();

  return (
    <Box className={clsx(classes.container, className)} style={style}>
      <Hidden mdUp>
        <FiltersButton
          icon={<Close className={classes.closeIcon} />}
          open
          className={classes.filtersButton}
          onClick={handleClick}
        />
      </Hidden>
      <DrawerChildrenBox title={t("sortBy")}>
        <RadioButtonGroup
          color='light'
          name='sort-by'
          value='created'
          items={[
            {label: t("newest"), value: "created"},
            {label: t("popular"), value: "popularity"},
          ]}
          onChange={(v) => handleUpdateQueryString({sort: v})}
        />
      </DrawerChildrenBox>
      <DrawerChildrenBox>
        <Checkbox
          checked={isPremium === "true"}
          label={t("premium")}
          onChange={(checked) =>
            handleUpdateQueryString({
              isPremium: checked ? "true" : "",
            })
          }
        />
      </DrawerChildrenBox>
      <DrawerChildrenBox title={t("orientation")}>
        <CheckboxGroup
          items={[
            {
              label: t("horizontal"),
              value: "Horizontal",
              icon: <CardMedia component='img' src={images.icons.horizontalOrientation} />,
            },
            {
              label: t("vertical"),
              value: "Vertical",
              icon: <CardMedia component='img' src={images.icons.verticalOrientation} />,
            },
            {
              label: t("square"),
              value: "Square",
              icon: <CardMedia component='img' src={images.icons.squareOrientation} />,
            },
          ]}
          value={orientation ? orientation.split(",") : []}
          onChange={(value) => handleUpdateQueryString({orientation: value.join(",")})}
        />
      </DrawerChildrenBox>
      <DrawerChildrenBox title='رنگ' collapsible open>
        <ColorPicker />
      </DrawerChildrenBox>
      <DrawerChildrenBox collapsible title={t("uploadedDate")}>
        <RadioButtonGroup
          color='light'
          name='upload-date'
          items={[
            {label: t("all"), value: ""},
            {label: t("24-hours"), value: "1"},
            {label: t("48-hours"), value: "2"},
            {label: t("72-hours"), value: "3"},
            {label: t("7-days"), value: "7"},
            {label: t("30-days"), value: "30"},
            {label: t("12-months"), value: "365"},
          ]}
          value={fromDaysBefore ?? ""}
          onChange={(value) => handleUpdateQueryString({fromDaysBefore: value})}
        />
      </DrawerChildrenBox>
      <DrawerChildrenBox>
        <ClearFiltersButton
          onClick={() =>
            handleUpdateQueryString({
              sort: userIsNotCustomer ? "created" : "popularity",
              isPremium: "",
              orientation: "",
              color: "",
              gray: "",
              fromDaysBefore: "",
            })
          }
        />
      </DrawerChildrenBox>
      <div style={{height: "8rem"}} />
    </Box>
  );
};

export default FiltersDrawer;

const useStyle = makeStyles(() => ({
  container: {
    backgroundColor: palette.darkGrey.A200,
    width: "100%",
    height: "100%",
  },
  filtersButton: {
    width: "100%",
    height: "7rem",
  },
  closeIcon: {
    fontSize: "2.3rem",
    color: "#adb9ba",
  },
}));
