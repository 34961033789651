import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, CardMedia, Grid, IconButton, Checkbox} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import Pagination from "@elements/panel/Pagination";
import Spinner from "@elements/Spinner";
import {routes} from "@constant/routes";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInput from "@elements/panel/QuerySearchInput";
import ImagesFilterModal from "@elements/panel/modals/ImagesFilterModal";
import Typography from "@elements/Typography";
import {RetrieveImageTypeEnum as ImageType, UpdateImage} from "@schema";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import {useQueryClient} from "react-query";
import {ApiNamespaces} from "@api";

const PAGE_SIZE = 100;
export default function Images() {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedImages, setSelectedImages] = React.useState<number[]>([]);
  const {t} = useTranslation("pages.panel");
  const classes = useStyle();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const {search, tag, type, isPremium, page, pageSize, fromDaysBefore, noPreview, manualEdit} =
    useGetQueryString();
  const ratio = 10;
  const shouldRenderBulkEditElements =
    !!type &&
    [ImageType.Photo, ImageType.Illustration].includes(type as ImageType) &&
    manualEdit === "false";

  const {data} = useExperimentalQuery("images", "getList", {
    variables: {
      search,
      tags: tag,
      type,
      isPremium,
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : PAGE_SIZE,
      fromDaysBefore: fromDaysBefore ? Number(fromDaysBefore) : undefined,
      notPreviwable: noPreview,
      manualEdit,
    },
  });

  const {mutate: bulkUpdate} = useExperimentalMutation("images", "bulkUpdate");

  const handleToggleChecked = (checked: boolean, id: number) => {
    checked
      ? setSelectedImages((prev) => [...prev, id])
      : setSelectedImages((prev) => prev.filter((_id) => _id !== id));
  };

  const handleUpdateImagesType = (confirmedType: ImageType.Photo | ImageType.Illustration) => {
    const otherType = confirmedType === ImageType.Photo ? ImageType.Illustration : ImageType.Photo;
    const updatedImages = data!.results!.map((image) => ({
      manual_edit: true,
      type: selectedImages.includes(image.id || 0) ? confirmedType : otherType,
      id: image.id,
    }));

    bulkUpdate({
      variables: {data: updatedImages as UpdateImage},
      onSuccess: () => {
        alert.success({});
        setSelectedImages([]);
        queryClient.invalidateQueries(ApiNamespaces.images.Query.getList);
      },
      onError: () => {
        alert.error({});
      },
    });
  };

  return (
    <>
      <Grid container direction='column' alignItems='center' className={classes.container}>
        <Grid container justify='space-between' className={classes.inputsBox}>
          <Grid container alignItems='center' className={classes.borderLeft}>
            <QuerySearchInput width={200} />
          </Grid>
          <Grid container justify='flex-end' className={classes.gap}>
            {shouldRenderBulkEditElements && !!selectedImages.length && (
              <>
                <Button onClick={() => setSelectedImages([])} variant='contained'>
                  <Typography>تنظیم مجدد</Typography>
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  onClick={() => handleUpdateImagesType(ImageType.Photo)}
                >
                  <Typography>ذخیره انتخابی‌ها به عنوان عکس</Typography>
                  <Typography>و مابقی به عنوان طرح</Typography>
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  onClick={() => handleUpdateImagesType(ImageType.Illustration)}
                >
                  <Typography>ذخیره انتخابی‌ها به عنوان طرح</Typography>
                  <Typography>و مابقی به عنوان عکس</Typography>
                </Button>
              </>
            )}
            <Button onClick={() => setShowModal(true)} color='primary' variant='contained'>
              <Typography size={1.2 * fontNormalizeFactor} color='#fff'>
                جستجوی پیشرفته
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {data?.results.length === 0 && <NoResult />}
        {!data && <Spinner />}
        <Grid item style={{width: "100%"}}>
          <Grid
            container
            wrap='wrap'
            direction='row'
            className={classes.imagesBox}
            style={{width: "100%"}}
          >
            {data?.results
              ?.filter(({width}) => !!width)
              .map(({file, height, width, id}, index) => (
                <Box
                  key={index.toString()}
                  className={classes.item}
                  style={{
                    width: `calc(${+width! / +height!} * ${ratio}%)`,
                    flexGrow: +width! / +height!,
                  }}
                >
                  <CardMedia component='img' src={file} className={classes.image} />
                  <Checkbox
                    color='primary'
                    className={classes.checkbox}
                    style={{display: shouldRenderBulkEditElements ? "block" : "none"}}
                    checked={selectedImages.includes(id!)}
                    onChange={(_, checked) => handleToggleChecked(checked, id || 0)}
                  />
                  <Link to={routes.panel.imagesDetail(id!)} className={classes.link}>
                    نمایش
                  </Link>
                </Box>
              ))}
            {/* style helpers */}
            {[1, 2, 3, 4, 5, 6, 7].map((item) => (
              <Grid
                key={item}
                item
                style={{
                  width: `${ratio}%`,
                  height: 1,
                  flexGrow: 1,
                  maxWidth: `${1.3 * ratio}%`,
                }}
              />
            ))}
          </Grid>
        </Grid>
        <Link to={routes.panel.imagesAdd}>
          <IconButton className={classes.addIcon}>
            <Add />
          </IconButton>
        </Link>
        <Pagination
          count={data?.count || 0}
          pageSize={PAGE_SIZE}
          pageSizeOptions={[
            {label: "30", value: 30},
            {label: "50", value: 50},
            {label: "80", value: 80},
            {label: "100", value: 100},
            {label: "120", value: 120},
          ]}
        />
      </Grid>
      <ImagesFilterModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={() => setShowModal(false)}
        onRemoveFilters={() => setShowModal(false)}
      />
    </>
  );
}

const useStyle = makeStyles((theme) => ({
  borderLeft: {
    borderRight: `solid 1px ${theme.palette.grey[200]}`,
    width: "auto",
  },
  filtersButton: {
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    margin: "0 24px 0 16px",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
      color: "white",
    },
  },
  addIcon: {
    width: 56,
    height: 56,
    marginTop: 32,
    marginBottom: 16,
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`,
      opacity: 0.8,
    },
    "& *": {
      color: "white",
      fontSize: "21px",
    },
  },
  margin: {
    marginLeft: 5,
  },

  container: {
    height: "100%",
    overflow: "hidden",
    paddingRight: 2,
  },
  inputsBox: {
    marginBottom: 20,
    height: 56,
    gap: 20,
  },
  imagesBox: {
    gap: 2,
  },
  item: {
    overflow: "hidden",
    position: "relative",
    "&:hover $link": {
      opacity: 1,
    },
  },
  link: {
    position: "absolute",
    right: 8,
    bottom: 6,
    background: "#444444cc",
    color: "#fff",
    borderRadius: 2,
    padding: "2px 4px",
    fontSize: 12 * fontNormalizeFactor,
    opacity: 0,
    transition: ".15s",
  },
  checkbox: {
    position: "absolute",
    left: 6,
    top: 6,
    background: "#44444488",
    "& .MuiIconButton-label": {
      transform: "scale(1.3)",
      color: "#fff",
    },
  },
  image: {
    // transition: ".15s ease-in",
    "&:hover": {
      // transform: "scale(1.05)",
    },
  },
  gap: {
    gap: 6,
    maxWidth: 900,
  },
  button: {
    "& > *": {
      display: "flex",
      flexDirection: "column",
      "& > *:nth-child(2)": {
        fontSize: 11 * fontNormalizeFactor,
        marginTop: 2,
      },
    },
  },
}));
