import {Box, Button, Dialog, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SaveButton, {ISaveButtonProps} from "./SaveButton";
import {Fullscreen as FullscreenIcon, Stars} from "@material-ui/icons";
import {IImageCardProps} from "./types";
import {useAuth} from "@context/AuthContext";

type IProps = IImageCardProps & ISaveButtonProps;

/** Don't import it outside, it's espoused to be used only in ImageCard component conditionally */
export function ImageCardForDetailPage(props: IProps) {
  const classes = useStyles();
  const [openFullScreen, setOpenFullScreen] = React.useState(false);
  const {alt, src, isPremium, ...saveButtonProps} = props;
  const {user} = useAuth();

  return (
    <>
      <Grid
        container
        justify='center'
        alignItems='center'
        className={classes.container}
        onClick={() => setOpenFullScreen(true)}
      >
        {user && <SaveButton {...saveButtonProps} className={classes.saveButton} />}
        <img src={src} alt={alt} className={classes.image} title={alt} />
        {isPremium && <Stars className={classes.starIcon} />}

        <Button className={classes.fullScreenButton}>
          <svg viewBox='-15 -15 130 130'>
            <path d='M67.35 10.29v4h14.83l-21.2 21.19 2.83 2.83 21.2-21.2v14.84h4V10.29H67.35zM32 14.29v-4H10.34v21.66h4V17.11l21.2 21.2 2.83-2.83-21.2-21.19H32zM85.01 65.24v14.83l-21.2-21.2-2.83 2.83 21.2 21.2H67.35v4h21.66V65.24h-4zM35.54 58.87l-21.2 21.2V65.24h-4V86.9H32v-4H17.17l21.2-21.2-2.83-2.83z' />
          </svg>
          <FullscreenIcon />
        </Button>
      </Grid>
      <Dialog
        fullScreen
        PaperProps={{
          className: classes.dialog,
          elevation: 0,
          onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
            setOpenFullScreen(false);
          },
        }}
        BackdropProps={{className: classes.backdrop}}
        open={openFullScreen}
        onClose={() => setOpenFullScreen(false)}
      >
        <Grid container alignItems='center' justify='center' className={classes.wFullHFull}>
          <Box className={classes.closeIcon}>
            <svg viewBox='0 0 98 98'>
              <path d='M78.2 23.3L75 20.1 48.7 46.4 22.5 20.1l-3.3 3.2 26.3 26.3-27.2 27.2 3.2 3.3 27.2-27.3L76 80.1l3.2-3.3L52 49.6l26.2-26.3z' />
            </svg>
          </Box>
          <img src={src} alt={alt} className={classes.image} />
        </Grid>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
    border: "solid 1px #ccc",
    "&:hover $fullScreenButton": {
      opacity: 1,
    },
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },

  saveButton: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
  },
  fullScreenButton: {
    opacity: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 20,
    height: "6.5rem",
    width: "6.5rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    "& svg": {
      fontSize: 52,
      color: "white",
    },
  },
  dialog: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
  },
  backdrop: {
    backgroundColor: "rgba(12, 13, 13, 0.8)",
  },
  starIcon: {
    position: "absolute",
    top: ".5rem",
    right: ".5rem",
    fontSize: 25,
  },
  closeIcon: {
    position: "absolute",
    left: "0.8rem",
    top: "0.8rem",
    cursor: "pointer",
    fill: "white",
    "& svg": {
      width: "4rem",
      height: "4rem",
      backgroundColor: "#aaaaaa55",
      borderRadius: 6,
    },
  },
  wFullHFull: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
}));
