import React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import Typography from "@elements/Typography";
import {palette} from "@constant/colors";
import {routes} from "@constant/routes";
import {useModalDispatch, useModalState} from "@context/ModalContext";
import {modalsPayload} from "@context/ModalContext/types";

export interface ModifyBoardMessageModalProps {
  open?: boolean;
  src: string;
  alt: string;
  boardId: number;
  boardName: string;
  isSaved: boolean;
  onClose?: () => void;
}

function ModalBody(props: ModifyBoardMessageModalProps) {
  const {open, src, alt, boardId, boardName, isSaved} = props;
  const [willDismiss, setWillDismiss] = React.useState(true);
  const classes = useStyles();
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const handleCloseModal = useModalDispatch(null);

  React.useEffect(() => {
    if (open && willDismiss) {
      timeoutRef.current = setTimeout(() => {
        handleCloseModal();
      }, 2500);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [handleCloseModal, open, willDismiss]);

  return open ? (
    <Link to={routes.landing.boardsDetail(boardId)} onClick={(e) => !boardId && e.preventDefault()}>
      <Grid
        container
        alignItems='center'
        className={classes.container}
        onMouseEnter={() => setWillDismiss(false)}
        onMouseLeave={() => setWillDismiss(true)}
      >
        <Grid container direction='column' alignItems='center'>
          <Typography size={1.4} color='#474747'>
            {isSaved ? "در بورد ذخیره شد" : "از بورد حذف شد"}
          </Typography>
          <Typography size={1.8} weight={500} color={palette.green[200]}>
            {boardName}
          </Typography>
        </Grid>
        <Grid container justify='center' alignItems='center' className={classes.imageBox}>
          <img src={src} alt={alt} />
        </Grid>
      </Grid>
    </Link>
  ) : null;
}

function Wrapper(DialogBody: React.ElementType, name: keyof modalsPayload) {
  const Component = () => {
    const openModal = useModalState();
    const open = openModal?.modal === name;
    const payload = openModal?.payload || {};
    return <DialogBody {...payload} open={open} />;
  };
  return () => Component();
}

export const ModifyBoardMessageModal = Wrapper(ModalBody, "modifyBoardMessageModal");

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    zIndex: 1000,
    bottom: 35,
    left: "50%",
    transform: "translateX(-50%)",
    width: 340,
    height: 157,
    padding: 12,
    gap: 12,
    borderRadius: 6,
    backgroundColor: "#DAE5E8",
    border: "1px solid #d1d1d1",
    boxShadow: "0 0 30px 1px #66666666",
  },
  imageBox: {
    height: "100%",
    width: 130,
    flexShrink: 0,
    backgroundColor: "white",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
}));
