import {useAuth} from "@context/AuthContext";
import Spinner from "@elements/Spinner";
import {UserRoleEnum as Role} from "@schema";
import {Redirect} from "react-router";

export {Role};

export interface PrivateRouteProps {
  children: any;
  allowedRoles?: Role[];
}

const defaultAllowedRoles: Role[] = [Role.Developer, Role.Owner, Role.Admin, Role.Support];

/**
 * @param allowedRolesToSee has a default value, so in some cases you may not need to pass
 */
export default function PrivateRoute(props: PrivateRouteProps) {
  const {loadedUser, user} = useAuth();
  const {children: Component, allowedRoles = defaultAllowedRoles} = props;

  if (!loadedUser) {
    return <Spinner />;
  } else {
    const hasPermission = allowedRoles.some((role) => role === user?.role);
    // const hasPermission = true;
    return !!user && hasPermission ? Component : <Redirect to='/' />;
  }
}
