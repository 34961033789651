// @ts-ignore
const {createMuiTheme} = require("@material-ui/core");

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#007DFF",
      main: "#2196F3",
      light: "#3397FF",
    },
    background: {
      default: "#E3F2FD",
      paper: "#ffffff",
    },
    text: {
      primary: "#0C0D0D",
      secondary: "#717171",
      disabled: "#8e8e8e",
      hint: "#1fa268",
    },
    secondary: {
      dark: "#c51162",
      main: "#f50057",
      light: "#ff4081",
    },
    error: {
      main: "#ff2727",
    },
    grey: {
      50: "#f9f9f9",
      100: "#f5f5f5",
      200: "#f0f0f0",
      300: "#e5e5e5",
      400: "#e0e0e0",
      700: "#9e9e9e",
      800: "#959595",
    },
  },
  typography: {
    fontFamily: "Kalameh",
    fontWeightMedium: 500,
    fontWeightRegular: "normal",
    fontWeightBold: "bold",
    color: "#0c0d0d",
  },
  props: {
    MuiGrid: {
      wrap: "nowrap",
    },
    MuiButton: {
      disableElevation: true,
      disableTouchRipple: true,
      // disableFocusRipple: true,
      // disableRipple: true,
    },
  },
  direction: "rtl",
  overrides: {
    MuiButton: {
      root: {
        "&.animated": {
          "& .MuiButton-label": {
            zIndex: 110,
            position: "relative",
          },
          "&:hover": {
            transform: "translateY(-3px)",
            boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.2)",
          },
          "&:after": {
            content: '""',
            display: "inline-block",
            height: "100%",
            width: "100%",
            borderRadius: "inherit",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
            transition: "all .6s",
            background: "inherit",
            opacity: "1 !important",
          },
          "&:hover:after": {
            transform: "scaleX(1.4) scaleY(1.6)",
            opacity: "0 !important",
          },
          "&:focus": {
            transform: "translateY(-1px)",
            boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
  },
});

module.exports = {theme};
