import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import Typography from "@elements/Typography";
import AccountTable from "@elements/table/AccountTable";
import {useTranslation} from "react-i18next";
import {GetApp, Payment, Check} from "@material-ui/icons";
import {lightGrey} from "@constant/colors";
import GreenLink from "@elements/GreenLink";
import {useAuth} from "@context/AuthContext";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {formatDate} from "@util/global";
import Tooltip from "@elements/Tooltip";
import {Link, useLocation} from "react-router-dom";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import {baseUrl} from "@constant/baseUrl";
import {routes} from "@constant/routes";
import createImageUrl from "@util/createImageUrl";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import {setUserLastLocationInLs} from "@util/userLastLocation";

const Overview = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const {user} = useAuth();
  const location = useLocation();
  const serverErrorNotify = useServerErrorNotify();
  const apiErrorHandler = useApiErrorHandler();

  const {data: planInvoices} = useExperimentalQuery("planInvoices", "getList", {
    variables: {pageSize: 3},
    onError: (error) => {
      serverErrorNotify(error);
    },
  });
  const {data: imageInvoices} = useExperimentalQuery("imagesInvoices", "getList", {
    variables: {pageSize: 3},
    onError: (error) => {
      serverErrorNotify(error);
    },
  });
  const {data: buyByPlan} = useExperimentalQuery("buyByPlan", "getList", {
    variables: {pageSize: 3},
  });

  const {mutate: buyImage} = useExperimentalMutation("imagesInvoices", "createPay");
  const handleCreateImagePay = (id: number) => {
    buyImage({
      variables: {id: String(id), data: {}},
      onError: (error) => apiErrorHandler(error),
      onSuccess: (res) => {
        setUserLastLocationInLs(location.pathname);
        const response = res as unknown as {link: string};
        window.location.href = response.link;
      },
    });
  };

  const {mutate: buyPlan} = useExperimentalMutation("planInvoices", "createPay");
  const handleCreatePlanPay = (id: number) => {
    buyPlan({
      variables: {id: String(id), data: {}},
      onError: (error) => apiErrorHandler(error),
      onSuccess: (res) => {
        setUserLastLocationInLs(location.pathname);
        const response = res as unknown as {link: string};
        window.location.href = response.link;
      },
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.section}>
        <Typography size={2.4} weight={500} className={classes.title}>
          {t("activePlans")}
        </Typography>
        {user?.plans?.length ? (
          <AccountTable
            titles={[t("orderNumber"), t("planName"), t("remainingCredit"), t("expire")]}
            rows={user?.plans?.map(
              ({id, plan_info, remained_signature, remained_essential, plan_expire_date}) => [
                <Typography>{id}</Typography>,
                <Typography>{plan_info?.name}</Typography>,
                <Typography>{`${remained_essential || 0} عادی / ${
                  remained_signature || 0
                } پرمیوم`}</Typography>,
                <Tooltip title={plan_expire_date?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(plan_expire_date?.split("T")[0])}</Typography>
                </Tooltip>,
              ]
            )}
          />
        ) : (
          <Box className={classes.noResultBox}>
            <Typography span>{t("noSubscriptionMessage")}</Typography>&nbsp;
            <GreenLink to={routes.landing.pricing} size={1.6}>
              {t("plansLink")}
            </GreenLink>
          </Box>
        )}
      </Box>
      <Box className={classes.section}>
        <Typography span size={2.4} weight={500} className={classes.title}>
          {t("imageInvoices")}
        </Typography>
        <GreenLink to='/account/image-invoices' size={1.6}>
          {t("seeAll")}
        </GreenLink>
        {!!imageInvoices?.results.length && (
          <AccountTable
            titles={[
              t("orderNumber"),
              t("imageId"),
              t("createDate"),
              t("purchaseDate"),
              t("expire"),
              t("invoicePrice"),
              t("pay"),
            ]}
            rows={imageInvoices.results.map(
              ({
                id,
                value,
                image,
                paid_date,
                created,
                invoice_expire_date,
                is_active,
                invoice_is_expired,
                image_info,
                ...item
              }) => [
                <Typography>{id}</Typography>,
                <Link
                  to={createImageUrl(
                    image_info?.type || "",
                    image_info?.title || "",
                    image_info?.id_code || ""
                  )}
                >
                  <Typography>{image}</Typography>
                </Link>,
                <Tooltip title={created?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(created)}</Typography>
                </Tooltip>,
                <div>
                  {!!paid_date ? (
                    <Tooltip title={paid_date?.split("T")[1]?.slice(0, 8) || ""}>
                      <Typography>{formatDate(paid_date)}</Typography>
                    </Tooltip>
                  ) : (
                    "❌"
                  )}
                </div>,
                <Tooltip title={invoice_expire_date?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(invoice_expire_date)}</Typography>
                </Tooltip>,
                <Typography>{value}</Typography>,
                <Typography>
                  {!paid_date && is_active && !invoice_is_expired ? (
                    <Payment className={classes.icon} onClick={() => handleCreateImagePay(id!)} />
                  ) : paid_date ? (
                    <Check htmlColor='seagreen' style={{fontSize: 28}} />
                  ) : (
                    "-"
                  )}
                </Typography>,
              ]
            )}
          />
        )}
        {imageInvoices && !imageInvoices?.results.length && (
          <Box className={classes.noResultBox}>
            <Typography>{t("noInvoicesMessage")}</Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.section}>
        <Typography span size={2.4} weight={500} className={classes.title}>
          {t("planInvoices")}
        </Typography>
        <GreenLink to='/account/plan-invoices' size={1.6}>
          {t("seeAll")}
        </GreenLink>
        {!!planInvoices?.results.length && (
          <AccountTable
            titles={[
              t("orderNumber"),
              t("planName"),
              t("createDate"),
              t("purchaseDate"),
              t("expire"),
              t("invoicePrice"),
              t("pay"),
            ]}
            rows={planInvoices.results.map(
              ({
                id,
                value,
                plan_info,
                created,
                paid_date,
                invoice_expire_date,
                invoice_is_expired,
                is_active,
              }) => [
                <Typography>{id}</Typography>,
                <Typography>{plan_info?.name}</Typography>,
                <Tooltip title={created?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(created)}</Typography>
                </Tooltip>,
                <div>
                  {!!paid_date ? (
                    <Tooltip title={paid_date?.split("T")[1]?.slice(0, 8) || ""}>
                      <Typography>{formatDate(paid_date)}</Typography>
                    </Tooltip>
                  ) : (
                    "❌"
                  )}
                </div>,
                <Tooltip title={invoice_expire_date?.split("T")[1]?.slice(0, 8) || ""}>
                  <Typography>{formatDate(invoice_expire_date)}</Typography>
                </Tooltip>,
                <Typography>{value}</Typography>,
                <Typography>
                  {!paid_date && is_active && !invoice_is_expired ? (
                    <Payment className={classes.icon} onClick={() => handleCreatePlanPay(id!)} />
                  ) : paid_date ? (
                    <Check htmlColor='seagreen' style={{fontSize: 28}} />
                  ) : (
                    "-"
                  )}
                </Typography>,
              ]
            )}
          />
        )}
        {planInvoices && !planInvoices?.results.length && (
          <Box className={classes.noResultBox}>
            <Typography>{t("noInvoicesMessage")}</Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.section}>
        <Typography span size={2.4} weight={500} className={classes.title}>
          {t("downloadsWithSubscription")}
        </Typography>
        <GreenLink to='/account/downloads/by-plan' size={1.6}>
          {t("seeAll")}
        </GreenLink>
        {!!buyByPlan?.results.length && (
          <AccountTable
            titles={[t("imageId"), t("date"), t("planId"), t("reDownload")]}
            rows={buyByPlan.results.map(({image_info, plan_invoice, created}) => [
              <Link
                to={createImageUrl(
                  image_info?.type || "",
                  image_info?.title || "",
                  image_info?.id_code || ""
                )}
              >
                <Typography>{image_info?.id}</Typography>
              </Link>,
              <Typography>{formatDate(created)}</Typography>,
              <Typography>{plan_invoice}</Typography>,
              // download link
              <GetApp
                className={classes.icon}
                onClick={() => {
                  const form = document.createElement("form");
                  form.action = `${baseUrl}images/${image_info?.id}/download/`;
                  form.method = "post";
                  document.body.appendChild(form);
                  form.submit();
                  form.remove();
                }}
              />,
            ])}
          />
        )}
        {buyByPlan && !buyByPlan?.results.length && (
          <Box className={classes.noResultBox}>
            <Typography>{t("noDownloadsMessage")}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Overview;

const useStyle = makeStyles(() => ({
  container: {},
  section: {
    marginBottom: "5rem",
  },
  title: {
    display: "inline-block",
    marginBottom: "2.1rem",
    marginRight: "1rem",
  },
  icon: {
    cursor: "pointer",
    fontSize: "2.5rem",
    color: "gray",
  },
  noResultBox: {
    padding: " 1.4rem 2rem 1.2rem 3rem",
    border: `1px solid ${lightGrey.A300}`,
    backgroundColor: "white",
  },
}));
