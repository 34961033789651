import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button} from "@material-ui/core";
import {palette} from "@constant/colors";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {CommonProps} from "@type/global";
import clsx from "clsx";
import {ArrowDropDown as ArrowDownIcon} from "@material-ui/icons";
import Typography from "@elements/Typography";

export interface ISaveButtonProps extends CommonProps {
  onClickMainButton?: () => void;
  onClickTooltip?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver?: () => void;
  imageIsAlreadyOnBoard?: boolean;
  tooltipTextPayload?: string;
  deviceHasMouseSupport: boolean;
  activeOnMount?: boolean;
  disabled?: boolean;
}

const SaveButton = (props: ISaveButtonProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const classes = useStyle();
  const {t} = useTranslation("elements");
  const {
    onClickMainButton,
    onClickTooltip: handleClickTooltip,
    onMouseOver: handleMouseOver,
    deviceHasMouseSupport,
    activeOnMount,
    imageIsAlreadyOnBoard = false,
    tooltipTextPayload,
    className,
    style,
    disabled,
  } = props;

  const buttonContentOptions = {
    isNotSaved: {
      text: t("imageCard.saveButton.save"),
      description: t("imageCard.saveButton.saveInBoard"),
      icon: <path d='M7.734 0v7.736H0v1.53h7.734V17h1.531V9.266H17v-1.53H9.265V0z' />,
    },
    isSaved: {
      notHovered: {
        text: "ذخیره‌شده",
        description: t("imageCard.saveButton.removeFromBoard"),
        icon: <path d='M7.582 16.619l-6.317-6.32 1.273-1.273 5.044 5.047 9.79-9.79 1.274 1.273z' />,
      },
      isHovered: {
        text: t("imageCard.saveButton.remove"),
        description: t("imageCard.saveButton.removeFromBoard"),
        icon: <path d='M15.16 7.735H-.001v1.53h7.735v.001h9.267V7.734z' />,
      },
    },
  };
  const buttonContent = !imageIsAlreadyOnBoard
    ? buttonContentOptions.isNotSaved
    : isHovered
    ? buttonContentOptions.isSaved.isHovered
    : buttonContentOptions.isSaved.notHovered;

  React.useEffect(() => {
    !deviceHasMouseSupport && activeOnMount && setIsHovered(true);
  }, [activeOnMount, deviceHasMouseSupport]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!deviceHasMouseSupport && !activeOnMount && isHovered) {
      timeout = setTimeout(() => {
        setIsHovered(false);
      }, 3500);
    }
    return () => timeout && clearTimeout(timeout);
  }, [activeOnMount, deviceHasMouseSupport, isHovered]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (deviceHasMouseSupport || activeOnMount) {
      onClickMainButton?.();
    } else {
      if (!isHovered) {
        setIsHovered(true);
      } else {
        onClickMainButton?.();
      }
    }
  };

  return (
    <Box
      className={clsx(
        classes.container,
        isHovered && !deviceHasMouseSupport && classes.hovered,
        className
      )}
      onMouseOver={handleMouseOver}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box style={{position: "relative"}}>
        <Button
          disabled={disabled}
          onClick={handleClick}
          classes={{label: classes.text}}
          className={classes.button}
          style={style}
          endIcon={
            <svg
              className={classes.plusButton}
              style={{transform: imageIsAlreadyOnBoard && !isHovered ? "translateY(-.5rem)" : ""}}
            >
              {buttonContent.icon}
            </svg>
          }
        >
          {buttonContent.text}
        </Button>
        <Box className={classes.tooltip} onClick={handleClickTooltip}>
          <Typography span size={1.2}>
            {buttonContent.description}
          </Typography>
          {tooltipTextPayload && (
            <Typography span size={1.2} weight={700} color={palette.green[200]}>
              {tooltipTextPayload}&nbsp;؟
            </Typography>
          )}
          <ArrowDownIcon className={classes.arrowDownIcon} />
        </Box>
      </Box>
    </Box>
  );
};

export default SaveButton;

const useStyle = makeStyles(() => ({
  container: {
    display: "inline-block",
    "&:hover button": {
      backgroundColor: palette.green[200],
    },
    "&:hover $tooltip": {
      opacity: 1,
      visibility: "visible",
      top: "-162%",
    },
  },
  hovered: {
    "& button": {
      backgroundColor: palette.green[200],
    },
    "& $tooltip": {
      opacity: 1,
      visibility: "visible",
      top: "-162%",
    },
  },
  button: {
    height: "3.8rem",
    color: "white",
    backgroundColor: "rgba(12, 13, 13, 0.65)",
    borderRadius: 2,
    padding: "1rem",
    "&:hover": {
      backgroundColor: palette.green[200],
    },
    "&:disabled": {
      backgroundColor: palette.green[200],
      "& *": {
        color: "white",
      },
    },
  },
  text: {
    fontSize: `${1.4 * fontNormalizeFactor}rem`,
    fontWeight: 500,
    margin: "0 .3rem 0 .5rem",
  },
  plusButton: {
    width: "1.7rem",
    height: "1.7rem",
    fill: "white",
  },
  tooltip: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    textAlign: "center",
    padding: "7px 12px",
    position: "absolute",
    top: "-140%",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    borderRadius: 0,
    border: "solid 1px #c5d2d2",
    visibility: "hidden",
    opacity: 0,
    transition: ".1s cubic-bezier(0.25, 0.1, 0.25, 1)",
    "& *": {
      whiteSpace: "nowrap",
    },
  },
  arrowDownIcon: {
    fontSize: 36,
    color: "white",
    position: "absolute",
    bottom: "-21px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 200,
  },
}));
