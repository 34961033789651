import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ChoosePlanSection from "@modules/pages/pricing/ChoosePlanSection";
import CollectionSection from "@modules/pages/pricing/CollectionSection";
import CompareTable from "@modules/pages/pricing/CompareTable";
import FaqSection from "@modules/pages/pricing/FaqSection";
import WhyChooseUsSection from "@modules/pages/pricing/WhyChooseUsSection";
import React from "react";
import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";

const Pricing = () => {
  const classes = useStyle();

  return (
    <>
      <Helmet>
        <title>{defaultPageTitle}</title>
        <meta
          name='description'
          content='عکس های royalty-free رو با اشتراک‌های پارس ایمیجز دانلود کن، یا اعتبار تا بتونی عکس‌ها، وکتورها و طرح‌ها رو دانلود کنی.'
        />
      </Helmet>
      <Box className={classes.container}>
        <Box className={classes.maxWidth}>
          <ChoosePlanSection />
          <CollectionSection />
          <CompareTable />
          <FaqSection />
          <WhyChooseUsSection />
        </Box>
      </Box>
    </>
  );
};

export default Pricing;

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
  },
  maxWidth: {
    maxWidth: "108.6rem",
    margin: "0 auto",
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down(270)]: {
      padding: "0 .75rem",
    },
  },
  subscription: {
    padding: "3rem 0",
  },
}));
