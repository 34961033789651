import {useAlert} from "@context/AlertContext";
import Spinner from "@elements/Spinner";
import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {DialogProps, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PanelModalRapper from "./PanelModalWrapper";

interface IProps extends Omit<DialogProps, "id"> {
  id: number | null;
}
export default function UserInfoModal({id, ...props}: IProps) {
  const classes = useStyles();
  const alert = useAlert();

  const {data: user, isLoading} = useExperimentalQuery("users", "readDetail", {
    variables: {
      id: id!,
    },
    enabled: !!id,
    onError: () => alert.error({}),
  });

  const userInfo = {
    آیدی: user?.id,
    "نام و نام‌خانوادگی": `${user?.first_name} ${user?.last_name}`,
    "نام کاربری": user?.username,
    نقش: user?.role,
    "نام شرکت": user?.company_name,
    "شماره موبایل": `${user?.mobile_number?.slice(1)}+`,
    ایمیل: user?.email,
  };

  return (
    <PanelModalRapper title='اطلاعات کاربر' {...props}>
      <Grid className={classes.root}>
        {isLoading ? (
          <Spinner />
        ) : (
          Object.keys(userInfo).map((title) => (
            <Grid container key={title} className={classes.row}>
              <Grid item>
                <Typography span size={1.8} color='#757575'>
                  {title}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography span size={1.8}>
                  {userInfo[title as keyof typeof userInfo]}
                </Typography>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </PanelModalRapper>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: 340,
  },
  row: {
    padding: "10px 7px",
    gap: 10,
    borderBottom: "1px solid #a2d3fa",
    "&:last-of-type": {
      borderBottom: "none",
      paddingBottom: 40,
    },
  },
}));
