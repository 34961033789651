import React, { useEffect } from "react";

export function useOnClickOutside<T extends Element>(
  handler: (event: MouseEvent) => void
) {
  const ref = React.useRef<T | null>(null);
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if ((ref && !ref.current) || (ref && ref.current?.contains(event.target as Node))) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    //   document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      // document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
  return ref;
};
