/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ThinListCategory } from './thin-list-category';
import { ThinListTag } from './thin-list-tag';
import { ThinRetrieveCollection } from './thin-retrieve-collection';

/**
 * 
 * @export
 * @interface RetrieveImage
 */
export interface RetrieveImage {
    /**
     * 
     * @type {number}
     * @memberof RetrieveImage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    id_code?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    title_fa?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    summary_fa?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    description_fa?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    width?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    volume?: string;
    /**
     * 
     * @type {Array<ThinListTag>}
     * @memberof RetrieveImage
     */
    tags?: Array<ThinListTag>;
    /**
     * 
     * @type {boolean}
     * @memberof RetrieveImage
     */
    is_premium?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    type?: RetrieveImageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    ext?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    orientation?: RetrieveImageOrientationEnum;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    price?: string;
    /**
     * 
     * @type {number}
     * @memberof RetrieveImage
     */
    credit?: number;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    size?: RetrieveImageSizeEnum;
    /**
     * 
     * @type {Array<ThinListCategory>}
     * @memberof RetrieveImage
     */
    categories?: Array<ThinListCategory>;
    /**
     * 
     * @type {Array<ThinRetrieveCollection>}
     * @memberof RetrieveImage
     */
    collections?: Array<ThinRetrieveCollection>;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetrieveImage
     */
    is_free?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RetrieveImage
     */
    user_bought?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RetrieveImageTypeEnum {
    Photo = 'Photo',
    Vector = 'Vector',
    Illustration = 'Illustration'
}
/**
    * @export
    * @enum {string}
    */
export enum RetrieveImageOrientationEnum {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical',
    Square = 'Square',
    PanoramicHorizontal = 'Panoramic Horizontal',
    PanoramicVertical = 'Panoramic Vertical'
}
/**
    * @export
    * @enum {string}
    */
export enum RetrieveImageSizeEnum {
    Xl = 'XL',
    L = 'L',
    M = 'M',
    S = 'S'
}



