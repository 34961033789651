import {makeStyles} from "@material-ui/core";

export function Envelop() {
  const classes = useStyles();
  return (
    <svg
      className={classes.svg}
      xmlnsXlink='http://www.w3.org/1999/xlink'
      xmlns='http://www.w3.org/2000/svg'
      width='63.5'
      height='42.5'
      viewBox='0 0 63.5 42.5'
    >
      <title>envelope</title>
      <line className='a' x1='60.69612' y1='39.59866' x2='38.16858' y2='21.37177' />
      <line className='a' x1='2.63719' y1='39.59866' x2='25.16473' y2='21.37177' />
      <polyline className='a' points='1.25 7.468 1.25 41.25 62.25 41.25 62.25 7.468' />
      <polygon
        className='b'
        points='62.25 7.468 62.25 1.25 1.25 1.25 1.25 7.468 31.75 25.078 62.25 7.468'
      />
    </svg>
  );
}
const useStyles = makeStyles({
  svg: {
    "& .a": {
      fill: "none",
    },
    "& .b": {
      fill: "#aeddd6",
    },
    "& .a, & .b": {
      stroke: "#0d0d0d",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "2.5px",
    },
  },
});
