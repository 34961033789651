import React from "react";
import {InputBase, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Search} from "@material-ui/icons";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {useGetQueryString} from "@hook/qs/useGetQueryString";

export default function QuerySearchInput({width}: {width?: number}) {
  const {search} = useGetQueryString();
  const [value, setValue] = React.useState(search || "");
  const {t} = useTranslation("pages.panel");
  const classes = useStyles({width});

  const handleUpdateQueryString = useUpdateQueryString();
  const handleUpdateSearchQuery = () => {
    value !== undefined && handleUpdateQueryString({search: value});
  };

  return (
    <InputBase
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={t("common.search")}
      classes={{root: classes.inputRoot}}
      endAdornment={<Search className={classes.searchIcon} onClick={handleUpdateSearchQuery} />}
      onKeyDown={(e) => e.key === "Enter" && handleUpdateSearchQuery()}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  inputRoot: ({width}: {width?: number}) => ({
    height: "100%",
    backgroundColor: theme.palette.primary.light,
    color: "white",
    borderRadius: 4,
    width: width || 274,
    paddingLeft: 20,
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
    },
  }),
  searchIcon: {
    height: "100%",
    width: 56,
    padding: 14,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
}));
