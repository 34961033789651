export const routes = {
  landing: {
    search: "/search",
    imagesDetail: (slug: number | string) => `/images/${slug}`,
    imagesDetailPhotoType: (slug: number | string) => `/images/photo/${slug}`,
    imagesDetailVectorType: (slug: number | string) => `/images/vector/${slug}`,
    imagesDetailIllustrationType: (slug: number | string) => `/images/illustration/${slug}`,
    pricing: "/pricing",
    boardsDetail: (slug: number | string) => `/boards/${slug}`,
    boards: "/boards",
    blogDetail: (slug: number | string) => `/blog/${slug}`,
    blog: "/blog",
    register: "/register",
    login: "/login",
    forgotPassword: "/forgot-password",
    account: "/account",
    faq: "/faq",
    contactUs: "/contact-us",
    loading: "/loading",
    pay: "/pay",
    promoCode: "/promo-code",
    privacyAndPolicy: "/privacy-and-policy",
    about: "/about",
  },
  panel: {
    dashboard: "/panel/dashboard",
    imagesAdd: "/panel/images/add-image",
    imagesDetail: (slug: number | string) => `/panel/images/${slug}`,
    images: "/panel/images",
    users: "/panel/users",
    plans: "/panel/plans",
    archivedPlans: "/panel/archived-plans",
    categories: "/panel/categories",
    planInvoices: "/panel/plan-invoices",
    imageInvoices: "/panel/image-invoices",
    planPurchases: "/panel/plan-purchases",
    messages: "/panel/messages",
  },
};
