import {
  BoardsApi,
  BoardsApiBoardsCreateRequest,
  BoardsApiBoardsDeleteRequest,
  BoardsApiBoardsMoveRequest,
  BoardsApiBoardsListRequest,
  BoardsApiBoardsPartialUpdateRequest,
  BoardsApiBoardsReadRequest,
  BoardsApiBoardsAddImagesRequest,
  BoardsApiBoardsRemoveImagesRequest,
  BoardsApiBoardsThinListReadRequest,
  BoardsApiBoardsDownloadRequest,
  BoardsApiBoardsPreDownloadRequest,
} from "@schema/api";
import {AxiosRequestConfig} from "axios";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const boardsApiInstance = new BoardsApi(undefined, undefined, axiosInstance);

export const boardsAPI = makeApi({
  identifier: "boards",
  Query: {
    getList: createQuery(async (params: BoardsApiBoardsListRequest) => {
      const {data} = await boardsApiInstance.boardsList(params);
      return data;
    }),
    getRecentBoardsList: createQuery(async (params: BoardsApiBoardsThinListReadRequest) => {
      const {data} = await boardsApiInstance.boardsThinListRead(params);
      return data;
    }),
    readDetail: createQuery(async (params: BoardsApiBoardsReadRequest) => {
      const {data} = await boardsApiInstance.boardsRead(params);
      return data;
    }),
  },
  Mutation: {
    update: async (params: BoardsApiBoardsPartialUpdateRequest) => {
      const {data} = await boardsApiInstance.boardsPartialUpdate(params);
      return data;
    },
    delete: async (params: BoardsApiBoardsDeleteRequest) => {
      const {data} = await boardsApiInstance.boardsDelete(params);
      return data;
    },
    create: async (params: BoardsApiBoardsCreateRequest) => {
      const {data} = await boardsApiInstance.boardsCreate(params);
      return data;
    },
    download: async (
      params: BoardsApiBoardsDownloadRequest & {axiosConfig?: AxiosRequestConfig}
    ) => {
      const {axiosConfig, ...rest} = params;
      const {data} = await boardsApiInstance.boardsDownload(rest, axiosConfig);
      return data;
    },
    preDownload: async (params: BoardsApiBoardsPreDownloadRequest) => {
      const {data} = await boardsApiInstance.boardsPreDownload(params);
      return data;
    },
    addToBoard: async (params: BoardsApiBoardsAddImagesRequest) => {
      const {data} = await boardsApiInstance.boardsAddImages(params);
      return data;
    },
    removeFromBoard: async (params: BoardsApiBoardsRemoveImagesRequest) => {
      const {data} = await boardsApiInstance.boardsRemoveImages(params);
      return data;
    },
    moveToAnotherBoard: async (params: BoardsApiBoardsMoveRequest) => {
      const {data} = await boardsApiInstance.boardsMove(params);
      return data;
    },
  },
});
