/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse20015 } from '../servermodel';
// @ts-ignore
import { PrivacyAndPolicyPage } from '../servermodel';
/**
 * PrivacyAndPolicyApi - axios parameter creator
 * @export
 */
export const PrivacyAndPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyCreate: async (data: PrivacyAndPolicyPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('privacyAndPolicyCreate', 'data', data)
            const localVarPath = `/privacy-and-policy/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('privacyAndPolicyDelete', 'id', id)
            const localVarPath = `/privacy-and-policy/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyList: async (page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/privacy-and-policy/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyPartialUpdate: async (id: number, data: PrivacyAndPolicyPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('privacyAndPolicyPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('privacyAndPolicyPartialUpdate', 'data', data)
            const localVarPath = `/privacy-and-policy/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('privacyAndPolicyRead', 'id', id)
            const localVarPath = `/privacy-and-policy/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyUpdate: async (id: number, data: PrivacyAndPolicyPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('privacyAndPolicyUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('privacyAndPolicyUpdate', 'data', data)
            const localVarPath = `/privacy-and-policy/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivacyAndPolicyApi - functional programming interface
 * @export
 */
export const PrivacyAndPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivacyAndPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyAndPolicyCreate(data: PrivacyAndPolicyPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivacyAndPolicyPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyAndPolicyCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyAndPolicyDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyAndPolicyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyAndPolicyList(page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyAndPolicyList(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyAndPolicyPartialUpdate(id: number, data: PrivacyAndPolicyPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivacyAndPolicyPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyAndPolicyPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyAndPolicyRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivacyAndPolicyPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyAndPolicyRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privacyAndPolicyUpdate(id: number, data: PrivacyAndPolicyPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivacyAndPolicyPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privacyAndPolicyUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivacyAndPolicyApi - factory interface
 * @export
 */
export const PrivacyAndPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivacyAndPolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyCreate(data: PrivacyAndPolicyPage, options?: any): AxiosPromise<PrivacyAndPolicyPage> {
            return localVarFp.privacyAndPolicyCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.privacyAndPolicyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyList(page?: number, options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.privacyAndPolicyList(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyPartialUpdate(id: number, data: PrivacyAndPolicyPage, options?: any): AxiosPromise<PrivacyAndPolicyPage> {
            return localVarFp.privacyAndPolicyPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyRead(id: number, options?: any): AxiosPromise<PrivacyAndPolicyPage> {
            return localVarFp.privacyAndPolicyRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this privacy and policy page.
         * @param {PrivacyAndPolicyPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privacyAndPolicyUpdate(id: number, data: PrivacyAndPolicyPage, options?: any): AxiosPromise<PrivacyAndPolicyPage> {
            return localVarFp.privacyAndPolicyUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for privacyAndPolicyCreate operation in PrivacyAndPolicyApi.
 * @export
 * @interface PrivacyAndPolicyApiPrivacyAndPolicyCreateRequest
 */
export interface PrivacyAndPolicyApiPrivacyAndPolicyCreateRequest {
    /**
     * 
     * @type {PrivacyAndPolicyPage}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyCreate
     */
    readonly data: PrivacyAndPolicyPage
}

/**
 * Request parameters for privacyAndPolicyDelete operation in PrivacyAndPolicyApi.
 * @export
 * @interface PrivacyAndPolicyApiPrivacyAndPolicyDeleteRequest
 */
export interface PrivacyAndPolicyApiPrivacyAndPolicyDeleteRequest {
    /**
     * A unique integer value identifying this privacy and policy page.
     * @type {number}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyDelete
     */
    readonly id: number
}

/**
 * Request parameters for privacyAndPolicyList operation in PrivacyAndPolicyApi.
 * @export
 * @interface PrivacyAndPolicyApiPrivacyAndPolicyListRequest
 */
export interface PrivacyAndPolicyApiPrivacyAndPolicyListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyList
     */
    readonly page?: number
}

/**
 * Request parameters for privacyAndPolicyPartialUpdate operation in PrivacyAndPolicyApi.
 * @export
 * @interface PrivacyAndPolicyApiPrivacyAndPolicyPartialUpdateRequest
 */
export interface PrivacyAndPolicyApiPrivacyAndPolicyPartialUpdateRequest {
    /**
     * A unique integer value identifying this privacy and policy page.
     * @type {number}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PrivacyAndPolicyPage}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyPartialUpdate
     */
    readonly data: PrivacyAndPolicyPage
}

/**
 * Request parameters for privacyAndPolicyRead operation in PrivacyAndPolicyApi.
 * @export
 * @interface PrivacyAndPolicyApiPrivacyAndPolicyReadRequest
 */
export interface PrivacyAndPolicyApiPrivacyAndPolicyReadRequest {
    /**
     * A unique integer value identifying this privacy and policy page.
     * @type {number}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyRead
     */
    readonly id: number
}

/**
 * Request parameters for privacyAndPolicyUpdate operation in PrivacyAndPolicyApi.
 * @export
 * @interface PrivacyAndPolicyApiPrivacyAndPolicyUpdateRequest
 */
export interface PrivacyAndPolicyApiPrivacyAndPolicyUpdateRequest {
    /**
     * A unique integer value identifying this privacy and policy page.
     * @type {number}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PrivacyAndPolicyPage}
     * @memberof PrivacyAndPolicyApiPrivacyAndPolicyUpdate
     */
    readonly data: PrivacyAndPolicyPage
}

/**
 * PrivacyAndPolicyApi - object-oriented interface
 * @export
 * @class PrivacyAndPolicyApi
 * @extends {BaseAPI}
 */
export class PrivacyAndPolicyApi extends BaseAPI {
    /**
     * 
     * @param {PrivacyAndPolicyApiPrivacyAndPolicyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyAndPolicyApi
     */
    public privacyAndPolicyCreate(requestParameters: PrivacyAndPolicyApiPrivacyAndPolicyCreateRequest, options?: any) {
        return PrivacyAndPolicyApiFp(this.configuration).privacyAndPolicyCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrivacyAndPolicyApiPrivacyAndPolicyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyAndPolicyApi
     */
    public privacyAndPolicyDelete(requestParameters: PrivacyAndPolicyApiPrivacyAndPolicyDeleteRequest, options?: any) {
        return PrivacyAndPolicyApiFp(this.configuration).privacyAndPolicyDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrivacyAndPolicyApiPrivacyAndPolicyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyAndPolicyApi
     */
    public privacyAndPolicyList(requestParameters: PrivacyAndPolicyApiPrivacyAndPolicyListRequest = {}, options?: any) {
        return PrivacyAndPolicyApiFp(this.configuration).privacyAndPolicyList(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrivacyAndPolicyApiPrivacyAndPolicyPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyAndPolicyApi
     */
    public privacyAndPolicyPartialUpdate(requestParameters: PrivacyAndPolicyApiPrivacyAndPolicyPartialUpdateRequest, options?: any) {
        return PrivacyAndPolicyApiFp(this.configuration).privacyAndPolicyPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrivacyAndPolicyApiPrivacyAndPolicyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyAndPolicyApi
     */
    public privacyAndPolicyRead(requestParameters: PrivacyAndPolicyApiPrivacyAndPolicyReadRequest, options?: any) {
        return PrivacyAndPolicyApiFp(this.configuration).privacyAndPolicyRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrivacyAndPolicyApiPrivacyAndPolicyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivacyAndPolicyApi
     */
    public privacyAndPolicyUpdate(requestParameters: PrivacyAndPolicyApiPrivacyAndPolicyUpdateRequest, options?: any) {
        return PrivacyAndPolicyApiFp(this.configuration).privacyAndPolicyUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
