import {blue} from "@constant/colors";
import Typography from "@elements/Typography";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart as LChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface LineChartProps {
  data: {
    value: React.ReactText;
    label?: React.ReactText;
  }[];
  label: React.ReactText;
}

export default function LineChart({data, label}: LineChartProps) {
  const classes = useStyles();
  return (
    <Grid container direction='column' className={classes.root}>
      <Typography align='center' weight={700}>
        {label}
      </Typography>
      <ResponsiveContainer height={280}>
        <LChart data={data}>
          <CartesianGrid vertical={false} stroke='#e5e5e5' className={classes.wrapper} />
          <XAxis dataKey='label' strokeWidth={0} interval='preserveStartEnd' />
          <YAxis
            strokeWidth={0}
            textAnchor='start'
            minTickGap={2.5}
            padding={{bottom: 15, top: 15}}
          />
          <Tooltip
            cursor={false}
            content={({payload}) => <Typography align='center'>{payload?.[0]?.value}</Typography>}
          />
          <Line
            type='monotone'
            strokeWidth={2}
            dataKey='value'
            fill={blue.A300}
            underlineThickness={0}
          />
        </LChart>
      </ResponsiveContainer>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    backgroundColor: "white",
    padding: "16px 24px",
    gap: 36,
  },
  wrapper: {
    "&:last-child,&:nth-last-child(2)": {
      display: "none",
    },
  },
}));
