import {Helmet} from "react-helmet";
import {ListImage} from "@schema";
import {defaultPageTitle} from "@constant/defultPageTitle";

export default function MetaTags({images}: {images?: ListImage[]}) {
  return (
    <Helmet>
      <title>{defaultPageTitle}</title>
      <meta name='robots' content='noindex' />
      <meta
        name='description'
        content='دانلود عکس در پارس ایمیجز. در بین هزاران عکس با کیفیت  جستجو کن. عکس‌هایی که به آسونی هر جایی گیر نمیان .'
      />
      <meta
        content={images?.[0]?.file || images?.[1]?.file || images?.[2]?.file}
        property='og:image'
      />
    </Helmet>
  );
}
