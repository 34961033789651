import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import ImageCardResponsive from "./ImageCardResponsive";
import {Link} from "react-router-dom";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {routes} from "@constant/routes";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {images} from "@constant/images";

const HeadlinesSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.home");
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(500));
  const xxxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(380));

  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  return (
    <Box component='section' className={classes.container}>
      <Grid container direction='column' className={classes.headingsBox}>
        <Typography component='h2' size={xsDown ? 2.1 : 2.8} align='center'>
          {t("headlinesSection.title")}
        </Typography>
        <Typography size={xxsDown ? 1.5 : xsDown ? 1.8 : 2.4} align='center'>
          {t("headlinesSection.description")}
        </Typography>
      </Grid>
      <Grid component='ul' container justify='center' wrap='wrap' className={classes.headlines}>
        {[
          {
            name: "Photo",
            title: "عکس",
            image: data?.photo_group_image || "",
          },
          {
            name: "Illustration",
            title: "طرح",
            image: data?.illustration_group_image || "",
          },

          {
            name: "Vector",
            title: "وکتور",
            image: data?.vector_group_image || "",
          },
          {
            name: "Video",
            title: "ویدئو",
            image: images.base.video,
          },
        ].map(({name, title, image}) => (
          <Grid
            component='li'
            key={name}
            item
            xs={xxxsDown ? 12 : 6}
            sm={4}
            md={3}
            className={classes.item}
          >
            {name !== "Video" ? (
              <Link to={`${routes.landing.search}?type=${name}`}>
                <ImageCardResponsive image={image} title={title} ratio={0.524} />
              </Link>
            ) : (
              <ImageCardResponsive image={image} title={title} comingSoon ratio={0.524} />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HeadlinesSection;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "4.2rem .6rem 7rem",
    [theme.breakpoints.down("xs")]: {
      padding: "3.2rem .6rem 3rem",
    },
  },
  headingsBox: {
    padding: "0 2.8rem",
    gap: "1rem",
    marginBottom: "6.5rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2.5rem",
      padding: 0,
    },
  },
  headlines: {
    margin: "0 auto",
  },
  item: {
    padding: ".8rem",
  },
}));
