import {ApiNamespaces} from "@api";
import {Params, QueryFetcher, Return} from "./types";
import {AxiosError} from "axios";
import {QueryClient, useQuery as useRQQuery, useQueryClient} from "react-query";
import {QueryBehavior} from "react-query/types/core/query";
import {RetryDelayValue, RetryValue} from "react-query/types/core/retryer";
import {
  GetNextPageParamFunction,
  GetPreviousPageParamFunction,
  InfiniteQueryObserverResult,
  InitialDataFunction,
  PlaceholderDataFunction,
  QueryKey,
  QueryKeyHashFunction,
  QueryObserverIdleResult,
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
} from "react-query/types/core/types";
import {UseQueryResult} from "react-query/types/react/types";

export interface QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> {
  /**
   * If `false`, failed queries will not retry by default.
   * If `true`, failed queries will retry infinitely., failureCount: num
   * If set to an integer number, e.g. 3, failed queries will retry until the failed query count meets that number.
   * If set to a function `(failureCount, error) => boolean` failed queries will retry until the function returns false.
   */
  retry?: RetryValue<TError>;
  retryDelay?: RetryDelayValue<TError>;
  cacheTime?: number;
  isDataEqual?: (oldData: TData | undefined, newData: TData) => boolean;
  queryFn?: QueryFunction<TQueryFnData, TQueryKey>;
  queryHash?: string;
  queryKey?: TQueryKey;
  queryKeyHashFn?: QueryKeyHashFunction<TQueryKey>;
  initialData?: TData | InitialDataFunction<TData>;
  initialDataUpdatedAt?: number | (() => number | undefined);
  behavior?: QueryBehavior<TQueryFnData, TError, TData>;
  /**
   * Set this to `false` to disable structural sharing between query results.
   * Defaults to `true`.
   */
  structuralSharing?: boolean;
  /**
   * This function can be set to automatically get the previous cursor for infinite queries.
   * The result will also be used to determine the value of `hasPreviousPage`.
   */
  getPreviousPageParam?: GetPreviousPageParamFunction<TQueryFnData>;
  /**
   * This function can be set to automatically get the next cursor for infinite queries.
   * The result will also be used to determine the value of `hasNextPage`.
   */
  getNextPageParam?: GetNextPageParamFunction<TQueryFnData>;
  _defaulted?: boolean;
}

export interface QueryObserverOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends QueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  /**
   * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
   * To refetch the query, use the `refetch` method returned from the `useQuery` instance.
   * Defaults to `true`.
   */
  enabled?: boolean;
  /**
   * The time in milliseconds after data is considered stale.
   * If set to `Infinity`, the data will never be considered stale.
   */
  staleTime?: number;
  /**
   * If set to a number, the query will continuously refetch at this frequency in milliseconds.
   * Defaults to `false`.
   */
  refetchInterval?: number | false;
  /**
   * If set to `true`, the query will continue to refetch while their tab/window is in the background.
   * Defaults to `false`.
   */
  refetchIntervalInBackground?: boolean;
  /**
   * If set to `true`, the query will refetch on window focus if the data is stale.
   * If set to `false`, the query will not refetch on window focus.
   * If set to `'always'`, the query will always refetch on window focus.
   * Defaults to `true`.
   */
  refetchOnWindowFocus?: boolean | "always";
  /**
   * If set to `true`, the query will refetch on reconnect if the data is stale.
   * If set to `false`, the query will not refetch on reconnect.
   * If set to `'always'`, the query will always refetch on reconnect.
   * Defaults to `true`.
   */
  refetchOnReconnect?: boolean | "always";
  /**
   * If set to `true`, the query will refetch on mount if the data is stale.
   * If set to `false`, will disable additional instances of a query to trigger background refetches.
   * If set to `'always'`, the query will always refetch on mount.
   * Defaults to `true`.
   */
  refetchOnMount?: boolean | "always";
  /**
   * If set to `false`, the query will not be retried on mount if it contains an error.
   * Defaults to `true`.
   */
  retryOnMount?: boolean;
  /**
   * If set, the component will only re-render if any of the listed properties change.
   * When set to `['data', 'error']`, the component will only re-render when the `data` or `error` properties change.
   */
  notifyOnChangeProps?: Array<keyof InfiniteQueryObserverResult>;
  /**
   * If set, the component will not re-render if any of the listed properties change.
   */
  notifyOnChangePropsExclusions?: Array<keyof InfiniteQueryObserverResult>;
  /**
   * This callback will fire any time the query successfully fetches new data.
   */
  onSuccess?: (data: TData) => void;
  /**
   * This callback will fire if the query encounters an error and will be passed the error.
   */
  onError?: (err: TError) => void;
  /**
   * This callback will fire any time the query is either successfully fetched or errors and be passed either the data or error.
   */
  onSettled?: (data: TData | undefined, error: TError | null) => void;
  /**
   * Whether errors should be thrown instead of setting the `error` property.
   * Defaults to `false`.
   */
  useErrorBoundary?: boolean;
  /**
   * This option can be used to transform or select a part of the data returned by the query function.
   */
  select?: (data: TQueryData) => TData;
  /**
   * If set to `true`, the query will suspend when `status === 'loading'`
   * and throw errors when `status === 'error'`.
   * Defaults to `false`.
   */
  suspense?: boolean;
  /**
   * Set this to `true` to keep the previous `data` when fetching based on a new query key.
   * Defaults to `false`.
   */
  keepPreviousData?: boolean;
  /**
   * If set, this value will be used as the placeholder data for this particular query observer while the query is still in the `loading` data and no initialData has been provided.
   */
  placeholderData?: TData | PlaceholderDataFunction<TData>;
}

export interface UseBaseQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TQueryData = TQueryFnData
> extends QueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey> {}

export interface UseQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends UseBaseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {}

export type QueryFunction<T = unknown, Q extends QueryKey = QueryKey> = (
  context: QueryFunctionContext<Q>
) => T | Promise<T>;

export interface QueryFunctionContext<TQueryKey extends QueryKey = QueryKey, TPageParam = any> {
  queryKey: TQueryKey;
  pageParam?: TPageParam;
}

export function useQuery<
  TQueryFnData = unknown,
  TError = AxiosError<unknown>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;
export function useQuery<
  TQueryFnData = unknown,
  TError = AxiosError<unknown>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError>;
export function useQuery<
  TQueryFnData = unknown,
  TError = AxiosError<unknown>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError>;
export function useQuery(...args: any[]) {
  const hook = useRQQuery as any;
  return hook(...args);
}

type useQueryReturn<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"],
  TQueryFnData = Return<QueryFetcher<T, U>> extends Promise<infer C> ? C : Return<QueryFetcher<T, U>>,
  TError = AxiosError<unknown>,
  TData = TQueryFnData
> =
  | QueryObserverIdleResult<TData, TError>
  | QueryObserverLoadingErrorResult<TData, TError>
  | QueryObserverLoadingResult<TData, TError>
  | QueryObserverRefetchErrorResult<TData, TError>
  | QueryObserverSuccessResult<TData, TError>;

export function useExperimentalQuery<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"],
  TQueryFnData = Return<QueryFetcher<T, U>> extends Promise<infer C> ? C : Return<QueryFetcher<T, U>>,
  TError = AxiosError<unknown>,
  TData = TQueryFnData
>(namespace: T, key: U, options: ExperimentalUseQueryOptions<T, U, TQueryFnData, TError, TData>): useQueryReturn<T, U>;
export function useExperimentalQuery<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"],
  TQueryFnData = Return<QueryFetcher<T, U>> extends Promise<infer C> ? C : Return<QueryFetcher<T, U>>,
  TError = AxiosError<unknown>,
  TData = TQueryFnData
>(
  namespace: T,
  key: U
): Params<QueryFetcher<T, U>>[0] extends undefined ? useQueryReturn<T, U, TQueryFnData, TError, TData> : never;
export function useExperimentalQuery<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"],
  TQueryFnData = Return<QueryFetcher<T, U>> extends Promise<infer C> ? C : Return<QueryFetcher<T, U>>,
  TError = AxiosError<unknown>,
  TData = TQueryFnData
>(...args: any[]) {
  const [namespace, key, options] = args;
  // @ts-ignore
  const queryFn = ApiNamespaces[namespace]["Query"][key];
  const {variables, ...opts} = options || {variables: undefined};
  const proxy = useQueryClient();
  const onSuccess = (data: TData) => options?.onSuccess?.(data, proxy);
  const onError = (err: TError) => options?.onError?.(err, proxy);
  const onSettled = (data: TData | undefined, error: TError | null) => options?.onSettled?.(data, error, proxy);
  const {queryKey} = queryFn;
  const rqQueryKey = variables ? [queryKey, variables] : [queryKey];
  return useQuery(rqQueryKey, queryFn, {...opts, onSuccess, onError, onSettled});
}

type ExperimentalUseQueryBaseOptions<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"]
> = Params<QueryFetcher<T, U>>[0] extends undefined
  ? {
      variables?: undefined;
    }
  : {
      variables: Params<QueryFetcher<T, U>>[0] extends {queryKey: [unknown, infer W]} ? W : never;
    };

type ExperimentalUseQueryOptions<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"],
  TQueryFnData = Return<QueryFetcher<T, U>> extends Promise<infer C> ? C : Return<QueryFetcher<T, U>>,
  TError = AxiosError<unknown>,
  TData = TQueryFnData
> = ExperimentalUseQueryBaseOptions<T, U> & {
  retry?: RetryValue<TError>;
  retryDelay?: RetryDelayValue<TError>;
  cacheTime?: number;
  isDataEqual?: (oldData: TData | undefined, newData: TData) => boolean;
  initialData?: TData | InitialDataFunction<TData>;
  /**
   * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
   * To refetch the query, use the `refetch` method returned from the `useQuery` instance.
   * Defaults to `true`.
   */
  enabled?: boolean;
  /**
   * The time in milliseconds after data is considered stale.
   * If set to `Infinity`, the data will never be considered stale.
   */
  staleTime?: number;
  /**
   * If set to a number, the query will continuously refetch at this frequency in milliseconds.
   * Defaults to `false`.
   */
  refetchInterval?: number | false;
  /**
   * If set to `true`, the query will continue to refetch while their tab/window is in the background.
   * Defaults to `false`.
   */
  refetchIntervalInBackground?: boolean;
  /**
   * If set to `true`, the query will refetch on window focus if the data is stale.
   * If set to `false`, the query will not refetch on window focus.
   * If set to `'always'`, the query will always refetch on window focus.
   * Defaults to `true`.
   */
  refetchOnWindowFocus?: boolean | "always";
  /**
   * If set to `true`, the query will refetch on reconnect if the data is stale.
   * If set to `false`, the query will not refetch on reconnect.
   * If set to `'always'`, the query will always refetch on reconnect.
   * Defaults to `true`.
   */
  refetchOnReconnect?: boolean | "always";
  /**
   * If set to `true`, the query will refetch on mount if the data is stale.
   * If set to `false`, will disable additional instances of a query to trigger background refetches.
   * If set to `'always'`, the query will always refetch on mount.
   * Defaults to `true`.
   */
  refetchOnMount?: boolean | "always";
  /**
   * If set to `false`, the query will not be retried on mount if it contains an error.
   * Defaults to `true`.
   */
  retryOnMount?: boolean;
  /**
   * This callback will fire any time the query successfully fetches new data.
   */
  onSuccess?: (data: TData, proxy: QueryClient) => void;
  /**
   * This callback will fire if the query encounters an error and will be passed the error.
   */
  onError?: (err: TError, proxy: QueryClient) => void;
  /**
   * This callback will fire any time the query is either successfully fetched or errors and be passed either the data or error.
   */
  onSettled?: (data: TData | undefined, error: TError | null, proxy: QueryClient) => void;
  /**
   * Whether errors should be thrown instead of setting the `error` property.
   * Defaults to `false`.
   */
  useErrorBoundary?: boolean;
  /**
   * If set to `true`, the query will suspend when `status === 'loading'`
   * and throw errors when `status === 'error'`.
   * Defaults to `false`.
   */
  suspense?: boolean;
  /**
   * Set this to `true` to keep the previous `data` when fetching based on a new query key.
   * Defaults to `false`.
   */
  keepPreviousData?: boolean;
  /**
   * If set, this value will be used as the placeholder data for this particular query observer while the query is still in the `loading` data and no initialData has been provided.
   */
  placeholderData?: TData | PlaceholderDataFunction<TData>;
};
