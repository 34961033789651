import {images} from "@constant/images";
import {useActionsDrawerContext} from "@context/ActionsDrawerContext";
import {useNavbarContext} from "@context/NavbarContext";
import Typography from "@elements/Typography";
import {Box, useScrollTrigger, Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {headerHeightBig, headerHeightSmall} from "@modules/Header/constant";
import {customMedium} from "@modules/mainLayout/breakpoints";
import SearchBar from "@modules/SearchBar";
import React, {useEffect, useState} from "react";
import PlansAndPricing from "./PlansAndPricing";
import {useTranslation} from "react-i18next";
import {useAuth} from "@context/AuthContext";
import {useExperimentalQuery} from "@hook/react-query/useQuery";

// !! to change or update searchBar styles, read comments first (last lines of document)

const Hero = () => {
  const [offsetTop, setOffsetTop] = useState(0);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const {openNavbar} = useNavbarContext();
  const {openActionsDrawer} = useActionsDrawerContext();
  const {t} = useTranslation("modules.pages.home");
  const {user, loadedUser} = useAuth();

  const headerHeight = smDown ? headerHeightSmall : headerHeightBig;
  const wallpaperHeight = smDown ? 350 : 600;
  const paddingTop = smDown ? 0 : 270;

  const isTimeToSetSearchBarPositionFixed = offsetTop > headerHeight + paddingTop;
  const trigger =
    !useScrollTrigger({threshold: headerHeight + wallpaperHeight}) &&
    !openNavbar &&
    !openActionsDrawer;
  const classes = useStyle({
    images,
    isTimeToSetSearchBarPositionFixed,
    trigger,
    wallpaperHeight,
    paddingTop,
  });
  const {data} = useExperimentalQuery("panel", "adminControl", {
    variables: {},
    staleTime: Infinity,
  });

  useEffect(() => {
    const listener = () => {
      setOffsetTop(window.pageYOffset);
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <Grid
      component='section'
      container
      direction='column'
      justify='center'
      className={classes.container}
      style={{backgroundImage: `url(${data?.hero_section_image})`}}
    >
      <Grid container direction='column' className={classes.paddingTop}>
        <Grid container direction='column' className={classes.textBox}>
          <Typography component='h1' color='white' align='center' size={smDown ? 2.4 : 3.2}>
            {t("wallpaperSection.title")}
          </Typography>
          <Typography
            component='h2'
            color='white'
            align='center'
            size={1.8}
            className={classes.margin}
          >
            {t("wallpaperSection.description")}
          </Typography>
        </Grid>
        <Box className={classes.searchBarBox}>
          <SearchBar opacity />
        </Box>
      </Grid>
      {loadedUser && !user?.plans?.length && (
        <Box className={classes.plansAndPricingBox}>
          <PlansAndPricing />
        </Box>
      )}
    </Grid>
  );
};

export default Hero;

interface StylesProps {
  images: any;
  isTimeToSetSearchBarPositionFixed: boolean;
  trigger: boolean;
  wallpaperHeight: number;
  paddingTop: number;
}

const useStyle = makeStyles((theme) => ({
  container: ({images, wallpaperHeight}: StylesProps) => ({
    height: wallpaperHeight,
    backgroundColor: "#184656",
    backgroundSize: "cover",
  }),
  paddingTop: ({paddingTop}: StylesProps) => ({
    position: "relative",
    height: "100%",
    paddingTop: paddingTop,
    [theme.breakpoints.down(customMedium)]: {
      paddingTop: 0,
    },
  }),
  margin: {
    margin: "1rem 0 3.2rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85%",
      margin: "1rem auto 3.2rem",
    },
  },
  textBox: {
    position: "absolute",
    top: 150,
    left: 0,
    padding: "0 .2rem",
    "& *": {
      textShadow: "1px 1px 3px rgba(0,0,0,1)",
    },
    [theme.breakpoints.down("sm")]: {
      top: 120,
    },
    [theme.breakpoints.down("xs")]: {
      top: 100,
    },
  },
  searchBarBox: ({isTimeToSetSearchBarPositionFixed, trigger}: StylesProps) => ({
    width: "100%",
    margin: "0 auto",
    transition: ".1s ease-in-out",
    zIndex: 1000,
    maxWidth: isTimeToSetSearchBarPositionFixed ? "100%" : "113rem",
    position: isTimeToSetSearchBarPositionFixed ? "fixed" : "static",
    opacity: isTimeToSetSearchBarPositionFixed ? 0.96 : 0.97,
    top: 0,
    right: 0,
    left: 0,
    transform: !trigger ? "translateY(-100%)" : undefined,
  }),
  plansAndPricingBox: {
    width: "100%",
    flexShrink: 0,
    [theme.breakpoints.down(400)]: {
      display: "none",
    },
  },
}));

// useScrollTrigger returns true when offsetTop is bigger than threshold or mouse wheel is turning down.
// so we want it the opposite, we want the searchBar to be appear when offsetTop is smaller than threshold
// or when user is scrolling up
//
// all sizes related to searchBar position are in px because trigger only accepts px
