import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import CategoryCard from "@elements/panel/CategoryCard";
import CategoryModal from "@elements/panel/modals/CategoryModal";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import Pagination from "@elements/panel/Pagination";
import Spinner from "@elements/Spinner";
import NoResult from "@elements/panel/NoResult";
import QuerySearchInputFullWidth from "@elements/panel/QuerySearchInputFullWidth";
import useModalState from "@hook/useModalState";

const Categories = () => {
  const [modalState, setModalState] = useModalState();
  const classes = useStyle();
  const {search, page, pageSize} = useGetQueryString();

  const {data} = useExperimentalQuery("categories", "getList", {
    variables: {
      search,
      page: page ? Number(page) : undefined,
      pageSize: pageSize ? Number(pageSize) : 20,
    },
  });

  return (
    <>
      <Grid className={classes.container}>
        <QuerySearchInputFullWidth
          onClickAddIcon={() => setModalState({visible: true, activeId: null})}
        />
        {!data && <Spinner />}
        {data?.results.length === 0 && <NoResult />}
        <Grid container wrap='wrap' className={classes.categoriesBox}>
          {data?.results.map(({id, name, image, description}) => (
            <Box className={classes.cardBox} key={id}>
              <CategoryCard
                id={id || 0}
                title={name}
                image={((image?.file as unknown) as string) || ""}
                description={description || ""}
                onClickEdit={() => setModalState({visible: true, activeId: id || 1})}
              />
            </Box>
          ))}
        </Grid>
        <Pagination count={data?.count || 0} />
      </Grid>
      <CategoryModal
        open={modalState.visible}
        id={modalState.activeId}
        onClose={() => setModalState({visible: false, activeId: null})}
        onSubmit={() => setModalState({visible: false, activeId: null})}
      />
    </>
  );
};

export default Categories;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  categoriesBox: {
    columnGap: "1.5%",
    rowGap: 28,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
  cardBox: {
    width: "32.33%",
    [theme.breakpoints.up(1500)]: {
      width: "23.875%",
    },
    [theme.breakpoints.up(1700)]: {
      width: "18.8%",
    },
  },
}));
