import {palette} from "@constant/colors";
import {AcceptedTypes, ReactCodeInputProps} from "@elements/CodeInput/types";
import classNames from "clsx";
import React from "react";
import {uuidv4} from "./utils";

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

export function ReactCodeInput({
  className,
  style = {},
  inputStyle = {},
  inputStyleInvalid = {},
  type = "text",
  autoFocus = true,
  autoComplete = "off",
  pattern,
  inputMode,
  disabled,
  isValid,
  touch,
  untouch,
  name,
  fields = 4,
  filterChars,
  filterCharsIsWhitelist,
  forceUppercase,
  filterKeyCodes,
  value,
  onChange,
}: ReactCodeInputProps) {
  const [valueState, setValueState] = React.useState("");
  const [fieldsState, setFieldsState] = React.useState(4);
  const [typeState, setTypeState] = React.useState<AcceptedTypes>("text");
  const [isValidState, setIsValidState] = React.useState(true);
  const [disabledState, setDisabledState] = React.useState(false);
  const [filterKeyCodesState, setFilterKeyCodeState] = React.useState([189, 190]);
  const defaultInputStyleState = {
    fontFamily: "monospace",
    MozAppearance: "textfield",
    borderRadius: "6px",
    border: "1px solid #aaa",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
    margin: "4px",
    paddingLeft: "8px",
    paddingRight: 0,
    width: "36px",
    height: "42px",
    fontSize: "32px",
    boxSizing: "border-box",
  };
  const [inputState, setInputState] = React.useState<string[]>([]);
  const styles = {
    container: {display: "inline-block", ...style},
    input: isValidState ? inputStyle : inputStyleInvalid,
  };

  React.useEffect(() => {
    const input = [];
    for (let i = 0; i < Number(fieldsState); i++) {
      if (i < 32) {
        const v = valueState[i] || "";
        input.push(v);
      }
    }
    setInputState(input);
  }, [fieldsState, valueState]);

  // React.useEffect(() => {
  //   setInputState(valueState.split(""));
  // }, [valueState]);

  React.useEffect(() => {
    if (value !== undefined) {
      let v = value;
      if (forceUppercase) {
        v = v.toUpperCase();
      }
      setValueState(v);
    }
    setFieldsState(fields);
    setTypeState(type);
    if (isValid !== undefined) {
      setIsValidState(isValid);
    }
    if (disabled !== undefined) {
      setDisabledState(disabled);
    }
    if (filterKeyCodes) {
      setFilterKeyCodeState(filterKeyCodes);
    }
  }, [disabled, fields, filterKeyCodes, forceUppercase, isValid, type, value]);

  const uuid = uuidv4();

  const textInput: (HTMLInputElement | null)[] = [];

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleTouch(e.target.value);
  };

  const handleTouch = (v: string) => {
    if (typeof touch === "function" && typeof untouch === "function") {
      if (v === "") {
        touch(name);
      } else {
        untouch(name);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let v = String(e.target.value);
    if (forceUppercase) {
      v = v.toUpperCase();
    }

    if (typeState === "number") {
      v = v.replace(/[^\d]/g, "");
    }

    /** Filter Chars */
    v = v
      .split("")
      .filter((currChar) => {
        if (filterCharsIsWhitelist) {
          return filterChars?.includes(currChar);
        }
        return !filterChars?.includes(currChar);
      })
      .join("");

    let fullValue = v;

    if (v !== "") {
      const input = inputState.slice();

      if (v.length > 1) {
        v.split("").map((chart, i) => {
          if (Number(e.target.dataset.id) + i < fieldsState) {
            input[Number(e.target.dataset.id) + i] = chart;
          }
          return false;
        });
      } else {
        input[Number(e.target.dataset.id)] = v;
      }

      input.map((s, i) => {
        const current = textInput[i];
        if (current) {
          current.value = s;
        }
        return false;
      });
      const id = Number(e.target.dataset.id);
      const newTarget = textInput[id < input.length ? id + 1 : id];

      if (newTarget) {
        newTarget.focus();
        newTarget.select();
      }

      fullValue = input.join("");
      setValueState(input.join(""));
      setInputState(input);
    }

    if (onChange && fullValue) {
      onChange(fullValue);
    }

    handleTouch(fullValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = Number(e.currentTarget.dataset.id),
      nextTarget = textInput[target + 1],
      prevTarget = textInput[target - 1];

    let input, v;

    if (filterKeyCodesState.length > 0) {
      filterKeyCodesState.map((item) => {
        if (item === e.keyCode) {
          e.preventDefault();
          return true;
        } else {
          return undefined;
        }
      });
    }

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        textInput[target]!.value = "";
        input = inputState.slice();
        input[target] = "";
        v = input.join("");
        setInputState(input);
        setValueState(v);
        if (textInput[target]!.value === "") {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
        }
        onChange?.(v);
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (e.currentTarget.type === "number") {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }

    handleTouch(v || "");
  };

  if (!className && Object.keys(inputStyle).length === 0) {
    Object.assign(inputStyle, {
      ...defaultInputStyleState,
      color: "black",
      backgroundColor: "white",
      borderColor: "lightgrey",
    });
  }

  if (!className && Object.keys(inputStyleInvalid).length === 0) {
    Object.assign(inputStyleInvalid, {
      ...defaultInputStyleState,
      color: palette.yellow[200],
      backgroundColor: "#f2dede",
      borderColor: "#eed3d7",
    });
  }

  if (disabled) {
    Object.assign(styles.input, {
      cursor: "not-allowed",
      color: "lightgrey",
      borderColor: "lightgrey",
      backgroundColor: "#efeff1",
    });
  }

  return (
    <div className={classNames(className, "react-code-input")} style={styles.container}>
      {inputState.map((v, i) => {
        return (
          <input
            ref={(ref) => {
              textInput[i] = ref;
            }}
            id={`${uuid}-${i}`}
            data-id={i}
            autoFocus={autoFocus && i === 0}
            value={v}
            key={`input_${i.toString()}`}
            type={type}
            min={0}
            max={9}
            maxLength={inputState.length === i + 1 ? 1 : inputState.length}
            style={styles.input}
            autoComplete={autoComplete}
            onFocus={(e) => e.target.select()}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={disabledState}
            data-valid={isValidState}
            pattern={pattern}
            inputMode={inputMode}
          />
        );
      })}
    </div>
  );
}
