import ScrollToTop from "components/ScrollToTop";
import CheckInternetConnection from "components/CheckInternetConnection";
import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {routes} from "@constant/routes";
import Providers from "@context";
import MainLayout from "@layouts/MainLayout";
import Home from "pages/home";
import Search from "pages/search";
import Pricing from "@pages/pricing";
import Image from "@pages/images/[id]";
import BoardDetail from "@pages/boards/[id]";
import Boards from "@pages/boards";
import Login from "@pages/login";
import Register from "@pages/register";
import ForgotPassword from "@pages/forgot-password";
import Blog from "@pages/blog";
import BlogPostDetail from "@pages/blog/[id]";
import Dashboard from "@pages/panel/dashboard";
import PanelLayout from "@layouts/PanelLayout";
import Images from "@pages/panel/images";
import ImageDetail from "@pages/panel/images/[id]";
import AddImage from "@pages/panel/images/add-image";
import Users from "@pages/panel/users";
import Plans from "@pages/panel/plans";
import Categories from "@pages/panel/categories";
import Account from "@pages/account";
import Loading from "@pages/loading";
import PlanInvoices from "@pages/panel/plan-invoices";
import ImageInvoices from "@pages/panel/image-invoices";
import PlanPurchases from "@pages/panel/plan-purchases";
import ArchivedPlans from "@pages/panel/archived-plans";
import PrivateRoute, {PrivateRouteProps, Role} from "services/hoc/PrivateRoute";
import Faq from "@pages/faq";
import ContactUs from "@pages/contact-us";
import Messages from "@pages/panel/messages";
import PromoCode from "@pages/promo-code";
import Pay from "@pages/pay";
import Custom404 from "@pages/404";
import ErrorBoundary from "services/hoc/ErrorBoundary";
import PrivacyAndPolicy from "@pages/privacy-and-policy";
import About from "@pages/about";
import BotImage from "@pages/bot-images/[id]";
import TopBanner from "@modules/pages/home/topBanner";

interface IRoute {
  path: string;
  component: () => JSX.Element;
  exact?: boolean;
  noLayout?: boolean;
  noDefaultSearchBar?: boolean;
  isPrivate?: boolean;
  allowedRoles?: PrivateRouteProps["allowedRoles"];
  topPayload?: React.ReactNode;
}

export default function App() {
  const {landing, panel} = routes;

  const allowedUsersToSeeImagesRoutes = [
    Role.Developer,
    Role.Owner,
    Role.Admin,
    Role.Support,
    Role.Editor,
  ];

  const appRoutes: IRoute[] = [
    // landing
    {path: landing.search, component: Search, noDefaultSearchBar: true},
    {path: landing.imagesDetailPhotoType(":slug"), component: Image},
    {path: landing.imagesDetailVectorType(":slug"), component: Image},
    {path: landing.imagesDetailIllustrationType(":slug"), component: Image},
    {path: landing.pricing, component: Pricing},
    {path: landing.boardsDetail(":id"), component: BoardDetail, noDefaultSearchBar: true},
    {path: landing.boards, component: Boards, noDefaultSearchBar: true},
    {path: landing.blogDetail(":id"), component: BlogPostDetail},
    {path: landing.blog, component: Blog},
    {path: landing.register, component: Register, noLayout: true},
    {path: landing.login, component: Login, noLayout: true},
    {path: landing.forgotPassword, component: ForgotPassword, noLayout: true},
    {path: landing.account, component: Account},
    {path: landing.faq, component: Faq},
    {path: landing.contactUs, component: ContactUs},
    {path: landing.loading, component: Loading},
    {path: landing.promoCode, component: PromoCode},
    {path: landing.pay, component: Pay, noDefaultSearchBar: true},
    {path: landing.privacyAndPolicy, component: PrivacyAndPolicy},
    {path: landing.about, component: About},
    {path: "/bot-images/:id", component: BotImage},
    {path: "/", component: Home, exact: true, noDefaultSearchBar: true, topPayload: <TopBanner />},
    //
    // panel routes => all of them are rendered as private routes
    // the PrivateRoute component uses a default value for allowedRoles => [Role.Developer, Role.Owner, Role.Admin, Role.Support]
    // so don't need to pass the prop if you need exactly as the same as default value
    {path: panel.dashboard, component: Dashboard, allowedRoles: [Role.Developer, Role.Owner]},
    {
      path: panel.imagesAdd,
      component: AddImage,
      allowedRoles: allowedUsersToSeeImagesRoutes,
    },
    {
      path: panel.imagesDetail(":id"),
      component: ImageDetail,
      allowedRoles: allowedUsersToSeeImagesRoutes,
    },
    {path: panel.images, component: Images, allowedRoles: allowedUsersToSeeImagesRoutes},
    {path: panel.users, component: Users},
    {path: panel.plans, component: Plans},
    {path: panel.archivedPlans, component: ArchivedPlans},
    {path: panel.categories, component: Categories},
    {path: panel.planInvoices, component: PlanInvoices},
    {path: panel.imageInvoices, component: ImageInvoices},
    {path: panel.planPurchases, component: PlanPurchases},
    {path: panel.messages, component: Messages},
  ];

  return (
    <BrowserRouter>
      <Providers>
        <Switch>
          {appRoutes.map((route) => (
            <Route path={route.path} exact={route.exact} key={route.path}>
              <ErrorBoundary>
                {route.path.startsWith("/panel") ? (
                  <PrivateRoute allowedRoles={route.allowedRoles}>
                    <PanelLayout>
                      <route.component />
                    </PanelLayout>
                  </PrivateRoute>
                ) : route.noLayout ? (
                  <route.component />
                ) : (
                  <MainLayout
                    noDefaultSearchBar={route.noDefaultSearchBar}
                    topPayload={route.topPayload}
                  >
                    <route.component />
                  </MainLayout>
                )}
              </ErrorBoundary>
            </Route>
          ))}
          <Redirect from='/panel' to={panel.images} />
          <Custom404 />
        </Switch>
        <CheckInternetConnection />
      </Providers>
      <ScrollToTop />
    </BrowserRouter>
  );
}
