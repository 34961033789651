import {images} from "@constant/images";
import Typography from "@elements/Typography";
import {CardMedia, Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {darkGrey, green} from "@constant/colors";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: "0.8rem 2rem",
    border: `1px solid ${green[300]}`,
    borderRadius: 4,
    boxShadow: "inset 0 0 2px 0 rgb(255 255 255 / 50%)",
  },
  gapX0_8r: {
    columnGap: "0.8rem",
  },
  editIcon: {
    width: "1.6rem",
    height: "1.6rem",
  },
}));

export interface EditButtonProps {
  onClick?: () => void;
  variant: "photo" | "video" /* may not need edit video */;
}

export function EditButton({onClick: handleClick, variant}: EditButtonProps) {
  const classes = useStyles();
  const {t} = useTranslation("elements");
  return (
    <Button className={classes.root} onClick={handleClick}>
      <Grid container justify='space-between' alignItems='center' className={classes.gapX0_8r}>
        <Grid item>
          <CardMedia component='img' src={images.icons.about} alt='edit' className={classes.editIcon} />
        </Grid>
        <Grid item>
          <Typography color={darkGrey.A700} size={1.4} height={1.8}>
            {t("button.editButton.editThis", {variant: variant})}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
}

export default EditButton;
