import {imagesProxy} from "@util/proxies/imagesProxy";

const imagesBasePath = "/images/";

const imagesInnerPaths = {
  base: "",
  icons: "icons",
  mock: "mock",
};

const base = {
  wallpaper: "wallpaper.jpg",
  promoCodePageWallpaper: "promo-code-page.jpg",
  video: "video.jpg",
};

const icons = {
  about: "about.svg",
  arrangement1Dark: "arrangement1Dark.svg",
  arrangement2Light: "arrangement2Light.svg",
  arrowDownDark: "arrowDownDark.svg",
  arrowDownLight: "arrowDownLight.svg",
  arrowDownWhite: "arrowDownWhite.svg",
  arrowLeftDark: "arrowLeftDark.svg",
  arrowRightDark: "arrowRightDark.svg",
  arrowRightGrey: "arrowRightGrey.svg",
  arrowRightWhite: "arrowRightWhite.svg",
  arrowUpWhite: "arrowUpWhite.svg",
  boards: "boards.svg",
  boardsGreen: "boardsGreen.svg",
  camera: "camera.svg",
  checkedDark: "checkedDark.svg",
  checkedGreenThick: "checkedGreenThick.svg",
  checkedGreenThin: "checkedGreenThin.svg",
  checkedWhite: "checkedWhite.svg",
  closeDarkThick: "closeDarkThick.svg",
  closeDarkThin: "closeDarkThin.svg",
  closeGrey: "closeGrey.svg",
  closeLightLarge: "closeLightLarge.svg",
  closeWithGreyBackground: "closeWithGreyBackground.svg",
  comment: "comment.svg",
  disk: "disk.svg",
  done: "done.svg",
  downloadWhite: "downloadWhite.svg",
  edit: "edit.svg",
  editorial: "editorial.svg",
  error: "error.svg",
  facebookGrey: "facebookGrey.svg",
  gradient: "gradient.svg",
  horizontalOrientation: "horizontalOrientation.svg",
  instagramGrey: "instagramGrey.svg",
  searchDark: "searchDark.svg",
  searchGrey: "searchGrey.svg",
  settingDark: "settingDark.svg",
  settingGreenLarge: "settingGreenLarge.svg",
  settingGreenSmall: "settingGreenSmall.svg",
  settingGrey: "settingGrey.svg",
  share: "share.svg",
  skewLineRed: "skewLineRed.svg",
  squareOrientation: "squareOrientation.svg",
  tweeterGrey: "tweeterGrey.svg",
  vector: "vector.svg",
  verticalOrientation: "verticalOrientation.svg",
  videoCameraGreen: "videoCameraGreen.svg",
  warning: "warning.svg",
  envelop: "envelop.svg",
  creditCard: "credit-card.svg",
  workflow: "workflow.svg",
  images: "images.svg",
  people: "people.svg",
};

const mock = {
  ad1: "Ad1.png",
};

const allImages = {
  base,
  icons,
  mock,
};

export const images = imagesProxy(imagesBasePath, allImages, imagesInnerPaths);
