import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ButtonWithIcon} from "@elements/button";
import {useTranslation} from "react-i18next";
import {useAuth} from "@context/AuthContext";
import {UserRoleEnum} from "@schema";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";

const ChangeSortButton = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.search");
  const handleUpdateQueryString = useUpdateQueryString();
  const {sort} = useGetQueryString();

  return (
    <ButtonWithIcon
      className={classes.button}
      icon={
        <svg width='20.219' height='17.063' viewBox='-287.75 385.885 27.219 21.063'>
          <path d='M-262.542 389.734h-11.625c-.442-1.618-1.91-2.817-3.667-2.817-1.756 0-3.224 1.199-3.667 2.817h-4.234a1 1 0 100 2h4.234c.443 1.617 1.91 2.816 3.667 2.816s3.224-1.199 3.667-2.816h11.625a1 1 0 100-2zm-15.292 2.816a1.817 1.817 0 110-3.633 1.817 1.817 0 010 3.633zM-286.735 402.109a1 1 0 001 1h11.625c.443 1.617 1.91 2.816 3.667 2.816 1.756 0 3.224-1.199 3.667-2.816h4.234a1 1 0 100-2h-4.234c-.443-1.618-1.91-2.817-3.667-2.817-1.756 0-3.225 1.199-3.667 2.817h-11.625a1 1 0 00-1 1zm14.475 0a1.817 1.817 0 113.634 0 1.817 1.817 0 01-3.634 0z' />
        </svg>
      }
      onClick={() => handleUpdateQueryString({sort: sort === "created" ? "popularity" : "created"})}
    >
      {sort === "popularity" ? "جدید‌ترین نتایج" : "محبوب‌ترین نتایج"}
    </ButtonWithIcon>
  );
};

export default ChangeSortButton;

const useStyle = makeStyles((theme) => ({
  button: {
    flexShrink: 0,
    width: "16.2rem",
    height: "4rem",
    direction: "rtl", // BUG: direction is reverse, icon is very small
  },
}));
