import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Divider} from "@material-ui/core";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";

const Copyright = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.footer");

  return (
    <Box className={classes.container}>
      <Divider className={classes.divider} />
      <Typography color={palette.lightGrey.A300} size={1.2} className={classes.copyright}>
        &copy; {t("copyright.rights")}
      </Typography>
    </Box>
  );
};

export default Copyright;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "0 2.5rem",
    color: palette.lightGrey.A800,
    margin: "0 auto",
    maxWidth: "110rem",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down(370)]: {
      padding: "0 1rem",
    },
  },
  divider: {
    border: `solid ${palette.darkGrey.A200} 1px`,
    borderTop: "solid black 1px",
  },
  copyright: {
    display: "inline-block",
    marginTop: "2rem",
  },
}));
