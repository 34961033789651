import {MainButton} from "@elements/button";
import {InputBase, makeStyles} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAlert} from "@context/AlertContext";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {maxPossibleBoards} from "@constant/boards";
import Typography from "@elements/Typography";
import Wrapper from "./Wrapper";
import {useModalDispatch} from "@context/ModalContext";

export interface CreateBoardModalProps {
  onSave?: (name: string) => void;
}

/** unlike panel modals, in landing modals, mutation are outside the modal itself
 * some times to prevent duplication, some time for different scenarios of using one modal
 * and for unification */
function ModalBody({onSave, ...props}: CreateBoardModalProps) {
  const classes = useStyles();
  const {t} = useTranslation("modules.modals");
  const [name, setName] = React.useState("");
  const alert = useAlert();
  const handleCloseModal = useModalDispatch(null);

  const {data: boards} = useExperimentalQuery("boards", "getList", {
    variables: {pageSize: 1, page: 1},
    onError: () => alert.error({}),
  });

  const handleSubmit = () => {
    onSave?.(name);
    handleCloseModal();
  };

  const areMaxPossibleBoardsCreated = boards && boards.count >= maxPossibleBoards;
  const disabled = !name || areMaxPossibleBoardsCreated;

  return (
    <div className={classes.form}>
      <InputBase
        autoFocus
        fullWidth
        placeholder={t("createBoard.boardName")}
        value={name}
        onChange={({target: {value}}) => setName(value)}
        onKeyDown={(e) => {
          if (!disabled && e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
      />
      {areMaxPossibleBoardsCreated && (
        <Typography color='salmon' align='center'>
          {t("common.maximumPossibleBoards")}
        </Typography>
      )}
      <MainButton color='green' disabled={disabled} fullWidth onClick={handleSubmit}>
        {t("createBoard.create")}
      </MainButton>
    </div>
  );
}

export const CreateBoardModal = Wrapper(ModalBody, "createBoard", {title: "ساخت بورد"});

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "30rem",
    gap: "1.6rem",
  },
}));
