import {fontNormalizeFactor} from "./constant";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography as MaterialTypography, TypographyProps} from "@material-ui/core";
import {palette} from "@constant/colors";
import clsx from "clsx";

export type ITypographyProps<T extends React.ElementType = "p"> = Omit<
  TypographyProps<T>,
  "color"
> & {
  color?: string;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 900 | "bold" | "normal";
  size?: number | string;
  component?: T;
  span?: boolean;
  height?: number | string;
};

const Typography = <T extends React.ElementType = "p">(props: ITypographyProps<T>) => {
  const {
    size = 1.6,
    weight = "normal",
    color = palette.darkGrey.A700,
    className,
    span,
    height = "normal",
    ...rest
  } = props;
  const classes = useStyle({size, weight, color, height});

  return (
    <MaterialTypography
      className={clsx(classes.container, span && classes.inlineBlock, className)}
      component={span ? "span" : undefined}
      {...rest}
    />
  );
};

export default Typography;

const useStyle = makeStyles(() => ({
  container: ({
    size,
    weight,
    color,
    height,
  }: Required<Pick<ITypographyProps, "color" | "weight" | "size" | "height">>) => ({
    color,
    fontSize: typeof size === "string" ? size : `${size * fontNormalizeFactor}rem`,
    fontWeight: weight,
    lineHeight: typeof height === "string" ? height : `${height}rem`,
  }),
  inlineBlock: {
    display: "inline-block",
  },
}));
