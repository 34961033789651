type QueryFetcher<I = any, O = any> = I extends void
  ? () => Promise<O>
  : (params: {queryKey: [unknown, I]}) => Promise<O>;

type MutationFetcher<I extends number | string | object = any, O = any> = (params: I) => Promise<O>;

type ConvertQuery<T extends Record<string, QueryFetcher>> = {[k in keyof T]: T[k] & {queryKey: string}};
type ConvertMutation<T extends Record<string, MutationFetcher>> = {[k in keyof T]: T[k] & {mutationKey: string}};

/**
 * @author Mohamadhassan Ebrahimi
 * makes api instances with `Query` and `Mutation` parts separated.
 * @param input
 */
export function makeApi<
  T extends {Query?: Record<string, QueryFetcher>; Mutation?: Record<string, MutationFetcher>; identifier: string}
>(
  input: T
): T["Query"] extends Record<string, QueryFetcher>
  ? T["Mutation"] extends Record<string, MutationFetcher>
    ? {Query: ConvertQuery<T["Query"]>; Mutation: ConvertMutation<T["Mutation"]>}
    : {Query: ConvertQuery<T["Query"]>; Mutation: {}}
  : T["Mutation"] extends Record<string, MutationFetcher>
  ? {Query: {}; Mutation: ConvertMutation<T["Mutation"]>}
  : {Query: {}; Mutation: {}} {
  const finalQuery = input.Query || {};
  const finalMutation = input.Mutation || {};
  const {identifier} = input;
  for (const key in finalQuery) {
    if (!finalQuery.hasOwnProperty(key)) {
      continue;
    }
    const query = finalQuery[key];
    // @ts-ignore
    query.queryKey = `${identifier}.Query.${key}`;
  }
  for (const key in finalMutation) {
    if (!finalMutation.hasOwnProperty(key)) {
      continue;
    }
    const mutation = finalMutation[key];
    // @ts-ignore
    mutation.mutationKey = `${identifier}.Mutation.${key}`;
  }
  return {Query: input.Query || {}, Mutation: input.Mutation || {}} as any;
}

/**
 * @author Mohamadhassan Ebrahimi
 * create a single parameter query function with async fetcher as input
 * @example
 * const api = makeApi({
 *   Query: {
 *     exampleQuery: createQuery(async (params: MyInputParamType) => {
 *       // body of fetcher using params
 *     })
 * }});
 * @param fetcher
 */
export function createQuery<I extends number | string | object, F>(
  fetcher: (input: I) => Promise<F>
): (params: {queryKey: [unknown, I]}) => Promise<F> {
  return ({queryKey: [, params]}: {queryKey: [unknown, I]}) => fetcher(params);
}
