import moduleA from "./moduleA.json";
import { pages } from "./pages";
import modals from "./modals.json";
import filtersDrawer from "./filtersDrawer.json";
import footer from "./footer.json";
import header from "./header.json";
import searchBar from "./searchBar.json";
import MainLayout from './mainLayout.json';

// nested namespaces are not supported, so instead we prepend name with
// .submodule_name. For example, if we want to have a 3-level nested submodule like modules/type1/category2/moduleA we
// will have: const modules = {...otherModules, "modules.type1.category2.moduleA": moduleA, ...yetAnotherModulesHere};

const modules = {
  ...pages,
  "modules.moduleA": moduleA,
  "modules.modals": modals,
  "modules.filtersDrawer": filtersDrawer,
  "modules.footer": footer,
  "modules.header": header,
  "modules.searchBar": searchBar,
  "modules.mainLayout": MainLayout,
};
export { modules };
