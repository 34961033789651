import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import clsx from "clsx";
import {palette} from "@constant/colors";

export interface IPlanCardProps {
  title: string;
  description: string;
  children: React.ReactNode;
  headerClassName?: string;
}

const PlanCard = (props: IPlanCardProps) => {
  const classes = useStyle();
  const {title, description, children, headerClassName} = props;

  return (
    <Box className={classes.card}>
      <Grid container direction='column' className={clsx(classes.cardHeader, headerClassName)}>
        <Typography color='white' size={1.8} weight={700}>
          {title}
        </Typography>
        <Typography color='white' size={1.6} style={{marginTop: "1rem"}}>
          {description}
        </Typography>
      </Grid>
      <Box className={classes.cardBody}>{children}</Box>
    </Box>
  );
};

export default PlanCard;

const useStyle = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    width: "48rem",
    maxWidth: "100%",
    border: `1px solid ${palette.lightGrey.A500}`,
    backgroundColor: "white",
  },
  cardHeader: {
    minHeight: "5.8rem",
    backgroundColor: "rgba(0,0,0,.6)",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.8rem",
    [theme.breakpoints.down(500)]: {
      padding: "1.8rem 1rem",
    },
  },
  cardBody: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1.8rem",
    [theme.breakpoints.down(500)]: {
      padding: "1.8rem .75rem",
    },
  },
}));
