import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import PanelCarousel from "@elements/panel/PanelCarousel";
import Typography from "@elements/Typography";
import LineChart from "@elements/panel/LineChart";
import BarChart from "@elements/panel/BarChart";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import AccountTable from "@elements/table/AccountTable";
import PieChart from "@elements/panel/PieChart";
import Spinner from "@elements/Spinner";
import {convertToNumberFormatWithComma} from "@util/convertToNumberFormatWithComma";
import Dropdown from "@elements/panel/Dropdown";
import Divider from "@elements/Divider";

type Period = "روز اخیر" | "هفته اخیر" | "ماه اخیر" | "سال اخیر";

const Dashboard = () => {
  const classes = useStyle();
  const [mostSoldPlansPeriod, setMostSoldPlansPeriod] = React.useState<Period>("ماه اخیر");
  const [mostBuyersPeriod, setMostBuyersPeriod] = React.useState<Period>("ماه اخیر");
  const [mostSoldEssentialPeriod, setMostSoldEssentialPeriod] = React.useState<Period>("ماه اخیر");
  const [mostSoldSignaturePeriod, setMostSoldSignaturePeriod] = React.useState<Period>("ماه اخیر");
  const [mostDownloadedEssentialPeriod, setMostDownloadedEssentialPeriod] = React.useState<Period>(
    "ماه اخیر"
  );
  const [mostDownloadedSignaturePeriod, setMostDownloadedSignaturePeriod] = React.useState<Period>(
    "ماه اخیر"
  );

  const {data} = useExperimentalQuery("panel", "getStats", {variables: {}});

  const renderPeriodSelect = (value: string, onChange?: (value: Period) => void) =>
    data && (
      <Dropdown
        label='بازه زمانی'
        color='white'
        value={value}
        onChange={(value) => onChange?.(value as Period)}
        items={Object.keys(data["پرفروش ترین پلن ها"]).map((name) => ({
          label: name,
          value: name,
        }))}
        className={classes.periodSelect}
      />
    );

  const renderTable = (key: string) =>
    data && (
      <div>
        <Typography style={{marginBottom: 4}} align='center' weight={700}>
          {key}
        </Typography>
        <AccountTable
          titles={Object.keys(data[key]).map((item) => item)}
          rows={[
            Object.keys(data[key]).map((item) => (
              <Typography key={item}>{convertToNumberFormatWithComma(data[key][item])}</Typography>
            )),
          ]}
        />
      </div>
    );

  return (
    <Grid container direction='column' className={classes.container}>
      {!data ? (
        <Spinner />
      ) : (
        <>
          <div style={{marginBottom: 18}}>
            <Typography style={{marginBottom: 40}} align='center' weight={700} size={2.6}>
              توزیع دانلود در ساعات روز
            </Typography>
            <PieChart
              data={Object.keys(data["توزیع دانلود در ساعات روز"]).map((key) => ({
                name: key,
                value: data["توزیع دانلود در ساعات روز"][key] + 1,
              }))}
            />
          </div>

          <LineChart
            label='فروش روزهای اخیر'
            data={Object.keys(data["فروش روزهای هفته"])
              .slice()
              .reverse()
              .map((item, index) => ({
                value: data["فروش روزهای هفته"][item],
                label: item.concat(index === 6 ? " (امروز)" : ""),
              }))}
          />
          <div style={{position: "relative"}}>
            <BarChart
              label='پرفروش ترین پلن ها'
              data={Object.keys(data["پرفروش ترین پلن ها"][mostSoldPlansPeriod]).map((item) => ({
                value: data["پرفروش ترین پلن ها"][mostSoldPlansPeriod][item]["sum"],
                label: data["پرفروش ترین پلن ها"][mostSoldPlansPeriod][item]["name"],
              }))}
            />
            {renderPeriodSelect(mostSoldPlansPeriod, setMostSoldPlansPeriod)}
          </div>

          <Divider style={{margin: "20px auto 10px "}} />
          <div style={{position: "relative"}}>
            <Typography style={{margin: "16px 0 36px"}} align='center' size={2} weight={700}>
              بیشترین دانلود عکس عادی
            </Typography>
            <PanelCarousel
              items={(data["بیشترین دانلود عکس عادی"][mostDownloadedEssentialPeriod] as any[]).map(
                (item) => ({
                  id: item["id"],
                  title: item["title"],
                  image: item["file"],
                })
              )}
            />
            {renderPeriodSelect(mostDownloadedEssentialPeriod, setMostDownloadedEssentialPeriod)}
          </div>

          <div style={{position: "relative", marginTop: 40}}>
            <Typography style={{margin: "16px 0 36px"}} align='center' size={2} weight={700}>
              بیشترین دانلود عکس پرمیوم
            </Typography>
            <PanelCarousel
              items={(data["بیشترین دانلود عکس ویژه"][mostDownloadedSignaturePeriod] as any[]).map(
                (item) => ({
                  id: item["id"],
                  title: item["title"],
                  image: item["file"],
                })
              )}
            />
            {renderPeriodSelect(mostDownloadedSignaturePeriod, setMostDownloadedSignaturePeriod)}
          </div>

          <div style={{position: "relative", marginTop: 40}}>
            <Typography style={{margin: "16px 0 36px"}} align='center' size={2} weight={700}>
              پرفروش ترین عکس عادی
            </Typography>
            <PanelCarousel
              items={(data["پرفروش ترین عکس عادی"][mostSoldEssentialPeriod] as any[]).map(
                (item) => ({
                  id: item["id"],
                  title: item["title"],
                  image: item["file"],
                })
              )}
            />
            {renderPeriodSelect(mostSoldEssentialPeriod, setMostSoldEssentialPeriod)}
          </div>

          <div style={{position: "relative", marginTop: 40}}>
            <Typography style={{margin: "16px 0 36px"}} align='center' size={2} weight={700}>
              پرفروش ترین عکس پرمیوم
            </Typography>
            <PanelCarousel
              items={(data["پرفروش ترین عکس ویژه"][mostSoldSignaturePeriod] as any[]).map(
                (item) => ({
                  id: item["id"],
                  title: item["title"],
                  image: item["file"],
                })
              )}
            />
            {renderPeriodSelect(mostSoldSignaturePeriod, setMostSoldSignaturePeriod)}
          </div>

          <Divider style={{margin: "40px auto 20px "}} />
          <div style={{position: "relative"}}>
            <Typography style={{margin: "15px 0 12px"}} align='center' size={2} weight={700}>
              کاربران با بیشترین خرید
            </Typography>
            <AccountTable
              titles={["آیدی کاربر", "نام کاربری", "شماره تماس", "جمع خرید"]}
              rows={(data["کاربران با بیشترین خرید"][mostBuyersPeriod] as any[]).map((item) => [
                <Typography>{item["id"]}</Typography>,
                <Typography>{item["username"]}</Typography>,
                <Typography>{item["mobile_number"].slice(1)}+</Typography>,
                <Typography>{convertToNumberFormatWithComma(item["sum"])}</Typography>,
              ])}
            />
            {renderPeriodSelect(mostBuyersPeriod, setMostBuyersPeriod)}
          </div>
          <Divider style={{margin: "50px auto 20px "}} />
          {renderTable("آپلودها")}
          {renderTable("کاربران جدید")}
          {renderTable("فروش پلن")}
          {renderTable("فروش فاکتوری عکس")}
          {renderTable("فروش کل")}
        </>
      )}
    </Grid>
  );
};

export default Dashboard;

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: 24,
    "& hr": {
      maxWidth: "90%",
      borderColor: "#02A388",
      backgroundColor: "#02A388",
    },
  },
  TopBox: {
    gap: 28,
  },
  TopRightBox: {
    gap: 34,
    flexShrink: 0,
  },
  TopLeftBox: {
    width: "100%",
    gap: 31,
  },
  salesBox: {
    height: 484,
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
  carouselBox: {
    height: 340,
  },
  padding: {
    padding: "0 20px",
  },
  popularImages: {
    display: "inline-block",
    padding: "8px 12px",
    marginBottom: 30,
    backgroundColor: theme.palette.background.default,
    borderRadius: 2,
    color: theme.palette.primary.main,
    "& *": {
      fontSize: 25,
      marginLeft: 8,
    },
  },
  border: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
  periodSelect: {
    position: "absolute",
    top: 4,
    left: 4,
    height: 50,
  },
}));
