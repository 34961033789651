import {blue} from "@constant/colors";
import Typography from "@elements/Typography";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Bar, BarChart as BChart, ResponsiveContainer, Tooltip, XAxis} from "recharts";

interface BarChartProps {
  data: {
    value: React.ReactText;
    label?: React.ReactText;
  }[];
  label: React.ReactText;
}

export default function BarChart({data, label}: BarChartProps) {
  const classes = useStyles();
  return (
    <Grid container direction='column' alignItems='center' className={classes.root}>
      <Typography weight={700} style={{marginBottom: 16}}>
        {label}
      </Typography>
      <ResponsiveContainer height={229}>
        <BChart data={data} barSize={8}>
          <Tooltip
            cursor={false}
            content={({payload}) => <Typography align='center'>{payload?.[0]?.value}</Typography>}
          />
          <XAxis dataKey='label' reversed strokeWidth={0} tickMargin={15} />
          <Bar dataKey='value' fill={blue.A300} underlineThickness={0} radius={[12, 12, 0, 0]} />
        </BChart>
      </ResponsiveContainer>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: 4,
    backgroundColor: "white",
    padding: "22px 8px 32px",
    gap: 6,
  },
}));
