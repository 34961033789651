import {fontNormalizeFactor} from "@elements/Typography/constant";
import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {InputBase, Box, InputBaseProps, IconButton} from "@material-ui/core";
import {palette} from "@constant/colors";
import {useAlert} from "@context/AlertContext";
import {Close} from "@material-ui/icons";

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
export type ITextInputProps = Pick<
  InputBaseProps,
  "placeholder" | "multiline" | "type" | "rows" | "style" | "onBlur"
> & {
  value?: string;
  onChange?: (value: string) => void;
  maxLength?: number;
  isEnglish?: boolean;
  renderRemoveButton?: boolean;
};

const TextInput = (props: ITextInputProps) => {
  const {
    value: controlledValue,
    onChange,
    maxLength,
    placeholder = "تایپ کنید",
    isEnglish,
    renderRemoveButton,
    ...rest
  } = props;
  const [value, setValue] = useState("");
  const classes = useStyle();
  const alert = useAlert();

  useEffect(() => {
    controlledValue !== undefined && setValue(controlledValue);
  }, [controlledValue]);

  const handleChange = (event: ChangeEvent) => {
    if (controlledValue === undefined) {
      setValue(event.target.value);
    }
    onChange?.(event.target.value);
  };

  const handleRemove = () => {
    setValue("");
    onChange?.("");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {charCode} = event;
    if (isEnglish && !(charCode >= 0 && charCode <= 127)) {
      alert.error({text: "لطفا زبان کیبورد خود را به انگلیسی تغییر دهید."});
    }
  };

  return (
    <Box className={classes.container}>
      <InputBase
        value={value}
        onChange={handleChange}
        className={classes.inputBox}
        classes={{input: classes.input, focused: classes.focused}}
        inputProps={{maxLength: maxLength, onKeyPress: handleKeyPress}}
        placeholder={placeholder}
        endAdornment={
          renderRemoveButton && (
            <IconButton className={classes.removeIcon} onClick={handleRemove}>
              <Close />
            </IconButton>
          )
        }
        {...rest}
      />
    </Box>
  );
};

export default TextInput;

const useStyle = makeStyles(() => ({
  container: {
    position: "relative",
    // maxHeight: "30rem",
    "&,& *": {
      height: "100%",
      width: "100%",
    },
    "& *::-webkit-scrollbar": {
      width: 0,
    },
  },
  inputBox: {
    padding: "0 3px",
    borderRadius: 4,
    backgroundColor: palette.lightGrey[200],
  },
  input: {
    height: "100%",
    color: palette.darkGrey[900],
    textOverflow: "ellipsis",
    padding: "5px 0",
    lineHeight: 1,
    fontSize: 18 * fontNormalizeFactor,
    "&::placeholder": {
      fontSize: 18,
    },
  },
  focused: {
    backgroundColor: palette.lightGrey[200],
  },
  removeIcon: {
    borderRadius: 4,
    width: "2.5rem",
    height: "2.5rem",
    padding: ".5rem",
  },
}));
