import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {useTranslation} from "react-i18next";
import Dropdown from "./Dropdown";

export default function DropdownFromDaysBefore({width}: {width?: number | string}) {
  const {fromDaysBefore} = useGetQueryString();
  const {t} = useTranslation("pages.panel");
  const handleUpdateQueryString = useUpdateQueryString();

  return (
    <Dropdown
      value={fromDaysBefore ?? ""}
      onChange={(value) => handleUpdateQueryString({fromDaysBefore: value})}
      style={{width: width || 140, flexShrink: 0}}
      fullWidth
      label={t("common.recentDays")}
      items={[
        {label: "همه", value: ""},
        {label: "1", value: "1"},
        {label: "3", value: "3"},
        {label: "7", value: "7"},
        {label: "10", value: "10"},
        {label: "30", value: "30"},
        {label: "60", value: "60"},
        {label: "90", value: "90"},
        {label: "180", value: "180"},
        {label: "365", value: "365"},
      ]}
    />
  );
}
