import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import Typography from "@elements/Typography";
import {darkGrey, green, lightGrey} from "@constant/colors";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import Overview from "./overview";
import Profile from "./profile";
import Downloads from "./downloads";
import PlanInvoices from "./planInvoices";
import ImageInvoices from "./imageInvoices";
import {useTranslation} from "react-i18next";
import {useAuth} from "@context/AuthContext";
import {routes} from "@constant/routes";
import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";

const Account = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const {user, loadedUser} = useAuth();
  const {landing} = routes;

  const navbarItems = [
    {title: t("overview"), route: landing.account, exact: true},
    {title: t("profile"), route: `${landing.account}/profile`},
    {title: t("planInvoices"), route: `${landing.account}/plan-invoices`},
    {title: t("imageInvoices"), route: `${landing.account}/image-invoices`},
    {title: t("downloads"), route: `${landing.account}/downloads/by-plan`},
  ];
  if (loadedUser && !user) {
    return <Redirect to='/' />;
  }
  return (
    <>
      <Helmet>
        <title>{defaultPageTitle}</title>
      </Helmet>
      <Box className={classes.container}>
        <Grid
          container
          direction={xs ? "column" : "row"}
          alignItems='center'
          className={classes.navbar}
        >
          {navbarItems.map((item) => (
            <NavLink
              key={item.route}
              to={item.route}
              activeClassName={classes.activeItem}
              exact={item.exact}
            >
              <Typography color='silver' weight={500} className={classes.item}>
                {item.title}
              </Typography>
            </NavLink>
          ))}
        </Grid>
        <Grid className={classes.contentBox}>
          <Switch>
            <Route path={`${landing.account}/profile`} component={Profile} />
            <Route path={`${landing.account}/plan-invoices`} component={PlanInvoices} />
            <Route path={`${landing.account}/image-invoices`} component={ImageInvoices} />
            <Route path={`${landing.account}/downloads`} component={Downloads} />
            <Route path={landing.account} exact component={Overview} />
          </Switch>
        </Grid>
      </Box>
    </>
  );
};

export default Account;

const useStyle = makeStyles((theme) => ({
  container: {
    minHeight: 400,
    backgroundColor: lightGrey[500],
  },
  navbar: {
    backgroundColor: darkGrey.A700,
    gap: "3rem",
    padding: "0 3rem",
    [theme.breakpoints.down(700)]: {
      gap: 0,
      alignItems: "stretch",
      padding: 0,
    },
  },
  item: {
    padding: "1.2rem 0",
    "&:hover": {
      color: green[200],
    },
    [theme.breakpoints.down(700)]: {
      padding: "1.5rem 1.5rem",
      borderBottom: "1px solid #353838",
      textAlign: "left",
    },
  },
  activeItem: {
    "& *": {
      color: "white",
      "&:hover": {
        color: "white",
      },
    },
  },
  contentBox: {
    padding: "3rem 2.5rem",
    maxWidth: "121rem",
    margin: "0 auto",
    [theme.breakpoints.down(700)]: {
      padding: "1.5rem",
    },
  },
}));
