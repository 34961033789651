import {ApiNamespaces} from "@api";
import {useAlert} from "@context/AlertContext";
import Spinner from "@elements/Spinner";
import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {DialogProps, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatDate} from "@util/global";
import React from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import PanelModalRapper from "./PanelModalWrapper";

interface IProps extends Omit<DialogProps, "id"> {
  id: number | null;
}
export default function MessageDetailModal({id, ...props}: IProps) {
  const classes = useStyles();
  const {t} = useTranslation("pages.panel");
  const queryClient = useQueryClient();
  const alert = useAlert();

  const {data, isLoading} = useExperimentalQuery("messages", "readDetail", {
    variables: {
      id: id!,
    },
    enabled: !!id,
    onError: () => alert.error({}),
    onSuccess: () => queryClient.invalidateQueries(ApiNamespaces.messages.Query.getList.queryKey),
  });

  const userInfo = {
    [t("messages.date")]: data ? formatDate(data.created || "") : "",
    [t("messages.time")]: data?.created?.split("T")[1]?.slice(0, 8) || "",
    [t("messages.nameAndFamilyName")]: data?.name,
    [t("messages.content")]: data?.content,
  };

  return (
    <PanelModalRapper title='نمایش کامل پیام' {...props}>
      <Grid className={classes.root}>
        {isLoading ? (
          <Spinner />
        ) : (
          Object.keys(userInfo).map((title, index) => (
            <Grid container key={title} className={classes.row} wrap='wrap'>
              <Grid item>
                <Typography span size={1.8} color='#757575'>
                  {title}:
                </Typography>
              </Grid>
              <Grid item xs={index < 3 ? undefined : 12}>
                <Typography span size={1.8}>
                  {userInfo[title as keyof typeof userInfo]}
                </Typography>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </PanelModalRapper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 560,
  },
  row: {
    padding: "10px 7px",
    gap: 10,
    borderBottom: "1px solid #a2d3fa",
    "&:last-of-type": {
      borderBottom: "none",
      paddingBottom: 40,
    },
  },
}));
