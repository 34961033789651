import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Collapse, Theme, useMediaQuery} from "@material-ui/core";
import Divider from "@elements/Divider";
import {ExpandMore} from "@material-ui/icons";
import clsx from "clsx";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";

interface ICollapseGroupProps {
  items: {
    title: string;
    value?: JSX.Element | string;
  }[];
  activeIndex?: number | null;
}

const CollapseGroup: React.FC<ICollapseGroupProps> = (props) => {
  const {items, activeIndex} = props;
  const [open, setOpen] = useState<number | null>(activeIndex !== undefined ? activeIndex : 0);
  const classes = useStyle({open});
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const handleClick = (index: number) => {
    index === open ? setOpen(null) : setOpen(index);
  };

  return (
    <Box>
      <Divider />
      {items.map(({title, value}, index) => (
        <Box
          key={index.toString()}
          className={open === index ? classes.background : classes.backgroundTransparent}
        >
          <Box className={classes.titleBox} onClick={() => handleClick(index)}>
            <Typography span size={xsDown ? 1.6 : 1.7} color={palette.darkGrey[800]}>
              {title}
            </Typography>
            <ExpandMore
              className={clsx(classes.arrow, open === index ? classes.rotate : classes.rotateReset)}
            />
          </Box>
          <Collapse in={open === index} timeout={400}>
            <Box className={classes.valueBox}>
              {typeof value === "string" ? (
                <Typography size={xsDown ? 1.5 : 1.6}>{value}</Typography>
              ) : (
                value
              )}
            </Box>
          </Collapse>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default CollapseGroup;

const useStyle = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
    minHeight: "7.1rem",
    padding: "1.5rem",
    "&:hover $title": {
      color: palette.darkGrey.A300,
    },
  },
  valueBox: {
    padding: "0 4.5rem 1.5rem 1.5rem",
    backgroundColor: "#dce1e1",
    [theme.breakpoints.down(500)]: {
      padding: "0 1.5rem 1.5rem 1.5rem",
    },
  },
  background: {
    transition: ".2s ease-in",
    backgroundColor: "#dce1e1",
  },
  backgroundTransparent: {
    transition: ".2s ease-in",
    backgroundColor: "transparent",
  },
  arrow: {
    color: palette.darkGrey[800],
    fontSize: "2.2rem",
    marginLeft: "2rem",
    [theme.breakpoints.down(400)]: {
      flexDirection: "column",
      marginLeft: "1rem",
    },
  },
  rotate: {
    transform: "rotate(-180deg)",
    transition: ".1s ease-in",
  },
  rotateReset: {
    transform: "rotate(0deg)",
    transition: ".1s ease-in",
  },
}));
