import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import SimilarSection from "@modules/pages/images/[id]/SimilarSection";
import KeywordsSection from "@modules/pages/images/[id]/KeywordsSection";
import TopCategoriesSection from "@modules/pages/images/[id]/TopCategoriesSection";
import FaqSection from "@modules/pages/images/[id]/FaqSection";
import {palette} from "@constant/colors";
import {useParams} from "react-router-dom";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import Typography from "@elements/Typography";
import Download from "@modules/pages/images/[id]/Download";
import Details from "@modules/pages/images/[id]/Details";
import Spinner from "@elements/Spinner";
import ImageCard from "@elements/ImageCard";
import {useTranslation} from "react-i18next";
import {Stars} from "@material-ui/icons";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {useErrorHandlerContext} from "@context/ErrorHandlerContext";
import {checkForMouseSupport} from "@util/checkForMouseSupport";
import MetaTags from "@modules/pages/images/[id]/MetaTags";
import {Skeleton} from "@material-ui/lab";

const Image = () => {
  const classes = useStyle();
  const {slug} = useParams<{slug: string}>();
  const {t} = useTranslation("modules.pages.images.id");
  const serverErrorNotify = useServerErrorNotify();
  const {setErrorStatusCode} = useErrorHandlerContext();
  const deviceHasMouseSupport = checkForMouseSupport();

  const {data} = useExperimentalQuery("images", "readDetailByCodeId", {
    variables: {
      // picking last item = idCode
      idCode: slug.split("-").slice(-1).toString(),
    },
    enabled: !!slug,
    onError: (error) => {
      setErrorStatusCode(error);
      serverErrorNotify(error);
    },
  });

  return (
    <>
      <MetaTags data={data} />
      <Grid className={classes.root}>
        <Box className={classes.wrapper}>
          <Box className={classes.heading}>
            {data ? (
              <Typography component='h1' size={1.8}>
                {data.title_fa || data.title}
              </Typography>
            ) : (
              <Skeleton width='50px' />
            )}

            {data ? (
              <Typography component='h1' size={1.5} className={classes.padding}>
                {data.summary_fa || data.summary}
              </Typography>
            ) : (
              <Skeleton width='70%' className={classes.padding} />
            )}
          </Box>
          <Grid component='section' container className={classes.gap}>
            <Grid component='section' item xs={12} md={7}>
              <Grid container>
                <Box className={classes.imageBox}>
                  {data ? (
                    <ImageCard
                      src={data.file!}
                      alt={data.title!}
                      isPremium={data.is_premium ?? false}
                      imageId={data.id || 0}
                      deviceHasMouseSupport={deviceHasMouseSupport}
                    />
                  ) : (
                    <Skeleton variant='rect' width='100%' height='100%' />
                  )}
                </Box>
              </Grid>
              {data?.is_premium && (
                <Typography color='white' size={1.2} span className={classes.label}>
                  {t("imageAndDescription.premium")}
                  <Stars />
                </Typography>
              )}
              {data ? (
                <Typography component='h1' size={1.4} className={classes.longDescription}>
                  {data.description_fa || data.description}
                </Typography>
              ) : (
                <Skeleton width='70%' className={classes.longDescription} />
              )}
            </Grid>
            <Grid component='section' item xs={12} md={5} className={classes.maxWidth}>
              {data ? (
                <>
                  <Download data={data} />
                  <Details data={data} />
                </>
              ) : (
                <>
                  {[
                    {width: 120, height: 28, marginBottom: 15},
                    {width: 80, height: 22, marginBottom: 30},
                    {width: 260, height: 35, marginBottom: 10},
                    {width: 260, height: 35, marginBottom: 30},
                    {height: 1, marginBottom: 15},
                    {height: 55, marginBottom: 30},
                    {height: 1, marginBottom: 30},
                    {width: 140, height: 20, marginBottom: 10},
                    {width: 120, height: 20, marginBottom: 10},
                    {width: 220, height: 20, marginBottom: 10},
                    {width: 120, height: 20, marginBottom: 1},
                  ].map(({width, height, marginBottom}) => (
                    <Skeleton
                      variant='rect'
                      height={`${height}px`}
                      width={width ? `${width}px` : "100%"}
                      style={{marginBottom}}
                    />
                  ))}
                </>
              )}
            </Grid>
          </Grid>
          <Box component='section' className={classes.marginTop}>
            <SimilarSection id={data?.id} imageType={data?.type || ""} />
            {data?.tags && !!Array.from(data.tags).length && <KeywordsSection tags={data.tags} />}
            <TopCategoriesSection />
          </Box>
          <FaqSection />
        </Box>
      </Grid>
    </>
  );
};

export default Image;

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.lightGrey[500],
  },
  wrapper: {
    padding: "4rem 3.3rem 7rem",
    maxWidth: "115rem",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 1.3rem 1rem",
    },
  },
  heading: {
    marginBottom: "1.8rem",
  },
  padding: {
    padding: ".5rem 0 1.5rem",
  },
  gap: {
    gap: "3rem",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  imageBox: {
    minHeight: "40rem",
    maxHeight: "45rem",
    width: "100%",
    maxWidth: 630,
    [theme.breakpoints.down(700)]: {
      minHeight: "auto",
    },
  },
  label: {
    display: "inline-flex",
    alignItems: "center",
    gap: "1rem",
    backgroundColor: palette.darkGrey.A700,
    padding: ".5rem 2rem",
    borderRadius: "28px",
    userSelect: "none",
    margin: "2rem 0 1rem",
  },
  description: {
    // temp
    display: "block",
    paddingTop: "1rem",
  },
  longDescription: {
    marginTop: "1rem",
  },
  maxWidth: {
    maxWidth: "50rem",
  },
  marginTop: {
    marginTop: "4rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2.5rem",
    },
  },
}));
