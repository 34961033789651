import {ImageCardForDetailPage} from "./ImageCardForDetailPage";
import {ImageCardForSearchPage} from "./ImageCardForSearchPage";
import React from "react";
import {ISaveButtonProps} from "./SaveButton";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import {ApiNamespaces} from "@api";
import {useQueryClient} from "react-query";
import {IImageCardProps} from "./types";
import {maxPossibleImagesInOneBoard} from "@constant/boards";
import {useTranslation} from "react-i18next";
import {useAuth} from "@context/AuthContext";
import {useModalDispatch} from "@context/ModalContext";

export type ImageCardProps = IImageCardProps &
  ISaveButtonProps & {
    imageId: number;
  };

export default function ImageCard(props: ImageCardProps) {
  // when an image is added, we store the board, so user can remove it as long as state is available
  // means route has not changed and page has'nt refreshed yet
  const [lastModifiedBoardByThisImage, setLastModifiedBoardByThisImage] = React.useState<{
    name: string;
    boardId: number;
    type: "add" | "remove";
  } | null>(null);
  const [imageIsAlreadyOnBoard, setImageIsAlreadyOnBoard] = React.useState(false);
  const {
    imageId,
    src,
    alt,
    isPremium,
    renderSimilarButton,
    onClickSimilarButton,
    deviceHasMouseSupport,
  } = props;
  const alert = useAlert();
  const queryClient = useQueryClient();
  const {user} = useAuth();
  // I used 'modules.modals' because all related messages are there.
  const {t} = useTranslation("modules.modals");
  const handleOpenAddToBoardModal = useModalDispatch("addToBoard");
  const handleOpenModifyBoardMessageModal = useModalDispatch("modifyBoardMessageModal");

  // it's temporary, creating set from array returns empty object on sending request !!!!
  const convertToNumberSet = (value: number[]) => (value as unknown) as Set<number>;

  const handleCallAddToBoardModal = () =>
    handleOpenAddToBoardModal({
      src,
      alt,
      onAddToNewBoard: (boardName) => handleAddToNewBoard(boardName),
      onAddToPreviousBoard: (name, boardId) => handleAddToPreviousBoards(name, boardId),
    });

  const handleCallModifyBoardMessageModal = (
    boardId: number,
    boardName: string,
    isSaved: boolean
  ) =>
    handleOpenModifyBoardMessageModal({
      src,
      alt,
      boardId,
      boardName,
      isSaved,
    });

  const invalidate = () => {
    queryClient.invalidateQueries(ApiNamespaces.boards.Query.getRecentBoardsList.queryKey);
  };

  const {data: recentBoardsList} = useExperimentalQuery("boards", "getRecentBoardsList", {
    variables: {},
    enabled: !!user,
    staleTime: Infinity,
  });
  const hasRecentBoard = recentBoardsList && recentBoardsList.length;

  /** tooltip will handle proper text for both adding and removing text with the help of imageIsAlreadyOnBoard,
   * but for payload (is board name actually), if is going to be removed, only
   * boardJustAnImageAddedIn.name will be used. all other options are for adding scenario */
  const tooltipTextPayload = imageIsAlreadyOnBoard
    ? lastModifiedBoardByThisImage?.name
    : hasRecentBoard
    ? recentBoardsList?.[0]?.name
    : "جدید";

  const {mutate: createNewBoard} = useExperimentalMutation("boards", "create");
  const {mutate: addToBoard, isLoading: isAddingToBoard} = useExperimentalMutation(
    "boards",
    "addToBoard"
  );
  const {mutate: removeFromBoard, isLoading: isRemoving} = useExperimentalMutation(
    "boards",
    "removeFromBoard",
    {
      onError: () => alert.error({}),
      onSuccess: () => {
        invalidate();
        setImageIsAlreadyOnBoard(false);
        handleOpenModifyBoardMessageModal({
          src,
          alt,
          boardId: lastModifiedBoardByThisImage?.boardId || 0,
          boardName: lastModifiedBoardByThisImage?.name || "",
          isSaved: false,
        });
      },
    }
  );

  const handleAddToPreviousBoards = (name: string, boardId: number) => {
    addToBoard({
      variables: {id: boardId, data: {images: convertToNumberSet([imageId])}},
      onSuccess: () => {
        invalidate();
        setLastModifiedBoardByThisImage({name, boardId, type: "add"});
        setImageIsAlreadyOnBoard(true);
        handleCallModifyBoardMessageModal(boardId, name, true);
      },
    });
  };

  const handleAddToNewBoard = (name: string) => {
    createNewBoard({
      variables: {data: {name}},
      onError: () => alert.error({}),
      onSuccess: (res) => {
        addToBoard({
          variables: {id: res.id || 0, data: {images: convertToNumberSet([imageId])}},
          onSuccess: () => {
            invalidate();
            setImageIsAlreadyOnBoard(true);
            setLastModifiedBoardByThisImage({name, boardId: res.id!, type: "add"});
            handleCallModifyBoardMessageModal(res.id!, name, true);
          },
        });
      },
    });
  };

  const handleClickSaveButton = () => {
    if (imageIsAlreadyOnBoard) {
      removeFromBoard({
        variables: {
          id: lastModifiedBoardByThisImage?.boardId || 0,
          data: {images: convertToNumberSet([imageId])},
        },
      });
    } else {
      if (hasRecentBoard) {
        if ((recentBoardsList?.[0]?.images_count || 0) >= maxPossibleImagesInOneBoard) {
          alert.error({text: t("common.maximumPossibleImageInBoard")});
          return;
        }
        handleAddToPreviousBoards(
          recentBoardsList?.[0]?.name || "",
          recentBoardsList?.[0]?.id || 0
        );
      } else {
        handleCallAddToBoardModal();
      }
    }
  };

  const handleClickSaveButtonTooltip = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleCallAddToBoardModal();
  };

  const commonProps = {
    src,
    alt,
    isPremium,
    imageIsAlreadyOnBoard,
    tooltipTextPayload,
    renderSimilarButton,
    onClickSimilarButton,
    deviceHasMouseSupport,
    onClickMainButton: handleClickSaveButton,
    onClickTooltip: handleClickSaveButtonTooltip,
    disabled: isAddingToBoard || isRemoving,
  };

  return "to" in props ? (
    <ImageCardForSearchPage {...commonProps} to={props.to!} />
  ) : (
    <ImageCardForDetailPage {...commonProps} />
  );
}
