import {ApiNamespaces} from "@api";
import {useAlert} from "@context/AlertContext";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {DialogProps, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useQueryClient} from "react-query";
import CategoryCardEditable, {CategoryCardReturnType} from "../CategoryCardEditable";
import PanelModalRapper from "./PanelModalWrapper";

interface CategoryModalProps extends Omit<DialogProps, "id"> {
  id: number | null;
  onSubmit?: () => void;
}

export default function CategoryModal({id, onSubmit, ...props}: CategoryModalProps) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const isNew = !id;
  const alert = useAlert();

  const events = {
    onSuccess: () => {
      alert.success({});
      queryClient.invalidateQueries(ApiNamespaces.categories.Query.getList.queryKey);
    },
    onError: () => alert.error({}),
    onSettled: () => onSubmit?.(),
  };

  const {data: detail} = useExperimentalQuery("categories", "readDetail", {
    variables: {
      id: id!,
    },
    enabled: !!id,
  });

  const {mutate: create} = useExperimentalMutation("categories", "create");
  const {mutate: update} = useExperimentalMutation("categories", "update");
  const {mutate: deleteReq} = useExperimentalMutation("categories", "delete");

  const handleSubmit = (values: CategoryCardReturnType) => {
    if (isNew) {
      create({
        variables: {
          data: {
            name: values.title,
            description: values.description,
            image: Number(values.imageId),
          },
        },
        ...events,
      });
    } else {
      update({
        variables: {
          id: id!,
          data: {
            name: values.title,
            description: values.description,
            image: Number(values.imageId),
            // @ts-ignore
            priority: Number(values.order),
          },
        },
        ...events,
      });
    }
  };

  const handleRemove = () => {
    deleteReq({
      variables: {
        id: id!,
      },
      ...events,
    });
  };

  return (
    <PanelModalRapper title={isNew ? "ایجاد موضوع" : "ویرایش موضوع"} {...props}>
      <Grid container direction='column' alignItems='center' className={classes.root}>
        {isNew ? (
          <CategoryCardEditable type='create' onSubmit={handleSubmit} />
        ) : (
          <CategoryCardEditable
            type='edit'
            id={id!}
            title={detail?.name || ""}
            image={detail?.image || 0}
            // @ts-ignore
            order={detail?.priority || "1"}
            description={detail?.description || ""}
            onSubmit={handleSubmit}
            onRemove={() => alert.prompt({}).then(({result}) => result && handleRemove())}
            isData={!!detail}
          />
        )}
      </Grid>
    </PanelModalRapper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 420,
  },
}));
