import {Helmet} from "react-helmet";
import {defaultPageTitle} from "@constant/defultPageTitle";

export default function MetaTags() {
  const pageDescription =
    "پارس ایمیجز ، آغاز داستان تو ! در فضای بینهایت تصاویر ناب برای رسیدن به بهترین جستجو در کنار تو هستیم!";

  return (
    <Helmet>
      <title>{defaultPageTitle}</title>
      <meta content={window.origin} property='og:url' />
      <meta name='title' content={defaultPageTitle} />
      <meta name='description' content={pageDescription} />
      <meta content={defaultPageTitle} property='og:title' />
      <meta content={pageDescription} property='og:description' />
      <meta content='gallery' property='twitter:card'></meta>
      <meta content={defaultPageTitle} property='og:title' />
      <meta property='twitter:title'></meta>
    </Helmet>
  );
}
