import {ApiNamespaces} from "@api";
import {routes} from "@constant/routes";
import {useAlert} from "@context/AlertContext";
import AutoCompleteCheckbox from "@elements/panel/AutoCompleteCheckbox";
import AutoCompleteTextField from "@elements/panel/AutoCompleteTextField";
import RadioGroup from "@elements/panel/RadioGroup";
import TextInput from "@elements/panel/TextInput";
import UploadButton from "@elements/panel/UploadButton";
import ProgressWithLabel from "@elements/ProgressWithLabel";
import Spinner from "@elements/Spinner";
import Tooltip from "@elements/Tooltip";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import useDebouncedValue from "@hook/useDebouncedValue";
import {Box, Button, Checkbox, FormControlLabel, Grid, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  DateRange,
  Delete,
  Fingerprint,
  Stars,
} from "@material-ui/icons";
import {RetrieveImageTypeEnum as ImageTypeEnum, ImageTypeEnumPersian} from "@schema";
import {Enum} from "@util/EnumUtils";
import {getBase64} from "@util/getBase64";
import {formatDate} from "@util/global";
import clsx from "clsx";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import ShouldRender from "services/hoc/ShouldRender";

const ImageComponent = () => {
  const classes = useStyle();
  const {id: _} = useParams<{id: string}>();
  const id = +_;
  const history = useHistory();
  const {t} = useTranslation("pages.panel");
  const queryClient = useQueryClient();
  const {panel} = routes;
  const alert = useAlert();

  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [image, setImage] = React.useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = React.useState("");
  const [collectionsState, setCollectionsState] = React.useState<number[]>([]);
  const [collectionsSearchValue, setCollectionsSearchValue] = React.useState("");
  const [tagsValue, setTagsValue] = React.useState<string[]>([]);
  const [tagsInputValue, setTagsInputValue] = React.useState("");
  const [categoriesState, setCategoriesState] = React.useState<number[]>([]);
  const [isPremium, setIsPremium] = React.useState<boolean>(false);
  const [price, setPrice] = React.useState("");
  const [credit, setCredit] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const debouncedTagsSearchValue = useDebouncedValue(tagsInputValue, 500);
  const debouncedCollectionsSearchValue = useDebouncedValue(collectionsSearchValue, 500);

  const {data: collections} = useExperimentalQuery("collections", "getList", {
    variables: {pageSize: 500, search: debouncedCollectionsSearchValue},
  });
  const {data: tags} = useExperimentalQuery("tags", "getList", {
    variables: {pageSize: 40, search: debouncedTagsSearchValue},
  });
  const {data: categories} = useExperimentalQuery("categories", "getList", {
    variables: {pageSize: 100},
  });

  const {data} = useExperimentalQuery("images", "readDetailForAdmin", {
    enabled: !!(categories && collections),
    variables: {id},
    onSuccess: (data) => {
      setTitle(data.title || "");
      setSummary(data.summary || "");
      setDescription(data.description || "");
      setType(data.type || "");
      setTagsValue(Array.from(data.tags || []).map((item) => item.name));
      setIsActive(data.is_active ?? true);
      setCategoriesState(Array.from(data.categories || []).map((item) => item.id!));
      setCollectionsState(Array.from(data.collections || []).map((item) => item.id!));
      setIsPremium(data.is_premium || false);
      setPrice(data.price || "");
      setCredit(data.created ? String(data.credit) : "");
      setImage(data.file || "");
    },
    refetchOnWindowFocus: false,
  });

  const {mutate: updateImage, isLoading: isLoadingMutate} = useExperimentalMutation(
    "images",
    "updateImage"
  );

  const handleUpdate = async (cb?: () => void) => {
    const categories = (categoriesState.map((item) => item) as unknown) as Set<number>;
    const collections = (collectionsState.map((item) => item) as unknown) as Set<number>;
    updateImage({
      variables: {
        id,
        data: {
          type: Enum(ImageTypeEnum).getValueOr(type, undefined),
          title,
          price: price ? price : undefined,
          description,
          summary,
          tags: tagsValue.map((item) => String(item)),
          categories,
          collections,
          is_premium: isPremium,
          credit: credit ? Number(credit) : undefined,
          file: imageFile ? await getBase64(imageFile) : undefined,
          is_active: isActive,
        },
        axiosConfig: !!imageFile
          ? {
              onUploadProgress: (e: any) => {
                const progress = (e.loaded / e.total) * 100;
                setUploadProgress(Math.round(progress));
              },
            }
          : undefined,
      },
      onSuccess: () => {
        alert.success({});
        setImageFile(null);
        queryClient.invalidateQueries([
          ApiNamespaces.images.Query.readDetailForAdmin.queryKey,
          {id},
        ]);
        cb?.();
      },
      onError: () => alert.error({}),
      onSettled: () => setUploadProgress(null),
    });
  };

  const {mutate: deleteImage, isLoading: isLoadingDelete} = useExperimentalMutation(
    "images",
    "deleteImage",
    {
      variables: {id},
      onSuccess: () => {
        alert.success({});
        history.replace(panel.images);
      },
      onError: () => alert.error({}),
    }
  );

  React.useEffect(() => {
    setImageFile(null);
  }, []);

  const handleNext = () => {
    setImageFile(null);
    data?.next && history.push(panel.imagesDetail(data.next));
  };

  const handlePrev = () => {
    setImageFile(null);
    data?.prev && history.push(panel.imagesDetail(data.prev));
  };

  return data ? (
    <Grid container direction='column' className={classes.container}>
      <Grid container className={classes.gap}>
        <Grid item xs={9} className={classes.imageBox}>
          {image ? (
            <>
              <img src={image} alt='' className={classes.image} />
              <IconButton className={classes.deleteIconBox} onClick={() => setImage("")}>
                <Close className={classes.deleteIcon} />
              </IconButton>
            </>
          ) : (
            <UploadButton
              onChange={(file) => {
                setImageFile(file);
                setImage(URL.createObjectURL(file));
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container justify='space-between' alignItems='center' style={{gap: 8}}>
            <Box style={{display: "flex", alignItems: "center"}}>
              <Fingerprint className={classes.icon} />
              <Typography size={1.4} span color='#757575' className={classes.margin}>
                {t("images.common.imageCode")}
              </Typography>
              <Typography size={1.5} span color='#007DFF'>
                {data.id_code}
              </Typography>
            </Box>
            <Box style={{display: "flex", alignItems: "center"}}>
              <DateRange className={classes.icon} />
              <Typography size={1.4} span color='#757575' className={classes.margin}>
                {t("images.common.uploadDate")}
              </Typography>
              <Typography size={1.5} span>
                {formatDate(data.created)}
              </Typography>
            </Box>
          </Grid>
          <Grid container direction='column' className={classes.textBox}>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography span color='#757575'>
                {t("images.common.title")}
              </Typography>
              <TextInput
                value={title}
                onChange={(v) => !v.split("").includes("-") && setTitle(v)}
              />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography span color='#757575'>
                {t("images.common.summary")}
              </Typography>
              <TextInput value={summary} onChange={(v) => setSummary(v)} multiline />
            </Grid>
            <Grid container direction='column' className={classes.wrapper}>
              <Typography span color='#757575'>
                {t("images.common.description")}
              </Typography>
              <TextInput value={description} onChange={(v) => setDescription(v)} multiline />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={clsx(classes.inputsBox, classes.gap)}>
        <Grid item container xs={4} wrap='wrap'>
          <div style={{width: "100%", marginBottom: "3rem"}}>
            <AutoCompleteCheckbox
              label='کالکشن‌'
              placeholder={collectionsState.length ? "" : "کالکشن‌ عکس"}
              options={collections?.results.map(({name, id}) => ({label: name, value: id!})) || []}
              value={collectionsState}
              onChange={(value) => setCollectionsState(value)}
              // onChangeInputValue={(value) => setCollectionsSearchValue(value)}
            />
          </div>
          <Grid container wrap='wrap'>
            <Grid item xs={7}>
              <RadioGroup
                label={t("images.common.type")}
                items={Enum(ImageTypeEnum)
                  .getEntries()
                  .map(([key, value]) => ({value, label: ImageTypeEnumPersian[key]}))}
                value={type}
                onChange={(type) => setType(type)}
              />
            </Grid>
            <Grid item xs={5} className={classes.childrenStyles}>
              <Grid container direction='column' style={{gap: ".5rem"}}>
                <Typography color='#757575'>قیمت</Typography>
                <TextInput value={price} onChange={(v) => setPrice(v)} type='number' />
              </Grid>
              <Grid container direction='column' style={{gap: ".5rem"}}>
                <Typography color='#757575'>اعتبار</Typography>
                <TextInput value={credit} onChange={(v) => setCredit(v)} type='number' />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.isPremium}>
              <FormControlLabel
                onChange={(_, checked) => setIsPremium(checked)}
                label={
                  <Typography size={1.8}>
                    عکس پرمیوم
                    <Stars className={classes.premiumIcon} />
                  </Typography>
                }
                control={
                  <Checkbox color='primary' className={classes.checkbox} checked={isPremium} />
                }
              />
              <FormControlLabel
                style={{marginTop: 10}}
                onChange={(_, checked) => {
                  alert.prompt({}).then(({result}) => result && setIsActive(checked));
                }}
                label={<Typography size={1.8}>فعال</Typography>}
                control={
                  <Checkbox color='primary' className={classes.checkbox} checked={isActive} />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={9} className={classes.bottomRightBox}>
          <Grid item xs={6}>
            <AutoCompleteTextField
              label={t("images.common.tags")}
              placeholder={tagsValue.length ? "" : t("images.common.typeTagName")}
              options={tags?.results.map(({name}) => name) || []}
              value={tagsValue}
              onChange={(value) => setTagsValue(value)}
              onChangeInputValue={(value) => setTagsInputValue(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoCompleteCheckbox
              label={t("images.common.categories")}
              placeholder={categoriesState.length ? "" : t("images.common.typeCategoryName")}
              options={categories?.results.map(({name, id}) => ({label: name, value: id!})) || []}
              value={categoriesState}
              onChange={(value) => setCategoriesState(value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify='center' alignItems='center' className={classes.marginAuto}>
        <Tooltip title='عکس بعدی' className={classes.tooltip}>
          <IconButton
            className={classes.navigationButtons}
            disabled={!data?.next || isLoadingDelete || isLoadingMutate}
            onClick={handleNext}
          >
            <ChevronRight />
          </IconButton>
        </Tooltip>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          disabled={!image || isLoadingMutate || isLoadingDelete || !data?.next}
          onKeyDown={(e) =>
            e.key === "Enter" && handleUpdate(() => history.push(panel.imagesDetail(data!.next!)))
          }
          onClick={() => handleUpdate(() => history.push(panel.imagesDetail(data!.next!)))}
        >
          ثبت و بعدی
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          disabled={!image || isLoadingMutate || isLoadingDelete}
          onClick={() => handleUpdate()}
        >
          {t("images.[id].saveChanges")}
        </Button>
        <ShouldRender>
          <Button
            color='secondary'
            variant='contained'
            className={classes.button}
            disabled={isLoadingDelete || isLoadingMutate}
            onClick={() => alert.prompt({}).then(({result}) => result && deleteImage())}
          >
            <Delete className={classes.trashIcon} /> {t("images.[id].deleteImage")}
          </Button>
        </ShouldRender>
        <Tooltip title='عکس قبلی' className={classes.tooltip}>
          <IconButton
            className={classes.navigationButtons}
            disabled={!data?.prev || isLoadingDelete || isLoadingMutate}
            onClick={handlePrev}
          >
            <ChevronLeft />
          </IconButton>
        </Tooltip>
      </Grid>
      {!!uploadProgress && <ProgressWithLabel value={uploadProgress} />}
    </Grid>
  ) : (
    <Spinner />
  );
};

export default ImageComponent;

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
    overflow: "hidden",
    alignItems: "stretch",
  },
  imageBox: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "solid 1px #ccc",
    borderRadius: 4,
    "&:hover $deleteIconBox": {
      display: "block",
    },
  },
  image: {
    maxHeight: 420,
    maxWidth: "100%",
    borderRadius: 4,
  },
  icon: {
    fontSize: 17,
    color: "#757575",
  },
  textBox: {
    marginTop: 28,
    gap: 12,
    height: "calc(100% - 50px)",
    justifyContent: "space-between",
  },
  wrapper: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.42)",
    padding: "9.5px 0 9.5px ",
    gap: 11,
  },
  inputsBox: {
    marginTop: 46,
    paddingLeft: 2,
  },
  margin: {
    margin: "0 6px 0 2px",
  },
  gap: {
    gap: 28,
  },
  bottomRightBox: {
    gap: 20,
  },
  marginAuto: {
    marginTop: "auto",
    gap: 20,
    paddingTop: "6rem",
  },
  button: {
    width: 200,
    "& *": {
      fontSize: 18 * fontNormalizeFactor,
    },
  },
  trashIcon: {
    fontSize: 22 * fontNormalizeFactor,
    marginRight: 5,
  },
  checkbox: {
    transform: "scale(1.5)",
    marginLeft: 2,
  },
  childrenStyles: {
    "& > *:not(:last-child) ": {
      marginBottom: 20,
    },
  },
  isPremium: {
    marginTop: 25,
    paddingLeft: 1,
    "& * ": {
      display: "flex",
      alignItems: "center",
    },
    "&:hover * ": {
      backgroundColor: "transparent",
    },
  },
  deleteIconBox: {
    display: "none",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "rgba(0,0,0,.4)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.6) !important",
    },
  },
  deleteIcon: {
    width: 25,
    height: 25,
    color: "white",
  },
  premiumIcon: {
    fontSize: 24,
    margin: "3px 5px 0",
    color: "gold",
  },
  navigationButtons: {
    backgroundColor: theme.palette.background.default,
    width: 40,
    height: 40,
    "& svg": {
      fontSize: 28,
      color: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: "#f1f1f1",
      "& svg": {
        color: "#aaa",
      },
    },
  },
  tooltip: {
    borderRadius: 6,
  },
}));
