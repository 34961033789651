import {images} from "./images";
import home from "./home.json";
import search from "./search.json";
import pricing from "./pricing.json";
import account from "./account.json";
import promoCode from "./promoCode.json";

const pages = {
  "modules.pages.home": home,
  "modules.pages.search": search,
  "modules.pages.pricing": pricing,
  "modules.pages.promoCode": promoCode,
  "modules.pages.account": account,
  ...images,
};

export {pages};
