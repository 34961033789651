import React from "react";
import {Theme, Grid, makeStyles, useMediaQuery} from "@material-ui/core";
import {palette} from "@constant/colors";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import Typography from "@elements/Typography";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import EditorContentWrapper from "@elements/EditorContentWrapper";
import Spinner from "@elements/Spinner";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {Helmet} from "react-helmet";

export default function Faq() {
  const classes = useStyles();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const serverErrorNotify = useServerErrorNotify();

  const {data, isLoading} = useExperimentalQuery("about", "getList", {
    variables: {},
    staleTime: Infinity,
    onError: (error) => serverErrorNotify(error),
  });

  return (
    <>
      <Helmet>
        <title>درباره ما - پارس ایمیجز</title>
        <meta name='title' content='درباره ما - پارس ایمیجز' />
      </Helmet>
      <BoxWithWallpaper color={palette.lightGrey[500]} className={classes.container}>
        <Grid container direction='column' alignItems='center' className={classes.maxWidth}>
          <Typography size={xsDown ? 2.5 : 3} weight={500} className={classes.title}>
            درباره ما
          </Typography>
          {isLoading && <Spinner />}
          {data && data.results[0] && <EditorContentWrapper content={data.results[0]?.content} />}
        </Grid>
      </BoxWithWallpaper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "1rem",
    paddingBottom: "10rem",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "5rem",
    },
  },
  maxWidth: {
    maxWidth: "108.6rem",
    margin: "0 auto",
    padding: "0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down(270)]: {
      padding: "0 .75rem",
    },
  },
  title: {
    margin: "3rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0 3rem",
    },
  },
}));
