import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";

export interface IImageCardResponsiveProps {
  image: string;
  /**
   * this is the height/width ratio.
   * for example if you want to render a image which it"s height, is half of it"s width, use ratio={0.5}
   */
  ratio: number;
  title: string;
  hoverScaleEffect?: boolean;
  comingSoon?: boolean;
}

const ImageCardResponsive = (props: IImageCardResponsiveProps) => {
  const classes = useStyle(props);
  const {title, comingSoon} = props;

  return (
    <Box className={classes.container} title={title}>
      <Box className={classes.imageCardBox}>
        <Box className={classes.positionAbsolute}>
          <Box className={classes.image} />
        </Box>
      </Box>
      <Typography
        component='h3'
        align='center'
        weight={700}
        color={palette.darkGrey.A700}
        className={classes.title}
      >
        {title}
        {comingSoon && (
          <a href='https://telegram.me/parsdream' target='_blank' rel='noreferrer'>
            <Typography
              span
              color={palette.green[200]}
              size={1.2}
              weight={700}
              style={{marginRight: ".5rem"}}
            >
              (به‌زودی)
            </Typography>
          </a>
        )}
      </Typography>
    </Box>
  );
};

export default ImageCardResponsive;

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: ".8rem",
    backgroundColor: theme.palette.background.paper,
    transition: "all 0.25s ease-in",
    "&:hover": {
      opacity: ".8",
    },
  },
  imageCardBox: ({ratio, hoverScaleEffect}: Partial<IImageCardResponsiveProps>) => ({
    paddingBottom: `calc(100% * ${ratio})`,
    position: "relative",
    overflow: "hidden",
    "&:hover $image": {
      transform: hoverScaleEffect ? "scale(1.03)" : undefined,
    },
  }),
  positionAbsolute: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  image: ({image}: Partial<IImageCardResponsiveProps>) => ({
    width: "100%",
    height: "100%",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    transition: "all 0.2s ease-out",
  }),
  title: {
    display: "block",
    padding: ".5rem 0 .3rem",
    marginTop: ".6rem",
  },
}));
