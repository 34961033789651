import {useAuth} from "@context/AuthContext";
import {setUserLastLocationInLs} from "@util/userLastLocation";
import {useAlert} from "@context/AlertContext";
import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {routes} from "@constant/routes";

/** For actions which needs user to be loggedIn
 * checks user status, if user is authenticated calls Promise.resolve otherwise
 * stores user current location in localStorage then redirects to login */
export default function useAuthenticateUser() {
  const alert = useAlert();
  const history = useHistory();
  const {user, loadedUser} = useAuth();
  const location = useLocation();

  const validateUser = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      if (!loadedUser) {
        return;
      }
      if (!user) {
        setUserLastLocationInLs(location.pathname);
        alert.info({text: "برای این کار لازمه اول وارد بشی"});
        // with the help of mode=redirect and setting user location in localStorage user will be
        // redirected back after being authenticated
        history.push({pathname: routes.landing.login, search: "mode=redirect"});
        return;
      } else {
        resolve();
      }
    });
  }, [alert, history, loadedUser, location.pathname, user]);

  return validateUser;
}
