import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import MuiPhoneNumber, {MaterialUiPhoneNumberProps} from "material-ui-phone-number";

interface IProps {
  errorMessage?: string;
}
export type TextInputProps = IProps & MaterialUiPhoneNumberProps;
export const PhoneNumberInput = (props: TextInputProps) => {
  const {value: controlledValue, onChange, errorMessage, ...rest} = props;
  const [value, setValue] = useState(controlledValue || "");
  const [focus, setFocus] = useState<boolean>(false);
  const classes = useStyle({focus, errorMessage});

  const handleChange = (value: string) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <Grid container direction='column'>
      <MuiPhoneNumber
        data-cy='user-phone'
        defaultCountry='ir'
        fullWidth
        value={value}
        onChange={handleChange}
        className={classes.input}
        variant='outlined'
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...rest}
      />
      {errorMessage && (
        <Typography size={1.4} className={classes.errorMessageBox}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
};
export default PhoneNumberInput;

const useStyle = makeStyles((theme) => ({
  input: ({focus, errorMessage}: {focus: boolean; errorMessage?: string}) => ({
    direction: "rtl",
    width: " 100%",
    boxSizing: "border-box",
    heigh: 59,
    border: errorMessage
      ? `1px solid ${palette.yellow[200]}`
      : !focus
      ? `1px solid ${palette.darkGrey[300]}`
      : `2px solid ${palette.darkGrey[300]}`,
    backgroundColor: theme.palette.background.paper,
    "& > *": {
      boxSizing: "border-box",
      fontSize: `${1.6 * fontNormalizeFactor}rem`,
      height: errorMessage || !focus ? 59 : 57,
    },
    "& *": {
      border: "none",
    },
  }),
  errorMessageBox: {
    marginTop: "3px",
    backgroundColor: palette.yellow[200],
    color: "black",
    lineHeight: "1.8rem",
    padding: "3.4rem 3rem 4rem 3rem",
    transition: "all .3s ease-out",
  },
}));
