import {darkGrey, lightGrey} from "@constant/colors";
import Typography from "@elements/Typography";
import {Button, Grid} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

interface IconButtonClasses {
  root?: string;
  icon?: string;
  disabled?: string;
}

export interface GreyButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  icon?: React.ReactElement;
  classes?: IconButtonClasses;
  children?: React.ReactText;
}

export function GreyButton({
  disabled,
  icon,
  onClick: handleClick,
  classes: externalClasses,
  children,
}: GreyButtonProps) {
  const classes = useStyles();
  const Icon =
    icon &&
    React.cloneElement(icon, {
      className: clsx(icon.props.className, classes.icon, externalClasses?.icon, {
        [clsx(classes.disabled, externalClasses?.disabled)]: disabled,
      }),
    });
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      className={clsx(classes.root, !children ? classes.noText : classes.withText, externalClasses?.root)}
    >
      <Grid container alignItems='center' justify='center' className={classes.wrapper}>
        {Icon}
        {children && (
          <Typography color='inherit' size={1.4} weight={500}>
            {children}
          </Typography>
        )}
      </Grid>
    </Button>
  );
}

export default GreyButton;

const useStyles = makeStyles(() => ({
  root: {
    // FIXME not in palette
    backgroundColor: "#f2f5f5",
    borderRadius: 2,
    height: "3.5rem",
    padding: 0,
    minWidth: 0,
    color: darkGrey[300],
    "&:hover": {
      backgroundColor: "#f2f5f5",
    },
  },
  noText: {
    width: "4rem",
  },
  wrapper: {
    gap: "0.6rem",
  },
  withText: {
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
  },
  icon: {
    color: "inherit",
    fontSize: "2.5rem", // chosen by hand
  },
  disabled: {
    color: lightGrey.A800,
  },
}));
