import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import {Link} from "react-router-dom";
import ImageCard from "./ImageCard";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import GreenLink from "@elements/GreenLink";
import {useTranslation} from "react-i18next";
import BoxWithWallpaper from "@elements/BoxWithWallpaper";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import {routes} from "@constant/routes";
import {ListCategory} from "@schema";

const CategoriesSection = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.home");
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const xxsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(400));

  const {data} = useExperimentalQuery("categories", "getList", {
    variables: {pageSize: 50},
    staleTime: Infinity,
  });

  const categories = data?.results.slice().sort((a, b) => a.priority - b.priority);

  return (
    <BoxWithWallpaper
      component='section'
      color={palette.lightGrey[700]}
      className={classes.container}
    >
      <Box className={classes.maxWidth}>
        <Box className={classes.headingsBox}>
          <Typography
            component='h2'
            size={xsDown ? 2.2 : 2.6}
            align='center'
            className={classes.marginBottom}
          >
            {t("categoriesSection.title")}
          </Typography>
          <Typography size={xsDown ? 1.8 : 2} align='center'>
            {t("categoriesSection.description")}
          </Typography>
        </Box>
        <Grid component='ul' container justify='center' wrap='wrap' className={classes.categories}>
          {categories
            ?.filter((_, index) => index <= 11)
            .map(({name, id, image}) => (
              <Grid
                component='li'
                key={id}
                item
                xs={xxsDown ? 12 : 6}
                sm={xsDown ? 6 : 4}
                md={3}
                className={classes.padding}
              >
                <Grid className={classes.category}>
                  <Link to={`${routes.landing.search}?categories=${id}`}>
                    <ImageCard
                      image={(image?.file as unknown as string) || ""}
                      title={name}
                      id={id}
                    />
                  </Link>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid component='ul' container justify='flex-start' wrap='wrap'>
          {(categories?.length || 0) > 12 &&
            categories
              ?.filter((_, index) => index > 11)
              .concat([{name: "متفرقه", id: "null"} as unknown as ListCategory])
              .map(({name, id}) => (
                <Grid
                  component='li'
                  container
                  justify='center'
                  key={id}
                  item
                  xs={xxsDown ? 12 : 6}
                  sm={4}
                  md={3}
                >
                  <GreenLink
                    component='h3'
                    to={`${routes.landing.search}?categories=${id}`}
                    size='medium'
                  >
                    {name}
                  </GreenLink>
                </Grid>
              ))}
        </Grid>
      </Box>
    </BoxWithWallpaper>
  );
};

export default CategoriesSection;

const useStyle = makeStyles((theme) => ({
  container: {
    padding: "4.2rem 1rem 5.2rem ",
    [theme.breakpoints.down("xs")]: {
      padding: "3.2rem .6rem 3rem",
    },
  },
  maxWidth: {
    maxWidth: "114.6rem",
    margin: "0 auto",
  },
  headingsBox: {
    marginBottom: "4rem",
    padding: "0 .6rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
  },
  marginBottom: {
    marginBottom: "2rem",
  },
  category: {
    height: "22.4rem",
  },
  padding: {
    padding: ".6rem",
  },
  categories: {
    marginBottom: "3.5rem",
  },
}));
