import {useAuth} from "@context/AuthContext";
import {UserRoleEnum as Role} from "@schema";
import React from "react";

export {Role};

export interface IProps {
  children: any;
  allowedRolesToSee?: Role[];
  fallback?: React.ReactNode;
}

const defaultAllowedRoles: Role[] = [Role.Developer, Role.Owner, Role.Admin, Role.Support];

/**
 * @param allowedRolesToSee has a default value, so in some cases you may not need to pass
 */
export default function ShouldRender(props: IProps) {
  const {user} = useAuth();
  const {children: Component, allowedRolesToSee = defaultAllowedRoles, fallback = null} = props;
  const hasPermission = allowedRolesToSee.some((role) => role === user?.role);
  // const hasPermission = true;

  return !!user && hasPermission ? Component : fallback;
}
