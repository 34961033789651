import {ApiNamespaces} from "@api";
import {AxiosError} from "axios";
import React from "react";
import {QueryClient, useMutation, useQueryClient} from "react-query";
import {RetryDelayValue, RetryValue} from "react-query/types/core/retryer";
import {
  InvalidateOptions,
  InvalidateQueryFilters,
  MutationStatus,
} from "react-query/types/core/types";
import {MutationFetcher, Params, QueryFetcher, Return} from "./types";

export function Q<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Query"]
>(
  namespace: T,
  key: U,
  variables?: Params<QueryFetcher<T, U>>[0] extends {queryKey: [unknown, infer W]}
    ? Partial<W>
    : undefined,
  configs?: {
    filters?: InvalidateQueryFilters;
    options?: InvalidateOptions;
  }
): {
  configs: {filters?: InvalidateQueryFilters; options?: InvalidateOptions} | undefined;
  variables:
    | (Params<QueryFetcher<T, U>>[0] extends {queryKey: [unknown, infer W]}
        ? Partial<W>
        : undefined)
    | undefined;
  namespace: T;
  key: U;
} {
  return {
    namespace,
    key,
    variables,
    configs,
  };
}

type QueryInvalidations = Return<typeof Q>[];

interface MutateBaseOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> {
  invalidations?: QueryInvalidations;
  onSuccess?: (
    data: TData,
    variables: TVariables,
    context: TContext,
    proxy: QueryClient
  ) => Promise<unknown> | void;
  onError?: (
    error: TError,
    variables: TVariables,
    context: TContext | undefined,
    proxy: QueryClient
  ) => Promise<unknown> | void;
  onSettled?: (
    data: TData | undefined,
    error: TError | null,
    variables: TVariables,
    context: TContext | undefined,
    proxy: QueryClient
  ) => Promise<unknown> | void;
}

type MutateOptionsWithVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = MutateBaseOptions<TData, TError, TVariables, TContext> & {variables: TVariables};

type MutateOptionsWithoutVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = MutateBaseOptions<TData, TError, TVariables, TContext> & {variables?: TVariables};

interface UseMutationBaseOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> {
  invalidations?: QueryInvalidations;
  retry?: RetryValue<TError>;
  retryDelay?: RetryDelayValue<TError>;
  useErrorBoundary?: boolean;
  onMutate?: (
    variables: TVariables extends undefined ? never : TVariables,
    proxy: QueryClient
  ) => Promise<TContext> | Promise<undefined> | TContext | undefined;
  onSuccess?: (
    data: TData,
    variables: TVariables extends undefined ? never : TVariables,
    context: TContext | undefined,
    proxy: QueryClient
  ) => Promise<unknown> | void;
  onError?: (
    error: TError,
    variables: TVariables extends undefined ? never : TVariables,
    context: TContext | undefined,
    proxy: QueryClient
  ) => Promise<unknown> | void;
  onSettled?: (
    data: TData | undefined,
    error: TError | null,
    variables: TVariables extends undefined ? never : TVariables,
    context: TContext | undefined,
    proxy: QueryClient
  ) => Promise<unknown> | void;
}

type UseMutationOptionsWithVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = UseMutationBaseOptions<TData, TError, TVariables, TContext> & {
  variables: TVariables extends undefined ? never : TVariables;
};

type UseMutationOptionsWithoutVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = UseMutationBaseOptions<TData, TError, TVariables, TContext> & {
  variables?: never;
};

type UseMutateFunctionWithVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = (options: MutateOptionsWithVariables<TData, TError, TVariables, TContext>) => void;

type UseMutateFunctionWithoutVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = (options?: MutateOptionsWithoutVariables<TData, TError, TVariables, TContext>) => void;

type UseMutateAsyncFunctionWithVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = (options: MutateOptionsWithVariables<TData, TError, TVariables, TContext>) => Promise<TData>;

type UseMutateAsyncFunctionWithoutVariables<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = (
  options?: MutateOptionsWithoutVariables<TData, TError, TVariables, TContext>
) => Promise<TData>;

interface UseMutationBaseResult<TData = unknown, TError = unknown, TContext = unknown> {
  context: TContext | undefined;
  data: TData | undefined;
  error: TError | null;
  failureCount: number;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isPaused: boolean;
  isSuccess: boolean;
  reset: () => void;
  status: MutationStatus;
}

interface UseMutationResultWithVariables<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
> extends UseMutationBaseResult<TData, TError, TContext> {
  mutate: UseMutateFunctionWithVariables<TData, TError, TVariables, TContext>;
  mutateAsync: UseMutateAsyncFunctionWithVariables<TData, TError, TVariables, TContext>;
}

interface UseMutationResultWithoutVariables<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
> extends UseMutationBaseResult<TData, TError, TContext> {
  mutate: UseMutateFunctionWithoutVariables<TData, TError, TVariables, TContext>;
  mutateAsync: UseMutateAsyncFunctionWithoutVariables<TData, TError, TVariables, TContext>;
}

type Variables<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Mutation"]
> = Params<MutationFetcher<T, U>>[0];

type Results<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Mutation"]
> = Return<MutationFetcher<T, U>> extends Promise<infer C> ? C : Return<MutationFetcher<T, U>>;

export function useExperimentalMutation<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Mutation"],
  TError = AxiosError<unknown>,
  TContext = unknown
>(
  namespace: T,
  key: U
): Variables<T, U> extends undefined
  ? UseMutationResultWithoutVariables<Results<T, U>, TError, Variables<T, U>, TContext>
  : UseMutationResultWithVariables<Results<T, U>, TError, Variables<T, U>, TContext>;
export function useExperimentalMutation<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Mutation"],
  TError = AxiosError<unknown>,
  TContext = unknown
>(
  namespace: T,
  key: U,
  options: UseMutationOptionsWithoutVariables<Results<T, U>, TError, Variables<T, U>, TContext>
): Variables<T, U> extends undefined
  ? UseMutationResultWithoutVariables<Results<T, U>, TError, Variables<T, U>, TContext>
  : UseMutationResultWithVariables<Results<T, U>, TError, Variables<T, U>, TContext>;
export function useExperimentalMutation<
  T extends keyof typeof ApiNamespaces,
  U extends keyof typeof ApiNamespaces[T]["Mutation"],
  TError = AxiosError<unknown>,
  TContext = unknown
>(
  namespace: T,
  key: U,
  options: UseMutationOptionsWithVariables<Results<T, U>, TError, Variables<T, U>, TContext>
): UseMutationResultWithoutVariables<Results<T, U>, TError, Variables<T, U>, TContext>;
export function useExperimentalMutation(namespace: any, key: any, options?: any) {
  // @ts-ignore
  const mutateFn = ApiNamespaces[namespace]["Mutation"][key];
  const {mutationKey} = mutateFn;
  const {
    variables,
    onSuccess: handleSuccess,
    onError: handleError,
    onMutate: handleMutate,
    onSettled: handleSettled,
    invalidations,
    ...opts
  } = options || {};
  const proxy = useQueryClient();
  const onSuccess = (data: any, variables: any, context: any) => {
    invalidations?.forEach(
      ({
        namespace,
        key,
        variables,
        configs,
      }: {
        namespace: any;
        key: any;
        variables: any;
        configs: any;
      }) => {
        // @ts-ignore
        const {queryKey} = ApiNamespaces[namespace].Query[key];
        // noinspection JSIgnoredPromiseFromCall
        proxy.invalidateQueries(
          [queryKey, variables],
          configs?.filters || {},
          configs?.options || {}
        );
      }
    );
    handleSuccess?.(data, variables, context, proxy);
  };
  const onError = (err: any, variables: any, context: any) =>
    handleError?.(err, variables, context, proxy);
  const onMutate = (variables: any) => handleMutate?.(variables, proxy);
  const onSettled = (data: any, err: any, variables: any, context: any) =>
    handleSettled?.(data, err, variables, context, proxy);
  const {mutate: handler, mutateAsync: handlerAsync, ...other} = useMutation(
    mutationKey,
    mutateFn,
    {
      ...opts,
      onMutate,
      onSettled,
      onError,
      onSuccess,
    }
  );
  const mutate = React.useCallback(
    (options?: any) => {
      const {
        variables: innerVariables,
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled: handleSettled,
        invalidations,
      } = options || {};
      const onSuccess = (data: any, variables: any, context: any) => {
        invalidations?.forEach(
          ({
            namespace,
            key,
            variables,
            configs,
          }: {
            namespace: any;
            key: any;
            variables: any;
            configs: any;
          }) => {
            // @ts-ignore
            const {queryKey} = ApiNamespaces[namespace].Query[key];
            // noinspection JSIgnoredPromiseFromCall
            proxy.invalidateQueries(
              [queryKey, variables],
              configs?.filters || {},
              configs?.options || {}
            );
          }
        );
        handleSuccess?.(data, variables, context, proxy);
      };
      const onError = (err: any, variables: any, context: any) =>
        handleError?.(err, variables, context, proxy);
      const onSettled = (data: any, err: any, variables: any, context: any) =>
        handleSettled?.(data, err, variables, context, proxy);
      handler(innerVariables || variables || {}, {onError, onSettled, onSuccess});
    },
    [handler, proxy, variables]
  );
  const mutateAsync = React.useCallback(
    async (options?: any) => {
      const {
        variables: innerVariables,
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled: handleSettled,
      } = options || {};
      const onSuccess = (data: any, variables: any, context: any) =>
        handleSuccess?.(data, variables, context, proxy);
      const onError = (err: any, variables: any, context: any) =>
        handleError?.(err, variables, context, proxy);
      const onSettled = (data: any, err: any, variables: any, context: any) =>
        handleSettled?.(data, err, variables, context, proxy);
      await handlerAsync(innerVariables || variables!, {onError, onSettled, onSuccess});
    },
    [handlerAsync, proxy, variables]
  );

  return {...other, mutate, mutateAsync};
}
