import React from "react";
import {Dialog, DialogProps, Grid, Theme, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {images} from "@constant/images";
import Typography from "@elements/Typography";
import {darkGrey, green} from "@constant/colors";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {modalBreakPoint} from "@modules/modals/constant";

export interface DialogSkeletonSpecificProps {
  hasCloseButton?: boolean;
}

export type DialogSkeletonProps = DialogProps & DialogSkeletonSpecificProps;
export type DialogSkeletonDefaultProps = Pick<
  DialogSkeletonProps,
  | "title"
  | "style"
  | "className"
  | "children"
  | "fullScreen"
  | "fullWidth"
  | "hasCloseButton"
  | "id"
  | "onClose"
  | "open"
>;

export default function DialogSkeleton(props: DialogSkeletonProps) {
  const classes = useStyles();
  const {onClose: handleClose, title, children, ...rest} = props;
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up(modalBreakPoint));

  return (
    <Dialog
      {...rest}
      classes={{paper: classes.paper}}
      BackdropProps={{className: classes.backdrop}}
      onClose={handleClose}
      fullScreen={!isLarge}
    >
      <img
        src={images.icons.closeGrey}
        alt='close'
        className={classes.closeIcon}
        onClick={() => handleClose?.({}, "escapeKeyDown")}
      />
      <Grid container direction='column' alignItems='center'>
        <Typography noWrap size={2.4} align='center' className={classes.title}>
          {title}
        </Typography>
        <Grid container justify='center'>
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    direction: "ltr",
    width: "48rem",
    padding: "4.8rem 3.2rem 6.4rem",
    position: "relative",
    [theme.breakpoints.up(modalBreakPoint)]: {
      borderRadius: 5,
    },
    "& .MuiInputBase-root": {
      height: "5.92rem",
      borderRadius: 0,
      color: darkGrey.A700,
      border: "1px solid #ccc",
      boxShadow: "none",
      padding: "1px 0.85rem",
      fontSize: `${1.6 * fontNormalizeFactor}rem`,
      width: "100%",
      "&.Mui-focused": {
        border: `1px solid ${green[200]}`,
      },
    },
  },
  title: {
    paddingBottom: "2.4rem",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  closeIcon: {
    left: "1em",
    top: "1em",
    position: "absolute",
    width: "3.6rem",
    height: "3.6rem",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
}));
