import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import {PhotoCamera} from "@material-ui/icons";

export interface IUploadButtonProps {
  onChange?: (file: File) => void;
}

const UploadButton = (props: IUploadButtonProps) => {
  const classes = useStyle();
  const {onChange} = props;

  return (
    <>
      <input
        accept='image/*,.ai,.eps'
        className={classes.input}
        id='contained-button-file'
        multiple
        type='file'
        onChange={(e) => {
          if (e.target.files?.[0]) {
            onChange?.(e.target.files[0]);
          }
        }}
        onProgress={(e) => {
          console.log("in progress");
          console.log(e, "e");
        }}
      />
      <label htmlFor='contained-button-file'>
        <IconButton color='primary' component='span' className={classes.button}>
          <PhotoCamera className={classes.icon} />
        </IconButton>
      </label>
    </>
  );
};

export default UploadButton;

const useStyle = makeStyles(() => ({
  input: {
    display: "none",
  },
  button: {
    width: 90,
    height: 90,
  },
  icon: {
    width: 50,
    height: 50,
  },
}));
