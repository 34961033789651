import { createContext, Dispatch, SetStateAction, useContext } from "react";

interface IInitialValue {
  openNavbar: boolean;
  setOpenNavbar: Dispatch<SetStateAction<boolean>>
  toggleNavbar: () => void;
}

const initialValue: IInitialValue = {
  openNavbar: false,
  setOpenNavbar: () => { },
  toggleNavbar: () => { },
};

export const navbarContext = createContext(initialValue);

export const useNavbarContext = () => useContext(navbarContext);


