import {
  ImagesApi,
  ImageApi,
  ImageApiImageByIdcodeReadRequest,
  ImagesApiImagesAdminRetrieveReadRequest,
  ImagesApiImagesCreateRequest,
  ImagesApiImagesDeleteRequest,
  ImagesApiImagesDownloadReadRequest,
  ImagesApiImagesListRequest,
  ImagesApiImagesPartialUpdateRequest,
  ImagesApiImagesReadRequest,
  ImagesApiImagesSimilarImagesRequest,
  ImagesApiImagesBulkUpdateRequest,
} from "@schema/api";
import {AxiosRequestConfig} from "axios";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const imagesApiInstance = new ImagesApi(undefined, undefined, axiosInstance);
const imageApiInstance = new ImageApi(undefined, undefined, axiosInstance);

export const imagesAPI = makeApi({
  identifier: "images",
  Query: {
    getList: createQuery(async (params: ImagesApiImagesListRequest) => {
      const {data} = await imagesApiInstance.imagesList(params);
      return data;
    }),
    getSimilarImages: createQuery(async (params: ImagesApiImagesSimilarImagesRequest) => {
      const {data} = await imagesApiInstance.imagesSimilarImages(params);
      return data;
    }),
    readDetail: createQuery(async (params: ImagesApiImagesReadRequest) => {
      const {data} = await imagesApiInstance.imagesRead(params);
      return data;
    }),
    readDetailByCodeId: createQuery(async (params: ImageApiImageByIdcodeReadRequest) => {
      const {data} = await imageApiInstance.imageByIdcodeRead(params);
      return data;
    }),
    readDetailForAdmin: createQuery(async (params: ImagesApiImagesAdminRetrieveReadRequest) => {
      const {data} = await imagesApiInstance.imagesAdminRetrieveRead(params);
      return data;
    }),
  },
  Mutation: {
    updateImage: async (
      params: ImagesApiImagesPartialUpdateRequest & {axiosConfig?: AxiosRequestConfig}
    ) => {
      const {axiosConfig, ...rest} = params;
      const {data} = await imagesApiInstance.imagesPartialUpdate(rest, axiosConfig);
      return data;
    },
    bulkUpdate: async (params: ImagesApiImagesBulkUpdateRequest) => {
      const {data} = await imagesApiInstance.imagesBulkUpdate(params);
      return data;
    },
    deleteImage: async (params: ImagesApiImagesDeleteRequest) => {
      const {data} = await imagesApiInstance.imagesDelete(params);
      return data;
    },
    createImage: async (
      params: ImagesApiImagesCreateRequest & {axiosConfig?: AxiosRequestConfig}
    ) => {
      const {axiosConfig, ...rest} = params;
      const {data} = await imagesApiInstance.imagesCreate(rest, axiosConfig);
      return data;
    },
    download: async (
      params: ImagesApiImagesDownloadReadRequest & {axiosConfig?: AxiosRequestConfig}
    ) => {
      const {axiosConfig, ...rest} = params;
      const {data} = await imagesApiInstance.imagesDownloadRead(rest, axiosConfig);
      return data;
    },
  },
});
