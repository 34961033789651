import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Close, HistoryRounded as HistoryIcon} from "@material-ui/icons";
import {Box} from "@material-ui/core";
import {localStPhraseHistoryClear, localStPhraseHistoryGet, localStPhraseHistorySet} from "./utils";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";

export interface IPhraseHistoryProps {
  history: string[];
  setShowHistory: (state: boolean) => void;
  setHistory: (history: string[]) => void;
  onClick?: (value: string) => void;
}

const PhraseHistory = (props: IPhraseHistoryProps) => {
  const {history, onClick, setShowHistory, setHistory} = props;
  const classes = useStyle();
  const {t} = useTranslation("modules.searchBar");

  const handleClick = (value: string) => onClick?.(value);

  const handleRemove = (event: React.MouseEvent, item: string) => {
    event?.stopPropagation();
    const newHistory = localStPhraseHistoryGet().filter((i) => i !== item);
    localStPhraseHistorySet(newHistory);
    setHistory(newHistory);
    // this is the last history item
    history.length === 1 && setShowHistory(false);
  };

  const handleClearHistory = () => {
    localStPhraseHistoryClear();
    setHistory([]);
    setShowHistory(false);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography size={1.2} color={palette.darkGrey[400]} weight={400} className={classes.title}>
          {t("phraseHistory.recentSearches")}
          <HistoryIcon className={classes.historyIcon} />
        </Typography>
        <Typography
          size={1.2}
          align='right'
          color={palette.green[200]}
          weight={500}
          className={classes.removeAll}
          onClick={() => handleClearHistory()}
        >
          {t("phraseHistory.clearAll")}
        </Typography>
      </Box>
      <Box className={classes.historyItemBox}>
        {history.map((item) => (
          <Typography key={item} className={classes.historyItem} onClick={() => handleClick(item)}>
            {item}
            <Close className={classes.removeIcon} onClick={(e) => handleRemove(e, item)} />
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default PhraseHistory;

const useStyle = makeStyles(() => ({
  container: {
    fontSize: "1.8rem",
    fontWeight: 500,
  },
  heading: {
    width: "100%",
    minHeight: "3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingLeft: ".5rem",
    paddingTop: ".5rem",
  },
  title: {
    position: "relative",
  },
  historyIcon: {
    position: "absolute",
    top: "50%",
    left: "-2.5rem",
    transform: "translateY(-50%)",
    color: "inherit",
    opacity: ".85",
    width: "1.9rem",
    height: "1.9rem",
  },
  removeAll: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    padding: "0 1.5rem",
    cursor: "pointer",
    "&:hover": {
      color: palette.green[500],
    },
  },
  historyItemBox: {
    padding: "1.2rem 0",
    display: "flex",
    flexFlow: "column-reverse",
  },
  historyItem: {
    display: "block",
    cursor: "pointer",
    color: palette.darkGrey.A100,
    padding: ".2rem .2rem .2rem .5rem",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      transform: "scaleX(3)",
      zIndex: -1,
    },
    "&:hover": {
      color: "black",
    },
    "&:hover::after": {
      backgroundColor: palette.blue[300],
    },
    "&:hover $removeIcon": {
      visibility: "visible",
    },
  },
  removeIcon: {
    position: "absolute",
    top: "50%",
    right: ".8rem",
    fontSize: "1.7rem",
    transform: "translateY(-50%)",
    visibility: "hidden",
    color: palette.lightGrey.A600,
    "&:hover": {
      color: palette.darkGrey[100],
    },
  },
}));
