import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {lightGrey} from "@constant/colors";
import TextInput from "@elements/TextInput";
import {MainButton} from "@elements/button";
import React from "react";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useAlert} from "@context/AlertContext";
import {useAuth} from "@context/AuthContext";
import {generateYupSchema, IFormSchema, setFormErrors} from "@util/reactHookFormUtils";
import useApiErrorHandler from "@hook/useApiErrorHandler";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {ApiNamespaces} from "@api";
import {useQueryClient} from "react-query";

interface IFormValueTypes {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  phone: string;
}

const Details = () => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.account");
  const alert = useAlert();
  const {user} = useAuth();
  const apiErrorHandler = useApiErrorHandler();
  const queryClient = useQueryClient();

  const schema: IFormSchema<IFormValueTypes> = [
    {name: "first_name", validations: yup.string()},
    {name: "last_name", validations: yup.string()},
    {name: "email", validations: yup.string().email("لطفا یک ایمیل صحیح وارد کنید")},
    {name: "company_name", validations: yup.string()},
    {name: "phone", validations: yup.string()},
  ];

  const {handleSubmit, setError, control, reset} = useForm<IFormValueTypes>({
    resolver: yupResolver(generateYupSchema(schema)),
  });

  React.useEffect(() => {
    user &&
      reset({
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        email: user?.email || "",
        company_name: user?.company_name || "",
        phone: user?.phone || "",
      });
  }, [reset, user]);

  const {mutate} = useExperimentalMutation("auth", "update");

  const handleSubmitChanges: SubmitHandler<IFormValueTypes> = (values) => {
    const {first_name, last_name, email, company_name, phone} = values;
    if (!!phone.length && (phone.startsWith("+") ? !Number(phone.slice(1)) : !Number(phone))) {
      setError("phone", {message: "لطفا یک شماره صحیح وارد کنید"});
      return;
    }
    // this 2 fields are optional but can't be blank if sent
    const companyNameField = company_name ? {company_name} : {};
    const phoneFiled = phone ? {phone} : {};
    mutate({
      variables: {
        data: {
          first_name: first_name,
          last_name: last_name,
          email,
          ...companyNameField,
          ...phoneFiled,
        },
      },
      onSuccess: () => {
        alert.success({});
        queryClient.invalidateQueries(ApiNamespaces.auth.Query.getUser);
      },
      onError: (error) => {
        if (error.response?.status === 400) {
          setFormErrors(schema, setError, error);
        }
        apiErrorHandler(error);
      },
    });
  };

  return (
    <Grid component='form' className={classes.form} onSubmit={handleSubmit(handleSubmitChanges)}>
      <Typography size={2.1} weight={500} className={classes.title}>
        {t("updateAccountProfile")}
      </Typography>
      <Typography size={1.4} weight={500} className={classes.customerNumber}>
        {t("yourCustomerNumber")}
      </Typography>
      <Grid className={classes.inputsBox}>
        <Controller
          name='first_name'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("firstName")}
              placeholder={t("firstName")}
              direction='rtl'
              error={!!error}
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name='last_name'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("lastName")}
              placeholder={t("lastName")}
              direction='rtl'
              error={!!error}
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("email")}
              placeholder={t("email")}
              error={!!error}
              isEnglish
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name='company_name'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("companyName")}
              placeholder={t("companyName")}
              direction='rtl'
              error={!!error}
              errorMessage={error?.message}
            />
          )}
        />
        <Controller
          name='phone'
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextInput
              {...field}
              label={t("phoneNumber")}
              placeholder={t("phoneNumber")}
              isEnglish
              error={!!error}
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <MainButton color='green' type='submit' fullWidth>
        {t("saveChange")}
      </MainButton>
    </Grid>
  );
};

export default Details;

const useStyle = makeStyles((theme) => ({
  form: {
    backgroundColor: lightGrey[200],
    padding: "2.5rem 4rem",
    marginTop: "1rem",
    border: `1px solid ${lightGrey[600]}`,
    [theme.breakpoints.down(500)]: {
      padding: "2.5rem 2rem",
    },
  },
  inputsBox: {
    marginBottom: "4rem",
    "& > *": {
      marginBottom: "1rem",
    },
  },
  title: {
    marginBottom: "3rem",
  },
  customerNumber: {
    marginBottom: "1rem",
  },
}));
