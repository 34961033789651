/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ListImage
 */
export interface ListImage {
    /**
     * 
     * @type {number}
     * @memberof ListImage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    id_code?: string;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    width?: string;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    type?: ListImageTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ListImage
     */
    is_premium?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ListImage
     */
    user_bought?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ListImageTypeEnum {
    Photo = 'Photo',
    Vector = 'Vector',
    Illustration = 'Illustration'
}



