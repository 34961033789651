import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import clsx from "clsx";
import {CommonProps} from "@type/global";

export interface ISpinnerProps extends CommonProps {
  width?: string;
  height?: string;
  noDefaultPadding?: boolean;
}

const Spinner = (props: ISpinnerProps) => {
  const classes = useStyle(props);
  const {className, style} = props;

  return (
    <Box className={clsx(classes.container, className)} style={style}>
      <Box className='spinner' />
    </Box>
  );
};

export default Spinner;

const useStyle = makeStyles(() => ({
  container: ({width, height, noDefaultPadding}: ISpinnerProps) => ({
    width: width ? width : "100%",
    height: height ? height : "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: noDefaultPadding ? 0 : "17% 0",
  }),
}));
