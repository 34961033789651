import {makeStyles} from "@material-ui/core/styles";
import {buttonsResponsiveWithCloseDrawer, buttonsResponsiveWithOpenDrawer} from "./constant";
import ButtonWithIcon from "@elements/button/ButtonWithIcon";
import {images} from "@constant/images";
import CarouselGenerator from "./CarouselGenerator";
import {palette} from "@constant/colors";
import React from "react";
import {Button, CardMedia, Theme, useMediaQuery} from "@material-ui/core";
import clsx from "clsx";
import {Box} from "@material-ui/core";

interface IButtonsCarouselProps<T> {
  drawerIsOpen: boolean;
  items: T[];
}

export function ButtonsCarousel<T extends {title: string; href: string}>(
  props: IButtonsCarouselProps<T>
) {
  const classes = useStyle();
  const {items: itemsArray, drawerIsOpen} = props;
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  // choose the right responsive object
  const responsive = drawerIsOpen
    ? buttonsResponsiveWithOpenDrawer
    : buttonsResponsiveWithCloseDrawer;

  const items = itemsArray.map(({title, href}) => ({
    href,
    element: (
      <Box title={title}>
        <ButtonWithIcon
          noIcon={xsDown}
          icon={images.icons.searchDark}
          className={classes.carouselButton}
        >
          {title}
        </ButtonWithIcon>
      </Box>
    ),
  }));

  const nextButton = (
    <Button className={clsx(classes.button, classes.next)}>
      <CardMedia
        component='img'
        className={clsx(classes.icon, classes.rotate)}
        src={images.icons.arrowLeftDark}
        alt='arrow-left'
      />
    </Button>
  );

  const prevButton = (
    <Button className={clsx(classes.button, classes.prev)}>
      <CardMedia
        component='img'
        className={classes.icon}
        src={images.icons.arrowLeftDark}
        alt='arrow-left'
      />
    </Button>
  );

  return (
    <CarouselGenerator
      items={items}
      responsive={responsive}
      customNextButton={nextButton}
      customPrevButton={prevButton}
      hiddenButtonsOnDisabled
    />
  );
}

export default ButtonsCarousel;

const useStyle = makeStyles((theme) => ({
  carouselButton: {
    width: "100%",
    height: "4rem",
  },
  button: () => ({
    position: "absolute",
    minWidth: 0,
    width: "2.6rem",
    height: "4rem",
    backgroundColor: palette.lightGrey[500],
    outline: "none",
    border: `solid 1px ${palette.lightGrey.A500}`,
    transition: "all .3s ease",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    "&:hover": {
      backgroundColor: palette.lightGrey.A500,
    },
  }),
  next: {
    borderRadius: 0,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    left: -1,
    [theme.breakpoints.down(600)]: {
      left: 4,
    },
  },
  prev: {
    borderRadius: 0,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    right: -1,
    [theme.breakpoints.down(600)]: {
      right: 4,
    },
  },
  hidden: {
    display: "none",
  },
  icon: {
    height: "1.2rem",
    width: ".8rem",
  },
  rotate: {
    transform: "rotate(180deg)",
  },
}));
