import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {data as fakeData} from "./mockData";
import {Box} from "@material-ui/core";
import clsx from "clsx";
import SentenceWithHighlightedQuery from "./SentenceWithHighlightedQuery";
import {palette} from "@constant/colors";
import Typography from "@elements/Typography";

export interface IAutoCompleteProps {
  value: string;
  debouncedValue: string;
  onClick?: (value: string) => void;
}

const AutoComplete = (props: IAutoCompleteProps) => {
  const {value, debouncedValue, onClick} = props;
  const classes = useStyle();

  const getResult = (value: string) => {
    return fakeData
      .filter((item) => {
        const itemArray = item.split(" ");
        return itemArray.some((item) => item.toLocaleLowerCase().startsWith(value.toLocaleLowerCase()));
      })
      .slice(0, 9);
  };

  const handleClick = (value: string) => onClick && onClick(value);

  return (
    <Box className={classes.container}>
      <Typography className={clsx(classes.item, classes.highlighted)} onClick={() => handleClick(value)}>
        {value}
      </Typography>
      {debouncedValue &&
        getResult(debouncedValue).map((item) => (
          <Typography key={item} className={classes.item} onClick={() => handleClick(item)}>
            <SentenceWithHighlightedQuery sentence={item} query={debouncedValue} />
          </Typography>
        ))}
    </Box>
  );
};

export default AutoComplete;

const useStyle = makeStyles(() => ({
  container: {
    padding: "1.5rem 0",
  },
  item: {
    display: "block",
    cursor: "pointer",
    color: palette.darkGrey.A100,
    padding: ".2rem .2rem .2rem .5rem",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: '""',
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      transform: "scaleX(5)",
      zIndex: -1,
    },
    "&:hover": {
      color: "black",
    },
    "&:hover::after": {
      backgroundColor: palette.blue[300],
    },
  },
  gap: {
    display: "inline-flex",
    gap: ".6rem",
    flexDirection: "row-reverse",
  },
  highlighted: {
    color: palette.darkGrey[200], // the original color is #666e6e, but here, not very obvious probably because of our current font
    "&:hover": {
      color: palette.darkGrey[200],
    },
  },
}));
