import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup as MaterialRadioGroup,
  Radio,
} from "@material-ui/core";
import Typography from "@elements/Typography";

export interface IRadioGroupProps {
  label: string;
  items: {label: string; value: string}[];
  value?: string;
  onChange?: (value: string) => void;
}

const RadioGroup = (props: IRadioGroupProps) => {
  const classes = useStyle();
  const {value: controlledValue, items, label, onChange} = props;
  const [value, setValue] = React.useState(controlledValue || items[0]?.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setValue(value);
    onChange?.(value);
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend' className={classes.title}>
        <Typography>{label}</Typography>
      </FormLabel>
      <MaterialRadioGroup name='image-type' value={value} onChange={handleChange}>
        {items.map(({value, label}) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio color='primary' classes={{root: classes.root}} />}
            label={<Typography size={1.4}>{label}</Typography>}
          />
        ))}
      </MaterialRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;

const useStyle = makeStyles(() => ({
  title: {
    marginBottom: 20,
  },
  root: {
    transform: "scale(1.4)",
    marginLeft: 3,
  },
  label: {
    fontSize: 14,
    margin: 4,
  },
}));
