import {makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import Divider from "@elements/Divider";
import {darkGrey} from "@constant/colors";
import {NavLink, Route, Switch} from "react-router-dom";
import Details from "./details";
import ChangePassword from "./change-password";
import {routes} from "@constant/routes";

const Profile = () => {
  const {t} = useTranslation("modules.pages.account");
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const classes = useStyle();
  const {landing} = routes;

  return (
    <Grid container className={classes.container} wrap='wrap'>
      <Grid item xs={xs ? 12 : 3} className={classes.list}>
        <Typography size={1.8}>{t("profile")}</Typography>
        <Divider className={classes.divider} />
        <NavLink activeClassName={classes.active} to={`${landing.account}/profile`} exact>
          <Typography size={1.4} color={darkGrey[300]} className={classes.marginBottom}>
            {t("details")}
          </Typography>
        </NavLink>
        <NavLink activeClassName={classes.active} to={`${landing.account}/profile/change-password`}>
          <Typography size={1.4} color={darkGrey[300]}>
            {t("changePassword")}
          </Typography>
        </NavLink>
      </Grid>
      <Grid item xs={xs ? 12 : 6}>
        <Switch>
          <Route path={`${landing.account}/profile/change-password`} component={ChangePassword} />
          <Route exact path={`${landing.account}/profile`} component={Details} />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default Profile;

const useStyle = makeStyles((theme) => ({
  container: {},
  list: {
    marginTop: "3rem",
    paddingRight: "2rem",
    [theme.breakpoints.down(700)]: {
      marginBottom: "2rem",
    },
  },
  divider: {
    margin: "1rem 0",
  },
  active: {
    "& *": {
      color: darkGrey.A700,
    },
  },
  marginBottom: {
    marginBottom: "1rem",
  },
}));
