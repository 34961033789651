import {makeStyles} from "@material-ui/core/styles";
import {ChevronRight, ChevronLeft, ArrowDropDown} from "@material-ui/icons";
import Typography from "@elements/Typography";
import {Box, IconButton, Grid, InputBase} from "@material-ui/core";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {useEffect, useState} from "react";
import clsx from "clsx";
import {useOnClickOutside} from "@hook/useOutsideClicker";
import {fontNormalizeFactor} from "@elements/Typography/constant";

export interface IPaginationProps {
  count: number;
  pageSize?: number;
  pageSizeOptions?: {label: string; value: number}[];
}

const Pagination = (props: IPaginationProps) => {
  const {count, pageSize: controlledPageSize = 20, pageSizeOptions} = props;
  const [showPageSizeOptions, setShowPageSizeOptions] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(controlledPageSize);
  const classes = useStyle();
  const {page: _page, pageSize: queryPageSize} = useGetQueryString();
  const page = _page ? Number(_page) : 1;
  const handleUpdateQueryString = useUpdateQueryString();
  const availablePages = Math.ceil(count / pageSize);
  const optionsRef = useOnClickOutside<HTMLDivElement>(() => setShowPageSizeOptions(false));
  const [pageState, setPageState] = useState(String(page));

  useEffect(() => {
    setPageState(String(page));
  }, [page]);

  useEffect(() => {
    queryPageSize && setPageSize(Number(queryPageSize));
  }, [queryPageSize]);

  const handleNext = () => {
    window.scrollTo({top: 0});
    handleUpdateQueryString({page: String(page + 1)});
  };
  const handlePrev = () => {
    window.scrollTo({top: 0});
    handleUpdateQueryString({page: String(page - 1)});
  };

  const options = pageSizeOptions || [
    {label: "10", value: 10},
    {label: "15", value: 15},
    {label: "20", value: 20},
    {label: "25", value: 25},
  ];

  if (count <= options[0]!.value) {
    return null;
  }
  return (
    <Grid container justify='flex-end' alignItems='center' className={classes.wrapper}>
      <Box className={classes.container}>
        <IconButton onClick={handleNext} disabled={page >= availablePages}>
          <ChevronRight />
        </IconButton>
        <InputBase
          type='number'
          className={classes.input}
          value={pageState}
          onChange={(e) => setPageState(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (Number(pageState) > availablePages) {
                return;
              }
              handleUpdateQueryString({page: pageState});
            }
          }}
        />
        <IconButton onClick={handlePrev} disabled={!page || page === 1}>
          <ChevronLeft />
        </IconButton>
        <Typography span size={1.6} weight={500} color='#666e6e'>
          از {!!availablePages ? availablePages : " "}
        </Typography>
        <Typography size={1.8}>تعداد در هر صفحه:</Typography>
        <Typography size={1.8}>
          <IconButton className={classes.small} onClick={() => setShowPageSizeOptions(true)}>
            <ArrowDropDown />
          </IconButton>
          {pageSize}
        </Typography>
        {showPageSizeOptions && (
          <Grid className={classes.itemsBox} ref={optionsRef}>
            {options.map(({label, value}) => (
              <Typography
                key={value}
                className={clsx(classes.item, value === pageSize && classes.active)}
                size={1.8}
                onClick={() => {
                  setPageSize(value);
                  setShowPageSizeOptions(false);
                  handleUpdateQueryString({pageSize: String(value), page: "1"});
                }}
              >
                {label}
              </Typography>
            ))}
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default Pagination;

const useStyle = makeStyles((theme) => ({
  wrapper: {
    marginTop: "auto",
    height: 60,
  },
  container: {
    display: "inline-flex",
    position: "relative",
    alignItems: "center",
    "& > *": {
      color: "#757575",
      marginLeft: 20,
    },
    "& svg": {
      fontSize: 25,
    },
  },
  small: {
    width: 35,
    height: 35,
    marginRight: 4,
  },
  itemsBox: {
    display: "inline-block",
    borderRadius: 4,
    overflow: "hidden",
    backgroundColor: "white",
    position: "absolute",
    bottom: 10,
    right: 0,
    width: 50,
    zIndex: 1000,
    marginTop: 5,
    paddingBottom: 10,
    minHeight: 50,
    boxShadow: "0 2px 3px 0 #888, 0 1px 3px 0 #777",
  },
  item: {
    padding: "2px 10px ",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  active: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  input: {
    backgroundColor: "white",
    fontSize: 16 * fontNormalizeFactor,
    padding: 4,
    width: 40,
    height: 40,
    borderRadius: 8,
    border: "1px solid #ccd3d3",
    marginRight: 5,
    "& *": {
      textAlign: "center",
    },
  },
}));
