import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SearchInput, {ISearchInputProps} from "./SearchInput";
import {Box} from "@material-ui/core";
import Dropdown from "./Dropdown";
import {localStMediaTypeHistoryGet, localStMediaTypeHistorySet} from "./utils";
import {palette} from "@constant/colors";
import {useTranslation} from "react-i18next";
import {searchBarHeightBig, searchBarHeightSmall} from "./constant";
import {useUpdateQueryString} from "@hook/qs/useUpdateQueryString";
import {useGetQueryString} from "@hook/qs/useGetQueryString";
import {routes} from "@constant/routes";

interface ISearchResult {
  search: string;
  type: string;
}
export type ImageType = "Photo" | "Illustration" | "Vector" | "";
export interface ISearchBarProps {
  updateUrl?: boolean;
  searchWithDropdownChange?: boolean;
  initializeDropdownFromHistory?: boolean;
  storeDropdownValueInHistory?: boolean;
  onSearch?: (result: ISearchResult) => void;
  resultNumber?: number;
  opacity?: ISearchInputProps["opacity"];
  badges?: ISearchInputProps["badges"];
}
const SearchBar = (props: ISearchBarProps) => {
  const {
    onSearch,
    updateUrl = true,
    searchWithDropdownChange = true,
    initializeDropdownFromHistory = true,
    storeDropdownValueInHistory = true,
    resultNumber,
    opacity,
    badges,
  } = props;
  const classes = useStyle();
  const {search, type} = useGetQueryString();
  const {t} = useTranslation("modules.searchBar");
  const [textInputValue, setTextInputValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState<ImageType>(() => {
    const mediaTypeHistory = localStMediaTypeHistoryGet();
    if (!!type) {
      return type as ImageType;
    } else {
      if (initializeDropdownFromHistory && mediaTypeHistory) {
        return mediaTypeHistory;
      } else {
        return "";
      }
    }
  });

  const handleUpdateQueryString = useUpdateQueryString();

  React.useEffect(() => {
    search !== undefined && setTextInputValue(search);
  }, [search]);

  const handleTextInputChange = (value: string) => setTextInputValue(value);

  const handleSearch = (tValue: string, dValue = dropdownValue) => {
    updateUrl && handleUpdateQueryString({search: tValue, type: dValue}, routes.landing.search);
    storeDropdownValueInHistory && localStMediaTypeHistorySet(dValue);
    onSearch?.({search: tValue, type: dValue});
  };

  const handleDropdownChange = (value: ImageType) => {
    setDropdownValue(value);
    searchWithDropdownChange && handleSearch(textInputValue, value);
  };

  const items: {value: ImageType; label: string}[] = [
    {value: "", label: "همه"},
    {value: "Photo", label: t("photos")},
    {value: "Vector", label: t("vectors")},
    {value: "Illustration", label: t("illustrations")},
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.fullSize}>
        <SearchInput
          value={textInputValue}
          onChange={handleTextInputChange}
          onSearch={handleSearch}
          opacity={opacity}
          badges={badges}
        />
      </Box>
      <Box className={classes.flexShrink}>
        <Dropdown
          value={dropdownValue}
          onChange={handleDropdownChange}
          resultNumber={resultNumber}
          items={items}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: searchBarHeightBig,
    display: "flex",
    borderBottom: `1.6px solid ${palette.lightGrey.A300}`,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("xs")]: {
      height: searchBarHeightSmall,
    },
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
  flexShrink: {
    flexShrink: 0,
    height: "100%",
    // pulling dropdown items box 1px up to prevent unexpected dropdown collapse
    "& > * > *:nth-child(2)": {
      marginTop: "-1px",
    },
    [theme.breakpoints.down(700)]: {
      width: 130,
    },
    [theme.breakpoints.down(500)]: {
      width: 90,
    },
  },
}));
