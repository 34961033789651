import React from "react";
import {makeStyles, ButtonProps, Button} from "@material-ui/core";
import {palette} from "@constant/colors";
import {fontNormalizeFactor} from "@elements/Typography/constant";

interface IProps extends Omit<ButtonProps, "variant"> {}

export function TextButton(props: IProps) {
  const classes = useStyles();

  return (
    <Button className={classes.button} classes={{label: classes.label}} variant='text' {...props} />
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    "& *": {
      padding: 0,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:hover > *": {
      color: palette.green[500],
    },
  },
  label: {
    color: palette.green[200],
    fontSize: 16 * fontNormalizeFactor,
  },
}));
