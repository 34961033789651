import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, IconButton} from "@material-ui/core";
import Typography from "@elements/Typography";
import {palette} from "@constant/colors";
import Checkbox from "@elements/Checkbox";
import Span from "@elements/Span";
import clsx from "clsx";
import {Close} from "@material-ui/icons";
import Tooltip from "@elements/Tooltip";
import {Link, LinkProps} from "react-router-dom";
import {useTranslation} from "react-i18next";

export interface IBoardImageCardProps {
  src: string;
  collection: string;
  id: number;
  idCode: number;
  to: LinkProps["to"];
  checked?: boolean;
  onChecked?: (checked: boolean, id: number) => void;
  onRemove?: (id: number) => void;
  disabledRemoveButton?: boolean;
  userBought: boolean;
}

const BoardImageCard = (props: IBoardImageCardProps) => {
  const classes = useStyle();
  const {
    src,
    collection,
    id,
    idCode,
    to,
    onRemove,
    disabledRemoveButton,
    checked,
    onChecked,
    userBought,
  } = props;
  const {t} = useTranslation("pages.boards.[id]");
  return (
    <Box className={classes.card}>
      <Box className={classes.imageBox}>
        <Link to={to} style={{display: "flex", width: "100%"}}>
          <Grid component='img' src={src} alt='' className={classes.image} />
        </Link>
      </Box>
      <Box className={classes.details}>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          className={clsx(classes.padding, classes.border)}
        >
          <Typography span color={palette.lightGrey[200]}>
            #{idCode}
          </Typography>
          {userBought && <Typography color={palette.lightGrey[200]}>خریداری‌ شده</Typography>}
        </Grid>
        <Grid container justify='space-between' alignItems='center' className={classes.padding}>
          <Span className={classes.checkboxBox}>
            <Checkbox
              activeColor={palette.darkGrey.A300}
              checked={checked}
              onChange={(checked) => onChecked?.(checked, id)}
            />
          </Span>
          <Typography span color={palette.lightGrey[200]}>
            {collection}
          </Typography>
        </Grid>
      </Box>
      <Tooltip title={t("boardImageCard.removeFromBoard")}>
        <IconButton
          className={classes.removeIcon}
          onClick={() => onRemove?.(id)}
          disabled={disabledRemoveButton}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default BoardImageCard;

const useStyle = makeStyles(() => ({
  card: {
    border: "solid 1px white",
    marginLeft: "-1px",
    marginTop: "-1px",
    position: "relative",
    "&:hover $removeIcon": {
      display: "block",
    },
  },
  imageBox: {
    padding: "1.3rem",
    height: "30rem",
    display: "flex",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto auto",
  },
  details: {
    backgroundColor: palette.lightGrey.A800,
    padding: "1rem 1.5rem 0",
    opacity: 0.97,
  },
  padding: {
    padding: "1rem 0",
  },
  border: {
    borderBottom: "1px solid rgba(255,255,255,0.2)",
  },
  checkboxBox: {
    backgroundColor: "white",
    width: "19px",
    height: "19px",
    display: "flex",
    alignItems: "center",
  },
  removeIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "2.8rem",
    height: "2.8rem",
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0.5)",
    opacity: 0.8,
    borderRadius: 2,
    padding: ".1rem",
    display: "none",
    color: "#aaa",
    "& svg": {
      fontSize: 20,
    },
    "&:hover ": {
      backgroundColor: "rgba(0,0,0,0.5)",
      color: palette.lightGrey[200],
    },
  },
}));
