import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Grid, useMediaQuery} from "@material-ui/core";
import Typography from "@elements/Typography";
import {darkGrey, lightGrey} from "@constant/colors";
import clsx from "clsx";

export interface IAccountTableProps {
  titles: string[];
  rows: JSX.Element[][];
}

const AccountTable = (props: IAccountTableProps) => {
  const classes = useStyle();
  const {titles, rows} = props;
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down(1000));
  const width = `${100 / titles.length}%`;

  return (
    <Box component='table' className={classes.container}>
      {!xs ? (
        <>
          <Grid component='thead' className={classes.header}>
            <Grid container component='tr' className={classes.wFull}>
              {titles.map((item) => (
                <Grid key={item} item component='th' className={classes.th} style={{width: width}}>
                  <Typography color='inherit'>{item}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid component='tbody'>
            {rows.map((row, index) => (
              <Grid key={index.toString()} container component='tr' className={classes.tr}>
                {row.map((item, index) => (
                  <Grid item key={index.toString()} component='td' className={classes.td} style={{width: width}}>
                    {item}
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Grid component='tbody'>
          {rows.map((row, index) => (
            <Grid
              container
              direction='column'
              key={index.toString()}
              component='tr'
              className={clsx(classes.tr, classes.trMobileSize)}
            >
              {row.map((item, index) => (
                <Grid container key={index.toString()} component='td' className={classes.tdMobileSize}>
                  <Typography span className={classes.titleMobileSize}>
                    {titles[index]}
                  </Typography>
                  {item}
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default AccountTable;

const useStyle = makeStyles(() => ({
  container: {
    width: "100%",
  },
  header: {
    backgroundColor: darkGrey.A200,
  },
  th: {
    color: "white",
    padding: "1.5rem 1rem",
    textAlign: "center",
  },
  tr: {
    border: `1px solid ${lightGrey.A300}`,
    borderTop: "none",
    backgroundColor: "white",
    "&:nth-child(even)": {
      backgroundColor: lightGrey[200],
    },
  },
  td: {
    padding: "1.5rem 1rem",
    textAlign: "center",
  },
  wFull: {
    width: "100%",
  },
  trMobileSize: {
    padding: "2rem 0",
    border: `1px solid ${lightGrey.A300}`,
    "&:not(:first-of-type)": {
      borderTop: "none",
    },
  },
  titleMobileSize: {
    width: "50%",
    paddingRight: "2rem",
  },
  tdMobileSize: {
    padding: "1rem",
  },
}));
