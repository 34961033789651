/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactUsMessage } from '../servermodel';
// @ts-ignore
import { InlineResponse20010 } from '../servermodel';
/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesCreate: async (data: ContactUsMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('messagesCreate', 'data', data)
            const localVarPath = `/messages/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesDelete', 'id', id)
            const localVarPath = `/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isSeen] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesList: async (fromDaysBefore?: number, isSeen?: string, order?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/messages/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (isSeen !== undefined) {
                localVarQueryParameter['is_seen'] = isSeen;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesPartialUpdate: async (id: number, data: ContactUsMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('messagesPartialUpdate', 'data', data)
            const localVarPath = `/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesRead', 'id', id)
            const localVarPath = `/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesUpdate: async (id: number, data: ContactUsMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('messagesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('messagesUpdate', 'data', data)
            const localVarPath = `/messages/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesCreate(data: ContactUsMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactUsMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isSeen] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesList(fromDaysBefore?: number, isSeen?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesList(fromDaysBefore, isSeen, order, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesPartialUpdate(id: number, data: ContactUsMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactUsMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactUsMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesUpdate(id: number, data: ContactUsMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactUsMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesCreate(data: ContactUsMessage, options?: any): AxiosPromise<ContactUsMessage> {
            return localVarFp.messagesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.messagesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isSeen] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesList(fromDaysBefore?: number, isSeen?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.messagesList(fromDaysBefore, isSeen, order, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesPartialUpdate(id: number, data: ContactUsMessage, options?: any): AxiosPromise<ContactUsMessage> {
            return localVarFp.messagesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesRead(id: number, options?: any): AxiosPromise<ContactUsMessage> {
            return localVarFp.messagesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this contact us message.
         * @param {ContactUsMessage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesUpdate(id: number, data: ContactUsMessage, options?: any): AxiosPromise<ContactUsMessage> {
            return localVarFp.messagesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for messagesCreate operation in MessagesApi.
 * @export
 * @interface MessagesApiMessagesCreateRequest
 */
export interface MessagesApiMessagesCreateRequest {
    /**
     * 
     * @type {ContactUsMessage}
     * @memberof MessagesApiMessagesCreate
     */
    readonly data: ContactUsMessage
}

/**
 * Request parameters for messagesDelete operation in MessagesApi.
 * @export
 * @interface MessagesApiMessagesDeleteRequest
 */
export interface MessagesApiMessagesDeleteRequest {
    /**
     * A unique integer value identifying this contact us message.
     * @type {number}
     * @memberof MessagesApiMessagesDelete
     */
    readonly id: number
}

/**
 * Request parameters for messagesList operation in MessagesApi.
 * @export
 * @interface MessagesApiMessagesListRequest
 */
export interface MessagesApiMessagesListRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiMessagesList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof MessagesApiMessagesList
     */
    readonly isSeen?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiMessagesList
     */
    readonly order?: string

    /**
     * A search term.
     * @type {string}
     * @memberof MessagesApiMessagesList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MessagesApiMessagesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MessagesApiMessagesList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for messagesPartialUpdate operation in MessagesApi.
 * @export
 * @interface MessagesApiMessagesPartialUpdateRequest
 */
export interface MessagesApiMessagesPartialUpdateRequest {
    /**
     * A unique integer value identifying this contact us message.
     * @type {number}
     * @memberof MessagesApiMessagesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ContactUsMessage}
     * @memberof MessagesApiMessagesPartialUpdate
     */
    readonly data: ContactUsMessage
}

/**
 * Request parameters for messagesRead operation in MessagesApi.
 * @export
 * @interface MessagesApiMessagesReadRequest
 */
export interface MessagesApiMessagesReadRequest {
    /**
     * A unique integer value identifying this contact us message.
     * @type {number}
     * @memberof MessagesApiMessagesRead
     */
    readonly id: number
}

/**
 * Request parameters for messagesUpdate operation in MessagesApi.
 * @export
 * @interface MessagesApiMessagesUpdateRequest
 */
export interface MessagesApiMessagesUpdateRequest {
    /**
     * A unique integer value identifying this contact us message.
     * @type {number}
     * @memberof MessagesApiMessagesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ContactUsMessage}
     * @memberof MessagesApiMessagesUpdate
     */
    readonly data: ContactUsMessage
}

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {MessagesApiMessagesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public messagesCreate(requestParameters: MessagesApiMessagesCreateRequest, options?: any) {
        return MessagesApiFp(this.configuration).messagesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiMessagesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public messagesDelete(requestParameters: MessagesApiMessagesDeleteRequest, options?: any) {
        return MessagesApiFp(this.configuration).messagesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiMessagesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public messagesList(requestParameters: MessagesApiMessagesListRequest = {}, options?: any) {
        return MessagesApiFp(this.configuration).messagesList(requestParameters.fromDaysBefore, requestParameters.isSeen, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiMessagesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public messagesPartialUpdate(requestParameters: MessagesApiMessagesPartialUpdateRequest, options?: any) {
        return MessagesApiFp(this.configuration).messagesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiMessagesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public messagesRead(requestParameters: MessagesApiMessagesReadRequest, options?: any) {
        return MessagesApiFp(this.configuration).messagesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagesApiMessagesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public messagesUpdate(requestParameters: MessagesApiMessagesUpdateRequest, options?: any) {
        return MessagesApiFp(this.configuration).messagesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
