import React from "react";
import ImageCard, {ImageCardProps} from "@elements/ImageCard";
import {Link} from "react-router-dom";

export default function ImageWrapper({
  children,
  style,
  imageCardProps,
}: {
  children: React.ReactNode;
  style: React.CSSProperties;
  imageCardProps: ImageCardProps;
}) {
  const [isInitialized, setIsInitialized] = React.useState(false);

  return (
    <div
      onMouseEnter={() => imageCardProps.deviceHasMouseSupport && setIsInitialized(true)}
      onClick={() => !imageCardProps.deviceHasMouseSupport && setIsInitialized(true)}
      style={{position: "relative", ...style}}
    >
      <Link to={imageCardProps.to || "#"} onClick={(e) => e.preventDefault()}>
        {children}
      </Link>
      {isInitialized && (
        <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}>
          <ImageCard {...imageCardProps} />
        </div>
      )}
    </div>
  );
}
