/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Blog } from '../servermodel';
// @ts-ignore
import { InlineResponse2001 } from '../servermodel';
/**
 * BlogsApi - axios parameter creator
 * @export
 */
export const BlogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsCreate: async (data: Blog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('blogsCreate', 'data', data)
            const localVarPath = `/blogs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blogsDelete', 'id', id)
            const localVarPath = `/blogs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsList: async (page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/blogs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsPartialUpdate: async (id: number, data: Blog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blogsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('blogsPartialUpdate', 'data', data)
            const localVarPath = `/blogs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blogsRead', 'id', id)
            const localVarPath = `/blogs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsUpdate: async (id: number, data: Blog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blogsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('blogsUpdate', 'data', data)
            const localVarPath = `/blogs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogsApi - functional programming interface
 * @export
 */
export const BlogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsCreate(data: Blog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Blog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsList(page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsList(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsPartialUpdate(id: number, data: Blog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Blog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Blog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsUpdate(id: number, data: Blog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Blog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlogsApi - factory interface
 * @export
 */
export const BlogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogsApiFp(configuration)
    return {
        /**
         * 
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsCreate(data: Blog, options?: any): AxiosPromise<Blog> {
            return localVarFp.blogsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.blogsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsList(page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.blogsList(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsPartialUpdate(id: number, data: Blog, options?: any): AxiosPromise<Blog> {
            return localVarFp.blogsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsRead(id: number, options?: any): AxiosPromise<Blog> {
            return localVarFp.blogsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this blog.
         * @param {Blog} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsUpdate(id: number, data: Blog, options?: any): AxiosPromise<Blog> {
            return localVarFp.blogsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for blogsCreate operation in BlogsApi.
 * @export
 * @interface BlogsApiBlogsCreateRequest
 */
export interface BlogsApiBlogsCreateRequest {
    /**
     * 
     * @type {Blog}
     * @memberof BlogsApiBlogsCreate
     */
    readonly data: Blog
}

/**
 * Request parameters for blogsDelete operation in BlogsApi.
 * @export
 * @interface BlogsApiBlogsDeleteRequest
 */
export interface BlogsApiBlogsDeleteRequest {
    /**
     * A unique integer value identifying this blog.
     * @type {number}
     * @memberof BlogsApiBlogsDelete
     */
    readonly id: number
}

/**
 * Request parameters for blogsList operation in BlogsApi.
 * @export
 * @interface BlogsApiBlogsListRequest
 */
export interface BlogsApiBlogsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BlogsApiBlogsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BlogsApiBlogsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for blogsPartialUpdate operation in BlogsApi.
 * @export
 * @interface BlogsApiBlogsPartialUpdateRequest
 */
export interface BlogsApiBlogsPartialUpdateRequest {
    /**
     * A unique integer value identifying this blog.
     * @type {number}
     * @memberof BlogsApiBlogsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Blog}
     * @memberof BlogsApiBlogsPartialUpdate
     */
    readonly data: Blog
}

/**
 * Request parameters for blogsRead operation in BlogsApi.
 * @export
 * @interface BlogsApiBlogsReadRequest
 */
export interface BlogsApiBlogsReadRequest {
    /**
     * A unique integer value identifying this blog.
     * @type {number}
     * @memberof BlogsApiBlogsRead
     */
    readonly id: number
}

/**
 * Request parameters for blogsUpdate operation in BlogsApi.
 * @export
 * @interface BlogsApiBlogsUpdateRequest
 */
export interface BlogsApiBlogsUpdateRequest {
    /**
     * A unique integer value identifying this blog.
     * @type {number}
     * @memberof BlogsApiBlogsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Blog}
     * @memberof BlogsApiBlogsUpdate
     */
    readonly data: Blog
}

/**
 * BlogsApi - object-oriented interface
 * @export
 * @class BlogsApi
 * @extends {BaseAPI}
 */
export class BlogsApi extends BaseAPI {
    /**
     * 
     * @param {BlogsApiBlogsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsCreate(requestParameters: BlogsApiBlogsCreateRequest, options?: any) {
        return BlogsApiFp(this.configuration).blogsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogsApiBlogsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsDelete(requestParameters: BlogsApiBlogsDeleteRequest, options?: any) {
        return BlogsApiFp(this.configuration).blogsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogsApiBlogsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsList(requestParameters: BlogsApiBlogsListRequest = {}, options?: any) {
        return BlogsApiFp(this.configuration).blogsList(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogsApiBlogsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsPartialUpdate(requestParameters: BlogsApiBlogsPartialUpdateRequest, options?: any) {
        return BlogsApiFp(this.configuration).blogsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogsApiBlogsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsRead(requestParameters: BlogsApiBlogsReadRequest, options?: any) {
        return BlogsApiFp(this.configuration).blogsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlogsApiBlogsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsUpdate(requestParameters: BlogsApiBlogsUpdateRequest, options?: any) {
        return BlogsApiFp(this.configuration).blogsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
