/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PlanInvoice } from './plan-invoice';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * الزامی. 150 کاراکتر یا کمتر. فقط شامل حروف، اعداد، و علامات @/./+/-/_
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mobile_number?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: UserRoleEnum;
    /**
     * 
     * @type {Array<PlanInvoice>}
     * @memberof User
     */
    plans?: Array<PlanInvoice>;
    /**
     * نشان می‌دهد که آیا این کاربر اجازهٔ فعالیت دارد یا خیر. به جای حذف کاربر این تیک را بردارید.
     * @type {boolean}
     * @memberof User
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    company_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    debit?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRoleEnum {
    Developer = 'developer',
    Owner = 'owner',
    Admin = 'admin',
    Support = 'support',
    Operator = 'operator',
    Editor = 'editor',
    Customer = 'customer'
}



