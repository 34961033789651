/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Category } from '../servermodel';
// @ts-ignore
import { InlineResponse2004 } from '../servermodel';
/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCreate: async (data: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoriesCreate', 'data', data)
            const localVarPath = `/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesDelete', 'id', id)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesList: async (search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPartialUpdate: async (id: number, data: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoriesPartialUpdate', 'data', data)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesRead', 'id', id)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesUpdate: async (id: number, data: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoriesUpdate', 'data', data)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCreate(data: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesList(search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesList(search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPartialUpdate(id: number, data: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesUpdate(id: number, data: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCreate(data: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.categoriesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesList(search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.categoriesList(search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPartialUpdate(id: number, data: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesRead(id: number, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this category.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesUpdate(id: number, data: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoriesCreate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCreateRequest
 */
export interface CategoriesApiCategoriesCreateRequest {
    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesCreate
     */
    readonly data: Category
}

/**
 * Request parameters for categoriesDelete operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesDeleteRequest
 */
export interface CategoriesApiCategoriesDeleteRequest {
    /**
     * A unique integer value identifying this category.
     * @type {number}
     * @memberof CategoriesApiCategoriesDelete
     */
    readonly id: number
}

/**
 * Request parameters for categoriesList operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesListRequest
 */
export interface CategoriesApiCategoriesListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof CategoriesApiCategoriesList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CategoriesApiCategoriesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CategoriesApiCategoriesList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for categoriesPartialUpdate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesPartialUpdateRequest
 */
export interface CategoriesApiCategoriesPartialUpdateRequest {
    /**
     * A unique integer value identifying this category.
     * @type {number}
     * @memberof CategoriesApiCategoriesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesPartialUpdate
     */
    readonly data: Category
}

/**
 * Request parameters for categoriesRead operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesReadRequest
 */
export interface CategoriesApiCategoriesReadRequest {
    /**
     * A unique integer value identifying this category.
     * @type {number}
     * @memberof CategoriesApiCategoriesRead
     */
    readonly id: number
}

/**
 * Request parameters for categoriesUpdate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesUpdateRequest
 */
export interface CategoriesApiCategoriesUpdateRequest {
    /**
     * A unique integer value identifying this category.
     * @type {number}
     * @memberof CategoriesApiCategoriesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesUpdate
     */
    readonly data: Category
}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {CategoriesApiCategoriesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCreate(requestParameters: CategoriesApiCategoriesCreateRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesDelete(requestParameters: CategoriesApiCategoriesDeleteRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesList(requestParameters: CategoriesApiCategoriesListRequest = {}, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesList(requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesPartialUpdate(requestParameters: CategoriesApiCategoriesPartialUpdateRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesRead(requestParameters: CategoriesApiCategoriesReadRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesUpdate(requestParameters: CategoriesApiCategoriesUpdateRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
