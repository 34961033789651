/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse20016 } from '../servermodel';
// @ts-ignore
import { Tag } from '../servermodel';
/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsCreate: async (data: Tag, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('tagsCreate', 'data', data)
            const localVarPath = `/tags/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsDelete', 'id', id)
            const localVarPath = `/tags/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [similarTags] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsList: async (fromDaysBefore?: number, order?: string, similarTags?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (similarTags !== undefined) {
                localVarQueryParameter['similar_tags'] = similarTags;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsPartialUpdate: async (id: number, data: Tag, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('tagsPartialUpdate', 'data', data)
            const localVarPath = `/tags/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsRead', 'id', id)
            const localVarPath = `/tags/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsUpdate: async (id: number, data: Tag, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tagsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('tagsUpdate', 'data', data)
            const localVarPath = `/tags/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsCreate(data: Tag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [similarTags] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsList(fromDaysBefore?: number, order?: string, similarTags?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsList(fromDaysBefore, order, similarTags, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsPartialUpdate(id: number, data: Tag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsUpdate(id: number, data: Tag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsCreate(data: Tag, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.tagsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [order] 
         * @param {string} [similarTags] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsList(fromDaysBefore?: number, order?: string, similarTags?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.tagsList(fromDaysBefore, order, similarTags, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsPartialUpdate(id: number, data: Tag, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsRead(id: number, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tag.
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsUpdate(id: number, data: Tag, options?: any): AxiosPromise<Tag> {
            return localVarFp.tagsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tagsCreate operation in TagsApi.
 * @export
 * @interface TagsApiTagsCreateRequest
 */
export interface TagsApiTagsCreateRequest {
    /**
     * 
     * @type {Tag}
     * @memberof TagsApiTagsCreate
     */
    readonly data: Tag
}

/**
 * Request parameters for tagsDelete operation in TagsApi.
 * @export
 * @interface TagsApiTagsDeleteRequest
 */
export interface TagsApiTagsDeleteRequest {
    /**
     * A unique integer value identifying this tag.
     * @type {number}
     * @memberof TagsApiTagsDelete
     */
    readonly id: number
}

/**
 * Request parameters for tagsList operation in TagsApi.
 * @export
 * @interface TagsApiTagsListRequest
 */
export interface TagsApiTagsListRequest {
    /**
     * 
     * @type {number}
     * @memberof TagsApiTagsList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof TagsApiTagsList
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TagsApiTagsList
     */
    readonly similarTags?: string

    /**
     * A search term.
     * @type {string}
     * @memberof TagsApiTagsList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof TagsApiTagsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof TagsApiTagsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for tagsPartialUpdate operation in TagsApi.
 * @export
 * @interface TagsApiTagsPartialUpdateRequest
 */
export interface TagsApiTagsPartialUpdateRequest {
    /**
     * A unique integer value identifying this tag.
     * @type {number}
     * @memberof TagsApiTagsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Tag}
     * @memberof TagsApiTagsPartialUpdate
     */
    readonly data: Tag
}

/**
 * Request parameters for tagsRead operation in TagsApi.
 * @export
 * @interface TagsApiTagsReadRequest
 */
export interface TagsApiTagsReadRequest {
    /**
     * A unique integer value identifying this tag.
     * @type {number}
     * @memberof TagsApiTagsRead
     */
    readonly id: number
}

/**
 * Request parameters for tagsUpdate operation in TagsApi.
 * @export
 * @interface TagsApiTagsUpdateRequest
 */
export interface TagsApiTagsUpdateRequest {
    /**
     * A unique integer value identifying this tag.
     * @type {number}
     * @memberof TagsApiTagsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Tag}
     * @memberof TagsApiTagsUpdate
     */
    readonly data: Tag
}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * 
     * @param {TagsApiTagsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsCreate(requestParameters: TagsApiTagsCreateRequest, options?: any) {
        return TagsApiFp(this.configuration).tagsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiTagsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsDelete(requestParameters: TagsApiTagsDeleteRequest, options?: any) {
        return TagsApiFp(this.configuration).tagsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiTagsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsList(requestParameters: TagsApiTagsListRequest = {}, options?: any) {
        return TagsApiFp(this.configuration).tagsList(requestParameters.fromDaysBefore, requestParameters.order, requestParameters.similarTags, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiTagsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsPartialUpdate(requestParameters: TagsApiTagsPartialUpdateRequest, options?: any) {
        return TagsApiFp(this.configuration).tagsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiTagsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsRead(requestParameters: TagsApiTagsReadRequest, options?: any) {
        return TagsApiFp(this.configuration).tagsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagsApiTagsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsUpdate(requestParameters: TagsApiTagsUpdateRequest, options?: any) {
        return TagsApiFp(this.configuration).tagsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
