import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {fontNormalizeFactor} from "@elements/Typography/constant";
import {Edit} from "@material-ui/icons";

export interface ICategoryCardProps {
  title: string;
  image: string;
  description: string;
  id: number;
  onClickEdit?: (id: number) => void;
}

const CategoryCard = (props: ICategoryCardProps) => {
  const classes = useStyle();
  const {title, image, description, id, onClickEdit} = props;
  const {t} = useTranslation("elements");

  return (
    <Box className={classes.container}>
      <Box className={classes.imageBox}>
        <img src={image} alt='' className={classes.image} />
      </Box>
      <Box className={classes.padding}>
        <Typography size={2} className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.descriptionBox}>
          {/* <Shiitake lines={3}> */}
          <Typography size={1.5} weight={500}>
            {description}
          </Typography>
          {/* </Shiitake> */}
        </Box>
        <Button
          color='primary'
          variant='contained'
          fullWidth
          className={classes.button}
          onClick={() => onClickEdit?.(id)}
        >
          {t("panel.common.edit")}
          <Edit />
        </Button>
      </Box>
    </Box>
  );
};

export default CategoryCard;

const useStyle = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,.2)",
    boxShadow: "0 4px 4px 0 #aaa",
  },
  imageBox: {
    height: 160,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #ddd",
    overflow: "hidden",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 4,
  },
  title: {
    marginBottom: 12,
    fontWeight: 500,
  },
  button: {
    height: 40,
    // backgroundColor: "#E3F2FD",
    marginTop: "auto",
    border: "none",
    borderRadius: 2,
    boxShadow: "0 1px 5px 0 #ccc, 0 2px 3px 0 #ddd",
    "& *": {
      fontSize: 15 * fontNormalizeFactor,
    },
    "& svg": {
      marginLeft: 8,
    },
  },
  descriptionBox: {
    paddingBottom: 30,
    // "& *": {
    //   fontSize: 18 * fontNormalizeFactor,
    // },
  },
  padding: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: "15px 15px 21.5px 15px",
  },
}));
