import React from "react";

export default function CheckInternetConnection() {
  const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);

  React.useEffect(() => {
    const setOnline = () => setIsOnline(true);
    const setOffline = () => setIsOnline(false);
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    if (!isOnline) {
      window.alert("دستگاه شما به اینترنت دسترسی ندارد");
    }

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, [isOnline]);

  return null;
}
