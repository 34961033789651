import qs from 'qs';
import { useLocation } from 'react-router-dom';

/**
 * 
 * @param param has been set to location.search by default 
 */
export const useGetQueryString = (param?: string) => {
	const { search } = useLocation();
	return qs.parse(param || search, { ignoreQueryPrefix: true });
};