/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminImageInvoice } from '../servermodel';
// @ts-ignore
import { ImageInvoice } from '../servermodel';
// @ts-ignore
import { InlineResponse2007 } from '../servermodel';
// @ts-ignore
import { InvoiceTransaction } from '../servermodel';
/**
 * ImageInvoicesApi - axios parameter creator
 * @export
 */
export const ImageInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesAdminCreate: async (data: AdminImageInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imageInvoicesAdminCreate', 'data', data)
            const localVarPath = `/image-invoices/admin_create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesCreate: async (data: ImageInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imageInvoicesCreate', 'data', data)
            const localVarPath = `/image-invoices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imageInvoicesDelete', 'id', id)
            const localVarPath = `/image-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [isPaid] 
         * @param {string} [isExpired] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesList: async (fromDaysBefore?: number, isActive?: string, isPaid?: string, isExpired?: string, order?: string, search?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/image-invoices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDaysBefore !== undefined) {
                localVarQueryParameter['from_days_before'] = fromDaysBefore;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (isPaid !== undefined) {
                localVarQueryParameter['is_paid'] = isPaid;
            }

            if (isExpired !== undefined) {
                localVarQueryParameter['is_expired'] = isExpired;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesPartialUpdate: async (id: string, data: AdminImageInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imageInvoicesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imageInvoicesPartialUpdate', 'data', data)
            const localVarPath = `/image-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceTransaction} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesPay: async (id: string, data: InvoiceTransaction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imageInvoicesPay', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imageInvoicesPay', 'data', data)
            const localVarPath = `/image-invoices/{id}/pay/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imageInvoicesRead', 'id', id)
            const localVarPath = `/image-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesUpdate: async (id: string, data: AdminImageInvoice, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imageInvoicesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imageInvoicesUpdate', 'data', data)
            const localVarPath = `/image-invoices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageInvoicesApi - functional programming interface
 * @export
 */
export const ImageInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesAdminCreate(data: AdminImageInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminImageInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesAdminCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesCreate(data: ImageInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [isPaid] 
         * @param {string} [isExpired] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesList(fromDaysBefore?: number, isActive?: string, isPaid?: string, isExpired?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesList(fromDaysBefore, isActive, isPaid, isExpired, order, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesPartialUpdate(id: string, data: AdminImageInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminImageInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceTransaction} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesPay(id: string, data: InvoiceTransaction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesPay(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageInvoicesUpdate(id: string, data: AdminImageInvoice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminImageInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageInvoicesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageInvoicesApi - factory interface
 * @export
 */
export const ImageInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesAdminCreate(data: AdminImageInvoice, options?: any): AxiosPromise<AdminImageInvoice> {
            return localVarFp.imageInvoicesAdminCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesCreate(data: ImageInvoice, options?: any): AxiosPromise<ImageInvoice> {
            return localVarFp.imageInvoicesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.imageInvoicesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [fromDaysBefore] 
         * @param {string} [isActive] 
         * @param {string} [isPaid] 
         * @param {string} [isExpired] 
         * @param {string} [order] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesList(fromDaysBefore?: number, isActive?: string, isPaid?: string, isExpired?: string, order?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.imageInvoicesList(fromDaysBefore, isActive, isPaid, isExpired, order, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesPartialUpdate(id: string, data: AdminImageInvoice, options?: any): AxiosPromise<AdminImageInvoice> {
            return localVarFp.imageInvoicesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InvoiceTransaction} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesPay(id: string, data: InvoiceTransaction, options?: any): AxiosPromise<InvoiceTransaction> {
            return localVarFp.imageInvoicesPay(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesRead(id: string, options?: any): AxiosPromise<ImageInvoice> {
            return localVarFp.imageInvoicesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AdminImageInvoice} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageInvoicesUpdate(id: string, data: AdminImageInvoice, options?: any): AxiosPromise<AdminImageInvoice> {
            return localVarFp.imageInvoicesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for imageInvoicesAdminCreate operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesAdminCreateRequest
 */
export interface ImageInvoicesApiImageInvoicesAdminCreateRequest {
    /**
     * 
     * @type {AdminImageInvoice}
     * @memberof ImageInvoicesApiImageInvoicesAdminCreate
     */
    readonly data: AdminImageInvoice
}

/**
 * Request parameters for imageInvoicesCreate operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesCreateRequest
 */
export interface ImageInvoicesApiImageInvoicesCreateRequest {
    /**
     * 
     * @type {ImageInvoice}
     * @memberof ImageInvoicesApiImageInvoicesCreate
     */
    readonly data: ImageInvoice
}

/**
 * Request parameters for imageInvoicesDelete operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesDeleteRequest
 */
export interface ImageInvoicesApiImageInvoicesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesDelete
     */
    readonly id: string
}

/**
 * Request parameters for imageInvoicesList operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesListRequest
 */
export interface ImageInvoicesApiImageInvoicesListRequest {
    /**
     * 
     * @type {number}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly fromDaysBefore?: number

    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly isActive?: string

    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly isPaid?: string

    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly isExpired?: string

    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly order?: string

    /**
     * A search term.
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ImageInvoicesApiImageInvoicesList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for imageInvoicesPartialUpdate operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesPartialUpdateRequest
 */
export interface ImageInvoicesApiImageInvoicesPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesPartialUpdate
     */
    readonly id: string

    /**
     * 
     * @type {AdminImageInvoice}
     * @memberof ImageInvoicesApiImageInvoicesPartialUpdate
     */
    readonly data: AdminImageInvoice
}

/**
 * Request parameters for imageInvoicesPay operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesPayRequest
 */
export interface ImageInvoicesApiImageInvoicesPayRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesPay
     */
    readonly id: string

    /**
     * 
     * @type {InvoiceTransaction}
     * @memberof ImageInvoicesApiImageInvoicesPay
     */
    readonly data: InvoiceTransaction
}

/**
 * Request parameters for imageInvoicesRead operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesReadRequest
 */
export interface ImageInvoicesApiImageInvoicesReadRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesRead
     */
    readonly id: string
}

/**
 * Request parameters for imageInvoicesUpdate operation in ImageInvoicesApi.
 * @export
 * @interface ImageInvoicesApiImageInvoicesUpdateRequest
 */
export interface ImageInvoicesApiImageInvoicesUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ImageInvoicesApiImageInvoicesUpdate
     */
    readonly id: string

    /**
     * 
     * @type {AdminImageInvoice}
     * @memberof ImageInvoicesApiImageInvoicesUpdate
     */
    readonly data: AdminImageInvoice
}

/**
 * ImageInvoicesApi - object-oriented interface
 * @export
 * @class ImageInvoicesApi
 * @extends {BaseAPI}
 */
export class ImageInvoicesApi extends BaseAPI {
    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesAdminCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesAdminCreate(requestParameters: ImageInvoicesApiImageInvoicesAdminCreateRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesAdminCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesCreate(requestParameters: ImageInvoicesApiImageInvoicesCreateRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesDelete(requestParameters: ImageInvoicesApiImageInvoicesDeleteRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesList(requestParameters: ImageInvoicesApiImageInvoicesListRequest = {}, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesList(requestParameters.fromDaysBefore, requestParameters.isActive, requestParameters.isPaid, requestParameters.isExpired, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesPartialUpdate(requestParameters: ImageInvoicesApiImageInvoicesPartialUpdateRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesPayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesPay(requestParameters: ImageInvoicesApiImageInvoicesPayRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesPay(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesRead(requestParameters: ImageInvoicesApiImageInvoicesReadRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImageInvoicesApiImageInvoicesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageInvoicesApi
     */
    public imageInvoicesUpdate(requestParameters: ImageInvoicesApiImageInvoicesUpdateRequest, options?: any) {
        return ImageInvoicesApiFp(this.configuration).imageInvoicesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
