import {PrivacyAndPolicyApi, PrivacyAndPolicyApiPrivacyAndPolicyListRequest} from "@schema";
import {axiosInstance} from "services/axios/axios";
import {createQuery, makeApi} from "./types";

const privacyAndPolicyApiInstance = new PrivacyAndPolicyApi(undefined, undefined, axiosInstance);

export const privacyAndPolicyAPI = makeApi({
  identifier: "privacyAndPolicy",
  Query: {
    getList: createQuery(async (params: PrivacyAndPolicyApiPrivacyAndPolicyListRequest) => {
      const {data} = await privacyAndPolicyApiInstance.privacyAndPolicyList(params);
      return data;
    }),
  },
  Mutation: {},
});
