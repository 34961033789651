import {ApiNamespaces} from "@api";
import {useExperimentalMutation} from "@hook/react-query/useMutation";
import {useExperimentalQuery} from "@hook/react-query/useQuery";
import useServerErrorNotify from "@hook/useServerErrorNotify";
import {User} from "@schema";
import React from "react";
import {useQueryClient} from "react-query";
import {AuthContext} from ".";

export default function AuthContextProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) {
  const [user, setUser] = React.useState<null | User>(null);
  const queryClient = useQueryClient();
  const serverErrorNotify = useServerErrorNotify();

  console.log("USER: ", user);

  const login = (params?: User) => {
    if (params) {
      setUser(params);
    } else {
      queryClient.invalidateQueries(ApiNamespaces.auth.Query.getUser);
    }
  };

  const {mutate: mutateLogout} = useExperimentalMutation("auth", "logout");
  const logout = () => {
    mutateLogout({
      onSuccess: () => setUser(null),
    });
  };
  const {status} = useExperimentalQuery("auth", "getUser", {
    variables: {},
    onSuccess: (result) => setUser(result),
    onError: (error) => serverErrorNotify(error),
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const loadedUser = status === "success" || status === "error";

  return (
    <AuthContext.Provider value={{login, logout, user, loadedUser}}>
      {children}
    </AuthContext.Provider>
  );
}
