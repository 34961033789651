import {Box, BoxProps} from "@material-ui/core";
import React from "react";

export const Span: React.FC<BoxProps> = (props) => {
  const {children, ...rest} = props;
  return (
    <Box component='span' {...rest}>
      {children}
    </Box>
  );
};

export default Span;
