import {User} from "@schema";
import React from "react";

export const AuthContext = React.createContext<AuthContextSchema>({
  logout: () => {},
  login: () => {},
  user: null,
  loadedUser: false,
});

export const useAuth = () => React.useContext(AuthContext);

export interface AuthContextSchema {
  login: (params?: User) => void;
  logout: () => void;
  user: null | User;
  loadedUser: boolean;
}
