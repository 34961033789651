/* tslint:disable */
/* eslint-disable */
/**
 * Image Bank API
 * Test description
 *
 * The version of the OpenAPI document: v1
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AboutUsPage } from '../servermodel';
// @ts-ignore
import { InlineResponse200 } from '../servermodel';
/**
 * AboutUsApi - axios parameter creator
 * @export
 */
export const AboutUsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsCreate: async (data: AboutUsPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aboutUsCreate', 'data', data)
            const localVarPath = `/about-us/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aboutUsDelete', 'id', id)
            const localVarPath = `/about-us/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsList: async (page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/about-us/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsPartialUpdate: async (id: number, data: AboutUsPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aboutUsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aboutUsPartialUpdate', 'data', data)
            const localVarPath = `/about-us/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aboutUsRead', 'id', id)
            const localVarPath = `/about-us/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsUpdate: async (id: number, data: AboutUsPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aboutUsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aboutUsUpdate', 'data', data)
            const localVarPath = `/about-us/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AboutUsApi - functional programming interface
 * @export
 */
export const AboutUsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AboutUsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutUsCreate(data: AboutUsPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutUsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutUsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutUsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutUsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutUsList(page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutUsList(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutUsPartialUpdate(id: number, data: AboutUsPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutUsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutUsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutUsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutUsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutUsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutUsUpdate(id: number, data: AboutUsPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutUsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutUsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AboutUsApi - factory interface
 * @export
 */
export const AboutUsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AboutUsApiFp(configuration)
    return {
        /**
         * 
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsCreate(data: AboutUsPage, options?: any): AxiosPromise<AboutUsPage> {
            return localVarFp.aboutUsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.aboutUsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsList(page?: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.aboutUsList(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsPartialUpdate(id: number, data: AboutUsPage, options?: any): AxiosPromise<AboutUsPage> {
            return localVarFp.aboutUsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsRead(id: number, options?: any): AxiosPromise<AboutUsPage> {
            return localVarFp.aboutUsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this about us page.
         * @param {AboutUsPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutUsUpdate(id: number, data: AboutUsPage, options?: any): AxiosPromise<AboutUsPage> {
            return localVarFp.aboutUsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aboutUsCreate operation in AboutUsApi.
 * @export
 * @interface AboutUsApiAboutUsCreateRequest
 */
export interface AboutUsApiAboutUsCreateRequest {
    /**
     * 
     * @type {AboutUsPage}
     * @memberof AboutUsApiAboutUsCreate
     */
    readonly data: AboutUsPage
}

/**
 * Request parameters for aboutUsDelete operation in AboutUsApi.
 * @export
 * @interface AboutUsApiAboutUsDeleteRequest
 */
export interface AboutUsApiAboutUsDeleteRequest {
    /**
     * A unique integer value identifying this about us page.
     * @type {number}
     * @memberof AboutUsApiAboutUsDelete
     */
    readonly id: number
}

/**
 * Request parameters for aboutUsList operation in AboutUsApi.
 * @export
 * @interface AboutUsApiAboutUsListRequest
 */
export interface AboutUsApiAboutUsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof AboutUsApiAboutUsList
     */
    readonly page?: number
}

/**
 * Request parameters for aboutUsPartialUpdate operation in AboutUsApi.
 * @export
 * @interface AboutUsApiAboutUsPartialUpdateRequest
 */
export interface AboutUsApiAboutUsPartialUpdateRequest {
    /**
     * A unique integer value identifying this about us page.
     * @type {number}
     * @memberof AboutUsApiAboutUsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AboutUsPage}
     * @memberof AboutUsApiAboutUsPartialUpdate
     */
    readonly data: AboutUsPage
}

/**
 * Request parameters for aboutUsRead operation in AboutUsApi.
 * @export
 * @interface AboutUsApiAboutUsReadRequest
 */
export interface AboutUsApiAboutUsReadRequest {
    /**
     * A unique integer value identifying this about us page.
     * @type {number}
     * @memberof AboutUsApiAboutUsRead
     */
    readonly id: number
}

/**
 * Request parameters for aboutUsUpdate operation in AboutUsApi.
 * @export
 * @interface AboutUsApiAboutUsUpdateRequest
 */
export interface AboutUsApiAboutUsUpdateRequest {
    /**
     * A unique integer value identifying this about us page.
     * @type {number}
     * @memberof AboutUsApiAboutUsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AboutUsPage}
     * @memberof AboutUsApiAboutUsUpdate
     */
    readonly data: AboutUsPage
}

/**
 * AboutUsApi - object-oriented interface
 * @export
 * @class AboutUsApi
 * @extends {BaseAPI}
 */
export class AboutUsApi extends BaseAPI {
    /**
     * 
     * @param {AboutUsApiAboutUsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutUsApi
     */
    public aboutUsCreate(requestParameters: AboutUsApiAboutUsCreateRequest, options?: any) {
        return AboutUsApiFp(this.configuration).aboutUsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AboutUsApiAboutUsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutUsApi
     */
    public aboutUsDelete(requestParameters: AboutUsApiAboutUsDeleteRequest, options?: any) {
        return AboutUsApiFp(this.configuration).aboutUsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AboutUsApiAboutUsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutUsApi
     */
    public aboutUsList(requestParameters: AboutUsApiAboutUsListRequest = {}, options?: any) {
        return AboutUsApiFp(this.configuration).aboutUsList(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AboutUsApiAboutUsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutUsApi
     */
    public aboutUsPartialUpdate(requestParameters: AboutUsApiAboutUsPartialUpdateRequest, options?: any) {
        return AboutUsApiFp(this.configuration).aboutUsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AboutUsApiAboutUsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutUsApi
     */
    public aboutUsRead(requestParameters: AboutUsApiAboutUsReadRequest, options?: any) {
        return AboutUsApiFp(this.configuration).aboutUsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AboutUsApiAboutUsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutUsApi
     */
    public aboutUsUpdate(requestParameters: AboutUsApiAboutUsUpdateRequest, options?: any) {
        return AboutUsApiFp(this.configuration).aboutUsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
