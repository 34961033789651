import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import KeywordButton from "@elements/button/KeywordButton";
import {Box, Grid} from "@material-ui/core";
import Typography from "@elements/Typography";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {routes} from "@constant/routes";
import {localStMediaTypeHistoryGet} from "@modules/SearchBar/utils";
import {ThinListTag} from "@schema";

const KeywordsSection = ({tags}: {tags: ThinListTag[]}) => {
  const classes = useStyle();
  const {t} = useTranslation("modules.pages.images.id");
  const type = localStMediaTypeHistoryGet();

  return (
    <Box component='section' className={classes.container}>
      <Typography component='h2' size={1.8}>
        {t("keywordsSection.keywords")}
      </Typography>
      <Grid component='ul' container className={classes.keywordsBox} wrap='wrap'>
        {tags.map((item, index) => (
          <li key={item.name}>
            <Link
              to={`${routes.landing.search}?tags=${item.id}`.concat(type ? `&type=${type}` : "")}
            >
              <KeywordButton>
                {item.name_fa ? `(${item.name}) ${item.name_fa}` : item.name}
              </KeywordButton>
            </Link>
          </li>
        ))}
      </Grid>
    </Box>
  );
};

export default KeywordsSection;

const useStyle = makeStyles(() => ({
  container: {
    padding: "3rem 0 5rem",
  },
  keywordsBox: {
    gap: ".5rem",
    marginTop: "1.25rem",
  },
}));
